.avatarCard {
  box-shadow: inset 0 10em 0 var(--black-050);
  border: 1px solid var(--black-100);
  border-radius: 3px;
  padding: 12px;
  min-height: 265px;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    
  }
}

.username {
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  margin-top: 10px;
  text-align: center;
}

.created {
  margin-top: 10px;
  text-align: center;
  p {
    font-family: monospace;
  }

  span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-weight: bold;
    font-size: 14px;
  }
}
