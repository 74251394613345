.container {
  position: relative;
  width: 350px;
  background: white;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  margin-left: 10px;
  font-family: 'poppins-regular';

}

.tagContainer {
  position: sticky;
  top: 74px;
  display: flex;
  flex-direction: column;
  padding-left: 12px;

  h2 {
    color: var(--black-700);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 1em;
  }
}

.popularTags {
  display: grid;
  grid-template-columns: auto auto;
}


@media screen and (max-width: 768px){ 
  .container {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  
  }
  .tagContainer{
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding: 10px;
    margin-top: 0px !important;
    h2 {
      font-size: 14px;
    }
  }
} 