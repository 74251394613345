.share-wrappre{
  // min-width: 450px;
  height: 100%;
}
.share-container-new{
  // border-bottom: 3px solid #fbfcfc;
  height: 100%;
  overflow: auto;
}
.share{
    background-color: #fff;
    padding: 15px;
    // width: 100%;
    height: 100%;
    padding-bottom: 0;
    // resize: vertical;
    overflow: auto;
    
}

.share-form input {
  width: 100% !important;
  max-width: 250px;
}
.share-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
        width: 100%;
    input{
        width: 250px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #ddd;
        margin-right: 15px;
        font-size: 12px;
        font-family: 'poppins-Medium';
        padding-left: 10px;
        &::placeholder{
          padding-left: 15px;
          color: #ddd;
        }
    }
    button{
      background-color: #9BBCCD !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      color: white;
      font-family: 'Poppins-medium';
    }
}  

.container-share-check {
    display: block;
    // width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #3a5765;
    margin-right: 12px;
    font-weight: 600;
  }
  
  .container-share-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: #adb5bd solid 1px;
    border-radius: 4px;
  }
  
  .container-share-check:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .container-share-check input:checked ~ .checkmark {
    background-color: #007bff;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container-share-check input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container-share-check .checkmark:after {
    left:5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

.share-check-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.options-wrapper {
  width: 100%;
  margin-right: 20px;
  border-bottom: 2px solid #E8ECF5;
  padding: 15px 0;
}
.share-lists {
  min-width: 100%;
  justify-content: space-between;
  width: 100%;
}
.share-container {
    display: block;
    width: 100%;
    height: 100%;
}

// .share-email-list {
//     display: flex;
//     flex-direction: column;
//     width: 50%;
// }
.share-email-heder{
  width: 100%;
  padding: 11px 0;
  text-align: left;
  font-family: 'poppins-Medium';
  font-size: 12px;
  color: #3a5765;
  font-weight: 600;
}
.share-email-item {
  background-color: #f2f2f2;
  padding: 2px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-width: 180px;
  position: relative;
  font-size: 13px;
  font-family: 'poppins-Medium';
  font-weight: 600;
  margin-bottom: 7px;
  display: inline-flex;
    width: 48%;
    margin: 6px 5px;
  .share-email-item-delete {
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #455E6C;
    cursor: pointer;
    border-radius: 50%;
    right: 4px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    &::before{
      content: '';
      position: absolute;
      width: 60%;
      height: 2px;
      background-color: white;
    }
    &::after{
      content: '';
      position: absolute;
      width: 2px;
      height: 60%;
      background-color: white;
    }
    
    &:hover{
      box-shadow: 3px 3px 6px 0px rgba(16,16,16,0.75);
    }
  }
  .item{
    white-space: nowrap;
    overflow: hidden;
    line-height: 29px;
    text-overflow: ellipsis;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .share-button-group {
    // width: 100vw !important;
    margin: 0px auto !important;
  }
}
@media (max-width: 400px) {
  .share-button-group {
    margin-left: 45px !important;
  }
}
.share-button-group {
  position: fixed;
  top: 7px;
  background-color: #fff;
  z-index: 101;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  // margin: 10px auto;
  margin-left: 200px;
  width: fit-content;
  button{
    i{
      margin-right: 10px;
    }
  }
  .share-button {
    @media (max-width: 450px) {
        padding: 0 5px;
      }
    padding: 0 15px 0 10px;
    height: 33px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #3a5765;
    font-size: 14px;
    background-color: white;
      color: #3a5765;
  }
  .active-share {
    background-color: #3a5765;
    color: #fff;
    border: 1px solid #3a5765;
  }



}
.share-files{
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid #E8ECF5;
  padding-bottom: 12px;
  a{
    padding: 3px 0;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #2C3E50;
    font-family: 'Poppins-medium';
    justify-content: space-between;
    div{
      font-family: 'Poppins-medium';
    }
  }
  svg{
    width: 20px;
    height: 14px;
  }
}
.resizeble {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  width: 100%;
  height: 10px;
  background-color: #71a1b7;
  cursor: row-resize;
  user-select: none;
}

.share-check-group {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  font-family: 'poppins-regular';
  width: 100%;
}

.by{
  font-size: 12px;
  font-weight: 600;
  span{
    font-size: 15px;
    font-family: 'Poppins-medium';
    color: #212529;
  }
}

// filters css
.filters-container {
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.filter_input {
  height: calc(2.25rem + 2px);
  border: 1px solid #ced4da;
  border-radius: 3px;
  width: 100%;
}

.filterTableDiv {
  height: 80%;
  overflow: auto;
  table thead tr th {
    font-weight: bold;
  }
}

.min-height-auto{
  min-height: auto !important;
}

.filterInputText{
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 12px;
}
