.container {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid var(--black-075);
  a{
    flex: 1;
    font-size: 15px;
    text-decoration: none;
    color: black;
    font-family: 'poppins-regular';
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.vote {
  text-align: center;
  color: var(--black-500);
  min-width: 36px;
  padding: 3px 6px;
  margin-right: 10px;
  border: 1px solid var(--black-300);
  border-radius: 2px;
}

.title {
  flex: 1;
  font-size: 15px;
  text-decoration: none;
  color: black;
  font-family: 'poppins-regular';
  &:hover{
    text-decoration: underline;
  }
}

.created {
  color: var(--black-300);
  margin-left: 6px;
  display: none;

  @media (--m) {
    display: block;
  }
}
