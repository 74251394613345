.blue-and-grey {
  background-color: rgb(113,160,184);
}

@font-face {
  font-family: 'Poppins-medium';
  src: url(../../assets/fonts/Poppins-Medium.ttf);
}

.authorization {
  width:calc(20% + 100px);
  padding: 20px;
  overflow: scroll;
  @media (max-width: 1100px) {
    width:calc(20% + 150px)
  }
  @media (max-width: 800px) {
    width:calc(18% + 200px)
  }

  &-logo {
    width:40px;
    height: auto;
  }

  &-title {
    font-size: 27px;
    padding-top: 10px;
    font-weight: 400;
    padding-left: 2px;
  }


  &-image {
    // background-image:url('./../../assets/images/auth_bg.png');
    background-image: url('./../../assets/images/landing/auth_bg_with_logo.png');
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }


  &-input {
    &::-webkit-input-placeholder {
      color:white !important;
    }

    &:focus {
      box-shadow: none !important;
      border:none;
    }
  }
}

.authorization-image {
  overflow: hidden;
}

#authImgText {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
}
.box-login {
  box-shadow: 0px 0px 15px #455E6C26;
  padding: 15px;
  border-radius: 10px;
}
.title-login {
  font-size: 18px;
  color: #48606D;
  padding-top: 0.6rem;
  font-family: 'Poppins-regular';
  padding-bottom: 10px;
}

input.input-login {
  height: 45px;
  width: 100%;
  background-color: #f9f9f9;
  border: none;
  border-radius: 10px;
  padding-left: 40px;
  font-size: 14px;
  font-family: 'poppins-regular';
}

select.input-login {
  height: 45px;
  width: 100%;
  background-color: #f9f9f9;
  border: none;
  border-radius: 10px;
  padding-left: 40px;
  font-size: 14px;
  font-family: 'poppins-regular';
}

input.btn-login-login {
  background: #455E6C;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-family: 'poppins-Medium';
  margin-top: 20px;
}
.bg-grey-blue
{
  background-color: #9bbccd !important;
}

.bottom-flex h3 {
  font-family: 'poppins-Medium';
  color: #fff;
  // font-size: 30px;
}

.bottom-flex p {
  opacity: 0.5;
  padding-top: 10px;
  color: #fff;
  // padding-bottom: 2rem;
  font-family: 'poppins-regular';
  line-height: 28px;
  // font-size: 22px;
}

.bottom-flex {
  padding-left: 4rem;
  padding-top: 3rem;
  // border-top: 2px solid #f9f9f9;
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
}
.icon_login {
  position: relative;
}

.icon_login svg {
  position: absolute;
  left: 12px;
  top: 13px;
  width: 18px;
  height: 18px;
}
.icon_login i {
  position: absolute;
  left: 12px;
  top: 13px;
  width: 18px;
  height: 18px;
  color: #aeaeae;
  z-index: 1;
}
.support-footer a {
  color: #455E6C;
  font-family: 'poppins-Medium';
  padding-left: 14px;
  font-size: 14px;
}

.support-footer {
  padding-top: 1rem;
}

.support-footer svg path {fill: #455e6c;}

.custom-control-label
{
  font-size: 14px;
  color: #7A7A7A;
  font-family: 'poppins-regular';
  padding-top: 2px;
}