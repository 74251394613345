.container {
  position: relative;
  margin: 2px 0;
  width: 220px;
  height: 36px;
}

.input {
  width: 100%;
  height: 100%;
  padding: 7.5px 9px;
  border: 1px solid var(--black-200);
  border-radius: 10px;
  color: var(--black-900);
  font-size: 13px;
  line-height: 1.15;
  box-sizing: border-box;
  padding-left: 32px;

  &:focus {
    border-color: var(--blue-300);
    box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
    outline: 0;
  }

  &::placeholder {
    color: var(--black-200);
  }
}

.search {
  position: absolute;
  font-size: 18px;
  color: var(--black-200);
  left: 9px;
  top: 9px;
}

.fullWidth {
  width: 100%;
}

.ml24 {
  margin-left: 24px;
}

.isLoading {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    opacity: 0.3;
    right: 0.5em;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-color: var(--black-200);
    border-radius: 50%;
    top: 9.5px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0.5em;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-left-color: var(--black-200);
    border-radius: 50%;
    animation: spin 0.9s infinite cubic-bezier(0.5, 0.1, 0.5, 0.9);
    filter: invert(0);
    transform-origin: 50% 50% 1px;
    top: 9.5px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.paddingRight {
  padding-right: 27px;
}


@media screen and (max-width: 768px){ 
  .ml24 {
    margin-left: 12px;
  }
  .container {
    position: relative;
    margin: 2px 0;
    width: 93%;
    height: 32px;
  }
 }