.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 10px;

  @media (--m) {
    grid-template-columns: 1fr 110px;
  }
}

.button {
  max-height: 34px;
}

.status {
  color: var(--red-500);
  font-size: 12px;
}