.resize-panel {
  // border: 1px solid #f2f2f2;
  &-container {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.resizer {
  width:11px;
  background-color:#fbfcfc;
  position: relative;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index:20;
  margin-left: -5px;
  margin-right: -5px;
  // margin-left: -5px;
}