.file-input {
  display: none;
}
.md-form {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.desc-height{
  min-height: 190px;
  padding: 15px !important;
}
.file-input-btn{
  font-family: "poppins-regular";
    font-size: 14px !important;
    cursor: pointer !important;
    text-transform: inherit !important;
    padding: 5px;
    margin-bottom: 0;
}

.modalButtons{
  display: flex;
  justify-content: space-between;
  align-items: center;
}