@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
#root {
  // margin: 10px;
}
@for $i from 1 through 4 {
  .font-size-#{$i} {
    font-size: 10px + ($i * 4);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  max-width: 100vw;
  width: 100%;
  // overflow-x: hidden;
}

.disabled,
:disabled {
  pointer-events: none !important;
  filter: brightness(90%);
  cursor: all-scroll;
}

p {
  color: black;
}

.resize-horizontal {
  resize: horizontal;
}

.btn-fixed-size {
  width: 200px;
}

.w-49 {
  width: 49%;
}

* {
  outline: none;
}

.pl-8 {
  padding-left: 50px;
}

.pl-12 {
  padding-left: 100px;
}

.pt-10 {
  padding-top: 56px;
}
.pt-12 {
  padding-top: 75px;
  padding-bottom: 50px;
}
.min-h-100 {
  min-height: 100vh;
}
.w-95 {
  width: 95%;
}
.left-0 {
  left: 0;
}
.mdb-btn {
  text-transform: inherit !important;
  font-size: 13px !important;
}
.shadow-none {
  box-shadow: none !important;
}
.text-style-inherit {
  text-transform: inherit !important;
}
// new styles starts

button {
  border-radius: 5px !important;
}
.bg-bid {
  background-color: #3a5765 !important;
}
.border-bid {
  border: 1px solid #3a5765 !important;
}
.text-bid {
  color: #3a5765 !important;
}
.text-success {
  color: rgba(59, 135, 62, 1) !important;
}
.text-warning {
  color: rgba(255, 165, 0, 1) !important;
}
.text-danger {
  color: rgba(178, 34, 34, 1) !important;
}
.bg-success {
  background-color: #3b873e !important;
}
.bg-warning {
  background-color: rgb(255, 165, 0) !important;
}
.bg-danger {
  background-color: rgba(178, 34, 34, 1) !important;
}
.bg-light-bid {
  background-color: #e9f1f5;
}
.bg-light-success {
  background-color: #eefaea !important;
}
.bg-light-warning {
  background-color: #fff6e6 !important;
}
.bg-light-danger {
  background-color: #f8e9e9 !important;
}
.personal-area {
  position: relative;
  background-color: #f6f7fb;
}
.border-top-none {
  border-top: none !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.btn-tool-group {
  display: flex;
  height: 100%;
  // align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  button {
    background-color: #91bacb;
    min-height: 30px;
    height: 30px;
    padding: 0 8px;
    display: flex;
    border: none;
    margin-right: 8px;
    font-size: 13px;
    font-family: "roboto";
    color: white;
    align-items: center;
    letter-spacing: 0.15px;
    // height: 30px;
    svg {
      width: 15px;
      height: 15px;
      color: white;
      margin-right: 5px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.white-space-pre {
  white-space: pre;
}
.my-heading {
  font-size: 18px;
  font-family: "poppins-Medium";
  color: #000;
  margin: 0;
  letter-spacing: -0.5px;
  white-space: nowrap;
}
.projActionButtons {
  gap: 5px;
}
.search-bar {
  // height: 38px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 15px;
  width: 400px;
  max-width: 100%;
  min-height: 30px;
  height: 30px;
  // margin-right: 35px;
  background-image: url("../assets/images/icons/search.svg");
  background-position: 97% 50%;
  background-repeat: no-repeat;
  &::placeholder {
    color: #ddd;
    font-size: 15px;
  }
}
.bg-light-100 {
  background: #f9f9f9;
}
.inner-header-position {
  position: sticky;
  top: 60px;
  width: 100%;
  z-index: 9;
  background-image: linear-gradient(#f9f9f9, #fff);
  // border-bottom: 3px solid #ddd;
  margin-top: -2px;
  box-shadow: 0px 0px 7px #ddd;
}

@media only screen and (max-width: 768px) {
  .inner-header-position {
    top: 40px;
  }
}
.adminTable {
  width: 100%;
  margin: auto;
  height: 80vh;
  overflow: auto;
}
.card {
  font-weight: 400;
  border: 0;
  box-shadow: 0px 0px 15px #ddd !important;
  border-radius: 8px !important;
  overflow: hidden;
}
.card-footer {
  background: #fff !important;
}
.card-title {
  font-family: "poppins-Bold";
  font-size: 0.9rem;
  color: #3a5765;
  margin-bottom: 10px !important;
  text-transform: capitalize;
}
.card-text {
  font-size: 0.8rem !important;
  font-weight: 400;
  color: #3a5765;
  font-family: "poppins-Regular";
  .box {
    padding: 10px 0;
  }
}
.personal-area .container {
  max-width: 1260px !important;
}
.card-price {
  font-size: 12px;
  font-weight: 500;
  color: #747373;
  font-family: "poppins-Regular";
}
.capitalize {
  text-transform: capitalize;
}
.card-button {
  width: 50%;
  margin: 0 10px;
  font-family: "poppins-regular";
  font-size: 13px !important;
  height: 35px;
  padding: 0 !important;
}
.card-footer {
  border-top: none !important;
}
.refresh-btn {
  padding: 8px 15px !important;
  svg {
    margin-right: 8px;
  }
}
.historyCards {
  max-width: 1300px;
  margin: auto;
}
.timelines-page {
  background-color: #f6f7fb;
  min-height: 110vh;
}
.modal-dialog .modal-content {
  border-radius: 8px !important;
  padding: 10px 28px;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-family: "poppins-Medium";
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.modal-header {
  border: none !important;
}
.modal-header .close {
  padding: 0px 5px !important;
  margin: 0 0 0 auto !important;
  border: none !important;
}
.close:focus {
  outline: none !important;
}
.mdb-input {
  border: 1px solid #ddd !important;
  border-radius: 7px !important;
  padding-left: 34px !important;
}
.md-form.form-sm label {
  margin-left: 17px;
  font-weight: 600;
}
.md-form label {
  top: -3px !important;
}
.md-form label.active {
  background-color: white !important;
}
.radius-btn {
  border-radius: 8px;
}

.modal-side-table {
  width: 100%;
  table {
    width: 20%;
  }
}
.tree-item {
  position: relative;
}
.tree-item div {
  font-family: "poppins-medium" !important;
  font-size: 11px;
  border: 1px solid #ddd;
  margin: 0;
  height: 90%;
  border-radius: 5px;
  padding-left: 10px;
  background-color: #fbfbfb;
  margin-right: 10px;
  position: relative;
  width: 100%;
}
.tree-item i:last-child {
  position: absolute;
  right: 10px;
  background: #fbfbfb;
}
.grey.lighten-4 {
  background-color: #f9f9f9 !important;
}
.component-sign-label {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  i:first-child {
    font-size: 12px;
    padding: 5px 6px;
    color: white;
    border-radius: 50%;
  }
  i:last-child {
    font-size: 6px;
    padding: 0;
    position: absolute;
    top: -3px;
    border-radius: 50%;
    background-color: #fff;
    right: -5px;
    height: 15px;
    width: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bg-bid-success {
  background-color: #87d7d2 !important;
}
.bg-bid-danger {
  background-color: #fb9292 !important;
}
.bid-border-success {
  border: 3px solid #87d7d2;
}
.bid-border-danger {
  border: 3px solid #fb9292;
}
.bid-success-text {
  color: #87d7d2;
}
.bid-danger-text {
  color: #fb9292;
}
.bg-existing {
  background-color: #b871a0;
  border: 1px solid #b871a0;
}
.bg-demolition {
  background-color: #c5842f;
  border: 1px solid #c5842f;
}
.project-admin-header {
  font-family: "Poppins-medium";
  color: #2c3e50;
  letter-spacing: -1px;
  padding: 0 3px;
  // border-bottom: 1px solid lightgray;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 15px;
  &:first-child {
    // margin-right: unset; // 100px;
    margin-right: 10px;
  }
  img {
    margin-right: 10px;
    width: 20px;
  }
}
.delete-timeline-label {
  font-size: 20px;
  font-family: "poppins-Medium";
  text-align: center;
  min-height: auto !important;
}
.border-none {
  border: none !important;
}
.project-item-date {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  padding: 3px;
  color: white;
  background-color: #455e6c;
  font-size: 13px;
  font-family: "poppins-regular";
  padding: 3px 10px;
  box-shadow: 0px 0px 10px black;
}
.spinner-text {
  font-family: "poppins-regular";
  color: #3a5765;
  font-size: 12px;
}
.btnFlex {
  width: 46%;
  display: inline-block;
  margin: 5px !important;
  font-family: "poppins-regular";
  font-size: 12px !important;
  height: 35px;
  padding: 0 !important;
  border-radius: 8px !important;

  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
}

.spinner-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  z-index: 99999;
}

.dlt-btnFlex {
  width: 20%;
  display: inline-block;
  margin: 5px !important;
  font-family: "poppins-regular";
  font-size: 13px !important;
  height: 35px;
  padding: 0 !important;
  border-radius: 8px !important;
}
.expiration-box {
  border: none;
  li {
    border: 1px solid #ddd !important;
    border-radius: 6px !important;
    margin: 10px;
    height: 58px;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  li.active {
    background-color: #3a5765 !important;
    border: 1px solid #3a5765 !important;
    margin: 10px !important;
  }
}
.expiration-modal > .modal-content > .modal-body {
  min-height: 100px !important;
  padding-bottom: 0px !important;
}

.expiration-btn {
  height: 40px !important;
  margin: 10px !important;
}
.user-name-container {
  border: 1px solid #3a5765 !important;
  border-radius: 5px;
  padding: 3px 10px;
  height: 32px;
  display: flex;
  p {
    margin: 0;
    font-family: "poppins-regular";
    font-weight: 600;
    color: #3a5765;
    line-height: 1.2;
    font-size: 14px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      width: 15px;
    }
  }
}
.view-switch-container {
  padding: 0 10px;
  gap: 10px;
  & > div {
    &.active,
    &:hover {
      background: #455e6c;
      svg {
        path {
          fill: white;
        }
      }
      i {
        color: white;
      }
    }
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 3px;
    border: 1px solid #ddd;
  }
}
.head-filter-icon {
  cursor: pointer;
  height: 34px;
  width: 34px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  background: transparent !important;
}
.member-list {
  border: 1px solid #ddd !important;
  margin-bottom: 5px;
  border-radius: 10px !important;
  // display: flex !important;
  align-items: center;
  div {
    font-size: 15px !important;
  }
  span {
    font-size: 15px !important;
  }
}
.member-list.active {
  background-color: rgb(68, 93, 107) !important;
  color: white !important;
  div {
    font-size: 15px !important;
  }
  span {
    font-size: 15px !important;
  }
}
.user-projects {
  background-color: #f6f7fb;
}
.upsideDown-svg svg {
  transform: scaleX(-1);
}
.modal-body {
  min-height: 365px;
  overflow: auto;
  max-height: 550px;
}

.modal-body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #3a5765;
}
.modal-body::-webkit-scrollbar-thumb:hover {
  background: #283f4b;
}
.modal-body::-webkit-scrollbar-thumb:active {
  background: #283f4b;
}

// footer styles
.footer-desc {
  // font-size: 18px;
  color: #a2aeb5;
  font-style: italic;
  margin: 50px 0;
}
.footer-social-container {
  display: flex;
  align-items: center;
  img {
    width: 45px;
    margin-right: 10px;
  }
}

.footer-logo {
  width: 50px;
}
.logo-title-footer {
  font-size: 40px !important;
  font-family: "poppins-bold";
  color: #fff !important;
  padding-left: 6px;
  position: relative;
  bottom: 5px;

  span {
    font-family: "poppins-regular";
  }
}
.footer-head {
  font-family: "poppins-SemiBold";
  color: white;
  font-size: 20px;
}

.footer-links-div {
  a {
    display: block;
    font-size: 0.8em;
    color: #a2aeb5 !important;
    // font-size: 16px;
    font-family: "Poppins-medium";
    display: flex;
    &:hover {
      color: #a2aeb5;
      opacity: 0.8;
    }
    svg {
      color: #a2aeb5;
      margin-right: 10px;
      width: 25px;
      height: 21px;
      margin-top: 10px;
    }
  }
}

.all-right-reserve {
  display: block;
  color: #a2aeb5;
  font-size: 16px;
  font-family: "Poppins-medium";
  text-align: center;
  margin-top: 30px;
}

.footer-container {
  max-width: 100%;
  width: 100%;
  padding: 0 8%;
  margin-left: auto;
  margin-right: auto;
}

.overflowX-hidden {
  overflow-x: hidden;
}

// .table-action-btn{
//   background-color: #91bacb;
//   padding: 0 8px;
//   display: flex;
//   border: none;
//   margin-right: 8px;
//   font-size: 13px;
//   font-family: "poppins-Regular";
//   color: white;
//   align-items: center;
//   height: 30px;
// }
.btn-radius {
  border-radius: 5px !important;
}

.kanban-box {
  // padding-top: 10px;
  // width: 100%;
  // height: 84vh;
  // overflow: hidden;

  width: 100%;
  overflow: auto;
  height: 84vh;
}
.karban-container::-webkit-scrollbar {
  display: block;
}

.karban-container {
  // gap: 10px;
  // background-color: #fafafa;
  // padding: 20px;
  // overflow: scroll;
  // width: 100%;
  // min-height: 100%;
  // height: 100%;

  gap: 10px;
  background-color: #fafafa;
  padding: 20px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;

  .board-box {
    // min-width: 19.5%;
    // background-color: rgba(246, 246, 246, 1);
    // border-radius: 15px;
    // display: flex;
    // flex-direction: column;
    // padding: 15px;
    // padding-top: 18px;
    // height: fit-content;
    // min-height: 100%;
    // transition: 0.2s;

    min-width: 19.5%;
    background-color: rgb(246, 246, 246);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-top: 18px;
    min-height: 100%;
    transition: 0.2s;
    padding-top: 5px;
    &:hover {
      box-shadow: 0px 0px 10px #ddd;
      transition: 0.2s;
    }
    .board-box-header {
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 3px solid;
      font-size: 16px;
      font-weight: 500;
      font-family: "Poppins-medium";
      text-transform: capitalize;
      position: sticky;
      top: 0;
      background: rgb(246, 246, 246);
      height: 56px;
      z-index: 1;
      .color-dot {
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 100%;
      }
      .count {
        margin-left: auto;
        font-size: 12px;
        width: 24px;
        border-radius: 100%;
        background-color: #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
      }
    }
    .card-container {
      gap: 10px;
      flex-grow: 1;
      height: fit-content;
      // height: 100%;
    }
    .kanban-card {
      padding: 20px 16px;
      background-color: white;
      border-radius: 15px;
      .card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 14px;
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
          font-family: "Poppins-medium";
          width: 70%;
          white-space: pre;
          margin-bottom: 0;
          color: black;
        }
        .dots {
          width: 20px;
        }
      }
      &.dragging {
        transform: rotate(2.007deg);
        box-shadow: 8.00050163269043px 19.001190185546875px 51.003196716308594px
          0px #00000063;
      }
      .card-detail-container {
        margin-top: 14px;
        display: flex;
        // gap: 4px;
        flex-wrap: wrap;
        .item {
          width: 50%;
          margin-bottom: 4px;
          font-size: 12px;
          font-weight: 400;
          font-family: "Poppins-medium";
          color: rgba(114, 114, 114, 1);
          .d-icon {
            width: 16px;
          }
          & > div {
            color: #727272;
            text-align: center;
            background: rgba(250, 250, 250, 1);
            border-radius: 5px;
            padding: 8px 4px;
            margin: 2px;
            height: 100%;
          }
        }
      }
    }
  }
}

.chat-room-container {
  padding: 5px;
}
.chat-room-container.selected {
  background: rgba(145, 186, 203, 0.2);
  border-radius: 5px;
}
.chat-status {
  background-color: #3a5765;
  color: white !important;
}

.project-list-view-table {
  width: 100%;
  .users-profile-list {
    display: flex;
    align-items: center;
    .u-profile {
      height: 24px;
      width: 24px;
      margin-left: -13px;
      border: 1px solid white;
      border-radius: 50%;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }
  }
  tr {
    border: 1px solid #e2e2e2;
    background-color: white;
    border-right: none;
    border-left: none;
    .cost {
      border-radius: 20px;
      background-color: #f7faff;
      color: #2785ff;
      padding: 5px 10px;
    }
    td {
      &:first-child {
        padding-left: 24px;
      }
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    tr {
      border: none;
      border-bottom: 1px solid #ddd;
      th {
        border-bottom: none;
        background-color: #eaeaea;
        border-radius: 0px;
      }
    }
  }
}
thead {
  tr {
    th {
      cursor: pointer;
      &:first-child {
        padding-left: 24px;
      }
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #667085;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.card-placeholder {
  background-color: red;
}

// profile css
.profile-sidebar {
  border: 1px solid rgba(234, 236, 240, 1);
  background-color: white;
  display: block;
  width: 310px;
  position: fixed;
  padding: 20px;
  border-radius: 10px;
  padding-right: 0;
  min-height: 79vh;
  // box-shadow: 0px 0px 5px #ddd;
  p {
    font-size: 18px;
    font-weight: 700;
    color: rgba(58, 87, 101, 1);
  }
  button,
  a {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 500;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    text-align: left;
    line-height: 1.5;
    padding-left: 10px;
    text-decoration: none;
    svg {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }
  }
  .ActiveTab {
    background-color: rgba(145, 186, 203, 0.2);
    color: #283f4b;
    width: 97%;
    svg {
      fill: #283f4b;
    }
  }
}

.profile-content {
  padding: 20px;
  border: 1px solid rgba(234, 236, 240, 1);
  background-color: white;
  margin-left: 320px;
  border-radius: 10px;
  // box-shadow: 0px 0px 5px #ddd;
  width: 100%;
  min-height: 79vh;
  overflow-x: hidden;
}
.content-bg {
  background-color: #fafafa;
}

.profile-upload-box {
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin: 15px 0;
  p {
    margin: 0;
  }
}
.pricingDropdown {
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid #e2e2e2;
  border-radius: 4px !important;
  width: 100%;
  color: rgba(29, 29, 29, 1) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto" !important ;
  font-weight: 400 !important;
  padding: 8px 8px !important;
  margin: 0 !important;
}
.tabledropdown {
  background-color: transparent !important;
  box-shadow: none;
  border: unset !important;
  width: 100%;
  color: rgba(29, 29, 29, 1) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto" !important ;
  font-weight: 400 !important;
  padding: 10px 8px !important;
  margin: 0 !important;

  .truncated {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85%;
    vertical-align: middle;
    white-space: nowrap;
  }
}
.tabledropdown:not([disabled]):not(.disabled):active,
.tabledropdown:not([disabled]):not(.disabled).active,
.show > .tabledropdown.dropdown-toggle {
  background-color: #f0f2f3 !important;
}
.newCatalogSelect {
  height: 38px;
}
.newCatalogInput {
  border: 1px solid !important;
}
.dropdownList {
  .lable {
    top: -9px;
    left: 17px;
    background-color: #fff;
    z-index: 2;
    font-size: 12px;
    color: #9c9c9c;
    line-height: 18px;
    font-weight: 400;
    font-family: Roboto;
  }
  .dropdown-menu {
    // height:300px !important;
    height: auto;
    max-height: 300px;
    border: 1px solid #e2e2e2 !important;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
    border-radius: 8px !important;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none !important;
    }
    .dropdown-item:not([disabled]):not(.disabled):active,
    .dropdown-item:not([disabled]):not(.disabled).active,
    .show > .dropdown-item.dropdown-toggle {
      background-color: #f0f2f3 !important;
      color: rgba(29, 29, 29, 1) !important;
    }
  }
  .optionItems {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
  }
  .dropdownItems {
    // display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    width: 100%;
    text-align: start;
  }
  .truncatedText {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    vertical-align: middle;
  }
}

.dropdownListItems {
  @extend .lable;
  .dropdown-menu {
    // height:300px !important;
    height: auto;
    max-height: 300px;
    border: 1px solid #e2e2e2 !important;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
    border-radius: 8px !important;
    .dropdown-item:not([disabled]):not(.disabled):active,
    .dropdown-item:not([disabled]):not(.disabled).active,
    .show > .dropdown-item.dropdown-toggle {
      background-color: #f0f2f3 !important;
      color: rgba(29, 29, 29, 1) !important;
    }
  }
  .dropDownInput {
    padding: 0px 12px 10px 12px;
  }
  .btnDropdown {
    padding: 10px 0px 0px 3px;
    background-color: transparent !important;
    border: unset;
    color: rgba(29, 29, 29, 1) !important;
    font-weight: bold;
    .fas {
      font-weight: 600 !important;
    }
  }
  .list {
    height: auto;
    max-height: 200px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .optionItems {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
  }
  .dropdownItems {
    // display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    width: 100%;
    text-align: start;
  }
}
.bg-bid:not([disabled]):not(.disabled):active,
.bg-bid:not([disabled]):not(.disabled).active,
.show > .bg-bid.dropdown-toggle {
  background-color: #3a5765 !important;
}
.pricingDropdown:not([disabled]):not(.disabled):active,
.pricingDropdown:not([disabled]):not(.disabled).active,
.show > .pricingDropdown.dropdown-toggle {
  background-color: #f0f2f3 !important;
}
.revitDropdown {
  width: 33%;

  .dropdowntoggle {
    white-space: nowrap;
  }
}

.dataNotFounnd {
  position: absolute;
  left: 50%;
  top: 65%;
}

.catalogbtn {
  width: 33%;
  .btn {
    padding: 10px 8px !important;
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    width: 100%;
    margin: 0;
  }
  .btn:hover {
    color: #fff !important;
  }
  .dropdown-item {
    white-space: break-spaces !important;
    font-size: 12px !important;
  }
  .dropdown-menu {
    border: 1px solid #e2e2e2 !important;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
    border-radius: 8px !important;
    .dropdown-item:not([disabled]):not(.disabled):active,
    .dropdown-item:not([disabled]):not(.disabled).active,
    .show > .dropdown-item.dropdown-toggle {
      background-color: #f4f9fa !important;
      color: rgba(29, 29, 29, 1) !important;
    }
  }
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px !important;
  padding: 8px !important;
  a {
    color: rgba(29, 29, 29, 1) !important;
    font-weight: 500;
    font-size: 14px;
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}

.pricindDatabaseMain {
  position: fixed;
  z-index: 100;
  width: 100%;
}

.pricing-table-container {
  height: 600px;
  overflow-y: auto;
}

.pricing-table-container::-webkit-scrollbar {
  display: none;
}

.input-placeholder {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.placholder-main {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.placeholder {
  background: #fff !important;

  position: absolute;
  left: 15px !important;
}
.placeholder span {
  color: red;
  position: absolute;
  font-size: 17px !important;
}
.dataTable {
  margin-top: 24px;
  .lowestUnitCost {
    text-align: center !important;
    border-bottom: 3px solid rgba(107, 208, 111, 1);
    overflow: hidden;
  }
  .highestUnitCost {
    border-bottom: 3px solid rgba(178, 34, 34, 0.5);
    text-align: center !important;
    overflow: hidden;
  }
  .secondHighestUnitCost {
    border-bottom: 3px solid rgb(240, 231, 47);
    text-align: center !important;
    overflow: hidden;
  }
  .middleUnitCost {
    border-bottom: 3px solid rgb(9, 9, 234);
    text-align: center !important;
    overflow: hidden;
  }
  .unitCountInput {
    width: 70px;
    .form-control {
      border: 1px solid #d0d5dd !important;
      border-radius: 4px !important;
      height: 30px !important;
      padding: 5px 0px !important;
      max-width: 112px;
      width: 100%;
      background: #eee;
      font-weight: 600;
    }
  }
  .numberInput {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .company {
    color: #9c9c9c !important;
  }

  .defaultCompany {
    color: #3a5765 !important;
  }

  th {
    padding: 5px 12px !important;
    color: rgba(102, 112, 133, 1);
    font-size: 12px;
    font-weight: 400;
    font-family: "poppins-Regular";
  }
  td {
    font-size: 16px;
    font-weight: 400;
    color: #101828;
    border-right: 2px solid #ededed;
    border-top: 2px solid #ededed;
    font-family: "poppins-Regular";
    display: table-cell;
    vertical-align: middle;
    // padding:  0px 12px !important;
  }
  th:nth-child(2) {
    width: 15%;
  }
  th:nth-child(6) {
    width: 7%;
  }
  th:nth-child(7) {
    width: 7%;
  }
  th:nth-child(8) {
    width: 7%;
  }
  .Div {
    font-family: "poppins-Regular";
  }
}

.help-btn {
  background-color: #f0f2f3 !important;
  box-shadow: none;
  border: 1px solid rgba(58, 87, 101, 1);
  width: 100%;
  color: rgba(58, 87, 101, 1) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto" !important ;
  border-radius: 12px !important;
  font-weight: 400 !important;
  padding: 8px 0 !important;
}

.help-btn:not([disabled]):not(.disabled):active,
.help-btn:not([disabled]):not(.disabled).active,
.show > .help-btn.dropdown-toggle {
  background-color: #f0f2f3 !important;
}

.inter {
  font-family: "Inter", sans-serif;
}
.poppins-medium {
  font-family: "Poppins-medium";
}

.card-body-help {
  background-color: white;
  color: black;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #ddd;
  font-size: 14px;
  font-weight: 500;
  padding: 20px 25px 10px 25px;
  margin-top: 2px;
}
.help-icons {
  width: 24px !important;
  margin-right: 20px !important;
}
.plan-container {
  border-radius: 15px;
  width: 100%;
  border: 1px solid rgba(234, 236, 240, 1);
  overflow: hidden;
  .plan-header {
    background-color: #f4f9fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    img {
      width: 40px;
    }
    p {
      font-size: 16px;
      font-weight: 600 !important;
      margin: 0;
      color: black;
      margin-left: 10px;
      font-family: "Inter", sans-serif;
    }
  }
  .plan-content {
    padding: 10px 10px 10px 15px;
    .price {
      font-size: 30px;
      font-weight: 600 !important;
      margin-bottom: 0;
      font-family: "Inter", sans-serif;
      color: black;
      span {
        font-size: 14px;
        color: rgba(71, 84, 103, 1);
        font-family: "Inter", sans-serif;
        font-weight: 500 !important;
      }
    }
    .limited {
      color: rgb(2, 122, 72);
      background-color: #ecfdf3;
      border-radius: 6px;
      padding: 2px 12px;
      margin: 0;
      font-size: 12px;
      font-weight: 500 !important;
      font-family: "Inter", sans-serif;
    }
  }
}

.rounded-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid grey;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  accent-color: rgba(58, 87, 101, 1);
}

.squared-checkbox {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 6px;
  vertical-align: middle;
  border: 1px solid grey;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.squared-checkbox:checked {
  appearance: auto;
  clip-path: circle(60% at 50% 50%);
  accent-color: rgba(58, 87, 101, 1);
}

.small-squared-checkbox {
  width: 14px;
  height: 14px;
  border-radius: 6px;
  vertical-align: middle;
  border: 1px solid grey;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.small-squared-checkbox:checked {
  appearance: auto;
  clip-path: circle(60% at 50% 50%);
  accent-color: rgba(58, 87, 101, 1);
}
.w-10 {
  width: 10px !important;
}
.w-15 {
  width: 15px !important;
}
.w-40px {
  width: 40px;
}
.w-50 {
  width: 50%;
}
.rounded-5 {
  border-radius: 20px;
}
.rounded-4 {
  border-radius: 16px;
}
.rounded-3 {
  border-radius: 12px;
}
.rounded-2 {
  border-radius: 8px;
}
.rounded-1 {
  border-radius: 4px;
}
.leading-1 {
  line-height: 1;
}

.left-arrow-img {
  width: 8px;
}
.delete-modal {
  .modal-content {
    border-radius: 12px !important;
    padding: 0px 5px !important;
    text-align: left !important;
    width: 85% !important;
    .modal-body {
      min-height: auto !important;
    }
    .modal-header {
      align-items: center;
    }
    .delete-modal-label-head {
      font-size: 18px;
      font-family: "Inter", sans-serif;
      font-weight: 600 !important;
      color: black;
      margin-bottom: 5px;
    }
    .delete-modal-label {
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: 400;
      color: rgba(71, 84, 103, 1);
      margin-bottom: 0;
    }
    .delete-modal-btn {
      border-radius: 8px !important;
      padding: 8px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      text-transform: capitalize;
      box-shadow: none;
    }
    .cancel-confirm {
      border: 1px solid rgba(16, 24, 40, 0.5);
      color: rgba(52, 64, 84, 1);
      background-color: white;
      margin-right: 15px;
    }
    .delete-confirm {
      background-color: rgba(217, 45, 32, 1);
      color: white;
      border: 1px solid rgba(217, 45, 32, 1);
      margin-left: 15px;
    }
    input {
      border-radius: 8px;
    }
  }
}

.font-weight-500 {
  font-weight: 500 !important;
}
.form-group-div {
  label {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: rgba(52, 64, 84, 1);
    width: 100%;
  }
  input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8px 15px;
  }
  select {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8px 15px;
  }
  .form-check-label {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: black;
    width: 100%;
    font-family: "Inter", sans-serif;
  }
  .form-check {
    margin-left: 20px;
  }
}
.rounded-box {
  border-radius: 8px;
}
.poppins-regular {
  font-family: "poppins-Regular";
}
.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .inner-loader-container {
    position: relative;
    width: 10rem;
    height: 10rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      width: 60px;
      padding: 12px;
      margin: 10px auto;
      -webkit-animation: breathing 5s ease-out infinite normal;
      animation: breathing 2s ease-out infinite normal;
      font-family: "Lantinghei SC";
      font-size: 16px;
      color: #fff;
      -webkit-font-smoothing: antialiased;
      border-radius: 2px;
      text-align: center;
    }
    .spinner-border {
      width: 10rem !important;
      height: 10rem !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @-webkit-keyframes breathing {
      0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
      }

      25% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      60% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
      }

      100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
      }
    }

    @keyframes breathing {
      0% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
      }

      25% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }

      60% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
      }

      100% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
      }
    }
  }
}
.health-cards {
  display: flex;
  box-shadow: 0px 4px 13.1px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: #fff;
  padding: 5px;
  .linked-projects {
    width: 24%;
    padding: 10px;
    border-right: 1px solid #ddd;
    padding-right: 0;
  }

  .scrollable-div {
    overflow-y: auto;
    width: 100%;
    height: 108px;
    padding-right: 15px;
  }
}
.number-count {
  border-radius: 50%;
  margin: 0;
  width: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins-medium";
}
.rule-count {
  border-radius: 3px;
  margin: 0;
  width: auto;
  height: auto;
  font-size: 12px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-family: "Poppins-medium";
}
.title-icon {
  width: 32px;
}
.bid-table {
  width: 100%;
  // border: 1px solid #ddd;
  padding: 5px;
  border-top: 1px solid #ddd;
  border-radius: 15px;
  overflow: auto;
  thead {
    border-bottom: 1px solid #ddd;
    th {
      color: rgba(102, 112, 133, 1);
      font-size: 11px;
      font-weight: 400;
      padding: 10px 10px;
      background-color: #eaeaea;
      border-radius: 0;

      &:first-child {
        padding-left: 10px;
      }
    }
  }
  tbody,
  tfoot {
    td {
      font-size: 12px;
      font-weight: 400;
      color: #101828;
      padding: 10px 10px;
    }
  }
}

.health-result-status {
  width: fit-content !important;
  border-radius: 10px !important;
}
.action-icon {
  width: 20px;
}
.collapsing {
  transition: none !important;
}

.dots-btn {
  background-color: transparent !important;
  box-shadow: none;
  border: none !important;
  width: 100%;
  color: rgba(58, 87, 101, 1) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto" !important ;
  // border-radius: 12px !important;
  font-weight: 400 !important;
  padding: 0 !important;
  margin: 0;
}
.dots-btn:not([disabled]):not(.disabled):active,
.dots-btn:not([disabled]):not(.disabled).active,
.show > .dots-btn.dropdown-toggle {
  background-color: transparent !important;
}
.create-modal {
  max-width: 40% !important;
  .modal-title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  .modal-header {
    padding: 10px 8px;
  }
  .modal-body {
    padding: 10px 8px;
    max-height: none;
  }
  .modal-content {
    padding: 10px;
  }
  input {
    border-radius: 8px;
  }
  textarea {
    border-radius: 8px;
  }
}
.create-modal-full-size {
  max-width: 55% !important;
  .modal-title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  .modal-header {
    padding: 10px 8px;
  }
  .modal-body {
    padding: 10px 8px;
    max-height: none;
  }
  .modal-content {
    padding: 10px;
  }
  input {
    border-radius: 8px;
  }
  textarea {
    border-radius: 8px;
  }
}
.upload-icon {
  width: 50px;
}
.uploaded-icon {
  width: 70px;
  border-radius: 10px;
  height: 70px;
  margin-left: 12px;
  border: 1px solid #ddd;
  object-fit: cover;
}
.w-fill {
  width: -webkit-fill-available;
}
.tableRow {
  position: relative;

  .checkBox {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
  }

  .headcol {
    position: sticky;
    left: 40px;
    background-color: white;
    z-index: 1;
    span {
      min-width: 112px;
    }
  }

  .role {
    position: sticky;
    left: 168px;
    background-color: white;
    z-index: 1;
  }

  .actions {
    position: sticky;
    right: 0;
    background-color: white;
  }
}

.toggle-group {
  .btn-secondary {
    background-color: white !important;
    box-shadow: none;
    // border-radius: 10px;
    border: 1px solid #ddd !important;
    color: black;
    text-transform: capitalize;
    font-size: 14px;
    padding: 8px 20px;
    line-height: 1;
    margin: 0;
    font-family: "Inter", sans-serif;
  }
  .btn-secondary:not([disabled]):not(.disabled):active,
  .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #3a5765 !important;
    box-shadow: none;
    // border-radius: 10px;
    border: 1px solid #3a5765 !important;
    color: white;
    text-transform: capitalize;
    font-size: 14px;
    padding: 8px 20px;
    line-height: 1;
    margin: 0;
    font-family: "Inter", sans-serif;
  }
}

.form-check-label {
  font-weight: 500;
  color: black;
}
.table-icon {
  svg {
    width: 20px;
  }
}

.table td,
.table th {
  vertical-align: middle;
}
.project-table-div {
  // background-color: white;
  // border: 1px solid #ddd;
  // border-radius: 10px;

  width: 97%;
  overflow-x: auto;
  height: 82vh;
  background: white;
  margin: 0 auto;
  padding: 0px;
  border-radius: 20px;
}

.border-top-none {
  border-top: none !important;
}

.modal-health-accordian {
  .accordion__item {
    border: 1px solid #dee2e6;
    border-radius: 8px;
  }
  .card-body {
    padding: 10px !important;
  }
}

.checkbox_accordian {
  .form-group-div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    div {
      &:first-child {
        width: 60%;
      }
    }
    // input[type="text"]{
    //   width: 100%;
    // }
  }
}

.permission-table-div {
  width: 100%;
  overflow-x: auto;
  height: 67vh;
}

.heading-indicator {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.dropdown-menu {
  border: none !important;
}

.toggle-small-group {
  transform: scale(0.8);
}
.rules-states-outer-container {
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  padding: 10px;
  border-radius: 20px;
  .rules-stats-div {
    border-radius: 5px;
    text-align: center;
    width: 32%;
    padding-top: 20px;
    h2 {
      font-size: 30px;
    }
    p {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 10px 7px;
      border-radius: 5px;
      padding: 3px 7px;
      font-size: 14px;
      font-weight: 900 !important;
      background: white;
    }
  }
  .total {
    background-color: white;
    h2 {
      color: black;
    }
    p {
      background-color: #f1f1f1;
      svg {
        fill: #000;
        stroke: #000;
      }
    }
  }

  .great {
    background-color: #eefaea;
    h2 {
      color: #3f8a42;
    }
    p {
      color: #3f8a42;
      svg {
        fill: #3f8a42;
        stroke: #3f8a42;
      }
    }
  }
  .fail {
    background-color: #f8e9e9;
    h2 {
      color: #b22222;
    }
    p {
      color: #b22222;
      svg {
        fill: #b22222;
        stroke: #b22222;
      }
    }
  }
  .pass {
    background-color: #fff6e6;
    h2 {
      color: rgba(255, 165, 0, 1);
    }
    p {
      color: rgba(255, 165, 0, 1);
      svg {
        fill: rgba(255, 165, 0, 1);
        stroke: rgba(255, 165, 0, 1);
      }
    }
  }
}

.btn {
  transition: 0.1s;
}

.btn:hover {
  transition: 0.1s;
  opacity: 0.95;
  box-shadow: 0px 0px 3px grey !important;
}

.header-btn:hover {
  transition: 0.1s;
  opacity: 0.95;
  box-shadow: 0px 0px 3px grey !important;
}
.row-low-padding {
  width: 100%;
  margin: auto;
}

.row-low-padding > div {
  padding: 3px;
}

.bid-table-small tbody tr {
  border-bottom: 1px solid #ddd !important;
}

.bid-table-small tbody td {
  font-size: 13px;
}
.site-wrap {
  margin-top: -60px;
}

.theme-text-color {
  color: #3a5765;
}

.text-capitalize {
  text-transform: capitalize;
}
.headerLink {
  text-decoration: none;
  &:hover {
    color: #283f4b;
  }
}
.select-proj-box {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #ddd;
  padding: 15px;
  width: 50%;
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}

.select-proj-box-active {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 2px solid #3a5765;
  padding: 15px;
  width: 50%;
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}

.gap-15 {
  gap: 15px;
}
.stepper-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  .step-01 {
    position: relative;
    width: 100%;
    img {
      margin-bottom: 15px;
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 11px;
      height: 2px;
      width: 46%;
      right: 0;
      background-color: #ddd;
    }
  }
  .step-02 {
    position: relative;
    width: 100%;
    img {
      margin-bottom: 15px;
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 11px;
      height: 2px;
      width: 46%;
      right: 0;
      background-color: #ddd;
    }
    &::before {
      content: "";
      position: absolute;
      top: 11px;
      height: 2px;
      width: 46%;
      left: 0;
      background-color: #ddd;
    }
  }
  .step-03 {
    position: relative;
    width: 100%;
    img {
      margin-bottom: 15px;
      position: relative;
      z-index: 1;
    }
    &::before {
      content: "";
      position: absolute;
      top: 11px;
      height: 2px;
      width: 46%;
      left: 0;
      background-color: #ddd;
    }
  }
}

.param-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 42%;
  .overflow-ul-div {
    height: 315px;
    overflow-y: auto;
    ul {
      padding-left: 0px;
      li {
        display: flex;
        padding: 7px 16px;
        border: 1px solid #ddd;
        border-left: none;
        border-right: none;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        overflow: hidden;
        width: 100%;
        input {
          margin-right: 20px;
          min-width: 20px;
        }
      }
    }
  }
}
.matching-param-box {
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.successfully-project-created-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 400px;
  img {
    width: 55px;
    margin-bottom: 18px;
  }
}
.hamburger-div {
  border-radius: 50%;
  padding: 10px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
  &:hover {
    background-color: #faf9f6;
    transition: 0.5s;
  }
}

.scrollable-permissions {
  width: 470px;
  overflow: auto;
}

.table-key-width {
  width: 300px;
}

.progress-bar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(hotpink 75%, pink 0);
}
.pagination-select {
  border-radius: 5px;
}
.pagination-css {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  padding-bottom: 10px;
}
.parameter-content {
  height: 67vh;
  overflow-y: auto;
}

.list-user-img {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  object-fit: contain;
}

.health-overflow-div {
  overflow-y: auto;
  height: 75.5vh;
}

.profile-div {
  .form-control {
    border-radius: 0.375rem !important;
  }
}

.upsideDown-svg {
  transform: rotate(180deg);
  margin-right: 5px;
}

.toggle-btn-group {
  .btn {
    &:hover {
      color: inherit !important;
    }
  }
}

.small-spinner {
  animation: mymove 1s infinite;
}

@keyframes mymove {
  100% {
    transform: rotate(360deg);
  }
}

.header-profile-icon {
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.parameter-table {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 15% 27% 10% 11% 5%;
  div {
    line-break: anywhere;
    text-align: left;
    padding-left: 20px;
    font-size: 12px;
    color: black;
  }
  .div-1 {
    padding-left: 10px;
  }
}

.parameter-table-header {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 15% 27% 10% 11% 5%;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  background-color: #eaeaea;
  div {
    line-break: anywhere;
    text-align: left;
    padding-left: 20px;
    font-size: 12px;
    color: grey;
  }
  .div-1 {
    padding-left: 10px;
  }
}

.copyIcon {
  padding: 5px;
  border: 1px #3a5765 solid;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.passwordIcon {
  padding: 5px;
  border: 1px #3a5765 solid;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.validation-error-msg {
  color: red;
  margin-top: 5px;
}

.text-underline-hover:hover {
  text-decoration: underline;
}
.sidebar-profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.admin-nav-list {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
  position: relative;
  padding-left: 32px;
  transition: 0.2s;

  &::after {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    left: 10px;
    background-color: #ddd;
    border-radius: 50%;
    transition: 0.2s;
  }

  &::before {
    content: "";
    width: 20px;
    height: 2px;
    position: absolute;
    left: -11px;
    background-color: #ddd;
    transition: 0.2s;
  }
}

.input-box-spinner {
  position: absolute;
  right: 10px;
  top: 29%;
}
.kanban-card-projectNo {
  font-size: 11px;
  margin-bottom: 0;
  i {
    float: right;
    margin-right: 5px;
  }
}

.filter-input {
  background-image: url("../assets/images/icons/search.svg");
  background-position: 97% 50%;
  background-repeat: no-repeat;
  height: 32px !important;
  padding: 0 11px !important;
}

.filter-indicator {
  width: 15px;
  height: 15px;
  background-color: #283f4b;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  text-decoration: none;
  border: none;
  position: absolute;
  right: 0;
  top: 2px;
}

.report-btn-group {
  label {
    &:hover {
      color: black !important;
    }
  }
  .active {
    background-color: #3a5765 !important;
    &:hover {
      color: white !important;
    }
  }
}

.export-save-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}
.overflow-hidden {
  overflow: hidden;
}

.save-btn-position {
  position: absolute;
  top: 12px;
  right: 12px;
}

.trend-div {
  border-bottom: 1px solid #ddd;
  svg {
  }
}

.flex-overallCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px !important;
}
.border-bottom {
  border-bottom: 1px solid #ddd;
}

.unit-cost-height {
  height: 46px;
}

.tree-item-table-view {
  height: 50px !important;
  font-size: 20px !important;
}
.threeD-viewer {
  height: calc(100vh - 140px) !important;
}
.h-76vh {
  height: 76vh;
}

.assestmgmt-table-div {
  width: 97%;
  overflow-x: auto;
  height: 82vh;
  background: white;
  margin: 0 auto;
  padding: 0px;
  border-radius: 20px;
  border: 1px solid #ddd;
  .users-profile-list {
    display: flex;
    align-items: center;
    .u-profile {
      height: 24px;
      width: 24px;
      margin-left: -13px;
      border: 1px solid white;
      border-radius: 50%;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }
  }
}

.assestmgmt-table {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sticky-table-header {
  thead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
  }
}

.sticky-table-header-1 {
  thead {
    position: sticky;
    top: 110px;
    background-color: white;
  }
}

.assestmgmt-checkbox-div {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 10px;
}

.warehousetable-height {
  height: 76vh !important;
  margin-bottom: 50px;
}

.asset-pagination-div {
  position: relative;
  width: 95%;
  background: white;
  border-radius: 20px;
}

input[type="checkbox"][disabled] {
  cursor: not-allowed;
  background-color: #ececec;
}

.disable-btn {
  cursor: not-allowed !important;
}

.download-btn-size {
  font-size: 17px;
}
.container-large {
  max-width: 98vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.search-bar-parameters {
  width: 97%;
  margin: auto;
  display: flex;
  margin-bottom: 10px;
}
.ext-select {
  width: 180px;
}
.enterprize-head {
  font-size: 35px !important;
}
.enterprize-subhead {
  font-size: 45px !important;
}

.enterprize-p {
  font-size: 17px !important;
}
.heading-svg-wrapper {
  svg {
    width: 20px;
    height: 20px;
  }
}

.switch-img-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;
  img {
    margin-right: 11px;
    width: 45px;
    cursor: pointer;
  }
  p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
  }
  .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    top: 30px;
    font-size: 14px;
  }
}

.switch-img-container:hover .tooltiptext {
  visibility: visible !important;
}
.underline_textboc {
  border: none !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0 !important;
}

.battery-container {
  img {
    width: 28px;
    // transform: rotate(90deg);
    // margin-left: 11px;
    cursor: pointer;
  }
}

.currency-prefix {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  font-size: 16px;
}

.poppins-bold {
  font-family: "poppins-bold";
}

.tracking-date-div {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  margin: 0 10px;
  i {
    margin: 0 10px;
    cursor: pointer;
  }
  input {
    border: 1px solid #ddd;
    border-top: none;
    border-bottom: none;
    padding: 0 10px;
    text-align: center;
    font-size: 14px;
  }
}

.dateStatus {
  font-size: 14px;
  font-weight: 600;
  margin: 10px;
}
.tabel-action-div {
  // font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  i {
    cursor: pointer;
    font-size: 25px;
    margin: 0;
    padding: 0;
    color: #3a5765;
  }
}

.tracking-table-input {
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 5px;
  height: 35px;
  padding-left: 10px;
  font-size: 15px;
}
.tracking-td {
  width: 8%;
}

.daysInput-width {
  width: 100%;
}

.track-input:disabled {
  background-color: #fbf7f745 !important;
}
.icon-div-model {
  width: 60px;
  height: 60px;
  background-color: rgb(244, 249, 250);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  i {
    color: #283f4b;
    font-size: 25px;
  }
}

.text-underline {
  text-decoration: underline;
}

.excel-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.expand-div {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #283f4b;
  border-radius: 50%;
  cursor: pointer;

  i {
    color: #283f4b;
    font-size: 12px;
  }
}

html {
  // zoom: 0.8; /* Old IE only */
  // -moz-transform: scale(0.8);
  // -webkit-transform: scale(0.8);
  // transform: scale(%);
  // font-size: 80%;
  background-color: #f6f7fb;
}

.disabled-inputs-track {
  border: none;
  color: black;
  background-color: white;
  font-size: 14px;
}

.track-name-width {
  min-width: 300px;
  max-width: 300px;
}

.disabled-inputs-track:disabled {
  background-color: white;
  filter: brightness(100%) !important;
}

.logo-title {
  b {
    text-transform: math-auto;
  }
}

.input-with-cancelBtn {
  display: flex;
  position: relative;
  button {
    position: absolute;
    right: 8px;
    top: 3px;
    bottom: 0;
    border: none;
    width: 25px;
    height: 25px;
    // margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    font-size: 11px;
  }
}

.header-select {
  min-width: 150px;

  .header-select__indicator {
    padding: 5px;
  }
  .header-select__control {
    height: 30px;
    min-height: 30px;
  }
  .header-select__value-container {
    padding: 0px 8px;
  }
  .header-select__placeholder,
  .header-select__single-value {
    line-height: 1;
    font-size: 12px;
  }
}

.global-btn {
  min-height: 30px;
  height: 30px;
  padding: 0 8px;
  display: flex;
  border: none;
  margin-right: 8px;
  font-size: 13px;
  font-family: "roboto";
  align-items: center;
  letter-spacing: 0.15px;
}

.bg-table-header {
  background-color: #eaeaea !important;
}

.pdf-svg {
  svg {
    fill: #283f4b;
  }
}

.admin-card-body {
  // border-left: 2px solid #455e6c;
  // border-top-left-radius: 0px !important;
  // border-bottom-left-radius: 0px !important;
  position: relative;
  transition: 0.2s;
  &::before {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    left: 0px;
    background-color: #ddd;
    top: 0;
    transition: 0.2s;
  }
}
.admin-inner-ul {
  transition: 0.2s;
  a {
    position: relative;
    padding-left: 32px;
    transition: 0.2s;
    &::before {
      content: "";
      width: 20px;
      height: 2px;
      position: absolute;
      left: -11px;
      background-color: #ddd;
      transition: 0.2s;
    }
    &::after {
      content: "";
      width: 7px;
      height: 7px;
      position: absolute;
      left: 10px;
      background-color: #ddd;
      border-radius: 50%;
      transition: 0.2s;
    }
  }
}

.admin-card-body1 {
  &::before {
    top: 19.5px !important;
  }
}

.collapse-active {
  border-left: 3px solid #ddd;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  &:hover {
    border-left: 3px solid #ddd !important;
  }
}

.siderbar-inner-ul {
  li {
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.inner-accordian {
  position: relative;
}

.inner-accordian-heading {
  transition: 0.2s;
  position: relative;
  padding-left: 32px;
  transition: 0.2s;
  &::before {
    content: "";
    width: 20px;
    height: 2px;
    position: absolute;
    left: 10px;
    background-color: #283f4b;
    transition: 0.2s;
    top: 50%;
  }
  &::after {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    left: 29px;
    background-color: #283f4b;
    border-radius: 50%;
    transition: 0.2s;
    top: 43%;
  }
}
.inner-accordian-item {
  transition: 0.2s;
  position: relative;
  &::before {
    content: "";
    width: 2px;
    height: 98%;
    position: absolute;
    left: 10px;
    background-color: #283f4b;
    transition: 0.2s;
    top: 0%;
  }
}

.nested-inner-panel {
  padding-left: 40px;
}

.dropdownList .optionItems {
  font-size: 12px;
}

.dropdown-item {
  font-size: 12px !important;
}

.spinner-large-size {
  font-size: 50px;
}

.small-redirect-text {
  font-size: 10px;
  color: #283f4b;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #283f4b;
  border-radius: 5px;
  padding: 2px 5px;
  &:hover {
    text-decoration: underline;
  }
}

.date-picker-input {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 10px;
  text-transform: lowercase;
  font-size: 12px;
}

.header-select__menu {
  font-size: 12px;
}

.password-eye {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.bid-table-border {
  border: 1px solid #ddd;
}

.editBankAccount {
  border: 1px solid #2c3e50;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  i {
    color: #2c3e50;
  }
}

.fail-box {
  background-color: rgba(178, 34, 34, 1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
}

.chat-head {
  font-family: "poppins-regular";
  font-weight: 600;
  font-size: 22px;
}

.chat-form-div {
  position: relative;
  i {
    position: absolute;
    left: 15px;
    color: gray;
    top: 13px;
    font-size: 12px;
  }
  input {
    border-radius: 0px;
    font-family: "poppins-regular";
    padding-left: 35px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1.5px solid rgba(58, 87, 101, 0.5);
    &::placeholder {
      font-size: 14px;
    }
  }
}

.start-chat-btn {
  display: flex;
  align-items: center;
  i {
    margin-left: 20px;
  }
}
.cancel-chat-div {
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 20px;
  }
}

.chat-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(58, 87, 101);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;
  transition: 0.2s;
  i {
    color: white;
    font-size: 30px;
  }

  &:hover {
    cursor: pointer;
    animation-name: bounce;
    -moz-animation-name: bounce;
    box-shadow: 0px 0px 20px rgba(58, 87, 101);
    transition: 0.2s;
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.chat-card-body {
  background-color: rgb(243 243 243);
}

.chat-send-div {
  position: relative;
  button {
    position: absolute;
    right: 0px;
    background-color: #2c3e50;
    width: 45px;
    // border-radius: 10px !important;
    border: none;
    height: 100%;
    border-top-right-radius: 22px !important;
    border-bottom-right-radius: 22px !important;
    z-index: 10;

    i {
      color: white;
    }
  }
}
.chat-send-div input {
  width: 100%;
  border-radius: 22px !important;
  padding-right: 50px;

  &:focus {
    box-shadow: none;
    outline: grey;
    border: 1px solid #ddd;
  }
}

.user-msgs {
  background-color: #283f4b;
  border-radius: 20px;
  border-bottom-right-radius: 0px;
  padding: 5px 12px;
  color: white;
}
.sender-msgs {
  background-color: #fff;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  padding: 5px 12px;
  color: black;
  margin-left: 3px;
}

.messages-list {
  background: rgba(243, 243, 243);
  padding: 14px;
  border-radius: 12px;
  height: 55vh;
  overflow: auto;
}

.admin-chat-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.chat-username-div{
  width: 30%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 12px;
  height: 70vh;
  overflow: auto;
}
.chat-main-div{
  position: relative;
  width: 70%;
}
.chat-div-info{
  p{
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .admin-topnavbar {
    .search-bar {
      width: 100%;
    }
  }
  // .admin-sidebar{
  //   display: none;
  // }
  .profile-content {
  margin-left: 0px ;
  margin-top: 20px;
  }
  .admin-chat-div{
    flex-direction: column;
  }
  .chat-username-div{
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 12px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .chat-room-container{
    box-shadow: 0px 0px 10px #ddd;
    margin: 0 10px;
    border-radius: 8px;
    padding: 5px 10px;
    white-space: pre;
    margin-bottom: 10px;
    width: fit-content;

  }
  .chat-main-div{
    position: relative;
    width: 100%;
  }
  .chat-div-info{
    p{
      font-size: 12px;
    }
  }
  .profile-sidebar{
    width: max-content;
    border: none;
    padding-left: 5px;
  }
  .sidebar-close-btn{
    position: absolute;
    right: -7px;
    width: 20px;
    top: -33px;
    i{
      font-size: 25px;
    }

  }
}




.search-div{
  display: flex;
  margin-top: 20px;
}
.input-with-cancelBtn {
  display: flex;
  position: relative;
}

.search-btn1{
  position: absolute;
  right: 8px;
  top: 3px;
  bottom: 0;
  border: none;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  font-size: 11px;
  background-color: #ddd;
}

.search-btn2{
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;
  font-size: 11px;
  background-color: #3A5765;
  min-height: 30px;
  height: 30px;
  padding: 0 15px;
  margin-left: 10px;
}

.search-bar {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 15px;
  width: 240px;
  max-width: 100%;
  min-height: 30px;
  height: 30px;
  /* background-image: url("../assets/images/icons/search.svg"); */
  background-position: 97% 50%;
  background-repeat: no-repeat;
  /* &::placeholder {
    color: #ddd;
    font-size: 15px;
  } */
}