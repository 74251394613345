.fixes-height-members {
  height: 300px;
}
.member-ul {
  border: none;
  min-height: 260px;
  li {
    font-size: 13px;
    border: none;
    font-family: "poppins-regular";
    border-radius: 10px !important;
    border: 1px solid #ddd !important;
    margin-bottom: 10px;
    padding: 9px 14px !important;
    // &:nth-child(odd){
    //   background-color: #fff;
    // }
    // &:nth-child(even){
    //   background-color: #F8FAFC;
    // }
    div {
      color: #455e6c;
      font-size: 14px !important;
    }
  }
  .active {
    background-color: #455e6c !important;
    color: #fff !important;
    border: 1px solid #455e6c !important;
    div {
      color: #fff;
    }
  }
}
