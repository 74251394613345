.topList {
  position: sticky;
  top: 74px;
  width: 100%;
  overflow-y: auto;
  height: 90vh;
}

.topList::-webkit-scrollbar {
  width: 6px;
}

.topList::-webkit-scrollbar-thumb {
  background-color: #4e6471;
  border-radius: 100vw;
}

.topList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px grey;
  border-radius: 100vw;
}

.rootAside {
  padding-top: 5px !important;
}

.topListRoot {
  margin-left: 15px;
  background: white;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  width: 20%;
  max-width: 20%;
  min-width: 20%;

  @media (--t) {
    display: initial;
  }
}

@media screen and (max-width: 768px) {
  .topListRoot {
    margin-left: 0px;
    background: white;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-top: 10px;
  }
}
