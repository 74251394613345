.modelDiv {
  display: flex;
  justify-content: space-between;
}

.project-item-img{
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 0px 2px #ddd;
}

.project-item-img::before{
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 5px;
  right: 5px;
  /* padding: 20px; */
  height: 40px;
  width: 40px;
  background-color: #e1e1e1;
  /* border-radius: 5px 0; */
  background-size: contain;
  border-radius: 5px;
  box-shadow: -3px -3px 8px dimgrey;
  border: 1px solid rgb(0 0 0 / 10%);
}

.project-item-img.revitIcon::before{
  background-image: url("../../../src/assets/images/modelIcons/revit.svg");
}

.project-item-img.ifcIcon::before{
  background-image: url("../../../src/assets/images/modelIcons/ifc.svg");
}

.project-item {
  &-date {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    padding: 3px;
    color: white;
    background-color: black;
    font-size: 13px;
  }

  &-img {
    position: relative;
    padding-top: 45%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.project-card-fav{
  border: 1px solid #eaeaea;
  position: absolute;
  top: 35px;
  right: 10px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  width: 27px;
  z-index: 1;
}
.upload-project-card-img{
  border: 1px solid #eaeaea;
  position: absolute;
  top: 15px;
  right: 10px;
  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;

  button{
    box-shadow: none;
    padding: 0 !important;
  }
}