$colors: (
  vinous: rgb(236, 0, 120),
  purple: purple,
  grey-and-blue: rgb(0, 79, 113),
  blue: rgb(0, 148, 163),
  lightGreen: rgb(70, 225, 115),
);

@mixin spreadBorderColors() {
  @each $className, $color in $colors {
    &_#{$className} {
      border-color: $color;
    }
  }
}

@mixin spreadTextColors() {
  @each $className, $color in $colors {
    &_#{$className} {
      color: $color;
    }
  }
}

@mixin spreadBgColors() {
  @each $className, $color in $colors {
    &_#{$className} {
      background-color: $color;
    }
  }
}

// html, body{
//   font-size: 70% !important;
// }

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

hr{
  border-top: 1px solid rgba(0,0,0,.4) !important;
}

.vr{
  background:rgba(192, 192, 192) !important;
}

.f-10{
  font-size: 10px !important;
}
.f-11{
  font-size: 11px !important;
}
.f-12{
  font-size: 12px !important;
}
.f-13{
  font-size: 13px !important;
}
.f-14{
  font-size: 14px !important;
}
.f-15{
  font-size: 15px !important;
}
.f-16{
  font-size: 16px !important;
}
.f-17{
  font-size: 17px !important;
}
.f-18{
  font-size: 18px !important;
}
.f-19{
  font-size: 19px !important;
}
.f-20{
  font-size: 20px !important;
}
.f-21{
  font-size: 21px !important;
}
.f-22{
  font-size: 22px !important;
}
.f-23{
  font-size: 23px !important;
}
.f-24{
  font-size: 24px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
  // opacity: 44%;
}

.custom-carousel {
  top: 12px;
  left: 30px;
  @media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
    top: 70px;
  }
}
.logo-price {
  // width: 412px;
  width: 40vw;
  background: #23343e;
  // height: 402px;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
  @media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
    top: 100px;
    bottom: -62px;
  }
  @media (max-width: 768px) {
    width: unset !important;
  }
}
.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
  //line-height: 55px;
  // opacity: 40%;
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
  &::placeholder {
    color: #bec6cb !important;
    font-size: 21px !important;
    line-height: 31px !important;
  }
}
.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
  .react-multi-carousel-track {
    height: 402px;
  }
}

.react-multi-carousel-dot-list {
  z-index: 1;
}
.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
  &:hover {
    background-color: transparent !important;
  }
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  // bottom: 52px;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}

@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}

.logo-sub-text {
  position: relative;
  bottom: -6px;
  span {
    font-family: "poppins-Bold";
  }
}

.custome-height {
  height: 824px;
  // @media only screen and (min-device-width: 992px) and (max-device-width: 1212px) {
  //   height: 807px;
  // }

  @media (max-width: 768px) {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

// sidebar css

.sidebar-logo-div{
  display: flex;
  align-items: center;
  img{
    &:first-child{
      width: 20px;
      margin-right: 20px;
    }
    &:last-child{
      width: 205px;
    }
    
  }
}

.roboto{
  font-family: 'Roboto' !important;
}

.sidebar-list{
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  svg{
    margin-right: 15px;
    width: 18px;
    height: 18px;
    // fill: #9C9C9C;
    // stroke: #9C9C9C;
  }
  i{
    margin-right: 15px;
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
  p{
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
  }
}
.access-btn{
  border: 2px solid #FE6919 !important;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 9px !important;
  font-weight: 600 !important;
  margin-left: auto;
  margin-bottom: 0;
  white-space: pre;
  cursor: pointer;
}
.w-20px{
 width: 20px;
}
.shadow-hover{
  box-shadow: none !important;
  &:hover{
    box-shadow: 0px 0px 10px #ddd !important;
  }
}
.sidebar-collapse-btn{
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2) ;
  color: black;
  transition: .1s;

  &:hover{
    background-color: rgba(145, 186, 203, 0.2) !important;
    // background-color: rgb(205 227 232) !important;
    border-left: 3px solid #3a5765;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    transition: .1s;
  }

  .active{
    background-color: rgba(145, 186, 203, 0.2) !important;
  }

  p{
    color: black;
    font-family: 'Roboto' !important;
  }
  svg{
    fill: black !important;
    // stroke: black !important;
  }
}



.arrow-icon{
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}


.collapsed{
  background-color: transparent;
  p{
    color: grey;
  }
  svg{
    fill: grey !important;
    stroke: grey;
  }
  .arrow-icon{
    width: 13px;
    margin-left: auto;
    transform: rotate(180deg);
  }

}

.show{
  .card-body{
    background-color: rgba(145, 186, 203, 0.2);
    color: rgba(58, 87, 101, 1);
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px 10px 12px;
    margin-top: 2px;
  }
}

.siderbar-inner-ul{
  padding-left: 0;
  margin-bottom: 0;
  li{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    font-family: 'Roboto';
    font-weight: 400;
    img{
      width: 15px;
      margin-right: 10px;
    }
    &:hover{
      font-weight: 800;
    }
    // &:last-child{
    //   margin-bottom: 0;
    // }
  }
  a{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 14px;
    color: rgba(58, 87, 101, 1);
    text-decoration: none;
    cursor: pointer;

    img{
      width: 15px;
      margin-right: 10px;
    }
    &:hover{
      font-weight: 800;
    }
  }
}

.admin-accordian{
  position: absolute;
  bottom: 10px;
  width: 87%;
  background-color: white;
}

$colors: (
  vinous: rgb(236, 0, 120),
  purple: purple,
  grey-and-blue: rgb(0, 79, 113),
  blue: rgb(0, 148, 163),
  lightGreen: rgb(70, 225, 115),
);

@mixin spreadBorderColors() {
  @each $className, $color in $colors {
    &_#{$className} {
      border-color: $color;
    }
  }
}

@mixin spreadTextColors() {
  @each $className, $color in $colors {
    &_#{$className} {
      color: $color;
    }
  }
}

@mixin spreadBgColors() {
  @each $className, $color in $colors {
    &_#{$className} {
      background-color: $color;
    }
  }
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3A5765 !important;
}
*::-webkit-scrollbar-thumb:hover {
  background: #3A5765 !important;
}
*::-webkit-scrollbar-thumb:active {
  background: #3A5765 !important;
}

hr{
  border-top: 1px solid rgba(0,0,0,.4) !important;
}

.vr{
  background:rgba(192, 192, 192) !important;
}
.f-8{
  font-size: 8px !important;
}
.f-9{
  font-size: 9px !important;
}
.f-10{
  font-size: 10px !important;
}
.f-11{
  font-size: 11px !important;
}
.f-12{
  font-size: 12px !important;
}
.f-13{
  font-size: 13px !important;
}
.f-14{
  font-size: 14px !important;
}
.f-15{
  font-size: 15px !important;
}
.f-16{
  font-size: 16px !important;
}
.f-17{
  font-size: 17px !important;
}
.f-18{
  font-size: 18px !important;
}
.f-19{
  font-size: 19px !important;
}
.f-20{
  font-size: 20px !important;
}
.f-21{
  font-size: 21px !important;
}
.f-22{
  font-size: 22px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.txt-cap {
  text-transform: capitalize !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
  // opacity: 44%;
}

.custom-carousel {
  top: 12px;
  left: 30px;
  @media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
    top: 70px;
  }
}
.logo-price {
  // width: 412px;
  width: 40vw;
  background: #23343e;
  // height: 402px;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
  @media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
    top: 100px;
    bottom: -62px;
  }
  @media (max-width: 768px) {
    width: unset !important;
  }
}
.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
  //line-height: 55px;
  // opacity: 40%;
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
  &::placeholder {
    color: #bec6cb !important;
    font-size: 21px !important;
    line-height: 31px !important;
  }
}
.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
  .react-multi-carousel-track {
    height: 402px;
  }
}

.react-multi-carousel-dot-list {
  z-index: 1;
}
.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
  &:hover {
    background-color: transparent !important;
  }
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  // bottom: 52px;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}

@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}

.logo-sub-text {
  position: relative;
  bottom: -6px;
  span {
    font-family: "poppins-Bold";
  }
}

.custome-height {
  height: 824px;
  // @media only screen and (min-device-width: 992px) and (max-device-width: 1212px) {
  //   height: 807px;
  // }

  @media (max-width: 768px) {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

// sidebar css

.sidebar-logo-div{
  display: flex;
  align-items: center;
  img{
    &:first-child{
      width: 20px;
      margin-right: 20px;
    }
    &:last-child{
      width: 205px;
    }
    
  }
}

.roboto{
  font-family: 'Roboto' !important;
}

.sidebar-list{
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  svg{
    margin-right: 15px;
    width: 18px;
    height: 18px;
    // fill: #9C9C9C;
    // stroke: #9C9C9C;
  }
  p{
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
  }
}
.access-btn{
  border: 1px solid #FE6919;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 8px !important;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0 !important;
  cursor: pointer;
}
.w-20px{
 width: 20px;
}
.pl-16px {
  padding-left: 1rem !important;
}
.shadow-hover{
  box-shadow: none !important;
  &:hover{
    box-shadow: 0px 0px 10px #ddd !important;
  }
}
.sidebar-collapse-btn{
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;

  p{
    color: black;
    font-family: 'Roboto' !important;
  }
  svg{
    fill: black !important;
    // stroke: black !important;
  }
}

.arrow-icon{
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}


.collapsed{
  background-color: transparent;
  p{
    color: grey;
  }
  svg{
    fill: grey !important;
    stroke: grey;
  }
}
.transform-180{
  transform: rotate(180deg);
}

.show{
  .card-body{
    // background-color: rgba(244, 249, 250, 1);
    background-color: rgba(145, 186, 203, 0.2);
    color: rgba(58, 87, 101, 1);
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0px 10px 12px;
    margin-top: 2px;
  }
}

.siderbar-inner-ul{
  padding-left: 0;
  li{
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    font-family: 'Roboto';
    font-weight: 400;
    // justify-content: space-between;
    img{
      width: 15px;
      margin-right: 10px;
    }
    .dots{
      width: 25px;
    }
    &:hover{
      font-weight: 800;
      color: black;
    }
  }
  a{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    font-family: 'Roboto';
    font-weight: 400;

    img{
      width: 15px;
      margin-right: 10px;
    }
    .dots{
      width: 25px;
    }
    &:hover{
      font-weight: 800;
      color: black;
    }
  }
}

.admin-accordian{
  position: absolute;
  bottom: 10px;
  width: 87%;
}

.ant-modal-mask {
  backdrop-filter: blur(3px);
}

.min-h-full{
  min-height: 100vh;
}