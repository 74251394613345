.container {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 6px;
  padding-right: 6px;

  @media (--m) {
    padding-left: 12px;
  }

  @media (--t) {
    padding-left: 24px;
    padding-right: 0;
    grid-template-columns: 1fr 250px;
  }
}
