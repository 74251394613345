.registration-image {
  background-image: url("../../assets/images/auth_bg.png");
  background-size: cover;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:0;
}

.registration_box{
  box-shadow: 0px 0px 15px #455e6c26;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  text-align: center;
  width: 40%;
  // min-height: 50vh;
  height: fit-content;
  overflow: hidden;
  overflow-x: hidden;
}

.reg-header{
  color: #48606D;
    font-size: 25px;
       font-family: 'Poppins-SemiBold';
    font-weight: 600 !important;
}
.reg-subheader{
  color: #48606D;
  font-size: 22px;
     font-family: 'Poppins-SemiBold';
  font-weight: 600 !important;
}

.reg_form{
  overflow: auto;
  height: 75%;
  overflow-x: hidden;
  input{
    background: #f9f9f9;
    border: none;
    border-radius: 8px;
    padding: 0 10px 0 40px;
    height: 55px;
    width: 100%;
    font-family: poppins-Medium;
    &::placeholder{
      color: #AEAEAE;
      font-size: 14px;
      font-family: "poppins-regular";
    }
  }
  select{
    background: #f9f9f9;
    border: none;
    border-radius: 8px;
    padding: 0 10px 0 15px;
    height: 55px;
    width: 100%;
    font-family: poppins-Medium;
    &::placeholder{
      color: #AEAEAE;
      font-size: 14px;
      font-family: "poppins-regular";
    }
  }
}

.reg_form::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #3a5765;
}
.reg_form::-webkit-scrollbar-thumb:hover {
  background: #283f4b;
}
.reg_form::-webkit-scrollbar-thumb:active {
  background: #283f4b;
}

.reg-btn{
  background-color: #48606D !important;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #48606D;
  color: white;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
}


.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: #f9f9f9;
  border: none;
  border-radius: 8px;
  padding: 0 10px 0 15px;
  height: 45px;
  width: 100%;
  color: #3A5765;
      font-size: 14px;
      font-family: "poppins-regular";
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}
.logo-view-div{
  background: #f9f9f9;
  border: none;
  border-radius: 8px;
  height: 87%;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width:50px;
    height: 50px;
  }
}


.color-form-group{
  background: #f9f9f9;
    border: none;
    border-radius: 8px;
    padding: 0 10px 0 15px;
    height: 45px;
    width: 95%;
    font-family: poppins-Medium;
    color: #bababa;
      font-size: 14px;
}

.color-wrapper{
  width: 30px;
  height: 30px;
  overflow: hidden;
  padding: 0 !important;
  border-radius: 50%;
  margin-right: 5px;
    
    input{
      width: 100px;
      height: 100px;
      padding: 0 !important;
      position: relative;
      top: -17px;
      left: -10px;
  
    }
}

.form-group{
  margin-bottom: 0.5rem !important;
}


.regForm1{
  height: 100% !important;
}

.admin-register-box{
  height: 90vh !important
}

// @media (min-width: 1775px) {
//   .admin-register-box{
//     height: 58% !important;
//   }
// }


.icon_login_1 i{
  top: 20px !important;
}