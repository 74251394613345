.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  padding: 24px;

  @media (--t) {
    grid-template-columns: 210px 1fr;
  }
}
