.nav {
  display: flex;
  background-color: white;
  border: 1px solid lightgray;
  top: 74px;

  position: sticky;
  width: auto;

  @media (--m) {
    display: initial;
    border: none;
  }
}

.stickyResponsive {
  position: sticky;
  top: 49px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .nav {
    border: none;
  }
}
