.card {
  color: var(--black-500);
  display: flex;
  padding: 6px;
}

.body {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  a {
    font-size: 14px;
    color: black !important;
    font-family: 'poppins-regular';
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }

  p {
    font-size: 12px;
  }
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 3px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}


@media screen and (max-width: 768px){ 
  .avatar {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  
    a {
      font-size: 10px;
      color: black !important;
      font-family: 'poppins-regular';
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  
    p {
      font-size: 8px;
      margin-bottom: 0px;
    }
  }

 }