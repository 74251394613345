.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  button {
    color: var(--black-500);
    border: 1px solid var(--black-300);
    border-radius: 3px;
    padding: 0.8em;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child):not(:first-child) {
      border-radius: 0px;
    }

    &:not(:last-child) {
      margin-right: -1px;
    }

    &:hover {
      color: var(--black-600);
      background-color: var(--black-025);
    }
  }
}

.active {
  color: #71a0b8 !important;
  background-color: var(--black-075) !important;

  &:hover {
    background-color: var(--black-075) !important;
  }
}

.borderBottom {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--black-100);
  margin-right: 5px;
}
