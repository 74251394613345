.viewer {
  // vh
  height: calc(100vh - (70px));
    // padding-top: 40px;
  // zoom: 1.176; 

  canvas {
    outline: none;
    width:100%;
  }



  &-total-price {
    top:15px;
    right:15px;
    width:200px;
    box-shadow: 1px 2px 9px grey;
  }

  &-zoom {
    z-index:10;
    right:50%;
    transform: translateX(50%);
    bottom:15px;
  }

  &-header {
    top:0;
    left:0;
    right:0;
    height:40px;
    display: flex;
    align-items: center;
    background-color: rgba(113,161,183,1);
    font-size: 17px;
  }

  &-lowerheader {
    height:38px;
  }

  &-header-item {
    border-right:2px solid rgb(200,200,200);
  }

  &-lowertree {
    height: calc(100% - 38px);
  }

  &-selected {
    min-width: 160px;
  }



  &-icon {
    cursor: pointer;
    svg {
      width:25px;
      height:10px;
      fill:white;
      transform: rotate(0deg);
      transition: .3s;
    }


    &-rotate {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &-popover {
    padding:3px 7px 3px 5px;
    background-color: white;
  }


  &-searching {
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:20;
    background-color: rgba(black,.5);
  }
}


.socketviewer {
  // vh
  height:calc(100vh );
  
  // zoom: 1.176;

  canvas {
    outline: none;
    width:100%;
  }



  &-total-price {
    top:15px;
    right:15px;
    width:200px;
    box-shadow: 1px 2px 9px grey;
  }

  &-zoom {
    z-index:10;
    right:50%;
    transform: translateX(50%);
    bottom:15px;
  }

  &-header {
    top:0;
    left:0;
    right:0;
    height:40px;
    display: flex;
    align-items: center;
    background-color: rgba(113,161,183,1);
    font-size: 17px;
  }

  &-lowerheader {
    height:38px;
  }

  &-header-item {
    border-right:2px solid rgb(200,200,200);
  }

  &-lowertree {
    height: calc(100% - 38px);
  }

  &-selected {
    min-width: 160px;
  }



  &-icon {
    cursor: pointer;
    svg {
      width:25px;
      height:10px;
      fill:white;
      transform: rotate(0deg);
      transition: .3s;
    }


    &-rotate {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &-popover {
    padding:3px 7px 3px 5px;
    background-color: white;
  }


  &-searching {
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:20;
    background-color: rgba(black,.5);
  }
}

.estimation-detail-itemViewer {

  position: absolute;
  display: flex;
  overflow: hidden;
max-height:1000px;
max-width:300px;
  overflow-y:scroll;
 
}

.modal3d-tool-group-btn {
  position: relative;
  align-content: space-around;
  display: flex;
  padding: 10px !important;
  margin-left: 10px !important;

}

.socketviewer .buttonEnabled {
  background-color: #3a5765 !important;
  color: white !important;
}
.socketviewer #clippingTooltip {
  position: absolute;
  font-size: small;
  right: 0;
  bottom: -25px;
  background-color: #3a5765;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
  margin-right: 10px;
}