.container {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  padding: 20px;
  grid-gap: 10px;

  h2 {
    color: var(--black-800);
    font-size: 18px;
    font-weight: 400;
  }

  h3 {
    font-size: 16px;
    padding-top: 10px;
  }
}

.textarea {
  height: 200px;
}

.status {
  color: var(--red-500);
  font-size: 12px;
}