
.react-tagsinput-tag {
  display: inline-block;
  background-color: var(--powder-100);
  color: var(--powder-700);
  font-size: 12px;
  padding: 5px 6px;
  border: 1px solid transparent;
  margin: 2px 5px 2px 0;
  line-height: 1;
  white-space: nowrap;
  border-radius: 3px;
}

.react-tagsinput-input {
  width: 200px;
  color: var(--black-900);

  &::placeholder {
    color: var(--black-200);
  }
}

.loading {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--black-200);
    height: 40px;
    width: 40px;
  }
}

.not-found {
  color: var(--red-500);
  margin-left: 24px;
}

.not-found-questions {
  margin-top: 10px;
  text-align: center;
}

.menu-container {
  position: relative;
  display: grid;
  justify-content: end;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 2;
}

.menu-icon .bar {
  height: 4px;
  width: 100%;
  background-color: #6e98ae;
  border-radius: 2px;
}

#menu-toggle {
  display: none;
}

.menu {
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  border-bottom: 1px solid #f0f0f0;
}

.menu li:last-child {
  border-bottom: none;
}

.menu a {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 10px 20px;
}

#menu-toggle:checked + .menu-icon + .menu {
  display: block;
}

.learning-btn-group {
  display: flex;

  button {
    width: 75px;
    border-radius: 5px;
    border: 1px solid #283f4b;
    text-align: center;
    height: 35px;
    font-weight: 600;
    color: #283f4b !important;
    margin-right: 8px;
    background: white;
  }

  .active {
    background-color: #283f4b !important;
    color: white !important;
  }
}

.post-panel {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 20px 0;
}

.post-metadata {
  display: flex;

  img.avatar-image {
    border-radius: 50%;
}
}

.post-metadata .post-info {
  padding-left: 10px;
}

.post-metadata .post-info small {
  color: rgba(0, 0, 0, .44);
}

.post-picture-wrapper {
  padding: 1.2em 0 0;
}

.post-picture-wrapper img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.main-body {
  margin-bottom: 20px;
  a{
    text-decoration: none !important;
  color: rgba(0, 0, 0, .44);

  }
}

.post-panel .main-body .post-body {
  font-size: 21px;
  line-height: 1.18;
  letter-spacing: -.022em;
  font-family: 'poppins-regular';


}

.post-panel .main-body .post-body h2, .post-panel .main-body .post-body h3 {
  font-size: 22px;
  color: rgba(0, 0, 0, .44);
}

.post-metadata .post-info a {
  color: #6e98ae;
  text-decoration: none;
}

.post-title{
  color: #3a5765  !important;
  margin-top: 20px;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}

.post-title-1{
  color: #3a5765  !important;
  margin-top: 20px;
  text-decoration: none;
  font-weight: 600 !important;
  font-family: 'Roboto';

  &:hover{
    text-decoration: underline;
  }
}

.article-font > p,h2,h3,h4,h5,h6{
  font-family: 'Roboto' !important;
}


.like-button, .bookmark-button{
  // margin-right: 10px;
    background: transparent;
    color: #6e98ae;
    border: none;
    display: flex;
    align-items: center;
    span{
      margin-right: 10px;
    }
}

.small-heading {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0 0 8px;
  font-size: 13px;
  font-family: 'poppins-regular';
  font-weight: 500;
}

aside.main-sidebar {
  padding-top: 2em;
  background-color: #fafafa;
}

.aside_rootAside__vAyQl {
  padding-top: 5px !important;
}

aside.main-sidebar .sidebar-top-stories ul {
  list-style: none;
  padding: 1em;
}

aside.main-sidebar .sidebar-top-stories .top-stories-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.1em;
}


.count-button-wrapper {
  position: relative;
  min-width: 35px;
  width: 35px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 50%;
  background-color: #fff;
  margin-right: 20px;
}

.count-button-wrapper .count-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 700;
}
.top-stories-links{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  width: 100%;
  small{
    a{
    text-decoration: none;
    color: grey;
    &:hover{
      text-decoration: underline;
    }
  }
  }
}

.post-body p {
  font-family: 'poppins-regular';
  font-size: 20px;
  line-height: 1.7;
  font-weight: 300;
  border: none;
  color: rgba(0, 0, 0, .8);
}

.like-button-wrapper{
  display: flex;
  align-items: center;
}

.follow-button{
  border-radius: 15px !important;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.unfollow{
  background-color: white;
  border: 1px solid #283f4b;
  color: #283f4b;
}

.follow{
  background-color: green;
  border: 1px solid green;
  color: white;
}

.author-metadata{
  display: flex;
    align-items: center;
    margin: 20px 0;
  img{
    border-radius: 50%;
    margin-right: 15px;
  }
}


.learning-bg{
  padding: 20px;
  border: 1px solid rgb(234, 236, 240);
  background-color: white;
  border-radius: 10px;
  width: 100%;
  min-height: 79vh;
  overflow-x: hidden;
}

// .medium-editor-toolbar{
//   position: absolute;
//   top: 00px;
//   width: 100%;
//   left: 0;
// }

.article-action-box{
  display: flex;
  li{
    list-style: none;
    border: 1px solid #283f4b;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center; 
    justify-content: center;
    i{
      color: #283f4b;
    }
  }
}

.learning-content {
  padding: 20px;
  border: 1px solid rgba(234, 236, 240, 1);
  background-color: white;
  margin-left: 20%;
  border-radius: 10px;
  min-height: 79vh;
  overflow-x: hidden;
  width: 60%;
}

.learning-sidebar {
  border: 1px solid rgba(234, 236, 240, 1);
  background-color: white;
  display: block;
  position: fixed;
  padding: 20px;
  border-radius: 10px;
  padding-right: 0;
  min-height: 88vh;
  width: 19%;
  min-width: 19%;

  // box-shadow: 0px 0px 5px #ddd;
  p {
    font-size: 18px;
    font-weight: 700;
    color: rgba(58, 87, 101, 1);
  }
  button, a {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 500;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    text-align: left;
    line-height: 1.5;
    padding-left: 10px;
    text-decoration: none;
    svg {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }
  }
  .ActiveTab {
    background-color: rgba(145, 186, 203, 0.2);
    color: #283f4b;
    width: 97%;
    svg {
      fill: #283f4b;
    }
  }
}

.poppins-regular{
  font-family: 'poppins-regular';

}

.add-article-btn{
  border-radius: 5px;
  text-decoration: none;
  padding: 5px 10px;
}

.mobile-hidden{
  display: none;
}

.mobile-show{
  display: none;
}
.desktop-show{
  display: block;
}

// ------------------------------------------------

.learning-container-flex{
  display: flex;
}


@media screen and (max-width: 768px){
  .learning-container-flex{
    display: block;
  }
  .learning-content{
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    padding: 5px;
  }
  .learning-sidebar {
    border: 1px solid rgb(234, 236, 240);
    background-color: white;
    display: flex;
    position: relative;
    padding: 5px;
    border-radius: 10px;
    padding-right: 0;
    min-height: 0;
    width: 100%;
    min-width: 100%;
    padding-right: 5px;
}
.learning-sidebar p{
  display: none;
}
.learning-sidebar button, .learning-sidebar a{
  margin-bottom: 0px;
  font-size: 12px;
  padding: 6px;
}
.learning-btn-group button {
  width: 55px;
  border-radius: 5px;
  border: 1px solid #283f4b;
  text-align: center;
  height: 25px;
  font-weight: 600;
  color: #283f4b !important;
  margin-right: 8px;
  background: white;
  font-size: 10px;
}
.custom-container{
  margin: 0 5px;
  padding: 5px;
}
.hamburger-div{
  padding: 5px;
}
.header-logo {
  width: 70px;
  cursor: pointer;
}
.header-profile-icon img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
}
.mobile-show{
  display: block;
}
.desktop-show{
  display: none;
}
.post-metadata .post-info small {
  color: rgba(0, 0, 0, 0.44);
  font-size: 10px;
  position: relative;
  display: block;
}
.post-panel .main-body .post-body {
  font-size: 10px;
  line-height: 1.18;
  letter-spacing: -0.022em;
  font-family: "poppins-regular";
}
.post-panel .main-body .post-body h2, .post-panel .main-body .post-body h3 {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.44);
}
.add-article-btn{
  font-size: 12px;
}
.count-button-wrapper {
  position: relative;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
}
.post-title{
  font-size: 14px;
}

}

.upload-box{
  position: sticky;
  top: 150px;
}
.editor-image-upload-box{
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px
}

.editor-bg{
  padding: 20px;
    border: 1px solid rgb(234, 236, 240);
    background-color: white;
    border-radius: 10px;
    width: 100%;
    min-height: 79vh;
}


.article-font{
  font-weight: 500;
  font-size: 18px;
}