.tree-item {
    height:30px;
    font-size: 14px;
    color: #455E6C;
  
    svg {
      width:20px;
      min-width: 20px;
      height:20px;
    }
    span{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  