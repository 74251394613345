.heading-bidlight {
  font-family: "poppins-bold";
  color: #455d6b;
}

.homeButtons {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 500px;
}

.homeButtons .btn {
  padding: 15px !important;
  font-size: 20px !important;
}

.btn-primary-custom {
  color: #fff !important;
  text-align: start !important;
  font-size: 24px !important;
  border-radius: 10px !important;
  border: 2px solid #71a0b8 !important;
  background: #71a0b8 !important;
  text-transform: unset !important;
  position: relative !important;
  top: 0;
  transition: 0.3s all ease;
  box-shadow: none !important;
  z-index: 12;
  line-height: 48px;
}

.homeButtons .btn-primary-custom {
  line-height: unset;
}

.btn-primary-custom:hover {
  color: #455d6b !important;
  background: #ffff !important;
}

.custome-home-review .react-multiple-carousel__arrow {
  bottom: 0 !important;
  right: 0;
}

.custom-dot-list-style.custom-dot-list-style-home {
  bottom: 0 !important;
}
.carousel-track-custom-home .react-multi-carousel-track {
  height: 250px !important;
  left: 0 !important;
}

.wrapperEstimation {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
}
@media (max-width: 768px) {
  #videoIdEstimation {
    width: 100% !important;
  }
  .playpauseEstimation {
    width: 100% !important;
  }
}

#videoIdEstimation {
  width: 120%;
  height: 100%;
  object-fit: cover;
}
.playpauseEstimation {
  background-image: url(../../../assets/images/big-play-button.png);
  background-repeat: no-repeat;
  width: 120%;
  height: 25%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}
