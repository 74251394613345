.admin-table-shadow > div {
  overflow: scroll;
}
.admin-table-shadow {
  max-width: 1500px;
  box-shadow: 0px 3px 99px #f1f3f5;
  padding: 10px;
  border-radius: 10px;
  // min-height: 90vh;
  font-size: small;
  margin: auto;
  }
  
  .admin-table-shadow table * {
    font-size: 12px;
}
.admin-table{
  thead{
    background-color: white;
    tr{
      th{
        color: #161212;
        border-bottom: 1px solid #ddd;
        font-family: 'Poppins-medium';
        background-color: #eaeaea;
        border-radius: 0px;
        &:first-child{
        }
        &:last-child {
          padding-right: 15px;
        }
      }
    }
  }
  tbody {
    background-color: white;
    tr {
      td {
        border: 1px solid transparent !important;
        color: #68676c;
        font-family: "poppins-Regular";
        padding: 10px 10px 10px 0;
        background-color: transparent;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        font-weight: 300 !important;
        text-transform: capitalize;
        font-size: 12px !important;
      }
    }
    tr:nth-child(odd) {
      background: #f8fafc;
      td {
        // background: #f8fafc;
        &:first-child {
          padding-left: 15px;
          border-radius: 13px 0 0 13px;
        }
        &:last-child {
          border-radius: 0px 13px 13px 0px;
        }
      }
    }

    tr:nth-child(even) {
      td {
        &:first-child {
          padding-left: 15px;
          border-radius: 13px 0 0 13px;
        }
        &:last-child {
          border-radius: 0px 13px 13px 0px;
        }
      }
    }
  }
}

.table-btn {
  border-radius: 6px !important;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border: none;
  padding: 0 10px;
  cursor: pointer;
  i {
    margin-right: 10px;
    font-size: 14px;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    filter: brightness(95%);
  }
}

.see-btn {
  background-color: #eef2f6 !important;
  color: #455d6b !important;
}
.dlt-btn {
  background-color: #fff6f6 !important;
  color: #fb9292 !important;
}
.admin-btn {
  background-color: #f1faf6;
  color: #87d7b2;
  i {
    color: #fff;
    background-color: #87d7b2;
    border-radius: 50%;
    padding: 4px;
    font-size: 9px;
  }
}

.change-btn {
  margin-left: auto;
  margin-right: 20px;
}

.change-admin-list-group {
  border: none;
  li {
    margin: 5px 10px;
    border: 1px solid #ddd !important;
    border-radius: 5px;
  }
}

.admin-list-name {
  font-size: 14px;
  line-height: 1;
}
.admin-list-email {
  font-size: 13px;
  line-height: 1;
}
.admin-badge-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #eef2f6;
  color: #455d6b;
  display: flex;
  align-items: center;
}


.grid-two-sets{
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 20px;
  overflow-y: auto;
  div{
    padding: 10px;
  }
}