.container {
  flex: 1 auto;
  overflow: hidden;
}

.link {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 8px;
  word-break: break-word;
}

.excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-break: break-word;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 60px;
}

.tagContainer {
  flex: 1 auto;
  margin-bottom: 8px;
  a{
    display: inline-block;
    background-color: var(--powder-100);
    color: var(--powder-700);
    font-size: 15px;
    padding: 5px 6px;
    border: 1px solid transparent;
    margin: 2px 5px 2px 0;
    line-height: 1;
    white-space: nowrap;
    border-radius: 3px;
  }
}

.userDetails {
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span {
    color: var(--black-400);
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    a {
      width: fit-content;
      color: #455d6b;
      text-decoration: none;
      
    }
  }
}


.title{
  font-size: 36px;
  color: #283f4b;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  color: #283f4b;
  }
}

@media screen and (max-width: 768px){ 
  .title{
    font-size: 14px;
  }

  .excerpt {
    font-size: 10px;
  }

  .tagContainer {
    flex: 1 auto;
    margin-bottom: 8px;
    a{
      display: inline-block;
      background-color: var(--powder-100);
      color: var(--powder-700);
      font-size: 10px;
      padding: 5px 6px;
      border: 1px solid transparent;
      margin: 2px 5px 2px 0;
      line-height: 1;
      white-space: nowrap;
      border-radius: 3px;
    }
  }

  .userDetails { 
    span{
      font-size: 10px;
    }
    a{
      font-size: 10px;
    }
  }
}