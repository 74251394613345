.button {
  background-color: transparent;
  border-radius: 3px;
  outline: none;
  font-family: inherit;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.15;
  text-decoration: none;
  cursor: pointer;
  color: var(--black-400);
  transition: var(--fade);

  &:hover {
    background-color: var(--black-075);
  }

  &:focus {
    box-shadow: 0 0 0 2.5px rgba(36, 39, 41, 0.1);
  }

  &:active {
    background: var(--black-050);
  }
}

.primary {
  color: #fff;
  background-color: #6e98ae;
  border: 1px solid transparent;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
  padding: 0.8em;

  &:hover {
    color: #000;
    background-color: #fafafb;
    border: 1px solid;
  }

  &:active {
    background-color: var(--blue-700);
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: var(--blue-600);
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
  }
}

.secondary {
  color: var(--powder-700);
  background-color: var(--powder-100);
  border: 1px solid transparent;
  border-color: var(--powder-500);
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.7);
  padding: 0.8em;

  &:hover {
    color: var(--powder-800);
    background-color: var(--powder-300);
  }

  &:active {
    background-color: var(--powder-400);
    border-color: var(--powder-600);
    box-shadow: none;
  }

  &:focus {
    color: var(--powder-800);
    background-color: var(--powder-300);
  }
}

.full {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.isLoading {
  padding-left: 2.2em;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    opacity: 0.3;
    left: 0.5em;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-color: currentColor;
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0.5em;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-left-color: currentColor;
    border-radius: 50%;
    animation: spin 0.9s infinite cubic-bezier(0.5, 0.1, 0.5, 0.9);
    filter: invert(0);
    transform-origin: 50% 50% 1px;
  }

  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
}
