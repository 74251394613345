.container {
  padding: 16px;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.05),
    0 2px 8px rgba(0, 0, 0, 0.05);
}

.buttonContainer {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  margin: 10px 0px;
  padding-left: 16px;
}

.status {
  color: var(--red-500);
  font-size: 12px;
}
