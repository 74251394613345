@media (max-width: 768px) {
  .articleView{
    margin-top: 40px;
  }}
.post-metadata-bar > div {
  max-width: 95vw;
  margin: auto;
}

button.theme-btn.btn {
  color: #fff;
  background-color: #6e98ae;
  border: 1px solid transparent;
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 40%);
  padding: 0.7em;
}

.gray {
  color: #828282;
}

.orange {
  background-color: #6e98ae;
}

.background-gray {
  background-color: rgb(246,246,239);
}

.fil {
  font-size: 11px;
}
.w100 {
  width: 100%;
}
.w85 {
  width: 85%;
}

/* 14-06-22 */
#__next{
  margin-top:16px;
}
.center {
  margin-right: auto;
  margin-left: auto;
}
.flex{
  display: flex;
  /* align-items: center; */
}
.flex.justify-between{
  justify-content: space-between;
}
.p-2{
  padding: 10px;
}
.mr1 {
  margin-right: 0.25rem;
}
.fw7 {
  font-weight: 700;
}
.white , .white a{
  color: #fff;
}
.ml1{
  margin-left: 4px;
}
.center .flex{
  font-size: 16px;
}
.ph3 {
  padding-left: 13px;
  padding-right: 13px;
}
.pv1 {
  padding-top:4px;
  padding-bottom:4px;
}
.mt2 {
  margin-top: 8px;
}
.mv3 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.ml4 {
  margin-left: 32px;
}
.mr2 {
  margin-right: 8px;
}
.lh-copy {
  font-size: 14px;
  display: flex;
}
.ml1.pointer.white a {
  padding-left: 6px;
  border-right: 2px solid;
  padding-right: 6px;
}