@import "styles/index";

.circle {
  border-radius: 50%;
  border-width: 22px;
  border-style: solid;
  width: 145px;
  height: 145px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 830px) {
    left: 0%;
    transform: translateX(0);
  }

  @include spreadBorderColors();

  &__line {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -180px;
    transform: translateY(-50%);
    height: 2px;
    width: 140px;

    @include spreadBgColors();

    &_right {
      left: 160px;
    }
  }
}

.timeline-item {
  height: 180px;
  display: flex;
  position: relative;

  &-text {
    width: 300px;
    font-size: 13px;
    @media (max-width: 830px) {
      width: auto;
      padding-left: 180px;
    }
  }

  &-estimation {
    @include spreadTextColors();
  }

  &-description {
    line-height: 20px;
    font-size: 13px;
  }

  &-link {
    color: #9bcf7e;

    &:hover {
      color: #9bcf7e;
    }
  }
}

.estimation-detail-item {
  position: relative;
  display: flex;
  height: 215px;
  align-items: baseline;
  // max-width: 400px;
  // max-height: 209px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 12px;
}

// **********************************
@media (max-width: 700px) {
  .main-bid-card>div:first-child {
    display: none;
  }
}
.main-bid-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ddd;
  padding: 10px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  position: relative;
  font-size: 12px !important;
}
.main-bid-card>* {
  font-size: 12px !important;
  flex: 2 1 auto;
}

.main-bid-card td {
  font-size: 12px !important;
}
.main-bid-card>div:first-child {
  width: 300px;
}
.main-bid-card > div {
  // min-width: 300px;
  margin: 0 10px;
}
// .main-bid-card > div:nth-child(1) {
//   width: 36%;
// }
// .main-bid-card > div:nth-child(2) {
//   width: 36%;
// }
// .main-bid-card > div:nth-child(3) {
//   width: 16%;
// }
// .main-bid-card > div:nth-child(4) {
//   width: 12%;
// }
.main-bid-card div div {
  width: 100%;
  // max-width: 100%;
}
.total-summary {
  font-size: 15px !important;
  font-family: "poppins-Medium";
  padding: 10px 0;
  padding-left: 0 !important;
}
.total-summary td {
  padding: 0 !important;
}
.bid-history-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}
.bid-history-table tbody tr td {
  color: #2c3e50;
  padding: 4.5px 10px;
  font-family: "Poppins-medium";
}

.bid-history-table tbody .item-child td {
  
  border: 1px solid #ddd;
  border-radius: 0px;
  border-left: none;
  border-right: none;
}
.bid-history-table tbody .item-child td:first-child {
  
  border: 1px solid #ddd;
  border-radius: 6px 0px 0px 6px;
  border-right: none;
  white-space: nowrap;
}
.bid-history-table tbody .item-child td:last-child {
  border: 1px solid #ddd;
  border-radius: 0px 6px 6px 0px;
  border-left: none;
}

.export-detail-div {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  color: #2c3e50;
}
.export-detail-div > div {
  font-family: "Poppins-medium";
  font-size: 13px;
}
.export-detail-div > div:first-child {
  margin-bottom: 10px;
}
.estimation-div {
  padding: 10px;
  color: #2c3e50;
  margin-top: 10px;
  div {
    font-family: "Poppins-medium";
    font-size: 13px;
  }
}
.timeline-item-link {
  color: #2c3e50 !important;
  justify-content: space-between;
  margin-top: 5px;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
  svg {
    width: 13px;
    margin-right: 8px;
  }
}

.bid-date-container {
  background-color: #2c3e50;
  border-radius: 6px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 160px;
  .month-text {
    font-family: "poppins-Medium";
    font-size: 24px;
    padding: 2.5px 0;
  }
  .time-text {
    font-size: 18px;
    padding: 2.5px 0;
  }
}

.bid-viewModal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  i {
    font-size: 40px;
    display: block;
  }
  a {
    font-size: 12px;
    font-family: "Poppins-medium";
    text-decoration: none;
    transition: .2s;
    &:hover{
       i {transform: scale(1.07);
      transition: .2s;}
    }
  }
}

.collapse-icon-wrap {
  border: 1px solid #3a5765;
  padding: 3px 4px;
  border-radius: 50%;
  font-size: 11px;
  color: #3a5765;
}
.resizer-icon {
  position: absolute;
  right: 0px;
  bottom: 0;
}
.view-modaltext {
  font-family: "Poppins-medium";
  color: #34495e;
}
