.light-grey {
  background-color:rgb(240,240,240);
}

.btn-radius{
  border-radius: 8px;
}

.action-btns{
  max-width: 20px;
  padding: 9px 22px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
}