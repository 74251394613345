@media only screen and (min-width: 768px) {
  .desktop-hide {
    display: none !important;
  }
}

.mobile-hide {
  gap: 15px;
}
@media only screen and (max-width: 768px) {
  .mobile-hide {
    li,
    button:not(#backButton) {
      display: none !important;
    }
  }

  #backButton {
    margin-right: 35px !important;
  }
  // .desktop-hide {
  //   zoom: 1.2;
  // }
}

@media (max-width: 400px) {
  #backButton {
    // width: 40px;
  }
}
.header-logo {
  margin-right: 25px;
}

@media only screen and (max-width: 768px) {
  .header-logo {
    margin-right: 100px;
  }
}
.centerMargin {
  margin: auto;
}
.custom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  margin: 0 25px;
}

.header {
  top: 0;
  // padding-top: 3px;
  left: 0;
  right: 0;
  max-width: 100vw;
  z-index: 50;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.header .custom-container {
  max-width: unset !important;
}
.header-content {
  // height:55px;
}

.header-content button {
  margin: 0 5px !important;
}

.header-content button span {
  padding-right: 2px;
}

.header-logo {
  width: 100px;
  cursor: pointer;
}
.bidlight-logo {
  width: 20px;
  cursor: pointer;
  color: "#3a5765";
  filter: brightness(0) saturate(100%) invert(28%) sepia(51%) saturate(292%) hue-rotate(154deg) brightness(99%) contrast(93%);
}

.oneBuild-logo {
  width: 40px;
  cursor: pointer;
  color: "#3a5765";
  filter: brightness(0) saturate(100%) invert(28%) sepia(51%) saturate(292%) hue-rotate(154deg) brightness(99%) contrast(93%);
}
.header-btn {
  padding: 0 10px !important;
  height: 33px !important;
  display: flex !important;
  align-items: center;
  margin-right: 20px !important;
  text-transform: inherit !important;
  font-weight: 600 !important;
  // font-size: 16px !important;
  img {
    margin-right: 8px;
    width: 20px;
  }
}

.download-list {
  background-color: #f8fafc;
  border-radius: 10px;
  padding: 0px 10px;
  color: #68676c;
  font-size: 14px;
  font-family: "poppins-regular";
  margin-bottom: 10px;
  height: 40px;
  position: relative;
  padding-right: 0;
  font-weight: 600;
  border: 1px solid #ddd;
  i {
    // background-color: #455e6c;
    color: #455e6c;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    margin-right: 14px;
  }
  img{
    width: 38px;
  }
}
// .navDropToggle .bm-overlay {
//   zoom: 1.5;
// }


.mobile-sidebar-btngroup{
  text-align: center;
  display: flex;
  align-items: center;
  height : 260px;
  justify-content: center;

  button{
    box-shadow: none;
    font-size: 14px;
    /* border-bottom: 1px solid #ddd; */
    border-radius: 7px !important;
    text-transform: capitalize;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px 25px;
    border: 1px solid #ddd;
    margin-bottom: 10px;

    i{
      font-size: 12px !important;
    }
    svg, img{
      width: 12px !important;
      fill: #455e6c;
      stroke: #455e6c;
    }
  }
}