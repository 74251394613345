.container {
  padding: 24px 12px 24px 24px;
  padding-top: 0;
}

.borderBottom {
  border-bottom: 1px solid var(--black-100);
}

.title {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  word-break: break-word;

  h1 {
    font-size: 27px;
    max-width: 950px;
    font-weight: 600 !important;
    font-family: 'poppins-SemiBold';
  }
}

.summary {
  margin-top: 20px;
  font-size: 15px;
  max-width: 630px;
}

.buttonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: fit-content;

  a {
    background-color: #283f4b !important;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    /* border-radius: ; */
    flex-shrink: 0;
    @media (max-width: 1200px) {
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 768px){ 
  .container {
    padding: 10px;
  }
  .title {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    word-break: break-word;
  
    h1 {
      font-size: 17px;
      max-width: 950px;
      font-weight: 600 !important;
      font-family: 'poppins-SemiBold';
    }
  }
  .buttonContainer {
    a{
      font-size: 10px;
      margin-bottom: 0px;
      padding: 5px 10px;
    }
  }
}