@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap");
@font-face {
  font-family: "poppins-bold";
  src: url("../../assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "poppins-SemiBold";
  src: url("../../assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "poppins-Medium";
  src: url("../../assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "poppins-italic";
  src: url("../../assets/fonts/Poppins-Italic.ttf");
}
.active1 {
  transition: all 500ms ease;
}
.hidden {
  transform: translateY(-120px) !important;
}
/* html {
     overflow-x: hidden;
} */
div,
p,
body {
  font-family: "Nunito", sans-serif;
  /* overflow-x: hidden;
     */
}
button.owl-dot.active span {
  color: #71a0b8;
  background: #71a0b8 !important;
}
#howto {
  overflow-x: hidden !important;
}
.footer a {
  color: #fff;
  /* font-size: 20px; */
  line-height: 40px;
}
.contactinfo {
  color: #000000 !important;
  font-weight: 700 !important;
}
.step-underline {
  color: #71a0b8;
}
.step-underline > p {
  color: #000000;
  font-weight: 700 !important;
}
.step-text {
  text-decoration: none;
  position: relative;
}
.step-text:after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0px;
  color: #71a0b8 !important;
  border-width: 0 0 3px;
  border-style: solid;
}
/* Base */
body {
  line-height: 1.7;
  color: #808080;
  font-weight: 400;
  font-size: 1rem;
  /* zoom: 0.85; */
}
::-moz-selection {
  background: #000;
  color: #fff;
}
::selection {
  background: #000;
  color: #fff;
}
a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a:hover {
  text-decoration: none;
}
h1,
h2 {
  font-weight: 700 !important;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Nunito", sans-serif;
}
.border-2 {
  border-width: 2px;
}
.text-black {
  color: #000 !important;
}
.bg-black {
  background: #000 !important;
}
.color-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5);
}
.color-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5);
}

.offcanvas-menu {
  position: relative;
}
.offcanvas-menu:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 21;
  background: rgba(0, 0, 0, 0.2);
}
.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-primary {
  font-size: 22px;
  border-radius: 10px !important;
  height: 65px !important;
  border: 2px solid #455d6b;
  background: transparent !important;
  border-color: #455d6b !important;
  text-transform: capitalize;
  color: #455d6b;
  position: relative;
  min-width: 300px;
  top: 0;
  transition: 0.3s all ease;
  box-shadow: none !important;
  z-index: 12;
  line-height: 32px !important;
  text-align: left;
  padding-left: 1.5rem !important;
}
.btn.btn-primary:hover {
  top: -2px;
  background: #71a0b8 !important;
  border-color: #71a0b8 !important;
  color: #fff;
  -webkit-box-shadow: 0 5px 30px -5px rgba(66, 133, 244, 0.7) !important;
  box-shadow: 0 5px 30px -5px rgba(66, 133, 244, 0.7) !important;
}
.font-icon {
  /* position: absolute; */
  /* right: 20px; */
  margin-left: 15px;
}
.line-height-1 {
  line-height: 1 !important;
}
.bg-black {
  background: #000;
}
.form-control {
  height: 43px;
  border-radius: 8px;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}
.form-control:active,
.form-control:focus {
}
.form-control:hover,
.form-control:active,
.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.site-section {
  padding: 2em 0;
}

.site-section.site-section-sm {
  padding: 4em 0;
}
.site-section-heading {
  padding-bottom: 20px;
  margin-bottom: 0px;
  position: relative;
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .site-section-heading {
    font-size: 3rem;
  }
}
.border-top {
  border-top: 1px solid #edf0f5 !important;
}
.site-footer {
  padding: 4em 0;
  background: #333333;
}
@media (min-width: 768px) {
  .site-footer {
    padding: 8em 0;
  }
}
.site-footer .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.site-footer p {
  color: #737373;
}
.site-footer h2,
.site-footer h3,
.site-footer h4,
.site-footer h5 {
  color: #fff;
}
.site-footer a {
  color: #999999;
}
.site-footer a:hover {
  color: white;
}
.site-footer ul li {
  margin-bottom: 10px;
}
.site-footer .footer-heading {
  font-size: 16px;
  color: #fff;
}
.bg-text-line {
  display: inline;
  background: #000;
  -webkit-box-shadow: 20px 0 0 #000, -20px 0 0 #000;
  box-shadow: 20px 0 0 #000, -20px 0 0 #000;
}
.text-white-opacity-05 {
  color: rgba(255, 255, 255, 0.5);
}
.text-black-opacity-05 {
  color: rgba(0, 0, 0, 0.5);
}
.hover-bg-enlarge {
  overflow: hidden;
  position: relative;
}
@media (max-width: 991.98px) {
  .hover-bg-enlarge {
    height: auto !important;
  }
}
.hover-bg-enlarge > div {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.8s all ease-in-out;
  -o-transition: 0.8s all ease-in-out;
  transition: 0.8s all ease-in-out;
}
.hover-bg-enlarge:hover > div,
.hover-bg-enlarge:focus > div,
.hover-bg-enlarge:active > div {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
@media (max-width: 991.98px) {
  .hover-bg-enlarge .bg-image-md-height {
    height: 300px !important;
  }
}
.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.bg-image.overlay {
  position: relative;
}
.bg-image.overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.bg-image > .container {
  position: relative;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .img-md-fluid {
    max-width: 100%;
  }
}
@media (max-width: 991.98px) {
  .display-1,
  .display-3 {
    font-size: 3rem;
  }
}
.play-single-big {
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff !important;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.play-single-big > span {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-40%, -50%);
  -ms-transform: translate(-40%, -50%);
  transform: translate(-40%, -50%);
}
.play-single-big:hover {
  width: 120px;
  height: 120px;
}
.overlap-to-top {
  margin-top: -150px;
}
.ul-check {
  margin-bottom: 50px;
}
.ul-check li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
  line-height: 1.8;
  color: #fff;
  /* font-size: 18px; */
  font-family: "poppins-SemiBold";
  /* padding-right: 180px; */
}
.ul-check li span {
  color: #b9c2c8;
  font-family: "poppins-regular";
}
.ul-check li:before {
  left: 0;
  font-size: 13px;
  top: 0.3rem;
  font-family: "icomoon";
  content: "\e5ca";
  position: absolute;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
}
.ul-check.white li:before {
  color: #fff;
}
.ul-check.success li:before {
  color: #fff;
}
.ul-check.primary li:before {
  color: #71a0b8 !important;
}
.select-wrap,
.wrap-icon {
  position: relative;
}
.select-wrap .icon,
.wrap-icon .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 22px;
}
.select-wrap select,
.wrap-icon select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}
/* Navbar */
.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  width: 100%;
  background: #fff;
}
.site-navbar .site-logo {
  position: relative;
  left: 0;
  top: -5px;
}
.site-navbar .site-navigation.border-bottom {
  border-bottom: 1px solid #f3f3f4 !important;
}
.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}
.site-navbar .site-navigation .site-menu .active {
  color: #71a0b8 !important;
  display: inline-block;
  padding: 15px 20px;
}
.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li > a {
  padding: 15px 20px;
  color: #000;
  display: inline-block;
  text-decoration: none !important;
}
.site-navbar .site-navigation .site-menu > li > a:hover {
  color: #71a0b8 !important;
}
.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}
.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}
.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: "\e313";
  font-size: 16px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon";
}
.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  border-top: 2px solid #71a0b8 !important;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #edf0f5;
  border-right: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  bottom: 100%;
  display: none;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
  color: #71a0b8 !important;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  background: #f4f5f9;
  color: #25262a;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: "\e315";
  right: 20px;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:hover
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:active
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:focus
  > a {
  background: #f4f5f9;
  color: #25262a;
}
.site-navbar .site-navigation .site-menu .has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children:focus > a,
.site-navbar .site-navigation .site-menu .has-children:active > a {
  color: #71a0b8 !important;
}
.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}
.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}
.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  /* vh */
  height: 100vh;
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.offcanvas-menu .site-mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #25262a;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}
.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  /* vh */
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}
.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #212529;
}
.site-mobile-menu .site-nav-wrap a:hover {
  color: #4285f4;
}
.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}
.site-mobile-menu .site-nav-wrap li .active {
  color: #4285f4;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: "icomoon";
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.site-mobile-menu .site-nav-wrap > li > a {
  padding-left: 20px;
  font-size: 20px;
}
.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] {
  float: left;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 5em;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li {
  width: auto;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
  padding-left: 15px !important;
}
.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}
.sticky-wrapper .site-navbar {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.sticky-wrapper.is-sticky .site-navbar {
  -webkit-box-shadow: 4px 0 20px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 0 20px -10px rgba(0, 0, 0, 0.2);
}
.sticky-wrapper .shrink {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
/* Blocks */
.primary-gradient,
.bg-image2.overlay:before {
  background: #455d6b;
  background: -moz-linear-gradient(left, #455d6b 0%, #42e2f4 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #455d6b),
    color-stop(100%, #42e2f4)
  );
  background: -webkit-linear-gradient(left, #455d6b 0%, #42e2f4 100%);
  background: -o-linear-gradient(left, #455d6b 0%, #455d6b 100%);
  background: -ms-linear-gradient(left, #455d6b 0%, #455d6b 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#455d6b),
    to(#455d6b)
  );
  background: linear-gradient(to right, #455d6b 0%, #455d6b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#455D6B', endColorstr='#455D6B', GradientType=1);
}
.img-absolute {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 60%;
  -webkit-transform: translateY(-50%) translateX(30%);
  -ms-transform: translateY(-50%) translateX(30%);
  transform: translateY(-50%) translateX(30%);
}
.img-absolute img {
  max-width: 100%;
}
@media (max-width: 991.98px) {
  .img-absolute {
    position: relative;
    top: auto;
    -webkit-transform: translateY(0%) translateX(0%);
    -ms-transform: translateY(0%) translateX(0%);
    transform: translateY(0%) translateX(0%);
    margin-bottom: 30px;
  }
}
.site-block-subscribe .btn {
  padding-left: 20px;
  padding-right: 20px;
}
.site-block-subscribe .form-control {
  font-weight: 300;
  border-color: #fff !important;
  height: 80px;
  font-size: 22px;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
}
.site-block-subscribe .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}
.site-block-subscribe .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}
.site-block-subscribe .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}
.site-block-subscribe .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}
.bg-custom {
  background: #f6f6f6 !important;
}
.team-member {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
}
.team-member img {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.team-member:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(66, 133, 244, 0.8);
  z-index: 2;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.team-member .text {
  top: 50%;
  text-align: center;
  position: absolute;
  padding: 20px;
  -webkit-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  opacity: 0;
  visibility: hidden;
  color: #fff;
}
.team-member:hover:before,
.team-member:focus:before,
.team-member:active:before {
  opacity: 1;
  visibility: visible;
}
.team-member:hover img,
.team-member:focus img,
.team-member:active img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.team-member:hover .text,
.team-member:focus .text,
.team-member:active .text {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
  z-index: 4;
}
.feature-blocks-1 {
  position: relative;
  margin-top: -70px;
  z-index: 2;
  color: #999999;
}
.feature-blocks-1.no-margin-top {
  margin-top: 0px;
}
.feature-blocks-1 .feature-block-1 {
  position: relative;
  top: 0;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  overflow: hidden;
  z-index: 1;
}
.feature-blocks-1 .feature-block-1.bg {
  position: relative;
  background-size: cover;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.feature-blocks-1 .feature-block-1 .text {
  z-index: 10;
  position: relative;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.feature-blocks-1 .feature-block-1:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.feature-blocks-1 .feature-block-1 p {
  color: #cccccc;
}
.feature-blocks-1 .feature-block-1:hover,
.feature-blocks-1 .feature-block-1:focus,
.feature-blocks-1 .feature-block-1:active {
  top: -10px;
  -webkit-box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.4);
}
.feature-blocks-1 .feature-block-1:hover .text,
.feature-blocks-1 .feature-block-1:focus .text,
.feature-blocks-1 .feature-block-1:active .text {
  opacity: 1;
  visibility: visible;
}
.feature-blocks-1 .feature-block-1:hover.bg,
.feature-blocks-1 .feature-block-1:focus.bg,
.feature-blocks-1 .feature-block-1:active.bg {
  position: relative;
  background-size: cover;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 2;
}
.feature-blocks-1 .feature-block-1:hover:before,
.feature-blocks-1 .feature-block-1:focus:before,
.feature-blocks-1 .feature-block-1:active:before {
  background: rgba(0, 0, 0, 0.9);
}
.site-blocks-vs .bg-image {
  padding-top: 3em;
  padding-bottom: 3em;
}
@media (min-width: 992px) {
  .site-blocks-vs .bg-image {
    padding-top: 5em;
    padding-bottom: 5em;
  }
}
.site-blocks-vs .image {
  width: 80px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
}
@media (max-width: 991.98px) {
  .site-blocks-vs .image {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.site-blocks-vs .image img {
  border-radius: 50%;
}
.site-blocks-vs .image.image-small {
  width: 50px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}
.site-blocks-vs .country {
  color: rgba(255, 255, 255, 0.7);
}
.match-entry {
  position: relative;
  -webkit-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.match-entry .small {
  color: #ccc;
  font-weight: normal;
  letter-spacing: 0.15em;
}
.match-entry:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  z-index: 90;
  -webkit-box-shadow: 0 5px 40px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 40px -5px rgba(0, 0, 0, 0.1);
}
.post-entry {
  position: relative;
  overflow: hidden;
  border: 1px solid #e6e6e6;
}
.post-entry,
.post-entry .text {
  border-radius: 4px;
  border-top: none !important;
}
.post-entry .date {
  color: #ccc;
  font-weight: normal;
  letter-spacing: 0.1em;
}
.post-entry .image {
  overflow: hidden;
  position: relative;
}
.post-entry .image img {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.post-entry:hover .image img,
.post-entry:active .image img,
.post-entry:focus .image img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.site-block-tab .nav-item {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 0 20px;
}
.site-block-tab .nav-item:first-child {
  margin-left: 0 !important;
}
.site-block-tab .nav-item > a {
  padding-left: 0 !important;
  background: none !important;
  padding-right: 0 !important;
  border-bottom: 2px solid transparent;
  border-radius: 0 !important;
  font-weight: bold;
  color: #cccccc;
}
.site-block-tab .nav-item > a:hover,
.site-block-tab .nav-item > a.active {
  border-bottom: 2px solid #4285f4;
  color: #000;
}
.block-13,
.slide-one-item {
  position: relative;
  z-index: 1;
}
.block-13 .owl-nav,
.slide-one-item .owl-nav {
  position: relative;
  position: absolute;
  bottom: -90px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.block-13 .owl-nav .owl-prev,
.block-13 .owl-nav .owl-next,
.slide-one-item .owl-nav .owl-prev,
.slide-one-item .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  padding: 20px;
  font-size: 30px;
  color: #000;
}
.block-13 .owl-nav .owl-prev.disabled,
.block-13 .owl-nav .owl-next.disabled,
.slide-one-item .owl-nav .owl-prev.disabled,
.slide-one-item .owl-nav .owl-next.disabled {
  opacity: 0.2;
}
.slide-one-item.home-slider .owl-nav {
  position: absolute !important;
  top: 50% !important;
  bottom: auto !important;
  width: 100%;
}
@media (max-width: 991.98px) {
  .slide-one-item.home-slider .owl-nav {
    display: none;
  }
}
.slide-one-item.home-slider .owl-prev {
  left: 10px !important;
}
.slide-one-item.home-slider .owl-next {
  right: 10px !important;
}
.slide-one-item.home-slider .owl-prev,
.slide-one-item.home-slider .owl-next {
  color: #fff;
  position: absolute !important;
  top: 50%;
  padding: 0px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  line-height: 0;
  text-align: center;
  font-size: 25px;
}
@media (min-width: 768px) {
  .slide-one-item.home-slider .owl-prev,
  .slide-one-item.home-slider .owl-next {
    font-size: 25px;
  }
}
.slide-one-item.home-slider .owl-prev > span,
.slide-one-item.home-slider .owl-next > span {
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.slide-one-item.home-slider .owl-prev:hover,
.slide-one-item.home-slider .owl-prev:focus,
.slide-one-item.home-slider .owl-next:hover,
.slide-one-item.home-slider .owl-next:focus {
  background: black;
}
.slide-one-item.home-slider:hover .owl-nav,
.slide-one-item.home-slider:focus .owl-nav,
.slide-one-item.home-slider:active .owl-nav {
  opacity: 10;
  visibility: visible;
}
.slide-one-item .owl-dots {
  text-align: center;
}
.slide-one-item .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}
.slide-one-item .owl-dots .owl-dot span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #dee2e6;
}
.slide-one-item .owl-dots .owl-dot.active span {
  background: #4285f4;
}
.block-12 {
  position: relative;
}
.block-12 figure {
  position: relative;
}
.block-12 figure:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(18%, transparent),
    color-stop(99%, rgba(0, 0, 0, 0.8)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: -o-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
}
.block-12 .text-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 10;
}
.block-12 .text-overlay h2 {
  color: #fff;
}
.block-12 .text {
  position: relative;
  top: -100px;
}
.block-12 .text .meta {
  text-transform: uppercase;
  padding-left: 40px;
  color: #fff;
  display: block;
  margin-bottom: 20px;
}
.block-12 .text .text-inner {
  -webkit-box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 10px;
  margin-right: 30px;
  position: relative;
}
@media (min-width: 576px) {
  .block-12 .text .text-inner {
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .block-12 .text .text-inner {
    padding: 30px 40px;
  }
}
.block-12 .text .text-inner:before {
  position: absolute;
  content: "";
  width: 80px;
  height: 4px;
  margin-top: -4px;
  background: #4285f4;
  top: 0;
}
.block-12 .text .text-inner .heading {
  font-size: 20px;
  margin: 0;
  padding: 0;
}
.block-16 figure {
  position: relative;
}
.block-16 figure .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  width: 70px;
  height: 70px;
  background: #fff;
  display: block;
  border-radius: 50%;
  opacity: 1;
  color: #4285f4 !important;
}
.block-16 figure .play-button:hover {
  opacity: 1;
}
.block-16 figure .play-button > span {
  position: absolute;
  left: 55%;
  top: 50%;
  -webkit-transform: translate(-60%, -50%);
  -ms-transform: translate(-60%, -50%);
  transform: translate(-60%, -50%);
}
.block-25 ul,
.block-25 ul li {
  padding: 0;
  margin: 0;
}
.block-25 ul li a .image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
}
.block-25 ul li a .image img {
  border-radius: 4px;
  max-width: 100%;
  opacity: 1;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.block-25 ul li a .text .heading {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #999999;
}
.block-25 ul li a .meta {
  color: #4285f4;
}
.block-25 ul li a:hover img {
  opacity: 0.5;
}
.block-25 ul li a:hover .text .heading {
  color: #fff;
}
#date-countdown .countdown-block {
  color: #b3b3b3;
}
#date-countdown .label {
  font-size: 40px;
  color: #000;
}
.next-match .image {
  width: 50px;
  border-radius: 50%;
}
.player {
  position: relative;
}
.player img {
  max-width: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.player .team-number {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #4285f4;
  border-radius: 50%;
  color: #fff;
}
.player .team-number > span {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.player h2 {
  font-size: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.player .position {
  font-size: 14px;
  color: #b3b3b3;
  text-transform: uppercase;
}
.site-block-27 ul,
.site-block-27 ul li {
  padding: 0;
  margin: 0;
}
.site-block-27 ul li {
  display: inline-block;
  margin-bottom: 4px;
}
.site-block-27 ul li a,
.site-block-27 ul li span {
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.site-block-27 ul li.active a,
.site-block-27 ul li.active span {
  background: #4285f4;
  color: #fff;
  border: 1px solid transparent;
}
.site-block-feature-7 .icon {
  -webkit-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  position: relative;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.site-block-feature-7:hover .icon,
.site-block-feature-7:focus .icon,
.site-block-feature-7:active .icon {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.unit-1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;
}
.unit-1:after {
  content: "";
  z-index: 1;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(18%, transparent),
    color-stop(99%, rgba(0, 0, 0, 0.8)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: -o-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
}
.unit-1 img {
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.unit-1 img,
.unit-1 .unit-1-text {
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.unit-1 .unit-1-text {
  z-index: 2;
  position: absolute;
  bottom: -90px;
  color: #fff;
  display: block;
  width: 100%;
}
.unit-1 .unit-1-text .unit-1-heading {
  font-size: 1.5rem;
  position: relative;
}
.unit-1 p {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: rgba(255, 255, 255, 0.5);
}
.unit-1:hover p {
  opacity: 1;
  visibility: visible;
}
.unit-1:hover .unit-1-text {
  bottom: 30px;
}
.unit-1:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.overlap-section {
  margin-top: -150px;
  position: relative;
  z-index: 9;
}
.unit-4 a {
  color: #71a0b8;
}
.unit-4 {
  padding: 30px;
  background: #fff;
  border-radius: 15px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  text-align: left;
  top: 0;
  margin-bottom: 1em;
  text-align: left;
}
/* .unit-4 .unit-4-icon {
     position: absolute;
     -webkit-transform: translateX(-50%);
     -ms-transform: translateX(-50%);
     transform: translateX(-50%);
     left: 50%;
     top: -50px;
}
 */
.unit-4 .unit-4-icon .icon-wrap {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  background-color: #455d6b;
  -webkit-box-shadow: 0 4px 20px -5px rgba(66, 133, 244, 0.4);
  box-shadow: 0 4px 20px -5px rgba(66, 133, 244, 0.4);
}
.unit-4 .unit-4-icon .icon-wrap span {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  color: #fff !important;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.unit-4 .unit-4-icon .icon-wrap span img {
  width: 35px;
  margin: auto;
  display: flex;
}
.unit-4 h3 {
  font-size: 18px;
  color: #000;
  transition: 0.3s all ease;
  font-family: "poppins-bold";
}

.unit-4 *:last-child {
  margin-bottom: 0;
}
/* .unit-4:hover {
     background: #71A0B8 !important;
     -webkit-box-shadow: 0 4px 20px -5px rgba(66, 133, 244, 0.4);
     box-shadow: 0 4px 20px -5px rgba(66, 133, 244, 0.4);
     top: -7px;
}
 .unit-4:hover .icon-wrap {
     background-color: #fff;
}
 .unit-4:hover .icon-wrap span {
     color: #71A0B8 !important;
}
 .unit-4:hover h3 {
     color: #fff;
}
 .unit-4:hover p {
     font-weight: 300;
     color: rgba(255, 255, 255, 0.7);
}
 .unit-4:hover p a {
     color: #fff;
}
 */
.h-entry img {
  margin-bottom: 30px;
}
.h-entry .meta {
  color: #b3b3b3;
  font-size: 14px;
}
.h-entry h2 {
  font-size: 20px;
}
.overlap-left {
  margin-left: -100px;
}
@media (max-width: 991.98px) {
  .overlap-left {
    margin-left: 0px;
  }
}
.overlap-section {
  margin-top: -100px;
}
.feature-1,
.free-quote,
.feature-3 {
  background: #4285f4;
  padding: 30px 50px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}
.feature-1 .heading,
.free-quote .heading,
.feature-3 .heading {
  font-size: 22px;
  text-transform: uppercase;
  color: #fff;
}
.feature-1 .icon,
.free-quote .icon,
.feature-3 .icon {
  border: 2px solid rgba(255, 255, 255, 0.5);
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: relative;
  border-radius: 50%;
  margin: 0 auto !important;
}
.feature-1 .icon > span,
.free-quote .icon > span,
.feature-3 .icon > span {
  font-size: 35px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.free-quote {
  background: #2a75f3;
}
.free-quote .form-control {
  border: 2px solid rgba(255, 255, 255, 0.5);
  background: none;
  color: #fff;
}
.free-quote .form-control:active,
.free-quote .form-control:focus {
  border: 2px solid #fff;
}
.free-quote .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-style: italic;
}
.free-quote .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-style: italic;
}
.free-quote .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-style: italic;
}
.free-quote .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-style: italic;
}
.feature-3 {
  background: #206ff2;
}
.border-primary {
  position: relative;
}
.border-primary h2 {
  font-weight: 700 !important;
}
.border-primary:after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 80px;
  height: 3px;
  background: #4285f4;
}
.border-primary.text-center:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.border-primary.text-left:after {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.testimonial {
  max-width: 800px;
  margin: 0 auto !important;
  text-align: center;
}
.testimonial blockquote {
  font-size: 1.5rem;
  font-style: italic;
}
.testimonial figure img {
  max-width: 100px;
  margin: 0 auto;
  border-radius: 50%;
}
.breadcrumb-custom {
  font-size: 1rem !important;
}
.text-muted {
  color: #ccc !important;
  font-size: 12px;
  text-transform: uppercase;
}
.how-it-work-item .number {
  width: 50px;
  height: 50px;
  background: #4285f4;
  color: #fff;
  line-height: 55px;
  font-weight: 300;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  border-radius: 50%;
  margin-bottom: 40px;
}
.how-it-work-item h2 {
  font-size: 18px;
  margin-bottom: 30px;
  color: #fff;
}
.how-it-work-item p {
  color: white;
}
.person h3 {
  font-size: 18px;
}
.ul-social-circle {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ul-social-circle li {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 5px;
}
.ul-social-circle li:first-child {
  margin-left: 0;
}
.ul-social-circle li span {
  width: 30px;
  height: 30px;
  background: #777;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  display: inline-block;
}
.ul-social-circle li a span {
  color: #fff;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.ul-social-circle li a:hover span {
  background: #2b2b2b;
  color: #fff;
}
.custom-pagination a,
.custom-pagination span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  background: #4285f4;
  color: #fff;
}
.custom-pagination span {
  background: #e6e6e6;
  color: #000;
}
.custom-pagination .more-page {
  background: none;
  letter-spacing: 0.1em;
}
.form-search-wrap {
  background: #fff;
  border-radius: 30px;
}
.form-search-wrap .form-control {
  border: none;
}
@media (max-width: 1199.98px) {
  .form-search-wrap .form-control {
    height: 55px;
  }
}
.form-search-wrap .btn {
  border-radius: 30px;
  padding: 10px 30px;
}
@media (max-width: 1199.98px) {
  .form-search-wrap .btn {
    width: 100% !important;
    display: block;
  }
}
.popular-category {
  background: #fff;
  display: block;
  text-align: center;
  padding: 30px 10px;
  border-radius: 7px;
  background: #f8f9fa;
  position: relative;
}
.popular-category .icon {
  display: block;
  -webkit-transition: 0.1s all ease;
  -o-transition: 0.1s all ease;
  transition: 0.1s all ease;
}
.popular-category .icon > span {
  line-height: 0;
  font-size: 40px;
}
.popular-category .caption {
  color: #666666;
  -webkit-transition: 0.1s all ease;
  -o-transition: 0.1s all ease;
  transition: 0.1s all ease;
}
.popular-category .number {
  padding: 2px 20px;
  border-radius: 30px;
  display: inline-block;
  background: #e9ecef;
  color: #000;
  font-size: 14px;
  -webkit-transition: 0.1s all ease;
  -o-transition: 0.1s all ease;
  transition: 0.1s all ease;
}
.popular-category:hover {
  background: #4285f4;
  -webkit-box-shadow: 0 5px 30px -5px rgba(66, 133, 244, 0.5);
  box-shadow: 0 5px 30px -5px rgba(66, 133, 244, 0.5);
}
.popular-category:hover .caption {
  color: #fff;
}
.popular-category:hover .icon {
  color: #fff;
}
.popular-category:hover .number {
  background: #2a75f3;
  color: #fff;
}
@media (max-width: 1199.98px) {
  .no-sm-border {
    border: none !important;
  }
}
.listing-item {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.listing-item:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
}
.listing-item img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.listing-item:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.listing-item .listing-item-content {
  position: absolute;
  bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 2;
  width: 100%;
}
.listing-item .listing-item-content .category {
  font-size: 12px;
  display: inline-block;
  padding: 5px 30px;
  background: #4285f4;
  border-radius: 30px;
  color: #fff;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.listing-item .listing-item-content h2 {
  font-size: 22px;
}
.listing-item .listing-item-content h2,
.listing-item .listing-item-content h2 a {
  color: #fff;
}
.listing-item .listing-item-content span {
  color: #fff;
}
.listing-item .listing-item-content .address {
  color: rgba(255, 255, 255, 0.8);
}
.listing-item .bookmark {
  position: absolute;
  right: 20px;
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(255, 255, 255, 0.3);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.listing-item .bookmark span {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.listing-item .bookmark:hover {
  background: #f23a2e;
}
.listing-item .bookmark:hover span {
  color: #fff;
}
.accordion-item[aria-expanded="true"] {
  color: #4285f4;
}
.social-media {
  display: inline-block;
  float: left;
  margin-bottom: 0px;
  padding: 0;
}
.social-media li {
  display: inline-block;
}
.ft-feature-1 {
  margin-bottom: 80px;
}
.ft-feature-1 .ft-feature-1-content {
  padding: 30px;
  position: relative;
  z-index: 10;
}
.ft-feature-1 h2,
.ft-feature-1 h3 {
  font-size: 20px;
  color: #fff;
}
.ft-feature-1 h3 {
  font-size: 18px;
}
.ft-feature-1 h3 .icon {
  font-size: 30px;
}
.ft-feature-1 a {
  color: #fff;
  text-decoration: underline;
}
.ft-feature-1 a:hover {
  color: #fff;
  text-decoration: none;
}
.ft-feature-1 .play {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #4285f4;
  margin-right: 1.5rem;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  text-decoration: none !important;
}
.ft-feature-1 .play span {
  color: #fff;
  display: inline;
}
.ft-feature-1 .img-feature {
  position: relative;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .ft-feature-1 .img-feature {
    margin-bottom: -80px;
  }
}
.ft-feature-1 p {
  color: #737373;
}
.box-with-humber {
  position: relative;
}
.box-with-humber .number-behind {
  font-size: 6rem;
  position: absolute;
  z-index: -1;
  top: -3rem;
  left: -3rem;
  font-weight: 900;
  color: #f2f4f5;
}
.box-with-humber h2 {
  font-size: 20px;
}
.media-1 {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}
.media-1:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  content: "";
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  opacity: 0;
  visibility: hidden;
}
.media-1 img {
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.media-1 .media-1-content {
  z-index: 4;
  top: 50%;
  position: absolute;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.media-1 .media-1-content h2 {
  position: relative;
  color: #fff;
  font-size: 1.5rem;
  -webkit-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
  opacity: 0;
  visibility: hidden;
  bottom: -10px;
  margin-bottom: 0px;
}
.media-1 .media-1-content .category {
  position: relative;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  opacity: 0;
  bottom: -10px;
  visibility: hidden;
  color: #999999;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.media-1:hover:after {
  opacity: 1;
  visibility: visible;
}
.media-1:hover h2 {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}
.media-1:hover .category {
  opacity: 1;
  visibility: visible;
  bottom: 0px;
  -webkit-transition-delay: 0.09s;
  -o-transition-delay: 0.09s;
  transition-delay: 0.09s;
}
.media-1:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.testimonial-wrap .owl-nav {
  display: none;
}
.section-sub-title {
  font-size: 13px;
  color: #4285f4;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.section-title {
  color: #455d6b;
  font-family: "poppins-bold";
  /* font-size: 34px; */
  font-weight: 900;
}
@media (max-width: 991.98px) {
  .section-title {
    /* font-size: 30px; */
  }
}
.position-relative {
  position: relative;
}
.experience {
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: #007bff;
  padding: 20px;
}
@media (max-width: 991.98px) {
  .experience {
    bottom: 0px;
    right: 0px;
  }
}
.experience span {
  display: block;
}
.experience .year {
  font-weight: 900;
  color: #fff;
  font-size: 3rem;
  letter-spacing: normal;
  line-height: 1.2;
}
.experience span {
  letter-spacing: 0.2em;
}
.experience .caption {
  color: #fff;
  letter-spacing: 0.4em;
  text-transform: uppercase;
}
.footer-subscribe .btn {
  height: 43px;
}
.feature-big h2 {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 30px;
}
.author-box {
  -webkit-box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #eee;
  border-left: 10px solid #4285f4;
}
.author-box *:last-child {
  margin-bottom: 0;
}
.author-box img {
  max-width: 50px;
}
.author-box blockquote {
  font-style: italic;
}
.shadow {
  -webkit-box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
}
.h-entry h2 a {
  color: #000;
  font-size: 30px;
}
@media (max-width: 991.98px) {
  .site-logo {
    top: 0;
  }
}
@media (max-width: 991.98px) {
  .main-menu {
    display: none !important;
  }
}
.overlap-image-wrap {
  position: relative;
}
.overlap-image-wrap .image-1 {
  position: relative;
}
.overlap-image-wrap .image-2 {
  max-width: 250px;
  position: absolute;
  bottom: -50px;
  right: -50px;
  z-index: 99;
}
.bg-image2 {
  background: url("../../assets/images/auth_bg.png");
  background-size: cover;
  // height: 550px;
  background-attachment: fixed;
  position: relative;
}
.bg-image2.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
}
.person {
  position: relative;
  text-align: center;
  background-color: white;
}
/* .last-child-team-row .col-lg-3 .person{
     margin-bottom: 0;
}
 */
.person figure {
  position: relative;
  margin-bottom: 0;
}
.person figure:before {
  display: none;
  position: absolute;
  content: "";
  z-index: 1;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  border: 2px solid #4285f4;
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.person .bio-img {
  position: relative;
  text-align: center;
  overflow: hidden;
  margin-bottom: 30px;
}
.person .bio-img img {
  position: relative;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.person .bio-img .social {
  z-index: 2;
  padding: 10px 0;
  width: 100%;
  position: absolute;
  bottom: 0%;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  left: 0;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  background: #71a0b8;
}
.person .bio-img .social a {
  display: inline-block;
  padding: 5px;
  color: #fff;
}
.person:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.person:hover .social {
  bottom: 0;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}
.person:hover figure:before {
  opacity: 0;
  visibility: hidden;
}
.sub-title {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #6e98ae;
  margin-bottom: 10px;
  font-family: "poppins-bold";
  height: 42px;
}
/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loader {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.form-subscribe .btn:hover,
.form-subscribe .btn:active,
.form-subscribe .btn:focus {
  background-color: #4285f4 !important;
  border-color: #4285f4 !important;
  color: #fff;
}
.bg-gray-features {
  background-color: #f1f1f1;
}
.bg-right-curve {
  border-top-right-radius: 25%;
}
.footer {
  background: #455d6b;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}
.subtitle {
  font-family: poppins-italic;
  color: #455d6b;
  opacity: 0.5;
  margin-top: 18px;
}
.title {
  font-size: 36px !important;
}
.lead {
  font-family: poppins-regular;
  color: #455d6b;
  opacity: 0.5;
  margin-top: 18px;
}
.font-team {
  letter-spacing: 0px;
  color: #455d6b !important;
  text-transform: capitalize;
  font-family: "poppins-SemiBold";
}
.form-register {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -2px 4px 8px #1b1e210f;
  border: none !important;
  margin-top: 25px;
  font-family: "poppins-medium";
  color: #848484 !important;
}
input.btn-login {
  background: #6e98ae 0% 0% no-repeat padding-box;
  box-shadow: -2px 4px 8px #1b1e210f;
  border-radius: 5px;
  width: 60%;
  text-align: center;
  height: 40px;
  border: none;
  color: #fff;
  font-family: "poppins-regular";
  margin-top: 30px;
}
.box-register {
  box-shadow: 0px 31px 72px #00000014;
  border-radius: 15px;
}
.box-register h2 {
  /* font-size: 28px; */
  font-family: "poppins-bold";
  margin-bottom: 15px;
}
.box-register small {
  font-size: 14px;
  display: block;
  width: 70%;
  margin: auto;
}
.formReg {
  width: 75%;
  margin: auto !important;
}
.formReg .col-md-12 input {
  height: 55px;
  border-radius: 5px;
  cursor: pointer;
}
.formReg .col-md-12 input:focus {
  box-shadow: -2px 4px 12px #1b1e210f !important;
}
.formReg .col-md-12 input:hover {
  box-shadow: -2px 4px 12px #1b1e210f !important;
}
.formReg .col-md-12 input::placeholder {
  font-family: "poppins-regular";
  padding-left: 20px;
}
.bg-about-image {
  background-image: url("../../assets/images/Intersection3.png");
  background-size: cover;
  min-height: 600px;
  padding-left: 60px !important;
  padding-right: 100px !important;
}
.bg-about-image-new {
  background-image: url("../../assets/images/landing/about-us.png");
  background-size: cover;
  padding: 40px;
  min-height: 600px;
}
.bg-about-image:before {
  content: "";
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.font-about {
  font-family: "poppins-bold";
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.font-mission {
  font-family: "poppins-Semibold";
  margin-top: 3rem;
  margin-bottom: 3rem;
  position: relative;
}
/* Brian */
.container {
  max-width: 1200px !important;
  margin: 20px auto;
}
@media (max-width: 768px) {
  nav#navigation {
    padding: 5px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
}

nav#navigation {
  max-width: 1300px !important;
  padding: unset;
}
nav#navigation .d-flex {
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-transform: capitalize;
  color: inherit;
  text-decoration: none;
}
nav#navigation .d-flex ul li a{
  white-space: pre;

  &:hover{
    color: #455d6b !important;
    font-weight: 600 !important;
    font-family: "poppins-bold";
  }
}
ul.nav.navbar-nav.navList.pt-3 {
  padding: 0 !important;
}
ul.nav.navbar-nav.navList.pt-3 a.pl-md-5 {
  padding: 10px 0px !important;
  margin: 0 8px;
  font-family: "poppins-regular";
}
ul.sign-up.mobile-hide.navbar-nav
  ul.nav.navbar-nav.navList.pt-3
  li:nth-child(2) {
  background: #6e98ae;
  padding: 17px 0;
  display: block;
  border-radius: 5px;
  color: #fff;
  font-family: "poppins-regular";
  cursor: pointer;
  border: 1px solid transparent;
}
/* ul.sign-up.mobile-hide.navbar-nav ul.nav.navbar-nav.navList.pt-3 li:nth-child(2){   
     background: transparent;
     color: #455D6B;
     border: 1px solid #455D6B;
} */
ul.nav.navbar-nav.navList.pt-3 {
  display: flex;
  align-items: center;
}

li.loginBtns {
  display: flex;
  height: 100%;
  gap: 2px;
}

.signup_btn a {
  /* background: #6e98ae; */
  padding: 12px;
  display: block;
  max-height: 100%;
  border-radius: 5px;
  color: #fff !important;
  font-family: "poppins-regular";
  cursor: pointer;
  border: 1px solid transparent;
  /* margin-right: 2px; */
  white-space: nowrap;
}
.btnLogin:hover {
  background: #fff;
  border: 1px solid black;
  color: inherit;
}
.signup_btn:hover a {
  color: black !important;
}
.authorization-logo {
  width: 20px;
}
nav#navigation .logo-title {
  font-size: 18px !important;
  font-family: "poppins-regular";
  padding-top: 3px !important;
  color: #fff !important;
  padding-left: 2px;
}
nav#navigation .navbar-brand {
  padding: 3px 24px;
  /* height: 100%;
  width: 100%;
  max-width: 150px; */
}
.btn.btn-primary {
  font-size: 24px;
  border-radius: 10px !important;
  height: 70px;
  border: 2px solid #455d6b;
  background: transparent !important;
  border-color: #455d6b !important;
  text-transform: capitalize;
  color: #455d6b;
  position: relative;
  min-width: 315px;
  top: 0;
  transition: 0.3s all ease;
  box-shadow: none !important;
  z-index: 12;
  line-height: 48px;
}
.unit-4.d-block {
  min-height: 358px;
  border-radius: 20px;
  padding: 30px 30px 40px 30px;
}
.unit-4 span.icon-wrap {
  margin-bottom: 16px !important;
}
.unit-4 h3 {
  margin-bottom: 20px;
  /* font-size: 24px; */
  color: #455d6b;
  transition: 0.3s all ease;
  font-family: "poppins-SemiBold";
  line-height: 35px;
}
.unit-4 p {
  font-weight: 500;
  color: #455d6b;
  opacity: 0.6;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  font-size: 12px;
  line-height: 25px;
  font-family: "poppins-italic";
}
.bg-about-image:before {
  content: "";
  background: rgb(69 93 107 / 80%);
}
p.lead-about {
  font-family: "poppins-italic";
  /* font-size: 20px; */
  border-bottom: 2px solid #fff;
  padding-bottom: 20px;
  position: relative;
  color: #ffffff61;
}
.person {
  position: relative;
  text-align: center;
  box-shadow: 0px 0px 9px 2px #0000000d;
  border-radius: 10px;
  padding: 32px 25px 15px 25px;
  min-height: 434px;
  margin-bottom: 5rem;
  margin-top: 1rem;
}
.person .bio-img {
  text-align: center;
  overflow: hidden;
  margin-bottom: 30px;
  position: absolute;
  top: -108px;
  left: 19%;
}
.row.justify-content-center .col-lg-3 {
  position: relative;
}
.person h2 {
  font-size: 26px;
  padding-top: 85px;
}
.person h5 {
  font-size: 26px;
  padding-top: 85px;
  min-height: 135px;
}
.person p {
  font-style: italic;
  color: grey;
}
.bg-step {
  background: #455d6b;
  height: 600px;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.bg-step .lead {
  color: #fff;
  padding-bottom: 2rem;
  width: 33%;
  margin: auto;
  /* height: 150px; */
}
.bg-step .section-title {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: "poppins-Semibold" !important;
}
.stepper-item-outer:nth-of-type(odd) .stepper-item-inner {
  width: 80px !important;
  height: 80px !important;
  transition: 0.2s;
}
.stepper-item-outer:nth-of-type(odd) {
  width: 80px !important;
  height: 80px !important;
  border: none !important;
}
.stepper-item-inner-active {
  background-color: #fff !important;
}
.stepper-item-inner-completed {
  background-color: white !important;
}
.stepper-item-outer:nth-of-type(odd)
  .stepper-item-inner.stepper-item-inner-active {
  transform: scale(1.4);
}
.step-button {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  width: 100% !important;
  bottom: 0;
}
.step-button input {
  letter-spacing: 0px;
  color: #fbfbfb;
  background: transparent;
  border: none;
  font-size: 24px;
  font-family: "poppins-Semibold";
}
.about-features {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 6rem !important;
  position: relative !important;
  top: 70px;
}
h3.head-bidlight {
  text-align: left;
  letter-spacing: 0px;
  color: #455d6b;
  font-family: "poppins-Medium";
  font-size: 44px;
}
h3.head-bidlight b {
  font-family: "poppins-bold";
}
p.lead-bidlight-estimate {
  letter-spacing: 0px;
  color: #455d6b;
  opacity: 0.5;
  font-family: "poppins-italic";
  /* font-size: 18px; */
  /* padding-right: 150px; */
  padding-top: 1rem;
}
.img-lead {
  position: relative;
  /* top: 90px; */
  width: 75%;
  z-index: 9;
}
.stepper-container .stepper-item .stepper-title {
  left: 34px;
  position: absolute;
  display: inline-block;
  width: auto !important;
  max-width: auto !important;
  min-width: auto !important;
  transform: translate(-39%, 5px);
  text-align: center !important;
  color: #455d6b !important;
  font-size: 12px !important;
  top: 15px;
  text-align: center;
  right: 0;
  font-size: 18px !important;
  font-family: "poppins-Semibold" !important;
  line-height: 22px;
  pointer-events: none;
}
.stepper-container {
  /* width: 1440px; */
  margin: 0 auto;
}
.stepper-container .stepper-item .stepper-title-active {
  color: #455d6b !important;
}
.img-custom-step {
  position: absolute;
  left: 0;
  right: 0;
  width: 450px;
  max-width: 40%;
}
.box-vacate {
  height: 250px;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  .box-vacate {
    height: 60px;
  }
}
.step-button svg path {
  fill: #fff;
}
.step-button svg {
  font-size: 24px;
  margin-left: 10px;
  margin-right: 10px;
}
#pricing-section .row .col-lg-3 {
  padding: 10px;
}
#pricing-section
  > div
  > ul
  > li.react-multi-carousel-item:not(.react-multi-carousel-item--active) {
  height: 0;
}

.pricing-box {
  box-shadow: 0px 3px 40px #0000000d;
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 12px 14px 32px;
  padding-bottom: 32px;
  transition: all 500ms ease 0s;
  cursor: pointer;
  height: 658px;
}
.pricing-box h3 {
  color: #455d6b;
  font-family: "poppins-Bold";
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pricing-box p {
  font-size: 12px;
  font-family: "poppins-italic";
  padding-bottom: 1rem;
}
.pricing-montly h2 {
  color: #455d6b;
  text-transform: capitalize;
  font-family: "poppins-bold";
  /* font-size: 38px; */
  letter-spacing: 0;
}
.pricing-montly span {
  color: #455d6b;
  text-transform: capitalize;
  opacity: 0.6;
  font-size: 16px;
  justify-content: center;
  font-family: "poppins-regular";
  line-height: 0;
  position: relative;
  bottom: 8px;
}
.pricing-montly {
  padding-bottom: 1rem;
}
ul.list-pricing-features {
  padding: 0;
  color: #8d9ca4;
  opacity: 1;
  line-height: 28px;
  font-family: "poppins-regular";
  padding-bottom: 18px;
  min-height: 340px;
  /* min-height: 600px; */
}

ul.trialtext {
  color: #8d9ca4;
  opacity: 1;
  font-family: "poppins-regular";
  padding-bottom: 18px;
}

ul.list-pricing-features li {
  list-style: none;
  font-size: 12px;
  background-image: url("../../assets/images/landing/Component531.svg");
  background-size: 15px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  padding-left: 40px;
  /* line-height: 40px; */
}

.pricing-form-image {
  background-image: url("../../assets/images/landing/landing-pricing-image.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 100%;
  background-size: 100% 100%;
  border-radius: 10px;
}

.pricing-form-title {
  line-height: 0.5 !important;
  margin-top: -5px;
  color: #d6d8d9;
  opacity: 0.5;
  padding-bottom: 1rem;
  font-family: poppins-italic;
}

.info-container {
  background: rgb(69, 93, 107);
  background: linear-gradient(180deg, rgba(69, 93, 107, 1) 0%, transparent 40%);
  width: 104.5%;
  margin: 0 -15px;
  padding: 0.1px 1.5rem;
  height: 80%;
  border-radius: 10px;
}

.btn-choose {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 40px #0000000d;
  border-radius: 10px;
  width: 100%;
  border: 2px solid #6e98ae;
  height: 50px;
  display: block;
  text-align: center;
  line-height: 48px;
  font-family: "poppins-Medium";
  color: #6e98ae !important;
}
.pricing-box.bg-gradient {
  position: relative;
  background: linear-gradient(180deg, #6e98ae 0%, #374c57 100%) !important;

  color: #fff;
}
.pricing-box h3 {
  font-size: 1.3rem;
}
.pricing-box.bg-gradient h3 {
  color: #fff;
}
.pricing-box.bg-gradient p {
  color: #fff;
}
.pricing-box.bg-gradient.pricing-montly {
  color: #fff;
}
.pricing-box.bg-gradient ul.list-pricing-features {
  color: #fff;
}
.pricing-box.active:hover .btn-choose {
  background: #71b8ad;
  color: #fff !important;
  border: 1px solid #71b8ad;
}
.bg-gradient .btn-choose {
  background: #71b8ad;
  color: #fff !important;
  border: 1px solid #71b8ad;
}
.pricing-box.bg-gradient .pricing-montly h2 {
  color: #fff;
}
.pricing-box.bg-gradient .pricing-montly span {
  color: #fff !important;
}

.pricing-box.active:hover {
  background: transparent linear-gradient(180deg, #6e98ae 0%, #374c57 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  transition: all 500ms ease 0s;
}
.pricing-box.active:hover .pricing-montly h2 {
  color: #fff;
}
.pricing-box.active:hover .pricing-montly span {
  color: #fff !important;
}
.pricing-box.active:hover ul.list-pricing-features {
  color: #fff;
}
.pricing-box.active:hover h3 {
  color: #fff;
}
.pricing-box.active:hover p {
  color: #fff;
}
label.bestOffer {
  font-size: 10px;
  background: #71b8ad;
  padding: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: absolute;
  right: 0;
}
.feature-shape-img {
  position: absolute;
  right: -19%;
  top: -160px;
  width: 600px;
  animation: rotation 50s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.stepper-item-inner-future {
  background-color: #84939d !important;
}
.stepper-item-outer:nth-of-type(even) {
  height: 2px !important;
  width: 9% !important;
  border: unset !important;
  border-bottom: 4px dashed white !important;
}

.stepper-item-outer:nth-of-type(odd) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='5' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
}
.feature-row-width {
  width: 80%;
  margin: auto !important;
}
#howto-section .lead {
  font-size: unset;
}
.stepper-bg-circle {
  position: absolute;
  left: -19%;
  top: -160px;
  width: 600px;
  max-width: 100vw;
  animation: rotation 20s infinite linear;
}
.about-bg-logo {
  width: 75%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}
.about-bg-circle {
  position: absolute;
  right: -19%;
  top: -160px;
  width: 456px;
  animation: rotation 30s infinite linear;
}
.team-inner-container {
  position: relative;
  z-index: 2;
}
.team-bg-container {
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  top: 345px;
  z-index: 1;
}
.team-bg-container img {
  width: 70%;
  margin: auto;
  display: block;
  animation: rotation 30s infinite linear;
}
@media (min-width: 1775px) {
  .team-bg-container img {
    width: 46%;
    margin: auto;
    display: block;
    animation: rotation 100s infinite linear;
  }
}
.teams-linkedin-img {
  width: 50px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 0;
  margin: auto;
  left: 0;
}
.bg-grey-300 {
  background-color: #f9f9f9;
}
.licence-btn {
  height: 50px;
  background-color: #71b8ad !important;
  outline: none;
  border: none;
  box-shadow: none;
  width: 50%;
  color: white;
  margin-top: 1.8rem;
}
.licence-btn-cancel {
  height: 50px;
  background-color: #455d6b !important;
  outline: none;
  border: none;
  box-shadow: none;
  width: 50%;
  color: white;
  margin-top: 1.8rem;
}
.social-icons {
  margin-right: 14px;
}
.mail-icon {
  width: 25px;
  margin-right: 14px;
}

//------------------------------------------------------------------ new csss

.banner-btn {
  display: flex;
  width: 238px;
  height: 68px;
  padding: 20px 17px 21px 17px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px !important;
  border: 2px solid #71a0b8;
  color: white;
  font-size: 20px;
  img {
    width: 20px;
    margin-left: 20px;
  }
}

.second-banner-btn {
  margin-left: 1rem;
}

.bg-secondary-bid {
  background-color: #71a0b8;
}

.affilation-img-container {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 270px;
  }
}

.landing-heading {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  color: #1d1d1d;
  text-transform: capitalize;
}

.affiliation_arrow_group {
  position: absolute;
  top: 0;
  width: 95%;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  margin: auto;
  button {
    border: 1px solid #ddd;
    border-radius: 50% !important;
    padding: 10px;
    background-color: transparent;
    &:first-child {
      left: 20px;
    }
    &:last-child {
      right: 20px;
    }
  }
}

.end-to-end-container {
  margin: 140px 0;
  text-align: center;
  margin-bottom: 0px;
  .content-img {
    width: 65%;
    margin-top: 80px;
  }

  .plan-do-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;

    img {
      width: 100%;
    }

    .plan {
      min-width: 80px;
      min-height: 80px;
      border-radius: 50%;
      background-color: #91bacb;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 25.139px;
      margin-right: -25px;
      margin-top: -34px;
      z-index: 9;
      font-family: "Inter";
      font-weight: 600;
    }

    .do {
      min-width: 80px;
      min-height: 80px;
      border-radius: 50%;
      background-color: #91bacb;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 25.139px;
      margin-left: -25px;
      margin-top: -34px;
      z-index: 9;
      font-family: "Inter";
      font-weight: 600;
    }
  }
}

.testimonial-container {
  background-color: #2e4651;
  padding: 96px 17%;
  margin: auto;
  position: relative;

  .testimonial-div {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;

    .client-img {
      width: 280px;
      height: 300px;
      margin-right: 120px;
      border-radius: 15px;
      margin-left: 3px;
    }
    .star-div {
      display: flex;
      margin-bottom: 20px;
      img {
        margin: 3px;
        width: 16px;
      }
    }
    .main-text {
      font-family: "Inter";
      font-size: 34px;
      font-weight: 500;
      color: white;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
    }
    .testimonial_info_text {
      p {
        margin-bottom: 0;
        color: white;
        &:first-child {
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }

        &:last-child {
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  .testimonial_arrow_group {
    position: absolute;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    right: 19%;
    margin: auto;
    grid-gap: 20px;
    gap: 20px;
    margin-top: -104px;
    button {
      border: 1px solid #ddd;
      border-radius: 50% !important;
      padding: 10px;
      background-color: rgba(234, 236, 240, 1);
      &:first-child {
        left: 20px;
      }
      &:last-child {
        right: 20px;
      }
    }
  }
}

.bg-dark-bid {
  background-color: rgba(58, 87, 101, 1) !important;
}

.saas-navigation-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  border-top: 3px solid #ddd;
  width: 80%;
  margin: auto;
  margin-top: 50px;
  .inner-container {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    .tracking_number {
      border-radius: 50%;
      font-size: 15px;
      color: white;
      background-color: rgba(156, 156, 156, 1);
      width: 40px;
      height: 40px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .head {
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
    }
    .subhead {
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: rgba(71, 84, 103, 1);
    }
  }
  .active {
    border-top: 3px solid rgba(58, 87, 101, 1) !important;
    margin-top: -3px;
    .tracking_number {
      background-color: rgba(58, 87, 101, 1) !important;
    }
  }
}

.banner-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.saas-img {
  max-width: 1500px !important;
  // width: 1500px;
  margin: 50px 0 !important;
  width: 40%;
}

.saas-features-container {
  background-color: #2e4651;
  padding: 96px 17%;
  margin: auto;
  position: relative;

  .feature-container {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    .img_container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fafafa;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: auto;
      margin-bottom: 20px;
      img {
        width: 20px;
      }
    }
    p:nth-child(2) {
      text-transform: capitalize;
    }
  }
}

.bim-services-container {
  margin: 80px auto;
  width: 80%;
}

.bim-section {
  background-color: rgba(249, 250, 251, 1);
  border-radius: 24px;

  display: flex;
  // width: 286px;
  min-height: 800px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;

  p {
    &:first-child {
      color: var(--Gray-900, #101828);
      /* Text xl/Semibold */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
    }
    &:last-child {
      color: var(--Gray-600, #475467);
      /* Text md/Regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}

.staff-container {
  background-color: #f9fafb;
  padding: 96px 15%;
  margin: auto;
  position: relative;

  .augmentation-div {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
    .left-side {
      width: 80%;
      border-left: 5px solid #ddd;
      .inner-div {
        padding: 15px 20px;
        margin-left: -5px;
        cursor: pointer;
        p {
          &:first-child {
            color: var(--1-d-1-d-1-d, #1d1d1d);
            /* Text xl/Semibold */
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 150% */
            margin-bottom: 8px;
            text-transform: capitalize;
          }

          &:last-child {
            color: var(--Neutral-Colors-Muted-Text---Elements, #8f98aa);
            /* Text md/Regular */
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            margin-bottom: 0;
          }
        }
      }
      .active {
        border-left: 5px solid #3a5765;
        background-color: #e4edf2;
      }
    }
    .aug-img {
      width: 100%;
      margin-left: 49px;
    }
    .staff-img-text {
      color: white;
      position: absolute;
      z-index: 1;
      top: 20px;
      font-family: Inter;
      font-size: 57px;
      font-style: normal;
      font-weight: 600;
      left: 70px;
      text-transform: capitalize;
    }
  }
}

.banner-content-div {
  width: 50%;
}

// responsive media query

@media only screen and (max-width: 600px) {
  .saas-navigation-container {
    grid-template-columns: none;
  }
  .landing-heading {
    font-size: 30px;
  }
  .staff-container {
    padding: 96px 8%;
  }
  .staff-container .augmentation-div {
    display: block;
  }
  .staff-container .augmentation-div .left-side {
    width: 100%;
  }
  .staff-container .augmentation-div .aug-img {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }
  .staff-container .augmentation-div .staff-img-text {
    color: white;
    position: absolute;
    z-index: 1;
    top: 75px;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    left: 30px;
    text-transform: capitalize;
  }

  .pricing-box {
    margin-bottom: 20px;
  }
  .footer-head {
    margin-top: 20px;
  }
  li.loginBtns {
    display: block;
  }
  .btnLogin {
    justify-content: center;
  }
  .banner-btn-div {
    display: block;
  }
  .banner-container .main-text {
    font-size: 44px !important;
    font-weight: 600;
    line-height: 1.2;
  }
  .banner-content-div {
    width: 75%;
  }
  .banner-btn {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .banner-container {
    // background-size: 100% !important;
  }
  .affilation-img-container img {
    width: 118px;
  }
  .testimonial-container {
    padding: 96px 8% 50px 8%;
  }
  .testimonial-container .testimonial-div {
    display: block;
  }
  .testimonial-container .testimonial-div .client-img {
    width: 150px;
    height: 150px;
    margin-right: 0;
    border-radius: 15px;
    margin-left: 3px;
    margin-bottom: 26px;
  }
  .second-banner-btn {
    margin-left: auto;
    margin-right: auto;
  }
  .bannerImg {
    width: 90% !important;
    margin-top: 20px;
  }
  .end-to-end-container .content-img {
    width: 100%;
  }
  .end-to-end-container {
    text-align: center;
    margin: 90px 0 0;
  }
  .testimonial-container .testimonial-div .main-text {
    font-size: 21px;
  }
  .bim-services-container {
    margin: 80px auto;
    width: 85%;
  }
  .wrapper-video {
    position: relative;
  
    iframe {
      width: 82% !important;
      height: 188px !important;
    }
    .playpause {
      position: absolute;
      top: 40%;
      bottom: 50%;
      margin: auto;
      left: 0;
      right: 0;
      cursor: pointer;
      img {
        width: 60px !important;
      }
    }
  }
}

.wrapper-video {
  position: relative;

  iframe {
    width: 1000px;
    height: 560px;
  }

  .playpause {
    position: absolute;
    top: 40%;
    bottom: 50%;
    margin: auto;
    left: 0;
    right: 0;
    cursor: pointer;
    img {
      width: 100px;
    }
  }
}



div.list-pricing-features {
  padding: 0;
  color: #8d9ca4;
  opacity: 1;
  line-height: 28px;
  font-family: "poppins-regular";
  padding-bottom: 18px;
  // min-height: 100;
  display: grid;
  grid-template-columns: 40% 50%;
  /* min-height: 600px; */
}

div.list-pricing-features div {
  list-style: none;
  font-size: 17px;
  background-image: url("../../assets/images/landing/Component531.svg");
  background-size: 15px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  padding-left: 40px;
  margin-bottom: 25px;
  /* line-height: 40px; */
}

.pricing-box.active:hover div.list-pricing-features div{
  color: white;
}

.img-autoEstimate{
  width: 100%;
  margin-top: 70px !important;
}