// @media (max-width: 1300px) {
//   .viewer-header .viewer-outer-div:nth-of-type(3) {
//     display: none;
//   }
// }

// @media (max-width: 900px) {
//   .viewer-header .viewer-outer-div:nth-of-type(2) {
//     display: none;
//   }
// }
.timeline-viewer-container {
  background-color: #fff;
  // padding-top: 20px;
    // margin-top: 30px;
  }

        #WebglViewer #clippingTooltip {
      position: absolute;
        font-size: small;
          right: 0;
          top: -20px;
          background-color: #3a5765;
          color: white;
          padding: 0 5px;
          border-radius: 5px;
    }
  .viewer-zoom {
    bottom: 40px !important;
    color: gray;
}

// #WebglViewer:before {
//   content: attr(data-before);
//   margin-left: 20px;
// }
#WebglViewer .buttonEnabled {
  background-color: #3a5765 !important;
  color: white;
}

.ifcjs-dimension-label {
  background-color: white;
  padding: 0 3px;
  border-radius: 100vw;
  font-size: 0.8rem;
}

.viewer {
  // vh
  height: calc(100vh - (60px));
    // padding-top: 40px;
  // zoom: 1.176;

  canvas {
    outline: none;
    width: 100%;
  }

  &-total-price {
    top: 15px;
    right: 15px;
    width: 200px;
    box-shadow: 1px 2px 9px grey;
  }

  &-zoom {
    z-index: 10;
    right: 50%;
    transform: translateX(50%);
    bottom: 15px;
  }

  &-header {
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: rgba(113, 161, 183, 1);
    font-size: 17px;
  }

  &-lowerheader {
    height: 38px;
  }

  &-header-item {
    border-right: 2px solid rgb(200, 200, 200);
  }

  &-lowertree {
    height: calc(100% - 38px);
  }

  &-selected {
    min-width: 160px;
  }

  &-icon {
    cursor: pointer;
    svg {
      width: 25px;
      height: 10px;
      fill: white;
      transform: rotate(0deg);
      transition: 0.3s;
    }

    &-rotate {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &-popover {
    padding: 3px 7px 3px 5px;
    background-color: white;
  }

  &-searching {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(black, 0.5);
  }
}

.estimation-detail-itemViewer {
  position: absolute;
  display: flex;
  overflow: hidden;
  max-height: 1000px;
  max-width: 300px;
  overflow-y: scroll;
}
.viewer {
  background-color: #fff;
}
.viewer-header {
  background-color: #fbfcfc !important;
  top: 0px;
  font-size: 12px;
}
.viewer-header1 {
  // border-bottom: 2px solid #f2f2f2;
  // border: 1px solid blue;
}
.viewer-outer-div{
  overflow: hidden;
  background-color: #fbfcfc;
  min-width: 80px;
}
.viewer-header-item {
  position: relative;
  color: #455e6c;
  background: #f2f2f2;
  border-radius: 10px 10px 0px 0px;
  border: none !important;
  margin: 0px;
  font-family: "poppins-Medium";
  letter-spacing: -0.7px;
  font-size: 12px;
  white-space: pre;
  gap: 8px;
}
.viewer-gapping {
  margin: 5px;
 height: 38px;  
}
.resize-panel-container {
  margin-top: 0px;
}
.resize-panel {
  margin: 0px;
  background: #fff;
  // width: 300px;
  // max-width: 300px;
  // min-width: 300px;
}
.inner_resizable{
  margin: 0px 5px;
  border: 1.5px solid #f2f2f2;
  width: auto;
  border-top: none;
  border-bottom: none;
}
.resize-panel:last-child {
  // min-width: 300px;
  max-width: inherit;
}
.w-40 {
  width: 40%;
}
.w-20 {
  width: 20%;
}
// .resizer {
//   display: none;
// }

.viewer-icon {
  position: absolute;
  right: 0;
  background: inherit;
  // border: 1px solid;
  // border-radius: 50%;
  svg {
    color: #455e6c;
    fill: #455e6c;
    width: 25px !important;
    height: 28px !important;
  }
}
.modal-side-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  padding: 0px 5px;
}
.modal-side-table tbody tr td {
  color: #2c3e50;
  padding: 0px 10px;
  font-family: "Poppins-medium";
  font-size: 11px !important;
  background: #fbfbfb;
  height: 29px;
}
.modal-side-table tbody tr td.total-summary {
  display: none;
}

.modal-side-table tbody .item-child td {
  // border: 1px solid #ddd;
  // border-radius:0px;
  // border-left: none;
  // border-right: none;

  border: 1px solid #ddd;
  border-radius: 0px;
  border-left: none;
  border-right: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 7rem;
}
.modal-side-table tbody .item-child td:first-child {
  border: 1px solid #ddd;
  border-radius: 5px 0px 0px 5px;
  border-right: none;
}
.modal-side-table tbody .item-child td:last-child {
  border: 1px solid #ddd;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}

.modal-sideShare-table tbody .item-child td:first-child {
  padding-left: 20px !important;
}
@media only screen and (max-width: 768px) {
  .custom-control {
    display: none !important;
  }
}
.custom-control-label1 {
  font-size: 12px !important;
  color: #455e6c !important;
  font-family: "poppins-Medium" !important;
  padding-top: 2px !important;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 13px;
  color: #455e6c;
  font-weight: 700;
}
.amber-light {
  background-color: #ffebc4;
}
#WebglViewer .modal3d-tool-btn {
  padding: 10px !important;
  margin-right: 13px !important;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
  &:hover {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
  }
}
.resizable-fragment {
  height: 25%;
  // border: 1px solid #f2f2f2;

  &:last-child {
    max-height: inherit;
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #3a5765;
  }
  div::-webkit-scrollbar-thumb:hover {
    background: #283f4b;
  }
  div::-webkit-scrollbar-thumb:active {
    background: #283f4b;
  }
}

.resize-panel {
  &:last-child {
    .resizable-fragment {
      &:first-child {
        max-height: 50%;
      }
    }
  }
}

.clock-icon {
  width: 20px;
  margin-right: 10px;
}

.modal-sideShare-table {
  width: auto;
  border-collapse: separate;
  table-layout: fixed;
  border-spacing: 0 10px;
  display: inline-table;
}
.modal-sideShare-table .item-child{
  outline: 1px solid #ddd;
  border-radius: 5px;
}
.modal-sideShare-table tbody{
  width: 100%;
}
.modal-sideShare-table tbody tr td {
  color: #2c3e50;
  padding: 8px 20px;
  font-family: "Poppins-medium";
  font-size: 12px !important;
  max-height: 50px;
  height: 100%  ;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

// .modal-sideShare-table tbody .item-child td {
//   border: 1px solid #ddd;
//   border-radius: 0px;
//   border-left: none;
//   border-right: none;
// }
// .modal-sideShare-table tbody .item-child td:first-child {
//   border: 1px solid #ddd;
//   border-radius: 5px 0px 0px 5px;
//   border-right: none;
// }
// .modal-sideShare-table tbody .item-child td:last-child {
//   border: 1px solid #ddd;
//   border-radius: 0px 5px 5px 0px;
//   border-left: none;
// }
.viewer-lowertree {
  height: calc(100% - 50px) !important;
}
.h-86vh {
  height: 86vh;
}

.existing-img {
  width: 32px;
  margin-right: 15px;
}
.demo-img {
  width: 28px;
  margin-right: 15px;
}
.unpriced-img {
  width: 32px;
  margin-right: 15px;
}
.priced-img {
  width: 32px;
  // margin-right: 15px;
}
.estimation-icon {
  width: 28px;
  // margin-right: 15px;
}
.project-icon {
  width: 30px;
  // margin-right: 15px;
}
.viewer-lowerheader {
  font-size: 12px;
  background-color: #f2f2f2;
  margin-top: 2px;
}
