.voteCell {
  width: auto;
  padding-right: 16px;
  vertical-align: top;
  grid-column: 1;
  display: flex;
  flex-direction: column;
}

.voteButton {
  margin: 2px;
  border: none;
  &:hover {
    background-color: transparent;
  }
  
  svg {
    color: var(--black-200);
    width: 36px;
    height: 36px;
  }
}

.voted {
  color: #455d6b !important;
}

.score {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  margin: 2px;
  color: var(--black-500)
}
