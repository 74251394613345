.container {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  margin-bottom: 24px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}
@media screen and (max-width: 768px){ 
  .container{
    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
    padding-left: 10px;
    padding-right: 10px;

  }
} 