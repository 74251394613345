// @import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");


.icon-shape{
    width: 50px;
    height: 50px;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.projectname_number_container{
    transition: .2s;
    overflow: hidden;
}

.project-cont-height{
    overflow-y: auto;
    height: 220px;
    transition: .2s;
}
.projectname_number_div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    border-bottom: 1px solid #ddd;
    border-radius: 0px;
    padding: 6px 7px;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: 600;

    &:hover{
        background-color: #eaeaea;
    }

    p{
        font-size: 12px;
        margin: 0;
    }
    .code{
        width: 15%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .desc{
        width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }
    .totalCost{
        width: 33%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.activity-img{
    border-radius: 50%;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;


    svg{
    width: 20px;
    height: 20px;
    fill: #283f4b
    }
}

a.text-heading.font-semibold {
    font-size: 12px !important;
    // font-weight: bold;
    text-decoration: none;
}

.battery-card-container{
    position: absolute;
    right: 16px;
    top: 60px
}

.height-0{
    height: 0px;
    overflow: hidden;
}

.dashboard-text{
    font-size: 14px;
    font-weight: 600;
    color: grey;
}

.legend-p{
    display: flex;
    align-items: center;
    margin-right: 12px;
    .legend-box{
        width: 12px;
        height: 12px;
        border-radius: 3px;
        margin-right: 7px;
    }
}

.chart-header{
    font-size: 15px;
    font-weight: 500;
    margin: 20px;
    font-family: 'poppins-Medium';
    position: relative;
    width: fit-content;
  
    &::before{
      content: '';
      position: absolute;
      height: 2px;
      width: 70%;
      background-color: #283f4b;
      left: 0;
      bottom: -10px;
      
  
    }
  }
  
  
  .dashboard-cards{
    box-shadow: 0px 0px 4px #ddd !important;
      /* border: 1px solid #ddd !important; */
      border-radius: 15px !important;
  }