.tag {
  display: inline-block;
  background-color: var(--powder-100);
  color: var(--powder-700);
  font-size: 13px;
  padding: 5px 6px;
  border: 1px solid transparent;
  margin: 2px 5px 2px 0;
  line-height: 1;
  white-space: nowrap;
  border-radius: 5px;
  text-decoration: none;
  font-family: "poppins-regular",;

  &:active {
    color: var(--blue-500);
  }

  &:hover {
    color: var(--powder-800);
    background-color: var(--powder-200);
    border-color: transparent;
  }
}

.multiplier {
  color: var(--black-400);
}

.count {
  color: var(--black-500);
  font-size: 11px;
}

@media screen and (max-width: 768px){ 

  .tag{
    font-size: 10px
  }
}