:root {
  --white: #fff;
  --orange: #f48024;
  --black-025: #fafafb;
  --black-050: #eff0f1;
  --black-075: #e4e6e8;
  --black-100: #d6d9dc;
  --black-200: #bbc0c4;
  --black-300: #6a737c;
  --black-350: #9199a1;
  --black-400: #848d95;
  --black-500: #6a737c;
  --black-600: #535a60;
  --black-700: #3c4146;
  --black-800: #242729;
  --black-900: #0c0d0e;
  --blue-300: #6cbbf7;
  --blue-500: #0095ff;
  --blue-600: #0077cc;
  --blue-700: #0064bd;
  --red-400: #de535e;
  --red-500: #d1383d;
  --powder-100: #e1ecf4;
  --powder-200: #d1e5f1;
  --powder-300: #b3d3ea;
  --powder-400: #a0c7e4;
  --powder-500: #7aa7c7;
  --powder-600: #5b8db1;
  --powder-700: #39739d;
  --powder-800: #2c5777;

  --fade: 120ms;
}
