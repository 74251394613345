.fileListTable {
  .col-01 {
    width: 130px;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-02 {
    width: 100px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-03 {
    width: 100px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-04 {
    width: 100px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-05 {
    width: 100px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-06 {
    width: 100px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-07 {
    width: 5%;
    max-width: 5%;
  }

  .users-profile-list {
    display: flex;
    align-items: center;
    .u-profile {
      height: 24px;
      width: 24px;
      margin-left: -13px;
      border: 1px solid white;
      border-radius: 5px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }
  }
}

.user-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
  }

.parameter-header {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
}

.parameter-container {
  margin-top: 60px !important;
}

.bg-detail{
    background-color: white;
    // box-shadow: inset #ddd 0px 0px 60px -12px;
    border-radius: 5px;
}