@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css);
@import url(https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/medium-editor.min.css);
	/*
  	Flaticon icon font: Flaticon
  	Creation date: 11/02/2019 11:47
  	*/

@font-face {
  font-family: "Flaticon";
  src: url(/static/media/Flaticon.d41d8cd9.eot);
  src: url(/static/media/Flaticon.d41d8cd9.eot?#iefix) format("embedded-opentype"),
       url(/static/media/Flaticon.d41d8cd9.woff2) format("woff2"),
       url(/static/media/Flaticon.d41d8cd9.woff) format("woff"),
       url(/static/media/Flaticon.e6f918d2.ttf) format("truetype"),
       url(/static/media/Flaticon.0e69e229.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.0e69e229.svg#Flaticon) format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-mexican-taco:before { content: "\f100"; }
.flaticon-house:before { content: "\f101"; }
.flaticon-guitar:before { content: "\f102"; }
.flaticon-gym:before { content: "\f103"; }
.flaticon-shopping-bag:before { content: "\f104"; }
.flaticon-cocktail:before { content: "\f105"; }
@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.d41d8cd9.eot);
  src:  url(/static/media/icomoon.d41d8cd9.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.bab8cf81.ttf) format('truetype'),
    url(/static/media/icomoon.d41d8cd9.woff) format('woff'),
    url(/static/media/icomoon.302fbc0a.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-bold';
  src: url(/static/media/Poppins-Bold.a3e0b5f4.ttf);
}
@font-face {
  font-family: 'poppins-regular';
  src: url(/static/media/Poppins-Regular.8b6af8e5.ttf);
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-asterisk:before {
  content: "\f069";
}
.icon-plus:before {
  content: "\f067";
}
.icon-question:before {
  content: "\f128";
}
.icon-minus:before {
  content: "\f068";
}
.icon-glass:before {
  content: "\f000";
}
.icon-music:before {
  content: "\f001";
}
.icon-search:before {
  content: "\f002";
}
.icon-envelope-o:before {
  content: "\f003";
}
.icon-heart:before {
  content: "\f004";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-user:before {
  content: "\f007";
}
.icon-film:before {
  content: "\f008";
}
.icon-th-large:before {
  content: "\f009";
}
.icon-th:before {
  content: "\f00a";
}
.icon-th-list:before {
  content: "\f00b";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-search-minus:before {
  content: "\f010";
}
.icon-power-off:before {
  content: "\f011";
}
.icon-signal:before {
  content: "\f012";
}
.icon-cog:before {
  content: "\f013";
}
.icon-gear:before {
  content: "\f013";
}
.icon-trash-o:before {
  content: "\f014";
}
.icon-home:before {
  content: "\f015";
}
.icon-file-o:before {
  content: "\f016";
}
.icon-clock-o:before {
  content: "\f017";
}
.icon-road:before {
  content: "\f018";
}
.icon-download:before {
  content: "\f019";
}
.icon-arrow-circle-o-down:before {
  content: "\f01a";
}
.icon-arrow-circle-o-up:before {
  content: "\f01b";
}
.icon-inbox:before {
  content: "\f01c";
}
.icon-play-circle-o:before {
  content: "\f01d";
}
.icon-repeat:before {
  content: "\f01e";
}
.icon-rotate-right:before {
  content: "\f01e";
}
.icon-refresh:before {
  content: "\f021";
}
.icon-list-alt:before {
  content: "\f022";
}
.icon-lock:before {
  content: "\f023";
}
.icon-flag:before {
  content: "\f024";
}
.icon-headphones:before {
  content: "\f025";
}
.icon-volume-off:before {
  content: "\f026";
}
.icon-volume-down:before {
  content: "\f027";
}
.icon-volume-up:before {
  content: "\f028";
}
.icon-qrcode:before {
  content: "\f029";
}
.icon-barcode:before {
  content: "\f02a";
}
.icon-tag:before {
  content: "\f02b";
}
.icon-tags:before {
  content: "\f02c";
}
.icon-book:before {
  content: "\f02d";
}
.icon-bookmark:before {
  content: "\f02e";
}
.icon-print:before {
  content: "\f02f";
}
.icon-camera:before {
  content: "\f030";
}
.icon-font:before {
  content: "\f031";
}
.icon-bold:before {
  content: "\f032";
}
.icon-italic:before {
  content: "\f033";
}
.icon-text-height:before {
  content: "\f034";
}
.icon-text-width:before {
  content: "\f035";
}
.icon-align-left:before {
  content: "\f036";
}
.icon-align-center:before {
  content: "\f037";
}
.icon-align-right:before {
  content: "\f038";
}
.icon-align-justify:before {
  content: "\f039";
}
.icon-list:before {
  content: "\f03a";
}
.icon-dedent:before {
  content: "\f03b";
}
.icon-outdent:before {
  content: "\f03b";
}
.icon-indent:before {
  content: "\f03c";
}
.icon-video-camera:before {
  content: "\f03d";
}
.icon-image:before {
  content: "\f03e";
}
.icon-photo:before {
  content: "\f03e";
}
.icon-picture-o:before {
  content: "\f03e";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-adjust:before {
  content: "\f042";
}
.icon-tint:before {
  content: "\f043";
}
.icon-edit:before {
  content: "\f044";
}
.icon-pencil-square-o:before {
  content: "\f044";
}
.icon-share-square-o:before {
  content: "\f045";
}
.icon-check-square-o:before {
  content: "\f046";
}
.icon-arrows:before {
  content: "\f047";
}
.icon-step-backward:before {
  content: "\f048";
}
.icon-fast-backward:before {
  content: "\f049";
}
.icon-backward:before {
  content: "\f04a";
}
.icon-play:before {
  content: "\f04b";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-stop:before {
  content: "\f04d";
}
.icon-forward:before {
  content: "\f04e";
}
.icon-fast-forward:before {
  content: "\f050";
}
.icon-step-forward:before {
  content: "\f051";
}
.icon-eject:before {
  content: "\f052";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-minus-circle:before {
  content: "\f056";
}
.icon-times-circle:before {
  content: "\f057";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-info-circle:before {
  content: "\f05a";
}
.icon-crosshairs:before {
  content: "\f05b";
}
.icon-times-circle-o:before {
  content: "\f05c";
}
.icon-check-circle-o:before {
  content: "\f05d";
}
.icon-ban:before {
  content: "\f05e";
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-arrow-up:before {
  content: "\f062";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-mail-forward:before {
  content: "\f064";
}
.icon-share:before {
  content: "\f064";
}
.icon-expand:before {
  content: "\f065";
}
.icon-compress:before {
  content: "\f066";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-gift:before {
  content: "\f06b";
}
.icon-leaf:before {
  content: "\f06c";
}
.icon-fire:before {
  content: "\f06d";
}
.icon-eye:before {
  content: "\f06e";
}
.icon-eye-slash:before {
  content: "\f070";
}
.icon-exclamation-triangle:before {
  content: "\f071";
}
.icon-warning:before {
  content: "\f071";
}
.icon-plane:before {
  content: "\f072";
}
.icon-calendar:before {
  content: "\f073";
}
.icon-random:before {
  content: "\f074";
}
.icon-comment:before {
  content: "\f075";
}
.icon-magnet:before {
  content: "\f076";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-retweet:before {
  content: "\f079";
}
.icon-shopping-cart:before {
  content: "\f07a";
}
.icon-folder:before {
  content: "\f07b";
}
.icon-folder-open:before {
  content: "\f07c";
}
.icon-arrows-v:before {
  content: "\f07d";
}
.icon-arrows-h:before {
  content: "\f07e";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-twitter-square:before {
  content: "\f081";
}
.icon-facebook-square:before {
  content: "\f082";
}
.icon-camera-retro:before {
  content: "\f083";
}
.icon-key:before {
  content: "\f084";
}
.icon-cogs:before {
  content: "\f085";
}
.icon-gears:before {
  content: "\f085";
}
.icon-comments:before {
  content: "\f086";
}
.icon-thumbs-o-up:before {
  content: "\f087";
}
.icon-thumbs-o-down:before {
  content: "\f088";
}
.icon-star-half:before {
  content: "\f089";
}
.icon-heart-o:before {
  content: "\f08a";
}
.icon-sign-out:before {
  content: "\f08b";
}
.icon-linkedin-square:before {
  content: "\f08c";
}
.icon-thumb-tack:before {
  content: "\f08d";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-sign-in:before {
  content: "\f090";
}
.icon-trophy:before {
  content: "\f091";
}
.icon-github-square:before {
  content: "\f092";
}
.icon-upload:before {
  content: "\f093";
}
.icon-lemon-o:before {
  content: "\f094";
}
.icon-phone:before {
  content: "\f095";
}
.icon-square-o:before {
  content: "\f096";
}
.icon-bookmark-o:before {
  content: "\f097";
}
.icon-phone-square:before {
  content: "\f098";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-github:before {
  content: "\f09b";
}
.icon-unlock:before {
  content: "\f09c";
}
.icon-credit-card:before {
  content: "\f09d";
}
.icon-feed:before {
  content: "\f09e";
}
.icon-rss:before {
  content: "\f09e";
}
.icon-hdd-o:before {
  content: "\f0a0";
}
.icon-bullhorn:before {
  content: "\f0a1";
}
.icon-bell-o:before {
  content: "\f0a2";
}
.icon-certificate:before {
  content: "\f0a3";
}
.icon-hand-o-right:before {
  content: "\f0a4";
}
.icon-hand-o-left:before {
  content: "\f0a5";
}
.icon-hand-o-up:before {
  content: "\f0a6";
}
.icon-hand-o-down:before {
  content: "\f0a7";
}
.icon-arrow-circle-left:before {
  content: "\f0a8";
}
.icon-arrow-circle-right:before {
  content: "\f0a9";
}
.icon-arrow-circle-up:before {
  content: "\f0aa";
}
.icon-arrow-circle-down:before {
  content: "\f0ab";
}
.icon-globe:before {
  content: "\f0ac";
}
.icon-wrench:before {
  content: "\f0ad";
}
.icon-tasks:before {
  content: "\f0ae";
}
.icon-filter:before {
  content: "\f0b0";
}
.icon-briefcase:before {
  content: "\f0b1";
}
.icon-arrows-alt:before {
  content: "\f0b2";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-chain:before {
  content: "\f0c1";
}
.icon-link:before {
  content: "\f0c1";
}
.icon-cloud:before {
  content: "\f0c2";
}
.icon-flask:before {
  content: "\f0c3";
}
.icon-cut:before {
  content: "\f0c4";
}
.icon-scissors:before {
  content: "\f0c4";
}
.icon-copy:before {
  content: "\f0c5";
}
.icon-files-o:before {
  content: "\f0c5";
}
.icon-paperclip:before {
  content: "\f0c6";
}
.icon-floppy-o:before {
  content: "\f0c7";
}
.icon-save:before {
  content: "\f0c7";
}
.icon-square:before {
  content: "\f0c8";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-navicon:before {
  content: "\f0c9";
}
.icon-reorder:before {
  content: "\f0c9";
}
.icon-list-ul:before {
  content: "\f0ca";
}
.icon-list-ol:before {
  content: "\f0cb";
}
.icon-strikethrough:before {
  content: "\f0cc";
}
.icon-underline:before {
  content: "\f0cd";
}
.icon-table:before {
  content: "\f0ce";
}
.icon-magic:before {
  content: "\f0d0";
}
.icon-truck:before {
  content: "\f0d1";
}
.icon-pinterest:before {
  content: "\f0d2";
}
.icon-pinterest-square:before {
  content: "\f0d3";
}
.icon-google-plus-square:before {
  content: "\f0d4";
}
.icon-google-plus:before {
  content: "\f0d5";
}
.icon-money:before {
  content: "\f0d6";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-columns:before {
  content: "\f0db";
}
.icon-sort:before {
  content: "\f0dc";
}
.icon-unsorted:before {
  content: "\f0dc";
}
.icon-sort-desc:before {
  content: "\f0dd";
}
.icon-sort-down:before {
  content: "\f0dd";
}
.icon-sort-asc:before {
  content: "\f0de";
}
.icon-sort-up:before {
  content: "\f0de";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-linkedin:before {
  content: "\f0e1";
}
.icon-rotate-left:before {
  content: "\f0e2";
}
.icon-undo:before {
  content: "\f0e2";
}
.icon-gavel:before {
  content: "\f0e3";
}
.icon-legal:before {
  content: "\f0e3";
}
.icon-dashboard:before {
  content: "\f0e4";
}
.icon-tachometer:before {
  content: "\f0e4";
}
.icon-comment-o:before {
  content: "\f0e5";
}
.icon-comments-o:before {
  content: "\f0e6";
}
.icon-bolt:before {
  content: "\f0e7";
}
.icon-flash:before {
  content: "\f0e7";
}
.icon-sitemap:before {
  content: "\f0e8";
}
.icon-umbrella:before {
  content: "\f0e9";
}
.icon-clipboard:before {
  content: "\f0ea";
}
.icon-paste:before {
  content: "\f0ea";
}
.icon-lightbulb-o:before {
  content: "\f0eb";
}
.icon-exchange:before {
  content: "\f0ec";
}
.icon-cloud-download:before {
  content: "\f0ed";
}
.icon-cloud-upload:before {
  content: "\f0ee";
}
.icon-user-md:before {
  content: "\f0f0";
}
.icon-stethoscope:before {
  content: "\f0f1";
}
.icon-suitcase:before {
  content: "\f0f2";
}
.icon-bell:before {
  content: "\f0f3";
}
.icon-coffee:before {
  content: "\f0f4";
}
.icon-cutlery:before {
  content: "\f0f5";
}
.icon-file-text-o:before {
  content: "\f0f6";
}
.icon-building-o:before {
  content: "\f0f7";
}
.icon-hospital-o:before {
  content: "\f0f8";
}
.icon-ambulance:before {
  content: "\f0f9";
}
.icon-medkit:before {
  content: "\f0fa";
}
.icon-fighter-jet:before {
  content: "\f0fb";
}
.icon-beer:before {
  content: "\f0fc";
}
.icon-h-square:before {
  content: "\f0fd";
}
.icon-plus-square:before {
  content: "\f0fe";
}
.icon-angle-double-left:before {
  content: "\f100";
}
.icon-angle-double-right:before {
  content: "\f101";
}
.icon-angle-double-up:before {
  content: "\f102";
}
.icon-angle-double-down:before {
  content: "\f103";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-desktop:before {
  content: "\f108";
}
.icon-laptop:before {
  content: "\f109";
}
.icon-tablet:before {
  content: "\f10a";
}
.icon-mobile:before {
  content: "\f10b";
}
.icon-mobile-phone:before {
  content: "\f10b";
}
.icon-circle-o:before {
  content: "\f10c";
}
.icon-quote-left:before {
  content: "\f10d";
}
.icon-quote-right:before {
  content: "\f10e";
}
.icon-spinner:before {
  content: "\f110";
}
.icon-circle:before {
  content: "\f111";
}
.icon-mail-reply:before {
  content: "\f112";
}
.icon-reply:before {
  content: "\f112";
}
.icon-github-alt:before {
  content: "\f113";
}
.icon-folder-o:before {
  content: "\f114";
}
.icon-folder-open-o:before {
  content: "\f115";
}
.icon-smile-o:before {
  content: "\f118";
}
.icon-frown-o:before {
  content: "\f119";
}
.icon-meh-o:before {
  content: "\f11a";
}
.icon-gamepad:before {
  content: "\f11b";
}
.icon-keyboard-o:before {
  content: "\f11c";
}
.icon-flag-o:before {
  content: "\f11d";
}
.icon-flag-checkered:before {
  content: "\f11e";
}
.icon-terminal:before {
  content: "\f120";
}
.icon-code:before {
  content: "\f121";
}
.icon-mail-reply-all:before {
  content: "\f122";
}
.icon-reply-all:before {
  content: "\f122";
}
.icon-star-half-empty:before {
  content: "\f123";
}
.icon-star-half-full:before {
  content: "\f123";
}
.icon-star-half-o:before {
  content: "\f123";
}
.icon-location-arrow:before {
  content: "\f124";
}
.icon-crop:before {
  content: "\f125";
}
.icon-code-fork:before {
  content: "\f126";
}
.icon-chain-broken:before {
  content: "\f127";
}
.icon-unlink:before {
  content: "\f127";
}
.icon-info:before {
  content: "\f129";
}
.icon-exclamation:before {
  content: "\f12a";
}
.icon-superscript:before {
  content: "\f12b";
}
.icon-subscript:before {
  content: "\f12c";
}
.icon-eraser:before {
  content: "\f12d";
}
.icon-puzzle-piece:before {
  content: "\f12e";
}
.icon-microphone:before {
  content: "\f130";
}
.icon-microphone-slash:before {
  content: "\f131";
}
.icon-shield:before {
  content: "\f132";
}
.icon-calendar-o:before {
  content: "\f133";
}
.icon-fire-extinguisher:before {
  content: "\f134";
}
.icon-rocket:before {
  content: "\f135";
}
.icon-maxcdn:before {
  content: "\f136";
}
.icon-chevron-circle-left:before {
  content: "\f137";
}
.icon-chevron-circle-right:before {
  content: "\f138";
}
.icon-chevron-circle-up:before {
  content: "\f139";
}
.icon-chevron-circle-down:before {
  content: "\f13a";
}
.icon-html5:before {
  content: "\f13b";
}
.icon-css3:before {
  content: "\f13c";
}
.icon-anchor:before {
  content: "\f13d";
}
.icon-unlock-alt:before {
  content: "\f13e";
}
.icon-bullseye:before {
  content: "\f140";
}
.icon-ellipsis-h:before {
  content: "\f141";
}
.icon-ellipsis-v:before {
  content: "\f142";
}
.icon-rss-square:before {
  content: "\f143";
}
.icon-play-circle:before {
  content: "\f144";
}
.icon-ticket:before {
  content: "\f145";
}
.icon-minus-square:before {
  content: "\f146";
}
.icon-minus-square-o:before {
  content: "\f147";
}
.icon-level-up:before {
  content: "\f148";
}
.icon-level-down:before {
  content: "\f149";
}
.icon-check-square:before {
  content: "\f14a";
}
.icon-pencil-square:before {
  content: "\f14b";
}
.icon-external-link-square:before {
  content: "\f14c";
}
.icon-share-square:before {
  content: "\f14d";
}
.icon-compass:before {
  content: "\f14e";
}
.icon-caret-square-o-down:before {
  content: "\f150";
}
.icon-toggle-down:before {
  content: "\f150";
}
.icon-caret-square-o-up:before {
  content: "\f151";
}
.icon-toggle-up:before {
  content: "\f151";
}
.icon-caret-square-o-right:before {
  content: "\f152";
}
.icon-toggle-right:before {
  content: "\f152";
}
.icon-eur:before {
  content: "\f153";
}
.icon-euro:before {
  content: "\f153";
}
.icon-gbp:before {
  content: "\f154";
}
.icon-dollar:before {
  content: "\f155";
}
.icon-usd:before {
  content: "\f155";
}
.icon-inr:before {
  content: "\f156";
}
.icon-rupee:before {
  content: "\f156";
}
.icon-cny:before {
  content: "\f157";
}
.icon-jpy:before {
  content: "\f157";
}
.icon-rmb:before {
  content: "\f157";
}
.icon-yen:before {
  content: "\f157";
}
.icon-rouble:before {
  content: "\f158";
}
.icon-rub:before {
  content: "\f158";
}
.icon-ruble:before {
  content: "\f158";
}
.icon-krw:before {
  content: "\f159";
}
.icon-won:before {
  content: "\f159";
}
.icon-bitcoin:before {
  content: "\f15a";
}
.icon-btc:before {
  content: "\f15a";
}
.icon-file:before {
  content: "\f15b";
}
.icon-file-text:before {
  content: "\f15c";
}
.icon-sort-alpha-asc:before {
  content: "\f15d";
}
.icon-sort-alpha-desc:before {
  content: "\f15e";
}
.icon-sort-amount-asc:before {
  content: "\f160";
}
.icon-sort-amount-desc:before {
  content: "\f161";
}
.icon-sort-numeric-asc:before {
  content: "\f162";
}
.icon-sort-numeric-desc:before {
  content: "\f163";
}
.icon-thumbs-up:before {
  content: "\f164";
}
.icon-thumbs-down:before {
  content: "\f165";
}
.icon-youtube-square:before {
  content: "\f166";
}
.icon-youtube:before {
  content: "\f167";
}
.icon-xing:before {
  content: "\f168";
}
.icon-xing-square:before {
  content: "\f169";
}
.icon-youtube-play:before {
  content: "\f16a";
}
.icon-dropbox:before {
  content: "\f16b";
}
.icon-stack-overflow:before {
  content: "\f16c";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-flickr:before {
  content: "\f16e";
}
.icon-adn:before {
  content: "\f170";
}
.icon-bitbucket:before {
  content: "\f171";
}
.icon-bitbucket-square:before {
  content: "\f172";
}
.icon-tumblr:before {
  content: "\f173";
}
.icon-tumblr-square:before {
  content: "\f174";
}
.icon-long-arrow-down:before {
  content: "\f175";
}
.icon-long-arrow-up:before {
  content: "\f176";
}
.icon-long-arrow-left:before {
  content: "\f177";
}
.icon-long-arrow-right:before {
  content: "\f178";
}
.icon-apple:before {
  content: "\f179";
}
.icon-windows:before {
  content: "\f17a";
}
.icon-android:before {
  content: "\f17b";
}
.icon-linux:before {
  content: "\f17c";
}
.icon-dribbble:before {
  content: "\f17d";
}
.icon-skype:before {
  content: "\f17e";
}
.icon-foursquare:before {
  content: "\f180";
}
.icon-trello:before {
  content: "\f181";
}
.icon-female:before {
  content: "\f182";
}
.icon-male:before {
  content: "\f183";
}
.icon-gittip:before {
  content: "\f184";
}
.icon-gratipay:before {
  content: "\f184";
}
.icon-sun-o:before {
  content: "\f185";
}
.icon-moon-o:before {
  content: "\f186";
}
.icon-archive:before {
  content: "\f187";
}
.icon-bug:before {
  content: "\f188";
}
.icon-vk:before {
  content: "\f189";
}
.icon-weibo:before {
  content: "\f18a";
}
.icon-renren:before {
  content: "\f18b";
}
.icon-pagelines:before {
  content: "\f18c";
}
.icon-stack-exchange:before {
  content: "\f18d";
}
.icon-arrow-circle-o-right:before {
  content: "\f18e";
}
.icon-arrow-circle-o-left:before {
  content: "\f190";
}
.icon-caret-square-o-left:before {
  content: "\f191";
}
.icon-toggle-left:before {
  content: "\f191";
}
.icon-dot-circle-o:before {
  content: "\f192";
}
.icon-wheelchair:before {
  content: "\f193";
}
.icon-vimeo-square:before {
  content: "\f194";
}
.icon-try:before {
  content: "\f195";
}
.icon-turkish-lira:before {
  content: "\f195";
}
.icon-plus-square-o:before {
  content: "\f196";
}
.icon-space-shuttle:before {
  content: "\f197";
}
.icon-slack:before {
  content: "\f198";
}
.icon-envelope-square:before {
  content: "\f199";
}
.icon-wordpress:before {
  content: "\f19a";
}
.icon-openid:before {
  content: "\f19b";
}
.icon-bank:before {
  content: "\f19c";
}
.icon-institution:before {
  content: "\f19c";
}
.icon-university:before {
  content: "\f19c";
}
.icon-graduation-cap:before {
  content: "\f19d";
}
.icon-mortar-board:before {
  content: "\f19d";
}
.icon-yahoo:before {
  content: "\f19e";
}
.icon-google:before {
  content: "\f1a0";
}
.icon-reddit:before {
  content: "\f1a1";
}
.icon-reddit-square:before {
  content: "\f1a2";
}
.icon-stumbleupon-circle:before {
  content: "\f1a3";
}
.icon-stumbleupon:before {
  content: "\f1a4";
}
.icon-delicious:before {
  content: "\f1a5";
}
.icon-digg:before {
  content: "\f1a6";
}
.icon-pied-piper-pp:before {
  content: "\f1a7";
}
.icon-pied-piper-alt:before {
  content: "\f1a8";
}
.icon-drupal:before {
  content: "\f1a9";
}
.icon-joomla:before {
  content: "\f1aa";
}
.icon-language:before {
  content: "\f1ab";
}
.icon-fax:before {
  content: "\f1ac";
}
.icon-building:before {
  content: "\f1ad";
}
.icon-child:before {
  content: "\f1ae";
}
.icon-paw:before {
  content: "\f1b0";
}
.icon-spoon:before {
  content: "\f1b1";
}
.icon-cube:before {
  content: "\f1b2";
}
.icon-cubes:before {
  content: "\f1b3";
}
.icon-behance:before {
  content: "\f1b4";
}
.icon-behance-square:before {
  content: "\f1b5";
}
.icon-steam:before {
  content: "\f1b6";
}
.icon-steam-square:before {
  content: "\f1b7";
}
.icon-recycle:before {
  content: "\f1b8";
}
.icon-automobile:before {
  content: "\f1b9";
}
.icon-car:before {
  content: "\f1b9";
}
.icon-cab:before {
  content: "\f1ba";
}
.icon-taxi:before {
  content: "\f1ba";
}
.icon-tree:before {
  content: "\f1bb";
}
.icon-spotify:before {
  content: "\f1bc";
}
.icon-deviantart:before {
  content: "\f1bd";
}
.icon-soundcloud:before {
  content: "\f1be";
}
.icon-database:before {
  content: "\f1c0";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-file-word-o:before {
  content: "\f1c2";
}
.icon-file-excel-o:before {
  content: "\f1c3";
}
.icon-file-powerpoint-o:before {
  content: "\f1c4";
}
.icon-file-image-o:before {
  content: "\f1c5";
}
.icon-file-photo-o:before {
  content: "\f1c5";
}
.icon-file-picture-o:before {
  content: "\f1c5";
}
.icon-file-archive-o:before {
  content: "\f1c6";
}
.icon-file-zip-o:before {
  content: "\f1c6";
}
.icon-file-audio-o:before {
  content: "\f1c7";
}
.icon-file-sound-o:before {
  content: "\f1c7";
}
.icon-file-movie-o:before {
  content: "\f1c8";
}
.icon-file-video-o:before {
  content: "\f1c8";
}
.icon-file-code-o:before {
  content: "\f1c9";
}
.icon-vine:before {
  content: "\f1ca";
}
.icon-codepen:before {
  content: "\f1cb";
}
.icon-jsfiddle:before {
  content: "\f1cc";
}
.icon-life-bouy:before {
  content: "\f1cd";
}
.icon-life-buoy:before {
  content: "\f1cd";
}
.icon-life-ring:before {
  content: "\f1cd";
}
.icon-life-saver:before {
  content: "\f1cd";
}
.icon-support:before {
  content: "\f1cd";
}
.icon-circle-o-notch:before {
  content: "\f1ce";
}
.icon-ra:before {
  content: "\f1d0";
}
.icon-rebel:before {
  content: "\f1d0";
}
.icon-resistance:before {
  content: "\f1d0";
}
.icon-empire:before {
  content: "\f1d1";
}
.icon-ge:before {
  content: "\f1d1";
}
.icon-git-square:before {
  content: "\f1d2";
}
.icon-git:before {
  content: "\f1d3";
}
.icon-hacker-news:before {
  content: "\f1d4";
}
.icon-y-combinator-square:before {
  content: "\f1d4";
}
.icon-yc-square:before {
  content: "\f1d4";
}
.icon-tencent-weibo:before {
  content: "\f1d5";
}
.icon-qq:before {
  content: "\f1d6";
}
.icon-wechat:before {
  content: "\f1d7";
}
.icon-weixin:before {
  content: "\f1d7";
}
.icon-paper-plane:before {
  content: "\f1d8";
}
.icon-send:before {
  content: "\f1d8";
}
.icon-paper-plane-o:before {
  content: "\f1d9";
}
.icon-send-o:before {
  content: "\f1d9";
}
.icon-history:before {
  content: "\f1da";
}
.icon-circle-thin:before {
  content: "\f1db";
}
.icon-header:before {
  content: "\f1dc";
}
.icon-paragraph:before {
  content: "\f1dd";
}
.icon-sliders:before {
  content: "\f1de";
}
.icon-share-alt:before {
  content: "\f1e0";
}
.icon-share-alt-square:before {
  content: "\f1e1";
}
.icon-bomb:before {
  content: "\f1e2";
}
.icon-futbol-o:before {
  content: "\f1e3";
}
.icon-soccer-ball-o:before {
  content: "\f1e3";
}
.icon-tty:before {
  content: "\f1e4";
}
.icon-binoculars:before {
  content: "\f1e5";
}
.icon-plug:before {
  content: "\f1e6";
}
.icon-slideshare:before {
  content: "\f1e7";
}
.icon-twitch:before {
  content: "\f1e8";
}
.icon-yelp:before {
  content: "\f1e9";
}
.icon-newspaper-o:before {
  content: "\f1ea";
}
.icon-wifi:before {
  content: "\f1eb";
}
.icon-calculator:before {
  content: "\f1ec";
}
.icon-paypal:before {
  content: "\f1ed";
}
.icon-google-wallet:before {
  content: "\f1ee";
}
.icon-cc-visa:before {
  content: "\f1f0";
}
.icon-cc-mastercard:before {
  content: "\f1f1";
}
.icon-cc-discover:before {
  content: "\f1f2";
}
.icon-cc-amex:before {
  content: "\f1f3";
}
.icon-cc-paypal:before {
  content: "\f1f4";
}
.icon-cc-stripe:before {
  content: "\f1f5";
}
.icon-bell-slash:before {
  content: "\f1f6";
}
.icon-bell-slash-o:before {
  content: "\f1f7";
}
.icon-trash:before {
  content: "\f1f8";
}
.icon-copyright:before {
  content: "\f1f9";
}
.icon-at:before {
  content: "\f1fa";
}
.icon-eyedropper:before {
  content: "\f1fb";
}
.icon-paint-brush:before {
  content: "\f1fc";
}
.icon-birthday-cake:before {
  content: "\f1fd";
}
.icon-area-chart:before {
  content: "\f1fe";
}
.icon-pie-chart:before {
  content: "\f200";
}
.icon-line-chart:before {
  content: "\f201";
}
.icon-lastfm:before {
  content: "\f202";
}
.icon-lastfm-square:before {
  content: "\f203";
}
.icon-toggle-off:before {
  content: "\f204";
}
.icon-toggle-on:before {
  content: "\f205";
}
.icon-bicycle:before {
  content: "\f206";
}
.icon-bus:before {
  content: "\f207";
}
.icon-ioxhost:before {
  content: "\f208";
}
.icon-angellist:before {
  content: "\f209";
}
.icon-cc:before {
  content: "\f20a";
}
.icon-ils:before {
  content: "\f20b";
}
.icon-shekel:before {
  content: "\f20b";
}
.icon-sheqel:before {
  content: "\f20b";
}
.icon-meanpath:before {
  content: "\f20c";
}
.icon-buysellads:before {
  content: "\f20d";
}
.icon-connectdevelop:before {
  content: "\f20e";
}
.icon-dashcube:before {
  content: "\f210";
}
.icon-forumbee:before {
  content: "\f211";
}
.icon-leanpub:before {
  content: "\f212";
}
.icon-sellsy:before {
  content: "\f213";
}
.icon-shirtsinbulk:before {
  content: "\f214";
}
.icon-simplybuilt:before {
  content: "\f215";
}
.icon-skyatlas:before {
  content: "\f216";
}
.icon-cart-plus:before {
  content: "\f217";
}
.icon-cart-arrow-down:before {
  content: "\f218";
}
.icon-diamond:before {
  content: "\f219";
}
.icon-ship:before {
  content: "\f21a";
}
.icon-user-secret:before {
  content: "\f21b";
}
.icon-motorcycle:before {
  content: "\f21c";
}
.icon-street-view:before {
  content: "\f21d";
}
.icon-heartbeat:before {
  content: "\f21e";
}
.icon-venus:before {
  content: "\f221";
}
.icon-mars:before {
  content: "\f222";
}
.icon-mercury:before {
  content: "\f223";
}
.icon-intersex:before {
  content: "\f224";
}
.icon-transgender:before {
  content: "\f224";
}
.icon-transgender-alt:before {
  content: "\f225";
}
.icon-venus-double:before {
  content: "\f226";
}
.icon-mars-double:before {
  content: "\f227";
}
.icon-venus-mars:before {
  content: "\f228";
}
.icon-mars-stroke:before {
  content: "\f229";
}
.icon-mars-stroke-v:before {
  content: "\f22a";
}
.icon-mars-stroke-h:before {
  content: "\f22b";
}
.icon-neuter:before {
  content: "\f22c";
}
.icon-genderless:before {
  content: "\f22d";
}
.icon-facebook-official:before {
  content: "\f230";
}
.icon-pinterest-p:before {
  content: "\f231";
}
.icon-whatsapp:before {
  content: "\f232";
}
.icon-server:before {
  content: "\f233";
}
.icon-user-plus:before {
  content: "\f234";
}
.icon-user-times:before {
  content: "\f235";
}
.icon-bed:before {
  content: "\f236";
}
.icon-hotel:before {
  content: "\f236";
}
.icon-viacoin:before {
  content: "\f237";
}
.icon-train:before {
  content: "\f238";
}
.icon-subway:before {
  content: "\f239";
}
.icon-medium:before {
  content: "\f23a";
}
.icon-y-combinator:before {
  content: "\f23b";
}
.icon-yc:before {
  content: "\f23b";
}
.icon-optin-monster:before {
  content: "\f23c";
}
.icon-opencart:before {
  content: "\f23d";
}
.icon-expeditedssl:before {
  content: "\f23e";
}
.icon-battery:before {
  content: "\f240";
}
.icon-battery-4:before {
  content: "\f240";
}
.icon-battery-full:before {
  content: "\f240";
}
.icon-battery-3:before {
  content: "\f241";
}
.icon-battery-three-quarters:before {
  content: "\f241";
}
.icon-battery-2:before {
  content: "\f242";
}
.icon-battery-half:before {
  content: "\f242";
}
.icon-battery-1:before {
  content: "\f243";
}
.icon-battery-quarter:before {
  content: "\f243";
}
.icon-battery-0:before {
  content: "\f244";
}
.icon-battery-empty:before {
  content: "\f244";
}
.icon-mouse-pointer:before {
  content: "\f245";
}
.icon-i-cursor:before {
  content: "\f246";
}
.icon-object-group:before {
  content: "\f247";
}
.icon-object-ungroup:before {
  content: "\f248";
}
.icon-sticky-note:before {
  content: "\f249";
}
.icon-sticky-note-o:before {
  content: "\f24a";
}
.icon-cc-jcb:before {
  content: "\f24b";
}
.icon-cc-diners-club:before {
  content: "\f24c";
}
.icon-clone:before {
  content: "\f24d";
}
.icon-balance-scale:before {
  content: "\f24e";
}
.icon-hourglass-o:before {
  content: "\f250";
}
.icon-hourglass-1:before {
  content: "\f251";
}
.icon-hourglass-start:before {
  content: "\f251";
}
.icon-hourglass-2:before {
  content: "\f252";
}
.icon-hourglass-half:before {
  content: "\f252";
}
.icon-hourglass-3:before {
  content: "\f253";
}
.icon-hourglass-end:before {
  content: "\f253";
}
.icon-hourglass:before {
  content: "\f254";
}
.icon-hand-grab-o:before {
  content: "\f255";
}
.icon-hand-rock-o:before {
  content: "\f255";
}
.icon-hand-paper-o:before {
  content: "\f256";
}
.icon-hand-stop-o:before {
  content: "\f256";
}
.icon-hand-scissors-o:before {
  content: "\f257";
}
.icon-hand-lizard-o:before {
  content: "\f258";
}
.icon-hand-spock-o:before {
  content: "\f259";
}
.icon-hand-pointer-o:before {
  content: "\f25a";
}
.icon-hand-peace-o:before {
  content: "\f25b";
}
.icon-trademark:before {
  content: "\f25c";
}
.icon-registered:before {
  content: "\f25d";
}
.icon-creative-commons:before {
  content: "\f25e";
}
.icon-gg:before {
  content: "\f260";
}
.icon-gg-circle:before {
  content: "\f261";
}
.icon-tripadvisor:before {
  content: "\f262";
}
.icon-odnoklassniki:before {
  content: "\f263";
}
.icon-odnoklassniki-square:before {
  content: "\f264";
}
.icon-get-pocket:before {
  content: "\f265";
}
.icon-wikipedia-w:before {
  content: "\f266";
}
.icon-safari:before {
  content: "\f267";
}
.icon-chrome:before {
  content: "\f268";
}
.icon-firefox:before {
  content: "\f269";
}
.icon-opera:before {
  content: "\f26a";
}
.icon-internet-explorer:before {
  content: "\f26b";
}
.icon-television:before {
  content: "\f26c";
}
.icon-tv:before {
  content: "\f26c";
}
.icon-contao:before {
  content: "\f26d";
}
.icon-500px:before {
  content: "\f26e";
}
.icon-amazon:before {
  content: "\f270";
}
.icon-calendar-plus-o:before {
  content: "\f271";
}
.icon-calendar-minus-o:before {
  content: "\f272";
}
.icon-calendar-times-o:before {
  content: "\f273";
}
.icon-calendar-check-o:before {
  content: "\f274";
}
.icon-industry:before {
  content: "\f275";
}
.icon-map-pin:before {
  content: "\f276";
}
.icon-map-signs:before {
  content: "\f277";
}
.icon-map-o:before {
  content: "\f278";
}
.icon-map:before {
  content: "\f279";
}
.icon-commenting:before {
  content: "\f27a";
}
.icon-commenting-o:before {
  content: "\f27b";
}
.icon-houzz:before {
  content: "\f27c";
}
.icon-vimeo:before {
  content: "\f27d";
}
.icon-black-tie:before {
  content: "\f27e";
}
.icon-fonticons:before {
  content: "\f280";
}
.icon-reddit-alien:before {
  content: "\f281";
}
.icon-edge:before {
  content: "\f282";
}
.icon-credit-card-alt:before {
  content: "\f283";
}
.icon-codiepie:before {
  content: "\f284";
}
.icon-modx:before {
  content: "\f285";
}
.icon-fort-awesome:before {
  content: "\f286";
}
.icon-usb:before {
  content: "\f287";
}
.icon-product-hunt:before {
  content: "\f288";
}
.icon-mixcloud:before {
  content: "\f289";
}
.icon-scribd:before {
  content: "\f28a";
}
.icon-pause-circle:before {
  content: "\f28b";
}
.icon-pause-circle-o:before {
  content: "\f28c";
}
.icon-stop-circle:before {
  content: "\f28d";
}
.icon-stop-circle-o:before {
  content: "\f28e";
}
.icon-shopping-bag:before {
  content: "\f290";
}
.icon-shopping-basket:before {
  content: "\f291";
}
.icon-hashtag:before {
  content: "\f292";
}
.icon-bluetooth:before {
  content: "\f293";
}
.icon-bluetooth-b:before {
  content: "\f294";
}
.icon-percent:before {
  content: "\f295";
}
.icon-gitlab:before {
  content: "\f296";
}
.icon-wpbeginner:before {
  content: "\f297";
}
.icon-wpforms:before {
  content: "\f298";
}
.icon-envira:before {
  content: "\f299";
}
.icon-universal-access:before {
  content: "\f29a";
}
.icon-wheelchair-alt:before {
  content: "\f29b";
}
.icon-question-circle-o:before {
  content: "\f29c";
}
.icon-blind:before {
  content: "\f29d";
}
.icon-audio-description:before {
  content: "\f29e";
}
.icon-volume-control-phone:before {
  content: "\f2a0";
}
.icon-braille:before {
  content: "\f2a1";
}
.icon-assistive-listening-systems:before {
  content: "\f2a2";
}
.icon-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.icon-asl-interpreting:before {
  content: "\f2a3";
}
.icon-deaf:before {
  content: "\f2a4";
}
.icon-deafness:before {
  content: "\f2a4";
}
.icon-hard-of-hearing:before {
  content: "\f2a4";
}
.icon-glide:before {
  content: "\f2a5";
}
.icon-glide-g:before {
  content: "\f2a6";
}
.icon-sign-language:before {
  content: "\f2a7";
}
.icon-signing:before {
  content: "\f2a7";
}
.icon-low-vision:before {
  content: "\f2a8";
}
.icon-viadeo:before {
  content: "\f2a9";
}
.icon-viadeo-square:before {
  content: "\f2aa";
}
.icon-snapchat:before {
  content: "\f2ab";
}
.icon-snapchat-ghost:before {
  content: "\f2ac";
}
.icon-snapchat-square:before {
  content: "\f2ad";
}
.icon-pied-piper:before {
  content: "\f2ae";
}
.icon-first-order:before {
  content: "\f2b0";
}
.icon-yoast:before {
  content: "\f2b1";
}
.icon-themeisle:before {
  content: "\f2b2";
}
.icon-google-plus-circle:before {
  content: "\f2b3";
}
.icon-google-plus-official:before {
  content: "\f2b3";
}
.icon-fa:before {
  content: "\f2b4";
}
.icon-font-awesome:before {
  content: "\f2b4";
}
.icon-handshake-o:before {
  content: "\f2b5";
}
.icon-envelope-open:before {
  content: "\f2b6";
}
.icon-envelope-open-o:before {
  content: "\f2b7";
}
.icon-linode:before {
  content: "\f2b8";
}
.icon-address-book:before {
  content: "\f2b9";
}
.icon-address-book-o:before {
  content: "\f2ba";
}
.icon-address-card:before {
  content: "\f2bb";
}
.icon-vcard:before {
  content: "\f2bb";
}
.icon-address-card-o:before {
  content: "\f2bc";
}
.icon-vcard-o:before {
  content: "\f2bc";
}
.icon-user-circle:before {
  content: "\f2bd";
}
.icon-user-circle-o:before {
  content: "\f2be";
}
.icon-user-o:before {
  content: "\f2c0";
}
.icon-id-badge:before {
  content: "\f2c1";
}
.icon-drivers-license:before {
  content: "\f2c2";
}
.icon-id-card:before {
  content: "\f2c2";
}
.icon-drivers-license-o:before {
  content: "\f2c3";
}
.icon-id-card-o:before {
  content: "\f2c3";
}
.icon-quora:before {
  content: "\f2c4";
}
.icon-free-code-camp:before {
  content: "\f2c5";
}
.icon-telegram:before {
  content: "\f2c6";
}
.icon-thermometer:before {
  content: "\f2c7";
}
.icon-thermometer-4:before {
  content: "\f2c7";
}
.icon-thermometer-full:before {
  content: "\f2c7";
}
.icon-thermometer-3:before {
  content: "\f2c8";
}
.icon-thermometer-three-quarters:before {
  content: "\f2c8";
}
.icon-thermometer-2:before {
  content: "\f2c9";
}
.icon-thermometer-half:before {
  content: "\f2c9";
}
.icon-thermometer-1:before {
  content: "\f2ca";
}
.icon-thermometer-quarter:before {
  content: "\f2ca";
}
.icon-thermometer-0:before {
  content: "\f2cb";
}
.icon-thermometer-empty:before {
  content: "\f2cb";
}
.icon-shower:before {
  content: "\f2cc";
}
.icon-bath:before {
  content: "\f2cd";
}
.icon-bathtub:before {
  content: "\f2cd";
}
.icon-s15:before {
  content: "\f2cd";
}
.icon-podcast:before {
  content: "\f2ce";
}
.icon-window-maximize:before {
  content: "\f2d0";
}
.icon-window-minimize:before {
  content: "\f2d1";
}
.icon-window-restore:before {
  content: "\f2d2";
}
.icon-times-rectangle:before {
  content: "\f2d3";
}
.icon-window-close:before {
  content: "\f2d3";
}
.icon-times-rectangle-o:before {
  content: "\f2d4";
}
.icon-window-close-o:before {
  content: "\f2d4";
}
.icon-bandcamp:before {
  content: "\f2d5";
}
.icon-grav:before {
  content: "\f2d6";
}
.icon-etsy:before {
  content: "\f2d7";
}
.icon-imdb:before {
  content: "\f2d8";
}
.icon-ravelry:before {
  content: "\f2d9";
}
.icon-eercast:before {
  content: "\f2da";
}
.icon-microchip:before {
  content: "\f2db";
}
.icon-snowflake-o:before {
  content: "\f2dc";
}
.icon-superpowers:before {
  content: "\f2dd";
}
.icon-wpexplorer:before {
  content: "\f2de";
}
.icon-meetup:before {
  content: "\f2e0";
}
.icon-3d_rotation:before {
  content: "\e84d";
}
.icon-ac_unit:before {
  content: "\eb3b";
}
.icon-alarm:before {
  content: "\e855";
}
.icon-access_alarms:before {
  content: "\e191";
}
.icon-schedule:before {
  content: "\e8b5";
}
.icon-accessibility:before {
  content: "\e84e";
}
.icon-accessible:before {
  content: "\e914";
}
.icon-account_balance:before {
  content: "\e84f";
}
.icon-account_balance_wallet:before {
  content: "\e850";
}
.icon-account_box:before {
  content: "\e851";
}
.icon-account_circle:before {
  content: "\e853";
}
.icon-adb:before {
  content: "\e60e";
}
.icon-add:before {
  content: "\e145";
}
.icon-add_a_photo:before {
  content: "\e439";
}
.icon-alarm_add:before {
  content: "\e856";
}
.icon-add_alert:before {
  content: "\e003";
}
.icon-add_box:before {
  content: "\e146";
}
.icon-add_circle:before {
  content: "\e147";
}
.icon-control_point:before {
  content: "\e3ba";
}
.icon-add_location:before {
  content: "\e567";
}
.icon-add_shopping_cart:before {
  content: "\e854";
}
.icon-queue:before {
  content: "\e03c";
}
.icon-add_to_queue:before {
  content: "\e05c";
}
.icon-adjust2:before {
  content: "\e39e";
}
.icon-airline_seat_flat:before {
  content: "\e630";
}
.icon-airline_seat_flat_angled:before {
  content: "\e631";
}
.icon-airline_seat_individual_suite:before {
  content: "\e632";
}
.icon-airline_seat_legroom_extra:before {
  content: "\e633";
}
.icon-airline_seat_legroom_normal:before {
  content: "\e634";
}
.icon-airline_seat_legroom_reduced:before {
  content: "\e635";
}
.icon-airline_seat_recline_extra:before {
  content: "\e636";
}
.icon-airline_seat_recline_normal:before {
  content: "\e637";
}
.icon-flight:before {
  content: "\e539";
}
.icon-airplanemode_inactive:before {
  content: "\e194";
}
.icon-airplay:before {
  content: "\e055";
}
.icon-airport_shuttle:before {
  content: "\eb3c";
}
.icon-alarm_off:before {
  content: "\e857";
}
.icon-alarm_on:before {
  content: "\e858";
}
.icon-album:before {
  content: "\e019";
}
.icon-all_inclusive:before {
  content: "\eb3d";
}
.icon-all_out:before {
  content: "\e90b";
}
.icon-android2:before {
  content: "\e859";
}
.icon-announcement:before {
  content: "\e85a";
}
.icon-apps:before {
  content: "\e5c3";
}
.icon-archive2:before {
  content: "\e149";
}
.icon-arrow_back:before {
  content: "\e5c4";
}
.icon-arrow_downward:before {
  content: "\e5db";
}
.icon-arrow_drop_down:before {
  content: "\e5c5";
}
.icon-arrow_drop_down_circle:before {
  content: "\e5c6";
}
.icon-arrow_drop_up:before {
  content: "\e5c7";
}
.icon-arrow_forward:before {
  content: "\e5c8";
}
.icon-arrow_upward:before {
  content: "\e5d8";
}
.icon-art_track:before {
  content: "\e060";
}
.icon-aspect_ratio:before {
  content: "\e85b";
}
.icon-poll:before {
  content: "\e801";
}
.icon-assignment:before {
  content: "\e85d";
}
.icon-assignment_ind:before {
  content: "\e85e";
}
.icon-assignment_late:before {
  content: "\e85f";
}
.icon-assignment_return:before {
  content: "\e860";
}
.icon-assignment_returned:before {
  content: "\e861";
}
.icon-assignment_turned_in:before {
  content: "\e862";
}
.icon-assistant:before {
  content: "\e39f";
}
.icon-flag2:before {
  content: "\e153";
}
.icon-attach_file:before {
  content: "\e226";
}
.icon-attach_money:before {
  content: "\e227";
}
.icon-attachment:before {
  content: "\e2bc";
}
.icon-audiotrack:before {
  content: "\e3a1";
}
.icon-autorenew:before {
  content: "\e863";
}
.icon-av_timer:before {
  content: "\e01b";
}
.icon-backspace:before {
  content: "\e14a";
}
.icon-cloud_upload:before {
  content: "\e2c3";
}
.icon-battery_alert:before {
  content: "\e19c";
}
.icon-battery_charging_full:before {
  content: "\e1a3";
}
.icon-battery_std:before {
  content: "\e1a5";
}
.icon-battery_unknown:before {
  content: "\e1a6";
}
.icon-beach_access:before {
  content: "\eb3e";
}
.icon-beenhere:before {
  content: "\e52d";
}
.icon-block:before {
  content: "\e14b";
}
.icon-bluetooth2:before {
  content: "\e1a7";
}
.icon-bluetooth_searching:before {
  content: "\e1aa";
}
.icon-bluetooth_connected:before {
  content: "\e1a8";
}
.icon-bluetooth_disabled:before {
  content: "\e1a9";
}
.icon-blur_circular:before {
  content: "\e3a2";
}
.icon-blur_linear:before {
  content: "\e3a3";
}
.icon-blur_off:before {
  content: "\e3a4";
}
.icon-blur_on:before {
  content: "\e3a5";
}
.icon-class:before {
  content: "\e86e";
}
.icon-turned_in:before {
  content: "\e8e6";
}
.icon-turned_in_not:before {
  content: "\e8e7";
}
.icon-border_all:before {
  content: "\e228";
}
.icon-border_bottom:before {
  content: "\e229";
}
.icon-border_clear:before {
  content: "\e22a";
}
.icon-border_color:before {
  content: "\e22b";
}
.icon-border_horizontal:before {
  content: "\e22c";
}
.icon-border_inner:before {
  content: "\e22d";
}
.icon-border_left:before {
  content: "\e22e";
}
.icon-border_outer:before {
  content: "\e22f";
}
.icon-border_right:before {
  content: "\e230";
}
.icon-border_style:before {
  content: "\e231";
}
.icon-border_top:before {
  content: "\e232";
}
.icon-border_vertical:before {
  content: "\e233";
}
.icon-branding_watermark:before {
  content: "\e06b";
}
.icon-brightness_1:before {
  content: "\e3a6";
}
.icon-brightness_2:before {
  content: "\e3a7";
}
.icon-brightness_3:before {
  content: "\e3a8";
}
.icon-brightness_4:before {
  content: "\e3a9";
}
.icon-brightness_low:before {
  content: "\e1ad";
}
.icon-brightness_medium:before {
  content: "\e1ae";
}
.icon-brightness_high:before {
  content: "\e1ac";
}
.icon-brightness_auto:before {
  content: "\e1ab";
}
.icon-broken_image:before {
  content: "\e3ad";
}
.icon-brush:before {
  content: "\e3ae";
}
.icon-bubble_chart:before {
  content: "\e6dd";
}
.icon-bug_report:before {
  content: "\e868";
}
.icon-build:before {
  content: "\e869";
}
.icon-burst_mode:before {
  content: "\e43c";
}
.icon-domain:before {
  content: "\e7ee";
}
.icon-business_center:before {
  content: "\eb3f";
}
.icon-cached:before {
  content: "\e86a";
}
.icon-cake:before {
  content: "\e7e9";
}
.icon-phone2:before {
  content: "\e0cd";
}
.icon-call_end:before {
  content: "\e0b1";
}
.icon-call_made:before {
  content: "\e0b2";
}
.icon-merge_type:before {
  content: "\e252";
}
.icon-call_missed:before {
  content: "\e0b4";
}
.icon-call_missed_outgoing:before {
  content: "\e0e4";
}
.icon-call_received:before {
  content: "\e0b5";
}
.icon-call_split:before {
  content: "\e0b6";
}
.icon-call_to_action:before {
  content: "\e06c";
}
.icon-camera2:before {
  content: "\e3af";
}
.icon-photo_camera:before {
  content: "\e412";
}
.icon-camera_enhance:before {
  content: "\e8fc";
}
.icon-camera_front:before {
  content: "\e3b1";
}
.icon-camera_rear:before {
  content: "\e3b2";
}
.icon-camera_roll:before {
  content: "\e3b3";
}
.icon-cancel:before {
  content: "\e5c9";
}
.icon-redeem:before {
  content: "\e8b1";
}
.icon-card_membership:before {
  content: "\e8f7";
}
.icon-card_travel:before {
  content: "\e8f8";
}
.icon-casino:before {
  content: "\eb40";
}
.icon-cast:before {
  content: "\e307";
}
.icon-cast_connected:before {
  content: "\e308";
}
.icon-center_focus_strong:before {
  content: "\e3b4";
}
.icon-center_focus_weak:before {
  content: "\e3b5";
}
.icon-change_history:before {
  content: "\e86b";
}
.icon-chat:before {
  content: "\e0b7";
}
.icon-chat_bubble:before {
  content: "\e0ca";
}
.icon-chat_bubble_outline:before {
  content: "\e0cb";
}
.icon-check2:before {
  content: "\e5ca";
}
.icon-check_box:before {
  content: "\e834";
}
.icon-check_box_outline_blank:before {
  content: "\e835";
}
.icon-check_circle:before {
  content: "\e86c";
}
.icon-navigate_before:before {
  content: "\e408";
}
.icon-navigate_next:before {
  content: "\e409";
}
.icon-child_care:before {
  content: "\eb41";
}
.icon-child_friendly:before {
  content: "\eb42";
}
.icon-chrome_reader_mode:before {
  content: "\e86d";
}
.icon-close2:before {
  content: "\e5cd";
}
.icon-clear_all:before {
  content: "\e0b8";
}
.icon-closed_caption:before {
  content: "\e01c";
}
.icon-wb_cloudy:before {
  content: "\e42d";
}
.icon-cloud_circle:before {
  content: "\e2be";
}
.icon-cloud_done:before {
  content: "\e2bf";
}
.icon-cloud_download:before {
  content: "\e2c0";
}
.icon-cloud_off:before {
  content: "\e2c1";
}
.icon-cloud_queue:before {
  content: "\e2c2";
}
.icon-code2:before {
  content: "\e86f";
}
.icon-photo_library:before {
  content: "\e413";
}
.icon-collections_bookmark:before {
  content: "\e431";
}
.icon-palette:before {
  content: "\e40a";
}
.icon-colorize:before {
  content: "\e3b8";
}
.icon-comment2:before {
  content: "\e0b9";
}
.icon-compare:before {
  content: "\e3b9";
}
.icon-compare_arrows:before {
  content: "\e915";
}
.icon-laptop2:before {
  content: "\e31e";
}
.icon-confirmation_number:before {
  content: "\e638";
}
.icon-contact_mail:before {
  content: "\e0d0";
}
.icon-contact_phone:before {
  content: "\e0cf";
}
.icon-contacts:before {
  content: "\e0ba";
}
.icon-content_copy:before {
  content: "\e14d";
}
.icon-content_cut:before {
  content: "\e14e";
}
.icon-content_paste:before {
  content: "\e14f";
}
.icon-control_point_duplicate:before {
  content: "\e3bb";
}
.icon-copyright2:before {
  content: "\e90c";
}
.icon-mode_edit:before {
  content: "\e254";
}
.icon-create_new_folder:before {
  content: "\e2cc";
}
.icon-payment:before {
  content: "\e8a1";
}
.icon-crop2:before {
  content: "\e3be";
}
.icon-crop_16_9:before {
  content: "\e3bc";
}
.icon-crop_3_2:before {
  content: "\e3bd";
}
.icon-crop_landscape:before {
  content: "\e3c3";
}
.icon-crop_7_5:before {
  content: "\e3c0";
}
.icon-crop_din:before {
  content: "\e3c1";
}
.icon-crop_free:before {
  content: "\e3c2";
}
.icon-crop_original:before {
  content: "\e3c4";
}
.icon-crop_portrait:before {
  content: "\e3c5";
}
.icon-crop_rotate:before {
  content: "\e437";
}
.icon-crop_square:before {
  content: "\e3c6";
}
.icon-dashboard2:before {
  content: "\e871";
}
.icon-data_usage:before {
  content: "\e1af";
}
.icon-date_range:before {
  content: "\e916";
}
.icon-dehaze:before {
  content: "\e3c7";
}
.icon-delete:before {
  content: "\e872";
}
.icon-delete_forever:before {
  content: "\e92b";
}
.icon-delete_sweep:before {
  content: "\e16c";
}
.icon-description:before {
  content: "\e873";
}
.icon-desktop_mac:before {
  content: "\e30b";
}
.icon-desktop_windows:before {
  content: "\e30c";
}
.icon-details:before {
  content: "\e3c8";
}
.icon-developer_board:before {
  content: "\e30d";
}
.icon-developer_mode:before {
  content: "\e1b0";
}
.icon-device_hub:before {
  content: "\e335";
}
.icon-phonelink:before {
  content: "\e326";
}
.icon-devices_other:before {
  content: "\e337";
}
.icon-dialer_sip:before {
  content: "\e0bb";
}
.icon-dialpad:before {
  content: "\e0bc";
}
.icon-directions:before {
  content: "\e52e";
}
.icon-directions_bike:before {
  content: "\e52f";
}
.icon-directions_boat:before {
  content: "\e532";
}
.icon-directions_bus:before {
  content: "\e530";
}
.icon-directions_car:before {
  content: "\e531";
}
.icon-directions_railway:before {
  content: "\e534";
}
.icon-directions_run:before {
  content: "\e566";
}
.icon-directions_transit:before {
  content: "\e535";
}
.icon-directions_walk:before {
  content: "\e536";
}
.icon-disc_full:before {
  content: "\e610";
}
.icon-dns:before {
  content: "\e875";
}
.icon-not_interested:before {
  content: "\e033";
}
.icon-do_not_disturb_alt:before {
  content: "\e611";
}
.icon-do_not_disturb_off:before {
  content: "\e643";
}
.icon-remove_circle:before {
  content: "\e15c";
}
.icon-dock:before {
  content: "\e30e";
}
.icon-done:before {
  content: "\e876";
}
.icon-done_all:before {
  content: "\e877";
}
.icon-donut_large:before {
  content: "\e917";
}
.icon-donut_small:before {
  content: "\e918";
}
.icon-drafts:before {
  content: "\e151";
}
.icon-drag_handle:before {
  content: "\e25d";
}
.icon-time_to_leave:before {
  content: "\e62c";
}
.icon-dvr:before {
  content: "\e1b2";
}
.icon-edit_location:before {
  content: "\e568";
}
.icon-eject2:before {
  content: "\e8fb";
}
.icon-markunread:before {
  content: "\e159";
}
.icon-enhanced_encryption:before {
  content: "\e63f";
}
.icon-equalizer:before {
  content: "\e01d";
}
.icon-error:before {
  content: "\e000";
}
.icon-error_outline:before {
  content: "\e001";
}
.icon-euro_symbol:before {
  content: "\e926";
}
.icon-ev_station:before {
  content: "\e56d";
}
.icon-insert_invitation:before {
  content: "\e24f";
}
.icon-event_available:before {
  content: "\e614";
}
.icon-event_busy:before {
  content: "\e615";
}
.icon-event_note:before {
  content: "\e616";
}
.icon-event_seat:before {
  content: "\e903";
}
.icon-exit_to_app:before {
  content: "\e879";
}
.icon-expand_less:before {
  content: "\e5ce";
}
.icon-expand_more:before {
  content: "\e5cf";
}
.icon-explicit:before {
  content: "\e01e";
}
.icon-explore:before {
  content: "\e87a";
}
.icon-exposure:before {
  content: "\e3ca";
}
.icon-exposure_neg_1:before {
  content: "\e3cb";
}
.icon-exposure_neg_2:before {
  content: "\e3cc";
}
.icon-exposure_plus_1:before {
  content: "\e3cd";
}
.icon-exposure_plus_2:before {
  content: "\e3ce";
}
.icon-exposure_zero:before {
  content: "\e3cf";
}
.icon-extension:before {
  content: "\e87b";
}
.icon-face:before {
  content: "\e87c";
}
.icon-fast_forward:before {
  content: "\e01f";
}
.icon-fast_rewind:before {
  content: "\e020";
}
.icon-favorite:before {
  content: "\e87d";
}
.icon-favorite_border:before {
  content: "\e87e";
}
.icon-featured_play_list:before {
  content: "\e06d";
}
.icon-featured_video:before {
  content: "\e06e";
}
.icon-sms_failed:before {
  content: "\e626";
}
.icon-fiber_dvr:before {
  content: "\e05d";
}
.icon-fiber_manual_record:before {
  content: "\e061";
}
.icon-fiber_new:before {
  content: "\e05e";
}
.icon-fiber_pin:before {
  content: "\e06a";
}
.icon-fiber_smart_record:before {
  content: "\e062";
}
.icon-get_app:before {
  content: "\e884";
}
.icon-file_upload:before {
  content: "\e2c6";
}
.icon-filter2:before {
  content: "\e3d3";
}
.icon-filter_1:before {
  content: "\e3d0";
}
.icon-filter_2:before {
  content: "\e3d1";
}
.icon-filter_3:before {
  content: "\e3d2";
}
.icon-filter_4:before {
  content: "\e3d4";
}
.icon-filter_5:before {
  content: "\e3d5";
}
.icon-filter_6:before {
  content: "\e3d6";
}
.icon-filter_7:before {
  content: "\e3d7";
}
.icon-filter_8:before {
  content: "\e3d8";
}
.icon-filter_9:before {
  content: "\e3d9";
}
.icon-filter_9_plus:before {
  content: "\e3da";
}
.icon-filter_b_and_w:before {
  content: "\e3db";
}
.icon-filter_center_focus:before {
  content: "\e3dc";
}
.icon-filter_drama:before {
  content: "\e3dd";
}
.icon-filter_frames:before {
  content: "\e3de";
}
.icon-terrain:before {
  content: "\e564";
}
.icon-filter_list:before {
  content: "\e152";
}
.icon-filter_none:before {
  content: "\e3e0";
}
.icon-filter_tilt_shift:before {
  content: "\e3e2";
}
.icon-filter_vintage:before {
  content: "\e3e3";
}
.icon-find_in_page:before {
  content: "\e880";
}
.icon-find_replace:before {
  content: "\e881";
}
.icon-fingerprint:before {
  content: "\e90d";
}
.icon-first_page:before {
  content: "\e5dc";
}
.icon-fitness_center:before {
  content: "\eb43";
}
.icon-flare:before {
  content: "\e3e4";
}
.icon-flash_auto:before {
  content: "\e3e5";
}
.icon-flash_off:before {
  content: "\e3e6";
}
.icon-flash_on:before {
  content: "\e3e7";
}
.icon-flight_land:before {
  content: "\e904";
}
.icon-flight_takeoff:before {
  content: "\e905";
}
.icon-flip:before {
  content: "\e3e8";
}
.icon-flip_to_back:before {
  content: "\e882";
}
.icon-flip_to_front:before {
  content: "\e883";
}
.icon-folder2:before {
  content: "\e2c7";
}
.icon-folder_open:before {
  content: "\e2c8";
}
.icon-folder_shared:before {
  content: "\e2c9";
}
.icon-folder_special:before {
  content: "\e617";
}
.icon-font_download:before {
  content: "\e167";
}
.icon-format_align_center:before {
  content: "\e234";
}
.icon-format_align_justify:before {
  content: "\e235";
}
.icon-format_align_left:before {
  content: "\e236";
}
.icon-format_align_right:before {
  content: "\e237";
}
.icon-format_bold:before {
  content: "\e238";
}
.icon-format_clear:before {
  content: "\e239";
}
.icon-format_color_fill:before {
  content: "\e23a";
}
.icon-format_color_reset:before {
  content: "\e23b";
}
.icon-format_color_text:before {
  content: "\e23c";
}
.icon-format_indent_decrease:before {
  content: "\e23d";
}
.icon-format_indent_increase:before {
  content: "\e23e";
}
.icon-format_italic:before {
  content: "\e23f";
}
.icon-format_line_spacing:before {
  content: "\e240";
}
.icon-format_list_bulleted:before {
  content: "\e241";
}
.icon-format_list_numbered:before {
  content: "\e242";
}
.icon-format_paint:before {
  content: "\e243";
}
.icon-format_quote:before {
  content: "\e244";
}
.icon-format_shapes:before {
  content: "\e25e";
}
.icon-format_size:before {
  content: "\e245";
}
.icon-format_strikethrough:before {
  content: "\e246";
}
.icon-format_textdirection_l_to_r:before {
  content: "\e247";
}
.icon-format_textdirection_r_to_l:before {
  content: "\e248";
}
.icon-format_underlined:before {
  content: "\e249";
}
.icon-question_answer:before {
  content: "\e8af";
}
.icon-forward2:before {
  content: "\e154";
}
.icon-forward_10:before {
  content: "\e056";
}
.icon-forward_30:before {
  content: "\e057";
}
.icon-forward_5:before {
  content: "\e058";
}
.icon-free_breakfast:before {
  content: "\eb44";
}
.icon-fullscreen:before {
  content: "\e5d0";
}
.icon-fullscreen_exit:before {
  content: "\e5d1";
}
.icon-functions:before {
  content: "\e24a";
}
.icon-g_translate:before {
  content: "\e927";
}
.icon-games:before {
  content: "\e021";
}
.icon-gavel2:before {
  content: "\e90e";
}
.icon-gesture:before {
  content: "\e155";
}
.icon-gif:before {
  content: "\e908";
}
.icon-goat:before {
  content: "\e900";
}
.icon-golf_course:before {
  content: "\eb45";
}
.icon-my_location:before {
  content: "\e55c";
}
.icon-location_searching:before {
  content: "\e1b7";
}
.icon-location_disabled:before {
  content: "\e1b6";
}
.icon-star2:before {
  content: "\e838";
}
.icon-gradient:before {
  content: "\e3e9";
}
.icon-grain:before {
  content: "\e3ea";
}
.icon-graphic_eq:before {
  content: "\e1b8";
}
.icon-grid_off:before {
  content: "\e3eb";
}
.icon-grid_on:before {
  content: "\e3ec";
}
.icon-people:before {
  content: "\e7fb";
}
.icon-group_add:before {
  content: "\e7f0";
}
.icon-group_work:before {
  content: "\e886";
}
.icon-hd:before {
  content: "\e052";
}
.icon-hdr_off:before {
  content: "\e3ed";
}
.icon-hdr_on:before {
  content: "\e3ee";
}
.icon-hdr_strong:before {
  content: "\e3f1";
}
.icon-hdr_weak:before {
  content: "\e3f2";
}
.icon-headset:before {
  content: "\e310";
}
.icon-headset_mic:before {
  content: "\e311";
}
.icon-healing:before {
  content: "\e3f3";
}
.icon-hearing:before {
  content: "\e023";
}
.icon-help:before {
  content: "\e887";
}
.icon-help_outline:before {
  content: "\e8fd";
}
.icon-high_quality:before {
  content: "\e024";
}
.icon-highlight:before {
  content: "\e25f";
}
.icon-highlight_off:before {
  content: "\e888";
}
.icon-restore:before {
  content: "\e8b3";
}
.icon-home2:before {
  content: "\e88a";
}
.icon-hot_tub:before {
  content: "\eb46";
}
.icon-local_hotel:before {
  content: "\e549";
}
.icon-hourglass_empty:before {
  content: "\e88b";
}
.icon-hourglass_full:before {
  content: "\e88c";
}
.icon-http:before {
  content: "\e902";
}
.icon-lock2:before {
  content: "\e897";
}
.icon-photo2:before {
  content: "\e410";
}
.icon-image_aspect_ratio:before {
  content: "\e3f5";
}
.icon-import_contacts:before {
  content: "\e0e0";
}
.icon-import_export:before {
  content: "\e0c3";
}
.icon-important_devices:before {
  content: "\e912";
}
.icon-inbox2:before {
  content: "\e156";
}
.icon-indeterminate_check_box:before {
  content: "\e909";
}
.icon-info2:before {
  content: "\e88e";
}
.icon-info_outline:before {
  content: "\e88f";
}
.icon-input:before {
  content: "\e890";
}
.icon-insert_comment:before {
  content: "\e24c";
}
.icon-insert_drive_file:before {
  content: "\e24d";
}
.icon-tag_faces:before {
  content: "\e420";
}
.icon-link2:before {
  content: "\e157";
}
.icon-invert_colors:before {
  content: "\e891";
}
.icon-invert_colors_off:before {
  content: "\e0c4";
}
.icon-iso:before {
  content: "\e3f6";
}
.icon-keyboard:before {
  content: "\e312";
}
.icon-keyboard_arrow_down:before {
  content: "\e313";
}
.icon-keyboard_arrow_left:before {
  content: "\e314";
}
.icon-keyboard_arrow_right:before {
  content: "\e315";
}
.icon-keyboard_arrow_up:before {
  content: "\e316";
}
.icon-keyboard_backspace:before {
  content: "\e317";
}
.icon-keyboard_capslock:before {
  content: "\e318";
}
.icon-keyboard_hide:before {
  content: "\e31a";
}
.icon-keyboard_return:before {
  content: "\e31b";
}
.icon-keyboard_tab:before {
  content: "\e31c";
}
.icon-keyboard_voice:before {
  content: "\e31d";
}
.icon-kitchen:before {
  content: "\eb47";
}
.icon-label:before {
  content: "\e892";
}
.icon-label_outline:before {
  content: "\e893";
}
.icon-language2:before {
  content: "\e894";
}
.icon-laptop_chromebook:before {
  content: "\e31f";
}
.icon-laptop_mac:before {
  content: "\e320";
}
.icon-laptop_windows:before {
  content: "\e321";
}
.icon-last_page:before {
  content: "\e5dd";
}
.icon-open_in_new:before {
  content: "\e89e";
}
.icon-layers:before {
  content: "\e53b";
}
.icon-layers_clear:before {
  content: "\e53c";
}
.icon-leak_add:before {
  content: "\e3f8";
}
.icon-leak_remove:before {
  content: "\e3f9";
}
.icon-lens:before {
  content: "\e3fa";
}
.icon-library_books:before {
  content: "\e02f";
}
.icon-library_music:before {
  content: "\e030";
}
.icon-lightbulb_outline:before {
  content: "\e90f";
}
.icon-line_style:before {
  content: "\e919";
}
.icon-line_weight:before {
  content: "\e91a";
}
.icon-linear_scale:before {
  content: "\e260";
}
.icon-linked_camera:before {
  content: "\e438";
}
.icon-list2:before {
  content: "\e896";
}
.icon-live_help:before {
  content: "\e0c6";
}
.icon-live_tv:before {
  content: "\e639";
}
.icon-local_play:before {
  content: "\e553";
}
.icon-local_airport:before {
  content: "\e53d";
}
.icon-local_atm:before {
  content: "\e53e";
}
.icon-local_bar:before {
  content: "\e540";
}
.icon-local_cafe:before {
  content: "\e541";
}
.icon-local_car_wash:before {
  content: "\e542";
}
.icon-local_convenience_store:before {
  content: "\e543";
}
.icon-restaurant_menu:before {
  content: "\e561";
}
.icon-local_drink:before {
  content: "\e544";
}
.icon-local_florist:before {
  content: "\e545";
}
.icon-local_gas_station:before {
  content: "\e546";
}
.icon-shopping_cart:before {
  content: "\e8cc";
}
.icon-local_hospital:before {
  content: "\e548";
}
.icon-local_laundry_service:before {
  content: "\e54a";
}
.icon-local_library:before {
  content: "\e54b";
}
.icon-local_mall:before {
  content: "\e54c";
}
.icon-theaters:before {
  content: "\e8da";
}
.icon-local_offer:before {
  content: "\e54e";
}
.icon-local_parking:before {
  content: "\e54f";
}
.icon-local_pharmacy:before {
  content: "\e550";
}
.icon-local_pizza:before {
  content: "\e552";
}
.icon-print2:before {
  content: "\e8ad";
}
.icon-local_shipping:before {
  content: "\e558";
}
.icon-local_taxi:before {
  content: "\e559";
}
.icon-location_city:before {
  content: "\e7f1";
}
.icon-location_off:before {
  content: "\e0c7";
}
.icon-room:before {
  content: "\e8b4";
}
.icon-lock_open:before {
  content: "\e898";
}
.icon-lock_outline:before {
  content: "\e899";
}
.icon-looks:before {
  content: "\e3fc";
}
.icon-looks_3:before {
  content: "\e3fb";
}
.icon-looks_4:before {
  content: "\e3fd";
}
.icon-looks_5:before {
  content: "\e3fe";
}
.icon-looks_6:before {
  content: "\e3ff";
}
.icon-looks_one:before {
  content: "\e400";
}
.icon-looks_two:before {
  content: "\e401";
}
.icon-sync:before {
  content: "\e627";
}
.icon-loupe:before {
  content: "\e402";
}
.icon-low_priority:before {
  content: "\e16d";
}
.icon-loyalty:before {
  content: "\e89a";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-map2:before {
  content: "\e55b";
}
.icon-markunread_mailbox:before {
  content: "\e89b";
}
.icon-memory:before {
  content: "\e322";
}
.icon-menu:before {
  content: "\e5d2";
}
.icon-message:before {
  content: "\e0c9";
}
.icon-mic:before {
  content: "\e029";
}
.icon-mic_none:before {
  content: "\e02a";
}
.icon-mic_off:before {
  content: "\e02b";
}
.icon-mms:before {
  content: "\e618";
}
.icon-mode_comment:before {
  content: "\e253";
}
.icon-monetization_on:before {
  content: "\e263";
}
.icon-money_off:before {
  content: "\e25c";
}
.icon-monochrome_photos:before {
  content: "\e403";
}
.icon-mood_bad:before {
  content: "\e7f3";
}
.icon-more:before {
  content: "\e619";
}
.icon-more_horiz:before {
  content: "\e5d3";
}
.icon-more_vert:before {
  content: "\e5d4";
}
.icon-motorcycle2:before {
  content: "\e91b";
}
.icon-mouse:before {
  content: "\e323";
}
.icon-move_to_inbox:before {
  content: "\e168";
}
.icon-movie_creation:before {
  content: "\e404";
}
.icon-movie_filter:before {
  content: "\e43a";
}
.icon-multiline_chart:before {
  content: "\e6df";
}
.icon-music_note:before {
  content: "\e405";
}
.icon-music_video:before {
  content: "\e063";
}
.icon-nature:before {
  content: "\e406";
}
.icon-nature_people:before {
  content: "\e407";
}
.icon-navigation:before {
  content: "\e55d";
}
.icon-near_me:before {
  content: "\e569";
}
.icon-network_cell:before {
  content: "\e1b9";
}
.icon-network_check:before {
  content: "\e640";
}
.icon-network_locked:before {
  content: "\e61a";
}
.icon-network_wifi:before {
  content: "\e1ba";
}
.icon-new_releases:before {
  content: "\e031";
}
.icon-next_week:before {
  content: "\e16a";
}
.icon-nfc:before {
  content: "\e1bb";
}
.icon-no_encryption:before {
  content: "\e641";
}
.icon-signal_cellular_no_sim:before {
  content: "\e1ce";
}
.icon-note:before {
  content: "\e06f";
}
.icon-note_add:before {
  content: "\e89c";
}
.icon-notifications:before {
  content: "\e7f4";
}
.icon-notifications_active:before {
  content: "\e7f7";
}
.icon-notifications_none:before {
  content: "\e7f5";
}
.icon-notifications_off:before {
  content: "\e7f6";
}
.icon-notifications_paused:before {
  content: "\e7f8";
}
.icon-offline_pin:before {
  content: "\e90a";
}
.icon-ondemand_video:before {
  content: "\e63a";
}
.icon-opacity:before {
  content: "\e91c";
}
.icon-open_in_browser:before {
  content: "\e89d";
}
.icon-open_with:before {
  content: "\e89f";
}
.icon-pages:before {
  content: "\e7f9";
}
.icon-pageview:before {
  content: "\e8a0";
}
.icon-pan_tool:before {
  content: "\e925";
}
.icon-panorama:before {
  content: "\e40b";
}
.icon-radio_button_unchecked:before {
  content: "\e836";
}
.icon-panorama_horizontal:before {
  content: "\e40d";
}
.icon-panorama_vertical:before {
  content: "\e40e";
}
.icon-panorama_wide_angle:before {
  content: "\e40f";
}
.icon-party_mode:before {
  content: "\e7fa";
}
.icon-pause2:before {
  content: "\e034";
}
.icon-pause_circle_filled:before {
  content: "\e035";
}
.icon-pause_circle_outline:before {
  content: "\e036";
}
.icon-people_outline:before {
  content: "\e7fc";
}
.icon-perm_camera_mic:before {
  content: "\e8a2";
}
.icon-perm_contact_calendar:before {
  content: "\e8a3";
}
.icon-perm_data_setting:before {
  content: "\e8a4";
}
.icon-perm_device_information:before {
  content: "\e8a5";
}
.icon-person_outline:before {
  content: "\e7ff";
}
.icon-perm_media:before {
  content: "\e8a7";
}
.icon-perm_phone_msg:before {
  content: "\e8a8";
}
.icon-perm_scan_wifi:before {
  content: "\e8a9";
}
.icon-person:before {
  content: "\e7fd";
}
.icon-person_add:before {
  content: "\e7fe";
}
.icon-person_pin:before {
  content: "\e55a";
}
.icon-person_pin_circle:before {
  content: "\e56a";
}
.icon-personal_video:before {
  content: "\e63b";
}
.icon-pets:before {
  content: "\e91d";
}
.icon-phone_android:before {
  content: "\e324";
}
.icon-phone_bluetooth_speaker:before {
  content: "\e61b";
}
.icon-phone_forwarded:before {
  content: "\e61c";
}
.icon-phone_in_talk:before {
  content: "\e61d";
}
.icon-phone_iphone:before {
  content: "\e325";
}
.icon-phone_locked:before {
  content: "\e61e";
}
.icon-phone_missed:before {
  content: "\e61f";
}
.icon-phone_paused:before {
  content: "\e620";
}
.icon-phonelink_erase:before {
  content: "\e0db";
}
.icon-phonelink_lock:before {
  content: "\e0dc";
}
.icon-phonelink_off:before {
  content: "\e327";
}
.icon-phonelink_ring:before {
  content: "\e0dd";
}
.icon-phonelink_setup:before {
  content: "\e0de";
}
.icon-photo_album:before {
  content: "\e411";
}
.icon-photo_filter:before {
  content: "\e43b";
}
.icon-photo_size_select_actual:before {
  content: "\e432";
}
.icon-photo_size_select_large:before {
  content: "\e433";
}
.icon-photo_size_select_small:before {
  content: "\e434";
}
.icon-picture_as_pdf:before {
  content: "\e415";
}
.icon-picture_in_picture:before {
  content: "\e8aa";
}
.icon-picture_in_picture_alt:before {
  content: "\e911";
}
.icon-pie_chart:before {
  content: "\e6c4";
}
.icon-pie_chart_outlined:before {
  content: "\e6c5";
}
.icon-pin_drop:before {
  content: "\e55e";
}
.icon-play_arrow:before {
  content: "\e037";
}
.icon-play_circle_filled:before {
  content: "\e038";
}
.icon-play_circle_outline:before {
  content: "\e039";
}
.icon-play_for_work:before {
  content: "\e906";
}
.icon-playlist_add:before {
  content: "\e03b";
}
.icon-playlist_add_check:before {
  content: "\e065";
}
.icon-playlist_play:before {
  content: "\e05f";
}
.icon-plus_one:before {
  content: "\e800";
}
.icon-polymer:before {
  content: "\e8ab";
}
.icon-pool:before {
  content: "\eb48";
}
.icon-portable_wifi_off:before {
  content: "\e0ce";
}
.icon-portrait:before {
  content: "\e416";
}
.icon-power:before {
  content: "\e63c";
}
.icon-power_input:before {
  content: "\e336";
}
.icon-power_settings_new:before {
  content: "\e8ac";
}
.icon-pregnant_woman:before {
  content: "\e91e";
}
.icon-present_to_all:before {
  content: "\e0df";
}
.icon-priority_high:before {
  content: "\e645";
}
.icon-public:before {
  content: "\e80b";
}
.icon-publish:before {
  content: "\e255";
}
.icon-queue_music:before {
  content: "\e03d";
}
.icon-queue_play_next:before {
  content: "\e066";
}
.icon-radio:before {
  content: "\e03e";
}
.icon-radio_button_checked:before {
  content: "\e837";
}
.icon-rate_review:before {
  content: "\e560";
}
.icon-receipt:before {
  content: "\e8b0";
}
.icon-recent_actors:before {
  content: "\e03f";
}
.icon-record_voice_over:before {
  content: "\e91f";
}
.icon-redo:before {
  content: "\e15a";
}
.icon-refresh2:before {
  content: "\e5d5";
}
.icon-remove2:before {
  content: "\e15b";
}
.icon-remove_circle_outline:before {
  content: "\e15d";
}
.icon-remove_from_queue:before {
  content: "\e067";
}
.icon-visibility:before {
  content: "\e8f4";
}
.icon-remove_shopping_cart:before {
  content: "\e928";
}
.icon-reorder2:before {
  content: "\e8fe";
}
.icon-repeat2:before {
  content: "\e040";
}
.icon-repeat_one:before {
  content: "\e041";
}
.icon-replay:before {
  content: "\e042";
}
.icon-replay_10:before {
  content: "\e059";
}
.icon-replay_30:before {
  content: "\e05a";
}
.icon-replay_5:before {
  content: "\e05b";
}
.icon-reply2:before {
  content: "\e15e";
}
.icon-reply_all:before {
  content: "\e15f";
}
.icon-report:before {
  content: "\e160";
}
.icon-warning2:before {
  content: "\e002";
}
.icon-restaurant:before {
  content: "\e56c";
}
.icon-restore_page:before {
  content: "\e929";
}
.icon-ring_volume:before {
  content: "\e0d1";
}
.icon-room_service:before {
  content: "\eb49";
}
.icon-rotate_90_degrees_ccw:before {
  content: "\e418";
}
.icon-rotate_left:before {
  content: "\e419";
}
.icon-rotate_right:before {
  content: "\e41a";
}
.icon-rounded_corner:before {
  content: "\e920";
}
.icon-router:before {
  content: "\e328";
}
.icon-rowing:before {
  content: "\e921";
}
.icon-rss_feed:before {
  content: "\e0e5";
}
.icon-rv_hookup:before {
  content: "\e642";
}
.icon-satellite:before {
  content: "\e562";
}
.icon-save2:before {
  content: "\e161";
}
.icon-scanner:before {
  content: "\e329";
}
.icon-school:before {
  content: "\e80c";
}
.icon-screen_lock_landscape:before {
  content: "\e1be";
}
.icon-screen_lock_portrait:before {
  content: "\e1bf";
}
.icon-screen_lock_rotation:before {
  content: "\e1c0";
}
.icon-screen_rotation:before {
  content: "\e1c1";
}
.icon-screen_share:before {
  content: "\e0e2";
}
.icon-sd_storage:before {
  content: "\e1c2";
}
.icon-search2:before {
  content: "\e8b6";
}
.icon-security:before {
  content: "\e32a";
}
.icon-select_all:before {
  content: "\e162";
}
.icon-send2:before {
  content: "\e163";
}
.icon-sentiment_dissatisfied:before {
  content: "\e811";
}
.icon-sentiment_neutral:before {
  content: "\e812";
}
.icon-sentiment_satisfied:before {
  content: "\e813";
}
.icon-sentiment_very_dissatisfied:before {
  content: "\e814";
}
.icon-sentiment_very_satisfied:before {
  content: "\e815";
}
.icon-settings:before {
  content: "\e8b8";
}
.icon-settings_applications:before {
  content: "\e8b9";
}
.icon-settings_backup_restore:before {
  content: "\e8ba";
}
.icon-settings_bluetooth:before {
  content: "\e8bb";
}
.icon-settings_brightness:before {
  content: "\e8bd";
}
.icon-settings_cell:before {
  content: "\e8bc";
}
.icon-settings_ethernet:before {
  content: "\e8be";
}
.icon-settings_input_antenna:before {
  content: "\e8bf";
}
.icon-settings_input_composite:before {
  content: "\e8c1";
}
.icon-settings_input_hdmi:before {
  content: "\e8c2";
}
.icon-settings_input_svideo:before {
  content: "\e8c3";
}
.icon-settings_overscan:before {
  content: "\e8c4";
}
.icon-settings_phone:before {
  content: "\e8c5";
}
.icon-settings_power:before {
  content: "\e8c6";
}
.icon-settings_remote:before {
  content: "\e8c7";
}
.icon-settings_system_daydream:before {
  content: "\e1c3";
}
.icon-settings_voice:before {
  content: "\e8c8";
}
.icon-share2:before {
  content: "\e80d";
}
.icon-shop:before {
  content: "\e8c9";
}
.icon-shop_two:before {
  content: "\e8ca";
}
.icon-shopping_basket:before {
  content: "\e8cb";
}
.icon-short_text:before {
  content: "\e261";
}
.icon-show_chart:before {
  content: "\e6e1";
}
.icon-shuffle:before {
  content: "\e043";
}
.icon-signal_cellular_4_bar:before {
  content: "\e1c8";
}
.icon-signal_cellular_connected_no_internet_4_bar:before {
  content: "\e1cd";
}
.icon-signal_cellular_null:before {
  content: "\e1cf";
}
.icon-signal_cellular_off:before {
  content: "\e1d0";
}
.icon-signal_wifi_4_bar:before {
  content: "\e1d8";
}
.icon-signal_wifi_4_bar_lock:before {
  content: "\e1d9";
}
.icon-signal_wifi_off:before {
  content: "\e1da";
}
.icon-sim_card:before {
  content: "\e32b";
}
.icon-sim_card_alert:before {
  content: "\e624";
}
.icon-skip_next:before {
  content: "\e044";
}
.icon-skip_previous:before {
  content: "\e045";
}
.icon-slideshow:before {
  content: "\e41b";
}
.icon-slow_motion_video:before {
  content: "\e068";
}
.icon-stay_primary_portrait:before {
  content: "\e0d6";
}
.icon-smoke_free:before {
  content: "\eb4a";
}
.icon-smoking_rooms:before {
  content: "\eb4b";
}
.icon-textsms:before {
  content: "\e0d8";
}
.icon-snooze:before {
  content: "\e046";
}
.icon-sort2:before {
  content: "\e164";
}
.icon-sort_by_alpha:before {
  content: "\e053";
}
.icon-spa:before {
  content: "\eb4c";
}
.icon-space_bar:before {
  content: "\e256";
}
.icon-speaker:before {
  content: "\e32d";
}
.icon-speaker_group:before {
  content: "\e32e";
}
.icon-speaker_notes:before {
  content: "\e8cd";
}
.icon-speaker_notes_off:before {
  content: "\e92a";
}
.icon-speaker_phone:before {
  content: "\e0d2";
}
.icon-spellcheck:before {
  content: "\e8ce";
}
.icon-star_border:before {
  content: "\e83a";
}
.icon-star_half:before {
  content: "\e839";
}
.icon-stars:before {
  content: "\e8d0";
}
.icon-stay_primary_landscape:before {
  content: "\e0d5";
}
.icon-stop2:before {
  content: "\e047";
}
.icon-stop_screen_share:before {
  content: "\e0e3";
}
.icon-storage:before {
  content: "\e1db";
}
.icon-store_mall_directory:before {
  content: "\e563";
}
.icon-straighten:before {
  content: "\e41c";
}
.icon-streetview:before {
  content: "\e56e";
}
.icon-strikethrough_s:before {
  content: "\e257";
}
.icon-style:before {
  content: "\e41d";
}
.icon-subdirectory_arrow_left:before {
  content: "\e5d9";
}
.icon-subdirectory_arrow_right:before {
  content: "\e5da";
}
.icon-subject:before {
  content: "\e8d2";
}
.icon-subscriptions:before {
  content: "\e064";
}
.icon-subtitles:before {
  content: "\e048";
}
.icon-subway2:before {
  content: "\e56f";
}
.icon-supervisor_account:before {
  content: "\e8d3";
}
.icon-surround_sound:before {
  content: "\e049";
}
.icon-swap_calls:before {
  content: "\e0d7";
}
.icon-swap_horiz:before {
  content: "\e8d4";
}
.icon-swap_vert:before {
  content: "\e8d5";
}
.icon-swap_vertical_circle:before {
  content: "\e8d6";
}
.icon-switch_camera:before {
  content: "\e41e";
}
.icon-switch_video:before {
  content: "\e41f";
}
.icon-sync_disabled:before {
  content: "\e628";
}
.icon-sync_problem:before {
  content: "\e629";
}
.icon-system_update:before {
  content: "\e62a";
}
.icon-system_update_alt:before {
  content: "\e8d7";
}
.icon-tab:before {
  content: "\e8d8";
}
.icon-tab_unselected:before {
  content: "\e8d9";
}
.icon-tablet2:before {
  content: "\e32f";
}
.icon-tablet_android:before {
  content: "\e330";
}
.icon-tablet_mac:before {
  content: "\e331";
}
.icon-tap_and_play:before {
  content: "\e62b";
}
.icon-text_fields:before {
  content: "\e262";
}
.icon-text_format:before {
  content: "\e165";
}
.icon-texture:before {
  content: "\e421";
}
.icon-thumb_down:before {
  content: "\e8db";
}
.icon-thumb_up:before {
  content: "\e8dc";
}
.icon-thumbs_up_down:before {
  content: "\e8dd";
}
.icon-timelapse:before {
  content: "\e422";
}
.icon-timeline:before {
  content: "\e922";
}
.icon-timer:before {
  content: "\e425";
}
.icon-timer_10:before {
  content: "\e423";
}
.icon-timer_3:before {
  content: "\e424";
}
.icon-timer_off:before {
  content: "\e426";
}
.icon-title:before {
  content: "\e264";
}
.icon-toc:before {
  content: "\e8de";
}
.icon-today:before {
  content: "\e8df";
}
.icon-toll:before {
  content: "\e8e0";
}
.icon-tonality:before {
  content: "\e427";
}
.icon-touch_app:before {
  content: "\e913";
}
.icon-toys:before {
  content: "\e332";
}
.icon-track_changes:before {
  content: "\e8e1";
}
.icon-traffic:before {
  content: "\e565";
}
.icon-train2:before {
  content: "\e570";
}
.icon-tram:before {
  content: "\e571";
}
.icon-transfer_within_a_station:before {
  content: "\e572";
}
.icon-transform:before {
  content: "\e428";
}
.icon-translate:before {
  content: "\e8e2";
}
.icon-trending_down:before {
  content: "\e8e3";
}
.icon-trending_flat:before {
  content: "\e8e4";
}
.icon-trending_up:before {
  content: "\e8e5";
}
.icon-tune:before {
  content: "\e429";
}
.icon-tv2:before {
  content: "\e333";
}
.icon-unarchive:before {
  content: "\e169";
}
.icon-undo2:before {
  content: "\e166";
}
.icon-unfold_less:before {
  content: "\e5d6";
}
.icon-unfold_more:before {
  content: "\e5d7";
}
.icon-update:before {
  content: "\e923";
}
.icon-usb2:before {
  content: "\e1e0";
}
.icon-verified_user:before {
  content: "\e8e8";
}
.icon-vertical_align_bottom:before {
  content: "\e258";
}
.icon-vertical_align_center:before {
  content: "\e259";
}
.icon-vertical_align_top:before {
  content: "\e25a";
}
.icon-vibration:before {
  content: "\e62d";
}
.icon-video_call:before {
  content: "\e070";
}
.icon-video_label:before {
  content: "\e071";
}
.icon-video_library:before {
  content: "\e04a";
}
.icon-videocam:before {
  content: "\e04b";
}
.icon-videocam_off:before {
  content: "\e04c";
}
.icon-videogame_asset:before {
  content: "\e338";
}
.icon-view_agenda:before {
  content: "\e8e9";
}
.icon-view_array:before {
  content: "\e8ea";
}
.icon-view_carousel:before {
  content: "\e8eb";
}
.icon-view_column:before {
  content: "\e8ec";
}
.icon-view_comfy:before {
  content: "\e42a";
}
.icon-view_compact:before {
  content: "\e42b";
}
.icon-view_day:before {
  content: "\e8ed";
}
.icon-view_headline:before {
  content: "\e8ee";
}
.icon-view_list:before {
  content: "\e8ef";
}
.icon-view_module:before {
  content: "\e8f0";
}
.icon-view_quilt:before {
  content: "\e8f1";
}
.icon-view_stream:before {
  content: "\e8f2";
}
.icon-view_week:before {
  content: "\e8f3";
}
.icon-vignette:before {
  content: "\e435";
}
.icon-visibility_off:before {
  content: "\e8f5";
}
.icon-voice_chat:before {
  content: "\e62e";
}
.icon-voicemail:before {
  content: "\e0d9";
}
.icon-volume_down:before {
  content: "\e04d";
}
.icon-volume_mute:before {
  content: "\e04e";
}
.icon-volume_off:before {
  content: "\e04f";
}
.icon-volume_up:before {
  content: "\e050";
}
.icon-vpn_key:before {
  content: "\e0da";
}
.icon-vpn_lock:before {
  content: "\e62f";
}
.icon-wallpaper:before {
  content: "\e1bc";
}
.icon-watch:before {
  content: "\e334";
}
.icon-watch_later:before {
  content: "\e924";
}
.icon-wb_auto:before {
  content: "\e42c";
}
.icon-wb_incandescent:before {
  content: "\e42e";
}
.icon-wb_iridescent:before {
  content: "\e436";
}
.icon-wb_sunny:before {
  content: "\e430";
}
.icon-wc:before {
  content: "\e63d";
}
.icon-web:before {
  content: "\e051";
}
.icon-web_asset:before {
  content: "\e069";
}
.icon-weekend:before {
  content: "\e16b";
}
.icon-whatshot:before {
  content: "\e80e";
}
.icon-widgets:before {
  content: "\e1bd";
}
.icon-wifi2:before {
  content: "\e63e";
}
.icon-wifi_lock:before {
  content: "\e1e1";
}
.icon-wifi_tethering:before {
  content: "\e1e2";
}
.icon-work:before {
  content: "\e8f9";
}
.icon-wrap_text:before {
  content: "\e25b";
}
.icon-youtube_searched_for:before {
  content: "\e8fa";
}
.icon-zoom_in:before {
  content: "\e8ff";
}
.icon-zoom_out:before {
  content: "\e901";
}
.icon-zoom_out_map:before {
  content: "\e56b";
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.vr {
  background: rgb(192, 192, 192) !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.f-24 {
  font-size: 24px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
}

.custom-carousel {
  top: 12px;
  left: 30px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .custom-carousel {
    top: 70px;
  }
}

.logo-price {
  width: 40vw;
  background: #23343e;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .logo-price {
    top: 100px;
    bottom: -62px;
  }
}
@media (max-width: 768px) {
  .logo-price {
    width: unset !important;
  }
}

.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
}
.form-price .form-control::placeholder {
  color: #bec6cb !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
}
.carousel-track-custom .react-multi-carousel-track {
  height: 402px;
}

.react-multi-carousel-dot-list {
  z-index: 1;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}
.logo-sub-text {
  position: relative;
  bottom: -6px;
}
.logo-sub-text span {
  font-family: "poppins-Bold";
}

.custome-height {
  height: 824px;
}
@media (max-width: 768px) {
  .custome-height {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

.sidebar-logo-div {
  display: flex;
  align-items: center;
}
.sidebar-logo-div img:first-child {
  width: 20px;
  margin-right: 20px;
}
.sidebar-logo-div img:last-child {
  width: 205px;
}

.roboto {
  font-family: "Roboto" !important;
}

.sidebar-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.sidebar-list svg {
  margin-right: 15px;
  width: 18px;
  height: 18px;
}
.sidebar-list i {
  margin-right: 15px;
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.sidebar-list p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.access-btn {
  border: 2px solid #FE6919 !important;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 9px !important;
  font-weight: 600 !important;
  margin-left: auto;
  margin-bottom: 0;
  white-space: pre;
  cursor: pointer;
}

.w-20px {
  width: 20px;
}

.shadow-hover {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 10px #ddd !important;
}

.sidebar-collapse-btn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;
  transition: 0.1s;
}
.sidebar-collapse-btn:hover {
  background-color: rgba(145, 186, 203, 0.2) !important;
  border-left: 3px solid #3a5765;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  transition: 0.1s;
}
.sidebar-collapse-btn .active {
  background-color: rgba(145, 186, 203, 0.2) !important;
}
.sidebar-collapse-btn p {
  color: black;
  font-family: "Roboto" !important;
}
.sidebar-collapse-btn svg {
  fill: black !important;
}

.arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}

.collapsed {
  background-color: transparent;
}
.collapsed p {
  color: grey;
}
.collapsed svg {
  fill: grey !important;
  stroke: grey;
}
.collapsed .arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(180deg);
}

.show .card-body {
  background-color: rgba(145, 186, 203, 0.2);
  color: rgb(58, 87, 101);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px 10px 12px;
  margin-top: 2px;
}

.siderbar-inner-ul {
  padding-left: 0;
  margin-bottom: 0;
}
.siderbar-inner-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul li img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul li:hover {
  font-weight: 800;
}
.siderbar-inner-ul a {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: rgb(58, 87, 101);
  text-decoration: none;
  cursor: pointer;
}
.siderbar-inner-ul a img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul a:hover {
  font-weight: 800;
}

.admin-accordian {
  position: absolute;
  bottom: 10px;
  width: 87%;
  background-color: white;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3A5765 !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #3A5765 !important;
}

*::-webkit-scrollbar-thumb:active {
  background: #3A5765 !important;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.vr {
  background: rgb(192, 192, 192) !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.txt-cap {
  text-transform: capitalize !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
}

.custom-carousel {
  top: 12px;
  left: 30px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .custom-carousel {
    top: 70px;
  }
}

.logo-price {
  width: 40vw;
  background: #23343e;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .logo-price {
    top: 100px;
    bottom: -62px;
  }
}
@media (max-width: 768px) {
  .logo-price {
    width: unset !important;
  }
}

.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
}
.form-price .form-control::placeholder {
  color: #bec6cb !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
}
.carousel-track-custom .react-multi-carousel-track {
  height: 402px;
}

.react-multi-carousel-dot-list {
  z-index: 1;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}
.logo-sub-text {
  position: relative;
  bottom: -6px;
}
.logo-sub-text span {
  font-family: "poppins-Bold";
}

.custome-height {
  height: 824px;
}
@media (max-width: 768px) {
  .custome-height {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

.sidebar-logo-div {
  display: flex;
  align-items: center;
}
.sidebar-logo-div img:first-child {
  width: 20px;
  margin-right: 20px;
}
.sidebar-logo-div img:last-child {
  width: 205px;
}

.roboto {
  font-family: "Roboto" !important;
}

.sidebar-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.sidebar-list svg {
  margin-right: 15px;
  width: 18px;
  height: 18px;
}
.sidebar-list p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.access-btn {
  border: 1px solid #FE6919;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 8px !important;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0 !important;
  cursor: pointer;
}

.w-20px {
  width: 20px;
}

.pl-16px {
  padding-left: 1rem !important;
}

.shadow-hover {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 10px #ddd !important;
}

.sidebar-collapse-btn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;
}
.sidebar-collapse-btn p {
  color: black;
  font-family: "Roboto" !important;
}
.sidebar-collapse-btn svg {
  fill: black !important;
}

.arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}

.collapsed {
  background-color: transparent;
}
.collapsed p {
  color: grey;
}
.collapsed svg {
  fill: grey !important;
  stroke: grey;
}

.transform-180 {
  transform: rotate(180deg);
}

.show .card-body {
  background-color: rgba(145, 186, 203, 0.2);
  color: rgb(58, 87, 101);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0px 10px 12px;
  margin-top: 2px;
}

.siderbar-inner-ul {
  padding-left: 0;
}
.siderbar-inner-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul li img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul li .dots {
  width: 25px;
}
.siderbar-inner-ul li:hover {
  font-weight: 800;
  color: black;
}
.siderbar-inner-ul a {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul a img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul a .dots {
  width: 25px;
}
.siderbar-inner-ul a:hover {
  font-weight: 800;
  color: black;
}

.admin-accordian {
  position: absolute;
  bottom: 10px;
  width: 87%;
}

.ant-modal-mask {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.min-h-full {
  min-height: 100vh;
}
.file-input {
  display: none;
}

.md-form {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.desc-height {
  min-height: 190px;
  padding: 15px !important;
}

.file-input-btn {
  font-family: "poppins-regular";
  font-size: 14px !important;
  cursor: pointer !important;
  text-transform: inherit !important;
  padding: 5px;
  margin-bottom: 0;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.updateModalBody {
  min-height: auto !important;
}

.file-input {
  display: none;
}

.desc-height {
  min-height: 190px;
  padding: 15px !important;
}

.file-input-btn {
  font-family: "poppins-regular";
  font-size: 14px !important;
  cursor: pointer !important;
  text-transform: inherit !important;
  padding: 5px;
  margin-bottom: 0;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tr {
  display: flex;
  width: calc(100% - 5px);
  box-sizing: border-box;
  position: relative;
  border-radius: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
}
.tr:nth-child(odd) {
  background-color: #fff;
}
.tr:nth-child(even) {
  background-color: #F8FAFC;
}
.tr .td {
  text-align: left;
  border: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  font-family: "Poppins-medium";
  font-size: 13px;
  color: #68676C;
}
.tr .td:nth-child(1) {
  width: 32%;
  border-top: none;
}
.tr .td:nth-child(2) {
  width: 32%;
  border-top: none;
  border-left: none;
  border-right: none;
}
.tr .td:nth-child(3) {
  width: 32%;
  border-top: none;
}
.tr .tr-delete {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: #445D6B;
  cursor: pointer;
  border-radius: 50%;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}
.tr .tr-delete::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 2px;
  background-color: white;
}
.tr .tr-delete::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 60%;
  background-color: white;
}
.tr .tr-delete:hover {
  box-shadow: 3px 3px 6px 0px rgba(16, 16, 16, 0.75);
}

.tbody {
  width: 100%;
  overflow: auto;
  max-height: 300px;
  box-sizing: border-box;
  margin-top: 20px;
}

.theader {
  background-color: transparent;
  color: #b4b4b4;
  font-family: "Poppins-medium";
  font-size: 15px;
  font-weight: 600;
  border: none;
}
.theader .td {
  text-align: center;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.modal-dialog-centered.modal-dialog.big {
  width: 96%;
  max-width: 800px;
}

.text-status {
  font-size: 15px;
  font-family: "poppins-Medium";
}

.shared-input-search {
  height: 40px;
  border: 1px solid #ddd;
  width: 100%;
  padding: 0px 10px;
  font-size: 15px;
  border-radius: 5px;
  background-image: url(/static/media/search.f6b7c5be.svg);
  background-position: 1% 50%;
  background-repeat: no-repeat;
  padding-left: 30px;
}
.shared-input-search::placeholder {
  font-size: 14px;
  color: #a5a5a5;
  font-weight: 400;
}

.sharePending-button {
  width: 50%;
  margin: 0 10px;
  font-family: "poppins-regular";
  font-size: 13px !important;
  height: 35px;
  padding: 0 !important;
  background-color: white;
  color: #445D6B;
  border: 1px solid #445D6B !important;
  text-transform: inherit !important;
}

.active-toggle {
  background-color: #445D6B !important;
  color: white !important;
}
.modelDiv {
  display: flex;
  justify-content: space-between;
}

.project-item-img {
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 0px 2px #ddd;
}

.project-item-img::before {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: 5px;
  right: 5px;
  /* padding: 20px; */
  height: 40px;
  width: 40px;
  background-color: #e1e1e1;
  /* border-radius: 5px 0; */
  background-size: contain;
  border-radius: 5px;
  box-shadow: -3px -3px 8px dimgrey;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.project-item-img.revitIcon::before {
  background-image: url(/static/media/revit.26c67f61.svg);
}

.project-item-img.ifcIcon::before {
  background-image: url(/static/media/ifc.c3526050.svg);
}

.project-item-date {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  padding: 3px;
  color: white;
  background-color: black;
  font-size: 13px;
}
.project-item-img {
  position: relative;
  padding-top: 45%;
}
.project-item-img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-card-fav {
  border: 1px solid #eaeaea;
  position: absolute;
  top: 35px;
  right: 10px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  width: 27px;
  z-index: 1;
}

.upload-project-card-img {
  border: 1px solid #eaeaea;
  position: absolute;
  top: 15px;
  right: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
}
.upload-project-card-img button {
  box-shadow: none;
  padding: 0 !important;
}
.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.vr {
  background: rgb(192, 192, 192) !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.f-24 {
  font-size: 24px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
}

.custom-carousel {
  top: 12px;
  left: 30px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .custom-carousel {
    top: 70px;
  }
}

.logo-price {
  width: 40vw;
  background: #23343e;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .logo-price {
    top: 100px;
    bottom: -62px;
  }
}
@media (max-width: 768px) {
  .logo-price {
    width: unset !important;
  }
}

.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
}
.form-price .form-control::placeholder {
  color: #bec6cb !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
}
.carousel-track-custom .react-multi-carousel-track {
  height: 402px;
}

.react-multi-carousel-dot-list {
  z-index: 1;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}
.logo-sub-text {
  position: relative;
  bottom: -6px;
}
.logo-sub-text span {
  font-family: "poppins-Bold";
}

.custome-height {
  height: 824px;
}
@media (max-width: 768px) {
  .custome-height {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

.sidebar-logo-div {
  display: flex;
  align-items: center;
}
.sidebar-logo-div img:first-child {
  width: 20px;
  margin-right: 20px;
}
.sidebar-logo-div img:last-child {
  width: 205px;
}

.roboto {
  font-family: "Roboto" !important;
}

.sidebar-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.sidebar-list svg {
  margin-right: 15px;
  width: 18px;
  height: 18px;
}
.sidebar-list i {
  margin-right: 15px;
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.sidebar-list p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.access-btn {
  border: 2px solid #FE6919 !important;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 9px !important;
  font-weight: 600 !important;
  margin-left: auto;
  margin-bottom: 0;
  white-space: pre;
  cursor: pointer;
}

.w-20px {
  width: 20px;
}

.shadow-hover {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 10px #ddd !important;
}

.sidebar-collapse-btn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;
  transition: 0.1s;
}
.sidebar-collapse-btn:hover {
  background-color: rgba(145, 186, 203, 0.2) !important;
  border-left: 3px solid #3a5765;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  transition: 0.1s;
}
.sidebar-collapse-btn .active {
  background-color: rgba(145, 186, 203, 0.2) !important;
}
.sidebar-collapse-btn p {
  color: black;
  font-family: "Roboto" !important;
}
.sidebar-collapse-btn svg {
  fill: black !important;
}

.arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}

.collapsed {
  background-color: transparent;
}
.collapsed p {
  color: grey;
}
.collapsed svg {
  fill: grey !important;
  stroke: grey;
}
.collapsed .arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(180deg);
}

.show .card-body {
  background-color: rgba(145, 186, 203, 0.2);
  color: rgb(58, 87, 101);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px 10px 12px;
  margin-top: 2px;
}

.siderbar-inner-ul {
  padding-left: 0;
  margin-bottom: 0;
}
.siderbar-inner-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul li img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul li:hover {
  font-weight: 800;
}
.siderbar-inner-ul a {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: rgb(58, 87, 101);
  text-decoration: none;
  cursor: pointer;
}
.siderbar-inner-ul a img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul a:hover {
  font-weight: 800;
}

.admin-accordian {
  position: absolute;
  bottom: 10px;
  width: 87%;
  background-color: white;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3A5765 !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #3A5765 !important;
}

*::-webkit-scrollbar-thumb:active {
  background: #3A5765 !important;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.vr {
  background: rgb(192, 192, 192) !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.txt-cap {
  text-transform: capitalize !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
}

.custom-carousel {
  top: 12px;
  left: 30px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .custom-carousel {
    top: 70px;
  }
}

.logo-price {
  width: 40vw;
  background: #23343e;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .logo-price {
    top: 100px;
    bottom: -62px;
  }
}
@media (max-width: 768px) {
  .logo-price {
    width: unset !important;
  }
}

.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
}
.form-price .form-control::placeholder {
  color: #bec6cb !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
}
.carousel-track-custom .react-multi-carousel-track {
  height: 402px;
}

.react-multi-carousel-dot-list {
  z-index: 1;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}
.logo-sub-text {
  position: relative;
  bottom: -6px;
}
.logo-sub-text span {
  font-family: "poppins-Bold";
}

.custome-height {
  height: 824px;
}
@media (max-width: 768px) {
  .custome-height {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

.sidebar-logo-div {
  display: flex;
  align-items: center;
}
.sidebar-logo-div img:first-child {
  width: 20px;
  margin-right: 20px;
}
.sidebar-logo-div img:last-child {
  width: 205px;
}

.roboto {
  font-family: "Roboto" !important;
}

.sidebar-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.sidebar-list svg {
  margin-right: 15px;
  width: 18px;
  height: 18px;
}
.sidebar-list p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.access-btn {
  border: 1px solid #FE6919;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 8px !important;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0 !important;
  cursor: pointer;
}

.w-20px {
  width: 20px;
}

.pl-16px {
  padding-left: 1rem !important;
}

.shadow-hover {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 10px #ddd !important;
}

.sidebar-collapse-btn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;
}
.sidebar-collapse-btn p {
  color: black;
  font-family: "Roboto" !important;
}
.sidebar-collapse-btn svg {
  fill: black !important;
}

.arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}

.collapsed {
  background-color: transparent;
}
.collapsed p {
  color: grey;
}
.collapsed svg {
  fill: grey !important;
  stroke: grey;
}

.transform-180 {
  transform: rotate(180deg);
}

.show .card-body {
  background-color: rgba(145, 186, 203, 0.2);
  color: rgb(58, 87, 101);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0px 10px 12px;
  margin-top: 2px;
}

.siderbar-inner-ul {
  padding-left: 0;
}
.siderbar-inner-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul li img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul li .dots {
  width: 25px;
}
.siderbar-inner-ul li:hover {
  font-weight: 800;
  color: black;
}
.siderbar-inner-ul a {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul a img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul a .dots {
  width: 25px;
}
.siderbar-inner-ul a:hover {
  font-weight: 800;
  color: black;
}

.admin-accordian {
  position: absolute;
  bottom: 10px;
  width: 87%;
}

.ant-modal-mask {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.min-h-full {
  min-height: 100vh;
}

.circle {
  border-radius: 50%;
  border-width: 22px;
  border-style: solid;
  width: 145px;
  height: 145px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 830px) {
  .circle {
    left: 0%;
    transform: translateX(0);
  }
}
.circle_vinous {
  border-color: rgb(236, 0, 120);
}
.circle_purple {
  border-color: purple;
}
.circle_grey-and-blue {
  border-color: rgb(0, 79, 113);
}
.circle_blue {
  border-color: rgb(0, 148, 163);
}
.circle_lightGreen {
  border-color: rgb(70, 225, 115);
}
.circle__line {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -180px;
  transform: translateY(-50%);
  height: 2px;
  width: 140px;
}
.circle__line_vinous {
  background-color: rgb(236, 0, 120);
}
.circle__line_purple {
  background-color: purple;
}
.circle__line_grey-and-blue {
  background-color: rgb(0, 79, 113);
}
.circle__line_blue {
  background-color: rgb(0, 148, 163);
}
.circle__line_lightGreen {
  background-color: rgb(70, 225, 115);
}
.circle__line_right {
  left: 160px;
}

.timeline-item {
  height: 180px;
  display: flex;
  position: relative;
}
.timeline-item-text {
  width: 300px;
  font-size: 13px;
}
@media (max-width: 830px) {
  .timeline-item-text {
    width: auto;
    padding-left: 180px;
  }
}
.timeline-item-estimation_vinous {
  color: rgb(236, 0, 120);
}
.timeline-item-estimation_purple {
  color: purple;
}
.timeline-item-estimation_grey-and-blue {
  color: rgb(0, 79, 113);
}
.timeline-item-estimation_blue {
  color: rgb(0, 148, 163);
}
.timeline-item-estimation_lightGreen {
  color: rgb(70, 225, 115);
}
.timeline-item-description {
  line-height: 20px;
  font-size: 13px;
}
.timeline-item-link {
  color: #9bcf7e;
}
.timeline-item-link:hover {
  color: #9bcf7e;
}

.estimation-detail-item {
  position: relative;
  display: flex;
  height: 215px;
  align-items: baseline;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 12px;
}

@media (max-width: 700px) {
  .main-bid-card > div:first-child {
    display: none;
  }
}
.main-bid-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ddd;
  padding: 10px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  position: relative;
  font-size: 12px !important;
}

.main-bid-card > * {
  font-size: 12px !important;
  flex: 2 1 auto;
}

.main-bid-card td {
  font-size: 12px !important;
}

.main-bid-card > div:first-child {
  width: 300px;
}

.main-bid-card > div {
  margin: 0 10px;
}

.main-bid-card div div {
  width: 100%;
}

.total-summary {
  font-size: 15px !important;
  font-family: "poppins-Medium";
  padding: 10px 0;
  padding-left: 0 !important;
}

.total-summary td {
  padding: 0 !important;
}

.bid-history-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.bid-history-table tbody tr td {
  color: #2c3e50;
  padding: 4.5px 10px;
  font-family: "Poppins-medium";
}

.bid-history-table tbody .item-child td {
  border: 1px solid #ddd;
  border-radius: 0px;
  border-left: none;
  border-right: none;
}

.bid-history-table tbody .item-child td:first-child {
  border: 1px solid #ddd;
  border-radius: 6px 0px 0px 6px;
  border-right: none;
  white-space: nowrap;
}

.bid-history-table tbody .item-child td:last-child {
  border: 1px solid #ddd;
  border-radius: 0px 6px 6px 0px;
  border-left: none;
}

.export-detail-div {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  color: #2c3e50;
}

.export-detail-div > div {
  font-family: "Poppins-medium";
  font-size: 13px;
}

.export-detail-div > div:first-child {
  margin-bottom: 10px;
}

.estimation-div {
  padding: 10px;
  color: #2c3e50;
  margin-top: 10px;
}
.estimation-div div {
  font-family: "Poppins-medium";
  font-size: 13px;
}

.timeline-item-link {
  color: #2c3e50 !important;
  justify-content: space-between;
  margin-top: 5px;
  text-decoration: underline;
}
.timeline-item-link:hover {
  text-decoration: underline;
}
.timeline-item-link svg {
  width: 13px;
  margin-right: 8px;
}

.bid-date-container {
  background-color: #2c3e50;
  border-radius: 6px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 160px;
}
.bid-date-container .month-text {
  font-family: "poppins-Medium";
  font-size: 24px;
  padding: 2.5px 0;
}
.bid-date-container .time-text {
  font-size: 18px;
  padding: 2.5px 0;
}

.bid-viewModal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.bid-viewModal-container i {
  font-size: 40px;
  display: block;
}
.bid-viewModal-container a {
  font-size: 12px;
  font-family: "Poppins-medium";
  text-decoration: none;
  transition: 0.2s;
}
.bid-viewModal-container a:hover i {
  transform: scale(1.07);
  transition: 0.2s;
}

.collapse-icon-wrap {
  border: 1px solid #3a5765;
  padding: 3px 4px;
  border-radius: 50%;
  font-size: 11px;
  color: #3a5765;
}

.resizer-icon {
  position: absolute;
  right: 0px;
  bottom: 0;
}

.view-modaltext {
  font-family: "Poppins-medium";
  color: #34495e;
}
.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.vr {
  background: rgb(192, 192, 192) !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.f-24 {
  font-size: 24px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
}

.custom-carousel {
  top: 12px;
  left: 30px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .custom-carousel {
    top: 70px;
  }
}

.logo-price {
  width: 40vw;
  background: #23343e;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .logo-price {
    top: 100px;
    bottom: -62px;
  }
}
@media (max-width: 768px) {
  .logo-price {
    width: unset !important;
  }
}

.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
}
.form-price .form-control::placeholder {
  color: #bec6cb !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
}
.carousel-track-custom .react-multi-carousel-track {
  height: 402px;
}

.react-multi-carousel-dot-list {
  z-index: 1;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}
.logo-sub-text {
  position: relative;
  bottom: -6px;
}
.logo-sub-text span {
  font-family: "poppins-Bold";
}

.custome-height {
  height: 824px;
}
@media (max-width: 768px) {
  .custome-height {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

.sidebar-logo-div {
  display: flex;
  align-items: center;
}
.sidebar-logo-div img:first-child {
  width: 20px;
  margin-right: 20px;
}
.sidebar-logo-div img:last-child {
  width: 205px;
}

.roboto {
  font-family: "Roboto" !important;
}

.sidebar-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.sidebar-list svg {
  margin-right: 15px;
  width: 18px;
  height: 18px;
}
.sidebar-list i {
  margin-right: 15px;
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.sidebar-list p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.access-btn {
  border: 2px solid #FE6919 !important;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 9px !important;
  font-weight: 600 !important;
  margin-left: auto;
  margin-bottom: 0;
  white-space: pre;
  cursor: pointer;
}

.w-20px {
  width: 20px;
}

.shadow-hover {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 10px #ddd !important;
}

.sidebar-collapse-btn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;
  transition: 0.1s;
}
.sidebar-collapse-btn:hover {
  background-color: rgba(145, 186, 203, 0.2) !important;
  border-left: 3px solid #3a5765;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  transition: 0.1s;
}
.sidebar-collapse-btn .active {
  background-color: rgba(145, 186, 203, 0.2) !important;
}
.sidebar-collapse-btn p {
  color: black;
  font-family: "Roboto" !important;
}
.sidebar-collapse-btn svg {
  fill: black !important;
}

.arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}

.collapsed {
  background-color: transparent;
}
.collapsed p {
  color: grey;
}
.collapsed svg {
  fill: grey !important;
  stroke: grey;
}
.collapsed .arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(180deg);
}

.show .card-body {
  background-color: rgba(145, 186, 203, 0.2);
  color: rgb(58, 87, 101);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px 10px 12px;
  margin-top: 2px;
}

.siderbar-inner-ul {
  padding-left: 0;
  margin-bottom: 0;
}
.siderbar-inner-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul li img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul li:hover {
  font-weight: 800;
}
.siderbar-inner-ul a {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: rgb(58, 87, 101);
  text-decoration: none;
  cursor: pointer;
}
.siderbar-inner-ul a img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul a:hover {
  font-weight: 800;
}

.admin-accordian {
  position: absolute;
  bottom: 10px;
  width: 87%;
  background-color: white;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3A5765 !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #3A5765 !important;
}

*::-webkit-scrollbar-thumb:active {
  background: #3A5765 !important;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.vr {
  background: rgb(192, 192, 192) !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.txt-cap {
  text-transform: capitalize !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
}

.custom-carousel {
  top: 12px;
  left: 30px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .custom-carousel {
    top: 70px;
  }
}

.logo-price {
  width: 40vw;
  background: #23343e;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .logo-price {
    top: 100px;
    bottom: -62px;
  }
}
@media (max-width: 768px) {
  .logo-price {
    width: unset !important;
  }
}

.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
}
.form-price .form-control::placeholder {
  color: #bec6cb !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
}
.carousel-track-custom .react-multi-carousel-track {
  height: 402px;
}

.react-multi-carousel-dot-list {
  z-index: 1;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}
.logo-sub-text {
  position: relative;
  bottom: -6px;
}
.logo-sub-text span {
  font-family: "poppins-Bold";
}

.custome-height {
  height: 824px;
}
@media (max-width: 768px) {
  .custome-height {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

.sidebar-logo-div {
  display: flex;
  align-items: center;
}
.sidebar-logo-div img:first-child {
  width: 20px;
  margin-right: 20px;
}
.sidebar-logo-div img:last-child {
  width: 205px;
}

.roboto {
  font-family: "Roboto" !important;
}

.sidebar-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.sidebar-list svg {
  margin-right: 15px;
  width: 18px;
  height: 18px;
}
.sidebar-list p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.access-btn {
  border: 1px solid #FE6919;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 8px !important;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0 !important;
  cursor: pointer;
}

.w-20px {
  width: 20px;
}

.pl-16px {
  padding-left: 1rem !important;
}

.shadow-hover {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 10px #ddd !important;
}

.sidebar-collapse-btn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;
}
.sidebar-collapse-btn p {
  color: black;
  font-family: "Roboto" !important;
}
.sidebar-collapse-btn svg {
  fill: black !important;
}

.arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}

.collapsed {
  background-color: transparent;
}
.collapsed p {
  color: grey;
}
.collapsed svg {
  fill: grey !important;
  stroke: grey;
}

.transform-180 {
  transform: rotate(180deg);
}

.show .card-body {
  background-color: rgba(145, 186, 203, 0.2);
  color: rgb(58, 87, 101);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0px 10px 12px;
  margin-top: 2px;
}

.siderbar-inner-ul {
  padding-left: 0;
}
.siderbar-inner-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul li img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul li .dots {
  width: 25px;
}
.siderbar-inner-ul li:hover {
  font-weight: 800;
  color: black;
}
.siderbar-inner-ul a {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul a img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul a .dots {
  width: 25px;
}
.siderbar-inner-ul a:hover {
  font-weight: 800;
  color: black;
}

.admin-accordian {
  position: absolute;
  bottom: 10px;
  width: 87%;
}

.ant-modal-mask {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.min-h-full {
  min-height: 100vh;
}

.main {
  width: 100%;
  color: #111;
}
.main_vinous {
  color: rgb(236, 0, 120);
}
.main_purple {
  color: purple;
}
.main_grey-and-blue {
  color: rgb(0, 79, 113);
}
.main_blue {
  color: rgb(0, 148, 163);
}
.main_lightGreen {
  color: rgb(70, 225, 115);
}
.main_vinous {
  border-color: rgb(236, 0, 120);
}
.main_purple {
  border-color: purple;
}
.main_grey-and-blue {
  border-color: rgb(0, 79, 113);
}
.main_blue {
  border-color: rgb(0, 148, 163);
}
.main_lightGreen {
  border-color: rgb(70, 225, 115);
}

tr.expanded {
  background-color: transparent;
}

tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2666666667);
}

tr.expandable > td {
  text-align: left;
  height: 15px;
  font-size: 14px;
}

tr.item-child > td {
  text-align: left;
  padding-left: 5px;
}
tr.item-child > td:first-child {
  padding-left: 20px;
}
.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.vr {
  background: rgb(192, 192, 192) !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.f-24 {
  font-size: 24px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
}

.custom-carousel {
  top: 12px;
  left: 30px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .custom-carousel {
    top: 70px;
  }
}

.logo-price {
  width: 40vw;
  background: #23343e;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .logo-price {
    top: 100px;
    bottom: -62px;
  }
}
@media (max-width: 768px) {
  .logo-price {
    width: unset !important;
  }
}

.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
}
.form-price .form-control::placeholder {
  color: #bec6cb !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
}
.carousel-track-custom .react-multi-carousel-track {
  height: 402px;
}

.react-multi-carousel-dot-list {
  z-index: 1;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}
.logo-sub-text {
  position: relative;
  bottom: -6px;
}
.logo-sub-text span {
  font-family: "poppins-Bold";
}

.custome-height {
  height: 824px;
}
@media (max-width: 768px) {
  .custome-height {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

.sidebar-logo-div {
  display: flex;
  align-items: center;
}
.sidebar-logo-div img:first-child {
  width: 20px;
  margin-right: 20px;
}
.sidebar-logo-div img:last-child {
  width: 205px;
}

.roboto {
  font-family: "Roboto" !important;
}

.sidebar-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.sidebar-list svg {
  margin-right: 15px;
  width: 18px;
  height: 18px;
}
.sidebar-list i {
  margin-right: 15px;
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.sidebar-list p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.access-btn {
  border: 2px solid #FE6919 !important;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 9px !important;
  font-weight: 600 !important;
  margin-left: auto;
  margin-bottom: 0;
  white-space: pre;
  cursor: pointer;
}

.w-20px {
  width: 20px;
}

.shadow-hover {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 10px #ddd !important;
}

.sidebar-collapse-btn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;
  transition: 0.1s;
}
.sidebar-collapse-btn:hover {
  background-color: rgba(145, 186, 203, 0.2) !important;
  border-left: 3px solid #3a5765;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  transition: 0.1s;
}
.sidebar-collapse-btn .active {
  background-color: rgba(145, 186, 203, 0.2) !important;
}
.sidebar-collapse-btn p {
  color: black;
  font-family: "Roboto" !important;
}
.sidebar-collapse-btn svg {
  fill: black !important;
}

.arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}

.collapsed {
  background-color: transparent;
}
.collapsed p {
  color: grey;
}
.collapsed svg {
  fill: grey !important;
  stroke: grey;
}
.collapsed .arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(180deg);
}

.show .card-body {
  background-color: rgba(145, 186, 203, 0.2);
  color: rgb(58, 87, 101);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px 10px 12px;
  margin-top: 2px;
}

.siderbar-inner-ul {
  padding-left: 0;
  margin-bottom: 0;
}
.siderbar-inner-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul li img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul li:hover {
  font-weight: 800;
}
.siderbar-inner-ul a {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: rgb(58, 87, 101);
  text-decoration: none;
  cursor: pointer;
}
.siderbar-inner-ul a img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul a:hover {
  font-weight: 800;
}

.admin-accordian {
  position: absolute;
  bottom: 10px;
  width: 87%;
  background-color: white;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background: rgba(0, 0, 0, 0.1); */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3A5765 !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #3A5765 !important;
}

*::-webkit-scrollbar-thumb:active {
  background: #3A5765 !important;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.vr {
  background: rgb(192, 192, 192) !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.btn-price {
  height: 60px !important;
  background: #455d6b !important;
  color: #ffffff !important;
  font-size: 21px !important;
  line-height: 31px !important;
  /* text-decoration-style: dashed; */
  text-transform: capitalize !important;
  box-shadow: none !important;
  transition: none !important;
}

.txt-cap {
  text-transform: capitalize !important;
}

.btn-price-cencel {
  background: #d6d4d4 !important;
  color: #455d6b !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-151 {
  margin-top: 120px;
}

.sub-text-price {
  font-size: 24px;
  line-height: 35px;
  color: #3a4952;
}

.custom-carousel {
  top: 12px;
  left: 30px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .custom-carousel {
    top: 70px;
  }
}

.logo-price {
  width: 40vw;
  background: #23343e;
  height: 361px;
  z-index: -1;
  position: relative;
  bottom: -32px;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .logo-price {
    top: 100px;
    bottom: -62px;
  }
}
@media (max-width: 768px) {
  .logo-price {
    width: unset !important;
  }
}

.logo-price-text {
  color: #ffffff;
  font-size: 28px;
  font-family: "poppins";
}

.logo-price-sub-text {
  color: #fff;
  font-size: 45px;
  font-family: "poppins-Bold";
  line-height: 93px;
}

.border-top-20 {
  border-top: 20px solid #455d6b;
}

.custom-dot-list-style {
  justify-content: start !important;
  padding-left: 24px !important;
  margin-top: 50px !important;
  bottom: 50px !important;
}

.form-price .form-control {
  padding-left: 2.6975rem;
  height: 82px !important;
  border-radius: 20px !important;
  border: 2px solid #e8ebef !important;
}
.form-price .form-control::placeholder {
  color: #bec6cb !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.form-price .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  color: #bec6cb;
  padding-left: 20px;
}

.carousel-track-custom {
  left: 24px;
  top: 24px;
}
.carousel-track-custom .react-multi-carousel-track {
  height: 402px;
}

.react-multi-carousel-dot-list {
  z-index: 1;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  bottom: 50px !important;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
}

.custom-image {
  position: relative !important;
  top: 80px !important;
}

.bg-light-gary {
  background-color: #f9f9f9;
}

.price-main-content {
  position: absolute;
  bottom: 0;
}

.custom-rating {
  width: 380px;
}

@media (max-width: 768px) {
  .price-main-content {
    bottom: -30px !important;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1348px) {
  .price-main-content {
    bottom: 60px;
  }
  .custom-rating {
    width: 300px;
  }
}
@media only screen and (min-device-width: 992px) {
  .cutom-price-row {
    margin-left: 0.25rem !important;
  }
}
.logo-sub-text {
  position: relative;
  bottom: -6px;
}
.logo-sub-text span {
  font-family: "poppins-Bold";
}

.custome-height {
  height: 824px;
}
@media (max-width: 768px) {
  .custome-height {
    height: 754px;
  }
}

.bg-dark-gary {
  background: #23343e;
}

.sidebar-logo-div {
  display: flex;
  align-items: center;
}
.sidebar-logo-div img:first-child {
  width: 20px;
  margin-right: 20px;
}
.sidebar-logo-div img:last-child {
  width: 205px;
}

.roboto {
  font-family: "Roboto" !important;
}

.sidebar-list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.sidebar-list svg {
  margin-right: 15px;
  width: 18px;
  height: 18px;
}
.sidebar-list p {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.access-btn {
  border: 1px solid #FE6919;
  color: #FE6919 !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 8px !important;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0 !important;
  cursor: pointer;
}

.w-20px {
  width: 20px;
}

.pl-16px {
  padding-left: 1rem !important;
}

.shadow-hover {
  box-shadow: none !important;
}
.shadow-hover:hover {
  box-shadow: 0px 0px 10px #ddd !important;
}

.sidebar-collapse-btn {
  width: 100%;
  outline: none;
  border: none;
  padding: 10px;
  background-color: rgba(145, 186, 203, 0.2);
  color: black;
}
.sidebar-collapse-btn p {
  color: black;
  font-family: "Roboto" !important;
}
.sidebar-collapse-btn svg {
  fill: black !important;
}

.arrow-icon {
  width: 13px;
  margin-left: auto;
  transform: rotate(0deg);
}

.collapsed {
  background-color: transparent;
}
.collapsed p {
  color: grey;
}
.collapsed svg {
  fill: grey !important;
  stroke: grey;
}

.transform-180 {
  transform: rotate(180deg);
}

.show .card-body {
  background-color: rgba(145, 186, 203, 0.2);
  color: rgb(58, 87, 101);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0px 10px 12px;
  margin-top: 2px;
}

.siderbar-inner-ul {
  padding-left: 0;
}
.siderbar-inner-ul li {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul li img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul li .dots {
  width: 25px;
}
.siderbar-inner-ul li:hover {
  font-weight: 800;
  color: black;
}
.siderbar-inner-ul a {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
}
.siderbar-inner-ul a img {
  width: 15px;
  margin-right: 10px;
}
.siderbar-inner-ul a .dots {
  width: 25px;
}
.siderbar-inner-ul a:hover {
  font-weight: 800;
  color: black;
}

.admin-accordian {
  position: absolute;
  bottom: 10px;
  width: 87%;
}

.ant-modal-mask {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.min-h-full {
  min-height: 100vh;
}

.main {
  width: 100%;
  color: #111;
}
.main_vinous {
  color: rgb(236, 0, 120);
}
.main_purple {
  color: purple;
}
.main_grey-and-blue {
  color: rgb(0, 79, 113);
}
.main_blue {
  color: rgb(0, 148, 163);
}
.main_lightGreen {
  color: rgb(70, 225, 115);
}
.main_vinous {
  border-color: rgb(236, 0, 120);
}
.main_purple {
  border-color: purple;
}
.main_grey-and-blue {
  border-color: rgb(0, 79, 113);
}
.main_blue {
  border-color: rgb(0, 148, 163);
}
.main_lightGreen {
  border-color: rgb(70, 225, 115);
}

tr.expandable {
  margin: 10px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
}

.modal-sideShare-table tr.expandable {
  top: -15px;
}

tr.expanded {
  min-height: 40px;
  border: none;
  white-space: nowrap;
}

tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2666666667);
}

tr.expandable > td {
  text-align: left;
  height: 15px;
  font-size: 14px;
  padding-right: 5px !important;
}

.modal-sideShare-table tr.expanded {
  outline: 1px solid white;
}

.modal-sideShare-table .item-child {
  display: table-row;
  height: 32px;
  min-height: 25px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 0 10px;
  /* border-left: none; */
  /* border-right: none; */
}

tr.item-child > td {
  text-align: left;
  padding-left: 5px !important;
}
tr.item-child > td:first-child {
  padding-left: 20px;
}

.total-summary img {
  margin: 0 10px;
}
.share-wrappre {
  height: 100%;
}

.share-container-new {
  height: 100%;
  overflow: auto;
}

.share {
  background-color: #fff;
  padding: 15px;
  height: 100%;
  padding-bottom: 0;
  overflow: auto;
}

.share-form input {
  width: 100% !important;
  max-width: 250px;
}

.share-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.share-form input {
  width: 250px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-right: 15px;
  font-size: 12px;
  font-family: "poppins-Medium";
  padding-left: 10px;
}
.share-form input::placeholder {
  padding-left: 15px;
  color: #ddd;
}
.share-form button {
  background-color: #9BBCCD !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: white;
  font-family: "Poppins-medium";
}

.container-share-check {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  line-height: 17px;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  color: #3a5765;
  margin-right: 12px;
  font-weight: 600;
}

.container-share-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: #adb5bd solid 1px;
  border-radius: 4px;
}

.container-share-check:hover input ~ .checkmark {
  background-color: #ccc;
}

.container-share-check input:checked ~ .checkmark {
  background-color: #007bff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-share-check input:checked ~ .checkmark:after {
  display: block;
}

.container-share-check .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.share-check-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options-wrapper {
  width: 100%;
  margin-right: 20px;
  border-bottom: 2px solid #E8ECF5;
  padding: 15px 0;
}

.share-lists {
  min-width: 100%;
  justify-content: space-between;
  width: 100%;
}

.share-container {
  display: block;
  width: 100%;
  height: 100%;
}

.share-email-heder {
  width: 100%;
  padding: 11px 0;
  text-align: left;
  font-family: "poppins-Medium";
  font-size: 12px;
  color: #3a5765;
  font-weight: 600;
}

.share-email-item {
  background-color: #f2f2f2;
  padding: 2px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-width: 180px;
  position: relative;
  font-size: 13px;
  font-family: "poppins-Medium";
  font-weight: 600;
  margin-bottom: 7px;
  display: inline-flex;
  width: 48%;
  margin: 6px 5px;
}
.share-email-item .share-email-item-delete {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: #455E6C;
  cursor: pointer;
  border-radius: 50%;
  right: 4px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}
.share-email-item .share-email-item-delete::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 2px;
  background-color: white;
}
.share-email-item .share-email-item-delete::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 60%;
  background-color: white;
}
.share-email-item .share-email-item-delete:hover {
  box-shadow: 3px 3px 6px 0px rgba(16, 16, 16, 0.75);
}
.share-email-item .item {
  white-space: nowrap;
  overflow: hidden;
  line-height: 29px;
  text-overflow: ellipsis;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .share-button-group {
    margin: 0px auto !important;
  }
}
@media (max-width: 400px) {
  .share-button-group {
    margin-left: 45px !important;
  }
}
.share-button-group {
  position: fixed;
  top: 7px;
  background-color: #fff;
  z-index: 101;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  margin-left: 200px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.share-button-group button i {
  margin-right: 10px;
}
.share-button-group .share-button {
  padding: 0 15px 0 10px;
  height: 33px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  border: 1px solid #3a5765;
  font-size: 14px;
  background-color: white;
  color: #3a5765;
}
@media (max-width: 450px) {
  .share-button-group .share-button {
    padding: 0 5px;
  }
}
.share-button-group .active-share {
  background-color: #3a5765;
  color: #fff;
  border: 1px solid #3a5765;
}

.share-files {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid #E8ECF5;
  padding-bottom: 12px;
}
.share-files a {
  padding: 3px 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #2C3E50;
  font-family: "Poppins-medium";
  justify-content: space-between;
}
.share-files a div {
  font-family: "Poppins-medium";
}
.share-files svg {
  width: 20px;
  height: 14px;
}

.resizeble {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  width: 100%;
  height: 10px;
  background-color: #71a1b7;
  cursor: row-resize;
  -webkit-user-select: none;
          user-select: none;
}

.share-check-group {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  font-family: "poppins-regular";
  width: 100%;
}

.by {
  font-size: 12px;
  font-weight: 600;
}
.by span {
  font-size: 15px;
  font-family: "Poppins-medium";
  color: #212529;
}

.filters-container {
  grid-gap: 20px;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.filter_input {
  height: calc(2.25rem + 2px);
  border: 1px solid #ced4da;
  border-radius: 3px;
  width: 100%;
}

.filterTableDiv {
  height: 80%;
  overflow: auto;
}
.filterTableDiv table thead tr th {
  font-weight: bold;
}

.min-height-auto {
  min-height: auto !important;
}

.filterInputText {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 12px;
}
.resize-panel-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.resizer {
  width: 11px;
  background-color: #fbfcfc;
  position: relative;
  cursor: col-resize;
  -webkit-user-select: none;
  user-select: none;
  z-index: 20;
  margin-left: -5px;
  margin-right: -5px;
}
.tree-item {
  height: 30px;
  font-size: 14px;
  color: #455E6C;
}
.tree-item svg {
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.tree-item span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.timeline-viewer-container {
  background-color: #fff;
}

#WebglViewer #clippingTooltip {
  position: absolute;
  font-size: small;
  right: 0;
  top: -20px;
  background-color: #3a5765;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}

.viewer-zoom {
  bottom: 40px !important;
  color: gray;
}

#WebglViewer .buttonEnabled {
  background-color: #3a5765 !important;
  color: white;
}

.ifcjs-dimension-label {
  background-color: white;
  padding: 0 3px;
  border-radius: 100vw;
  font-size: 0.8rem;
}

.viewer {
  height: calc(100vh - 60px);
}
.viewer canvas {
  outline: none;
  width: 100%;
}
.viewer-total-price {
  top: 15px;
  right: 15px;
  width: 200px;
  box-shadow: 1px 2px 9px grey;
}
.viewer-zoom {
  z-index: 10;
  right: 50%;
  transform: translateX(50%);
  bottom: 15px;
}
.viewer-header {
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: rgb(113, 161, 183);
  font-size: 17px;
}
.viewer-lowerheader {
  height: 38px;
}
.viewer-header-item {
  border-right: 2px solid rgb(200, 200, 200);
}
.viewer-lowertree {
  height: calc(100% - 38px);
}
.viewer-selected {
  min-width: 160px;
}
.viewer-icon {
  cursor: pointer;
}
.viewer-icon svg {
  width: 25px;
  height: 10px;
  fill: white;
  transform: rotate(0deg);
  transition: 0.3s;
}
.viewer-icon-rotate svg {
  transform: rotate(90deg);
}
.viewer-popover {
  padding: 3px 7px 3px 5px;
  background-color: white;
}
.viewer-searching {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}

.estimation-detail-itemViewer {
  position: absolute;
  display: flex;
  overflow: hidden;
  max-height: 1000px;
  max-width: 300px;
  overflow-y: scroll;
}

.viewer {
  background-color: #fff;
}

.viewer-header {
  background-color: #fbfcfc !important;
  top: 0px;
  font-size: 12px;
}

.viewer-outer-div {
  overflow: hidden;
  background-color: #fbfcfc;
  min-width: 80px;
}

.viewer-header-item {
  position: relative;
  color: #455e6c;
  background: #f2f2f2;
  border-radius: 10px 10px 0px 0px;
  border: none !important;
  margin: 0px;
  font-family: "poppins-Medium";
  letter-spacing: -0.7px;
  font-size: 12px;
  white-space: pre;
  grid-gap: 8px;
  gap: 8px;
}

.viewer-gapping {
  margin: 5px;
  height: 38px;
}

.resize-panel-container {
  margin-top: 0px;
}

.resize-panel {
  margin: 0px;
  background: #fff;
}

.inner_resizable {
  margin: 0px 5px;
  border: 1.5px solid #f2f2f2;
  width: auto;
  border-top: none;
  border-bottom: none;
}

.resize-panel:last-child {
  max-width: inherit;
}

.w-40 {
  width: 40%;
}

.w-20 {
  width: 20%;
}

.viewer-icon {
  position: absolute;
  right: 0;
  background: inherit;
}
.viewer-icon svg {
  color: #455e6c;
  fill: #455e6c;
  width: 25px !important;
  height: 28px !important;
}

.modal-side-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  padding: 0px 5px;
}

.modal-side-table tbody tr td {
  color: #2c3e50;
  padding: 0px 10px;
  font-family: "Poppins-medium";
  font-size: 11px !important;
  background: #fbfbfb;
  height: 29px;
}

.modal-side-table tbody tr td.total-summary {
  display: none;
}

.modal-side-table tbody .item-child td {
  border: 1px solid #ddd;
  border-radius: 0px;
  border-left: none;
  border-right: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 7rem;
}

.modal-side-table tbody .item-child td:first-child {
  border: 1px solid #ddd;
  border-radius: 5px 0px 0px 5px;
  border-right: none;
}

.modal-side-table tbody .item-child td:last-child {
  border: 1px solid #ddd;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}

.modal-sideShare-table tbody .item-child td:first-child {
  padding-left: 20px !important;
}

@media only screen and (max-width: 768px) {
  .custom-control {
    display: none !important;
  }
}
.custom-control-label1 {
  font-size: 12px !important;
  color: #455e6c !important;
  font-family: "poppins-Medium" !important;
  padding-top: 2px !important;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: rgba(1, 1, 1, 0.04) !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 13px;
  color: #455e6c;
  font-weight: 700;
}

.amber-light {
  background-color: #ffebc4;
}

#WebglViewer .modal3d-tool-btn {
  padding: 10px !important;
  margin-right: 13px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
#WebglViewer .modal3d-tool-btn:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.resizable-fragment {
  height: 25%;
}
.resizable-fragment:last-child {
  max-height: inherit;
}
.resizable-fragment div::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #3a5765;
}
.resizable-fragment div::-webkit-scrollbar-thumb:hover {
  background: #283f4b;
}
.resizable-fragment div::-webkit-scrollbar-thumb:active {
  background: #283f4b;
}

.resize-panel:last-child .resizable-fragment:first-child {
  max-height: 50%;
}

.clock-icon {
  width: 20px;
  margin-right: 10px;
}

.modal-sideShare-table {
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
  border-spacing: 0 10px;
  display: inline-table;
}

.modal-sideShare-table .item-child {
  outline: 1px solid #ddd;
  border-radius: 5px;
}

.modal-sideShare-table tbody {
  width: 100%;
}

.modal-sideShare-table tbody tr td {
  color: #2c3e50;
  padding: 8px 20px;
  font-family: "Poppins-medium";
  font-size: 12px !important;
  max-height: 50px;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.viewer-lowertree {
  height: calc(100% - 50px) !important;
}

.h-86vh {
  height: 86vh;
}

.existing-img {
  width: 32px;
  margin-right: 15px;
}

.demo-img {
  width: 28px;
  margin-right: 15px;
}

.unpriced-img {
  width: 32px;
  margin-right: 15px;
}

.priced-img {
  width: 32px;
}

.estimation-icon {
  width: 28px;
}

.project-icon {
  width: 30px;
}

.viewer-lowerheader {
  font-size: 12px;
  background-color: #f2f2f2;
  margin-top: 2px;
}
.viewer {
  height: calc(100vh - 70px);
}
.viewer canvas {
  outline: none;
  width: 100%;
}
.viewer-total-price {
  top: 15px;
  right: 15px;
  width: 200px;
  box-shadow: 1px 2px 9px grey;
}
.viewer-zoom {
  z-index: 10;
  right: 50%;
  transform: translateX(50%);
  bottom: 15px;
}
.viewer-header {
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: rgb(113, 161, 183);
  font-size: 17px;
}
.viewer-lowerheader {
  height: 38px;
}
.viewer-header-item {
  border-right: 2px solid rgb(200, 200, 200);
}
.viewer-lowertree {
  height: calc(100% - 38px);
}
.viewer-selected {
  min-width: 160px;
}
.viewer-icon {
  cursor: pointer;
}
.viewer-icon svg {
  width: 25px;
  height: 10px;
  fill: white;
  transform: rotate(0deg);
  transition: 0.3s;
}
.viewer-icon-rotate svg {
  transform: rotate(90deg);
}
.viewer-popover {
  padding: 3px 7px 3px 5px;
  background-color: white;
}
.viewer-searching {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}

.socketviewer {
  height: 100vh;
}
.socketviewer canvas {
  outline: none;
  width: 100%;
}
.socketviewer-total-price {
  top: 15px;
  right: 15px;
  width: 200px;
  box-shadow: 1px 2px 9px grey;
}
.socketviewer-zoom {
  z-index: 10;
  right: 50%;
  transform: translateX(50%);
  bottom: 15px;
}
.socketviewer-header {
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: rgb(113, 161, 183);
  font-size: 17px;
}
.socketviewer-lowerheader {
  height: 38px;
}
.socketviewer-header-item {
  border-right: 2px solid rgb(200, 200, 200);
}
.socketviewer-lowertree {
  height: calc(100% - 38px);
}
.socketviewer-selected {
  min-width: 160px;
}
.socketviewer-icon {
  cursor: pointer;
}
.socketviewer-icon svg {
  width: 25px;
  height: 10px;
  fill: white;
  transform: rotate(0deg);
  transition: 0.3s;
}
.socketviewer-icon-rotate svg {
  transform: rotate(90deg);
}
.socketviewer-popover {
  padding: 3px 7px 3px 5px;
  background-color: white;
}
.socketviewer-searching {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}

.estimation-detail-itemViewer {
  position: absolute;
  display: flex;
  overflow: hidden;
  max-height: 1000px;
  max-width: 300px;
  overflow-y: scroll;
}

.modal3d-tool-group-btn {
  position: relative;
  align-content: space-around;
  display: flex;
  padding: 10px !important;
  margin-left: 10px !important;
}

.socketviewer .buttonEnabled {
  background-color: #3a5765 !important;
  color: white !important;
}

.socketviewer #clippingTooltip {
  position: absolute;
  font-size: small;
  right: 0;
  bottom: -25px;
  background-color: #3a5765;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
  margin-right: 10px;
}
.admin-table-shadow > div {
  overflow: scroll;
}

.admin-table-shadow {
  max-width: 1500px;
  box-shadow: 0px 3px 99px #f1f3f5;
  padding: 10px;
  border-radius: 10px;
  font-size: small;
  margin: auto;
}

.admin-table-shadow table * {
  font-size: 12px;
}

.admin-table thead {
  background-color: white;
}
.admin-table thead tr th {
  color: #161212;
  border-bottom: 1px solid #ddd;
  font-family: "Poppins-medium";
  background-color: #eaeaea;
  border-radius: 0px;
}
.admin-table thead tr th:last-child {
  padding-right: 15px;
}
.admin-table tbody {
  background-color: white;
}
.admin-table tbody tr td {
  border: 1px solid transparent !important;
  color: #68676c;
  font-family: "poppins-Regular";
  padding: 10px 10px 10px 0;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  font-weight: 300 !important;
  text-transform: capitalize;
  font-size: 12px !important;
}
.admin-table tbody tr:nth-child(odd) {
  background: #f8fafc;
}
.admin-table tbody tr:nth-child(odd) td:first-child {
  padding-left: 15px;
  border-radius: 13px 0 0 13px;
}
.admin-table tbody tr:nth-child(odd) td:last-child {
  border-radius: 0px 13px 13px 0px;
}
.admin-table tbody tr:nth-child(even) td:first-child {
  padding-left: 15px;
  border-radius: 13px 0 0 13px;
}
.admin-table tbody tr:nth-child(even) td:last-child {
  border-radius: 0px 13px 13px 0px;
}

.table-btn {
  border-radius: 6px !important;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border: none;
  padding: 0 10px;
  cursor: pointer;
}
.table-btn i {
  margin-right: 10px;
  font-size: 14px;
}
.table-btn:focus {
  outline: none;
}
.table-btn:hover {
  filter: brightness(95%);
}

.see-btn {
  background-color: #eef2f6 !important;
  color: #455d6b !important;
}

.dlt-btn {
  background-color: #fff6f6 !important;
  color: #fb9292 !important;
}

.admin-btn {
  background-color: #f1faf6;
  color: #87d7b2;
}
.admin-btn i {
  color: #fff;
  background-color: #87d7b2;
  border-radius: 50%;
  padding: 4px;
  font-size: 9px;
}

.change-btn {
  margin-left: auto;
  margin-right: 20px;
}

.change-admin-list-group {
  border: none;
}
.change-admin-list-group li {
  margin: 5px 10px;
  border: 1px solid #ddd !important;
  border-radius: 5px;
}

.admin-list-name {
  font-size: 14px;
  line-height: 1;
}

.admin-list-email {
  font-size: 13px;
  line-height: 1;
}

.admin-badge-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #eef2f6;
  color: #455d6b;
  display: flex;
  align-items: center;
}

.grid-two-sets {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px;
  overflow-y: auto;
}
.grid-two-sets div {
  padding: 10px;
}
.fixes-height-members {
  height: 300px;
}

.member-ul {
  border: none;
  min-height: 260px;
}
.member-ul li {
  font-size: 13px;
  border: none;
  font-family: "poppins-regular";
  border-radius: 10px !important;
  border: 1px solid #ddd !important;
  margin-bottom: 10px;
  padding: 9px 14px !important;
}
.member-ul li div {
  color: #455e6c;
  font-size: 14px !important;
}
.member-ul .active {
  background-color: #455e6c !important;
  color: #fff !important;
  border: 1px solid #455e6c !important;
}
.member-ul .active div {
  color: #fff;
}
.light-grey {
  background-color: rgb(240, 240, 240);
}

.btn-radius {
  border-radius: 8px;
}

.action-btns {
  max-width: 20px;
  padding: 9px 22px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
}
.blue-and-grey {
  background-color: rgb(113, 160, 184);
}

@font-face {
  font-family: "Poppins-medium";
  src: url(/static/media/Poppins-Medium.f61a4eb2.ttf);
}
.authorization {
  width: calc(20% + 100px);
  padding: 20px;
  overflow: scroll;
}
@media (max-width: 1100px) {
  .authorization {
    width: calc(20% + 150px);
  }
}
@media (max-width: 800px) {
  .authorization {
    width: calc(18% + 200px);
  }
}
.authorization-logo {
  width: 40px;
  height: auto;
}
.authorization-title {
  font-size: 27px;
  padding-top: 10px;
  font-weight: 400;
  padding-left: 2px;
}
.authorization-image {
  background-image: url(/static/media/auth_bg_with_logo.cdc5a59f.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.authorization-input::-webkit-input-placeholder {
  color: white !important;
}
.authorization-input:focus {
  box-shadow: none !important;
  border: none;
}

.authorization-image {
  overflow: hidden;
}

#authImgText {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.box-login {
  box-shadow: 0px 0px 15px rgba(69, 94, 108, 0.1490196078);
  padding: 15px;
  border-radius: 10px;
}

.title-login {
  font-size: 18px;
  color: #48606D;
  padding-top: 0.6rem;
  font-family: "Poppins-regular";
  padding-bottom: 10px;
}

input.input-login {
  height: 45px;
  width: 100%;
  background-color: #f9f9f9;
  border: none;
  border-radius: 10px;
  padding-left: 40px;
  font-size: 14px;
  font-family: "poppins-regular";
}

select.input-login {
  height: 45px;
  width: 100%;
  background-color: #f9f9f9;
  border: none;
  border-radius: 10px;
  padding-left: 40px;
  font-size: 14px;
  font-family: "poppins-regular";
}

input.btn-login-login {
  background: #455E6C;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-family: "poppins-Medium";
  margin-top: 20px;
}

.bg-grey-blue {
  background-color: #9bbccd !important;
}

.bottom-flex h3 {
  font-family: "poppins-Medium";
  color: #fff;
}

.bottom-flex p {
  opacity: 0.5;
  padding-top: 10px;
  color: #fff;
  font-family: "poppins-regular";
  line-height: 28px;
}

.bottom-flex {
  padding-left: 4rem;
  padding-top: 3rem;
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
}

.icon_login {
  position: relative;
}

.icon_login svg {
  position: absolute;
  left: 12px;
  top: 13px;
  width: 18px;
  height: 18px;
}

.icon_login i {
  position: absolute;
  left: 12px;
  top: 13px;
  width: 18px;
  height: 18px;
  color: #aeaeae;
  z-index: 1;
}

.support-footer a {
  color: #455E6C;
  font-family: "poppins-Medium";
  padding-left: 14px;
  font-size: 14px;
}

.support-footer {
  padding-top: 1rem;
}

.support-footer svg path {
  fill: #455e6c;
}

.custom-control-label {
  font-size: 14px;
  color: #7A7A7A;
  font-family: "poppins-regular";
  padding-top: 2px;
}
.registration-image {
  background-image: url(/static/media/auth_bg.8142efff.png);
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.registration_box {
  box-shadow: 0px 0px 15px rgba(69, 94, 108, 0.1490196078);
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  text-align: center;
  width: 40%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  overflow-x: hidden;
}

.reg-header {
  color: #48606D;
  font-size: 25px;
  font-family: "Poppins-SemiBold";
  font-weight: 600 !important;
}

.reg-subheader {
  color: #48606D;
  font-size: 22px;
  font-family: "Poppins-SemiBold";
  font-weight: 600 !important;
}

.reg_form {
  overflow: auto;
  height: 75%;
  overflow-x: hidden;
}
.reg_form input {
  background: #f9f9f9;
  border: none;
  border-radius: 8px;
  padding: 0 10px 0 40px;
  height: 55px;
  width: 100%;
  font-family: poppins-Medium;
}
.reg_form input::placeholder {
  color: #AEAEAE;
  font-size: 14px;
  font-family: "poppins-regular";
}
.reg_form select {
  background: #f9f9f9;
  border: none;
  border-radius: 8px;
  padding: 0 10px 0 15px;
  height: 55px;
  width: 100%;
  font-family: poppins-Medium;
}
.reg_form select::placeholder {
  color: #AEAEAE;
  font-size: 14px;
  font-family: "poppins-regular";
}

.reg_form::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #3a5765;
}

.reg_form::-webkit-scrollbar-thumb:hover {
  background: #283f4b;
}

.reg_form::-webkit-scrollbar-thumb:active {
  background: #283f4b;
}

.reg-btn {
  background-color: #48606D !important;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #48606D;
  color: white;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: #f9f9f9;
  border: none;
  border-radius: 8px;
  padding: 0 10px 0 15px;
  height: 45px;
  width: 100%;
  color: #3A5765;
  font-size: 14px;
  font-family: "poppins-regular";
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.logo-view-div {
  background: #f9f9f9;
  border: none;
  border-radius: 8px;
  height: 87%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-view-div img {
  width: 50px;
  height: 50px;
}

.color-form-group {
  background: #f9f9f9;
  border: none;
  border-radius: 8px;
  padding: 0 10px 0 15px;
  height: 45px;
  width: 95%;
  font-family: poppins-Medium;
  color: #bababa;
  font-size: 14px;
}

.color-wrapper {
  width: 30px;
  height: 30px;
  overflow: hidden;
  padding: 0 !important;
  border-radius: 50%;
  margin-right: 5px;
}
.color-wrapper input {
  width: 100px;
  height: 100px;
  padding: 0 !important;
  position: relative;
  top: -17px;
  left: -10px;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

.regForm1 {
  height: 100% !important;
}

.admin-register-box {
  height: 90vh !important;
}

.icon_login_1 i {
  top: 20px !important;
}
@media only screen and (min-width: 768px) {
  .desktop-hide {
    display: none !important;
  }
}
.mobile-hide {
  grid-gap: 15px;
  gap: 15px;
}

@media only screen and (max-width: 768px) {
  .mobile-hide li,
  .mobile-hide button:not(#backButton) {
    display: none !important;
  }
  #backButton {
    margin-right: 35px !important;
  }
}
.header-logo {
  margin-right: 25px;
}

@media only screen and (max-width: 768px) {
  .header-logo {
    margin-right: 100px;
  }
}
.centerMargin {
  margin: auto;
}

.custom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  margin: 0 25px;
}

.header {
  top: 0;
  left: 0;
  right: 0;
  max-width: 100vw;
  z-index: 50;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.header .custom-container {
  max-width: unset !important;
}

.header-content button {
  margin: 0 5px !important;
}

.header-content button span {
  padding-right: 2px;
}

.header-logo {
  width: 100px;
  cursor: pointer;
}

.bidlight-logo {
  width: 20px;
  cursor: pointer;
  color: "#3a5765";
  filter: brightness(0) saturate(100%) invert(28%) sepia(51%) saturate(292%) hue-rotate(154deg) brightness(99%) contrast(93%);
}

.oneBuild-logo {
  width: 40px;
  cursor: pointer;
  color: "#3a5765";
  filter: brightness(0) saturate(100%) invert(28%) sepia(51%) saturate(292%) hue-rotate(154deg) brightness(99%) contrast(93%);
}

.header-btn {
  padding: 0 10px !important;
  height: 33px !important;
  display: flex !important;
  align-items: center;
  margin-right: 20px !important;
  text-transform: inherit !important;
  font-weight: 600 !important;
}
.header-btn img {
  margin-right: 8px;
  width: 20px;
}

.download-list {
  background-color: #f8fafc;
  border-radius: 10px;
  padding: 0px 10px;
  color: #68676c;
  font-size: 14px;
  font-family: "poppins-regular";
  margin-bottom: 10px;
  height: 40px;
  position: relative;
  padding-right: 0;
  font-weight: 600;
  border: 1px solid #ddd;
}
.download-list i {
  color: #455e6c;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  margin-right: 14px;
}
.download-list img {
  width: 38px;
}

.mobile-sidebar-btngroup {
  text-align: center;
  display: flex;
  align-items: center;
  height: 260px;
  justify-content: center;
}
.mobile-sidebar-btngroup button {
  box-shadow: none;
  font-size: 14px;
  /* border-bottom: 1px solid #ddd; */
  border-radius: 7px !important;
  text-transform: capitalize;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 25px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}
.mobile-sidebar-btngroup button i {
  font-size: 12px !important;
}
.mobile-sidebar-btngroup button svg, .mobile-sidebar-btngroup button img {
  width: 12px !important;
  fill: #455e6c;
  stroke: #455e6c;
}
.wrap-not-valid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-user-select: none;
          user-select: none;
}
.wrap-not-valid .h-not-valid {
  font-size: 32px;
  font-weight: 600;
}
.wrap-not-valid .p-not-valid {
  letter-spacing: 1.7px;
  margin-top: -7px;
}
@font-face {
  font-family: "poppins-bold";
  src: url(/static/media/Poppins-Bold.a3e0b5f4.ttf);
}
@font-face {
  font-family: "poppins-SemiBold";
  src: url(/static/media/Poppins-SemiBold.4cdacb8f.ttf);
}
@font-face {
  font-family: "poppins-Medium";
  src: url(/static/media/Poppins-Medium.f61a4eb2.ttf);
}
@font-face {
  font-family: "poppins-italic";
  src: url(/static/media/Poppins-Italic.5e956c44.ttf);
}
.active1 {
  transition: all 500ms ease;
}

.hidden {
  transform: translateY(-120px) !important;
}

/* html {
     overflow-x: hidden;
} */
div,
p,
body {
  font-family: "Nunito", sans-serif;
  /* overflow-x: hidden;
     */
}

button.owl-dot.active span {
  color: #71a0b8;
  background: #71a0b8 !important;
}

#howto {
  overflow-x: hidden !important;
}

.footer a {
  color: #fff;
  /* font-size: 20px; */
  line-height: 40px;
}

.contactinfo {
  color: #000000 !important;
  font-weight: 700 !important;
}

.step-underline {
  color: #71a0b8;
}

.step-underline > p {
  color: #000000;
  font-weight: 700 !important;
}

.step-text {
  text-decoration: none;
  position: relative;
}

.step-text:after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0px;
  color: #71a0b8 !important;
  border-width: 0 0 3px;
  border-style: solid;
}

/* Base */
body {
  line-height: 1.7;
  color: #808080;
  font-weight: 400;
  font-size: 1rem;
  /* zoom: 0.85; */
}

::selection {
  background: #000;
  color: #fff;
}

a {
  transition: 0.3s all ease;
}

a:hover {
  text-decoration: none;
}

h1,
h2 {
  font-weight: 700 !important;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Nunito", sans-serif;
}

.border-2 {
  border-width: 2px;
}

.text-black {
  color: #000 !important;
}

.bg-black {
  background: #000 !important;
}

.color-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5);
}

.color-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5);
}

.offcanvas-menu {
  position: relative;
}

.offcanvas-menu:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 21;
  background: rgba(0, 0, 0, 0.2);
}

.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
  box-shadow: none !important;
}

.btn.btn-primary {
  font-size: 22px;
  border-radius: 10px !important;
  height: 65px !important;
  border: 2px solid #455d6b;
  background: transparent !important;
  border-color: #455d6b !important;
  text-transform: capitalize;
  color: #455d6b;
  position: relative;
  min-width: 300px;
  top: 0;
  transition: 0.3s all ease;
  box-shadow: none !important;
  z-index: 12;
  line-height: 32px !important;
  text-align: left;
  padding-left: 1.5rem !important;
}

.btn.btn-primary:hover {
  top: -2px;
  background: #71a0b8 !important;
  border-color: #71a0b8 !important;
  color: #fff;
  box-shadow: 0 5px 30px -5px rgba(66, 133, 244, 0.7) !important;
}

.font-icon {
  /* position: absolute; */
  /* right: 20px; */
  margin-left: 15px;
}

.line-height-1 {
  line-height: 1 !important;
}

.bg-black {
  background: #000;
}

.form-control {
  height: 43px;
  border-radius: 8px;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.form-control:hover,
.form-control:active,
.form-control:focus {
  box-shadow: none !important;
}

.site-section {
  padding: 2em 0;
}

.site-section.site-section-sm {
  padding: 4em 0;
}

.site-section-heading {
  padding-bottom: 20px;
  margin-bottom: 0px;
  position: relative;
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .site-section-heading {
    font-size: 3rem;
  }
}
.border-top {
  border-top: 1px solid #edf0f5 !important;
}

.site-footer {
  padding: 4em 0;
  background: #333333;
}

@media (min-width: 768px) {
  .site-footer {
    padding: 8em 0;
  }
}
.site-footer .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.site-footer p {
  color: #737373;
}

.site-footer h2,
.site-footer h3,
.site-footer h4,
.site-footer h5 {
  color: #fff;
}

.site-footer a {
  color: #999999;
}

.site-footer a:hover {
  color: white;
}

.site-footer ul li {
  margin-bottom: 10px;
}

.site-footer .footer-heading {
  font-size: 16px;
  color: #fff;
}

.bg-text-line {
  display: inline;
  background: #000;
  box-shadow: 20px 0 0 #000, -20px 0 0 #000;
}

.text-white-opacity-05 {
  color: rgba(255, 255, 255, 0.5);
}

.text-black-opacity-05 {
  color: rgba(0, 0, 0, 0.5);
}

.hover-bg-enlarge {
  overflow: hidden;
  position: relative;
}

@media (max-width: 991.98px) {
  .hover-bg-enlarge {
    height: auto !important;
  }
}
.hover-bg-enlarge > div {
  transform: scale(1);
  transition: 0.8s all ease-in-out;
}

.hover-bg-enlarge:hover > div,
.hover-bg-enlarge:focus > div,
.hover-bg-enlarge:active > div {
  transform: scale(1.2);
}

@media (max-width: 991.98px) {
  .hover-bg-enlarge .bg-image-md-height {
    height: 300px !important;
  }
}
.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-image.overlay {
  position: relative;
}

.bg-image.overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.bg-image > .container {
  position: relative;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .img-md-fluid {
    max-width: 100%;
  }
}
@media (max-width: 991.98px) {
  .display-1,
  .display-3 {
    font-size: 3rem;
  }
}
.play-single-big {
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff !important;
  border-radius: 50%;
  position: relative;
  transition: 0.3s all ease-in-out;
}

.play-single-big > span {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}

.play-single-big:hover {
  width: 120px;
  height: 120px;
}

.overlap-to-top {
  margin-top: -150px;
}

.ul-check {
  margin-bottom: 50px;
}

.ul-check li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
  line-height: 1.8;
  color: #fff;
  /* font-size: 18px; */
  font-family: "poppins-SemiBold";
  /* padding-right: 180px; */
}

.ul-check li span {
  color: #b9c2c8;
  font-family: "poppins-regular";
}

.ul-check li:before {
  left: 0;
  font-size: 13px;
  top: 0.3rem;
  font-family: "icomoon";
  content: "\e5ca";
  position: absolute;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
}

.ul-check.white li:before {
  color: #fff;
}

.ul-check.success li:before {
  color: #fff;
}

.ul-check.primary li:before {
  color: #71a0b8 !important;
}

.select-wrap,
.wrap-icon {
  position: relative;
}

.select-wrap .icon,
.wrap-icon .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
}

.select-wrap select,
.wrap-icon select {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
}

/* Navbar */
.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  width: 100%;
  background: #fff;
}

.site-navbar .site-logo {
  position: relative;
  left: 0;
  top: -5px;
}

.site-navbar .site-navigation.border-bottom {
  border-bottom: 1px solid #f3f3f4 !important;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu .active {
  color: #71a0b8 !important;
  display: inline-block;
  padding: 15px 20px;
}

.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}

.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
}

.site-navbar .site-navigation .site-menu > li > a {
  padding: 15px 20px;
  color: #000;
  display: inline-block;
  text-decoration: none !important;
}

.site-navbar .site-navigation .site-menu > li > a:hover {
  color: #71a0b8 !important;
}

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: "\e313";
  font-size: 16px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-family: "icomoon";
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  border-top: 2px solid #71a0b8 !important;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #edf0f5;
  border-right: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  transition: 0.2s 0s;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  bottom: 100%;
  display: none;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  transition: 0s all;
  color: #343a40;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
  color: #71a0b8 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
  background: #f4f5f9;
  color: #25262a;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
  content: "\e315";
  right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown,
.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
  left: 100%;
  top: 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:active > a,
.site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:focus > a {
  background: #f4f5f9;
  color: #25262a;
}

.site-navbar .site-navigation .site-menu .has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children:focus > a,
.site-navbar .site-navigation .site-menu .has-children:active > a {
  color: #71a0b8 !important;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  /* vh */
  height: 100vh;
  transform: translateX(110%);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.offcanvas-menu .site-mobile-menu {
  transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s all ease;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #25262a;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}

.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  /* vh */
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #212529;
}

.site-mobile-menu .site-nav-wrap a:hover {
  color: #4285f4;
}

.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}

.site-mobile-menu .site-nav-wrap li .active {
  color: #4285f4;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: "icomoon";
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg);
  transition: 0.3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.site-mobile-menu .site-nav-wrap > li > a {
  padding-left: 20px;
  font-size: 20px;
}

.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}

.site-mobile-menu .site-nav-wrap[data-class=social] {
  float: left;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 5em;
}

.site-mobile-menu .site-nav-wrap[data-class=social] > li {
  width: auto;
}

.site-mobile-menu .site-nav-wrap[data-class=social] > li:first-child a {
  padding-left: 15px !important;
}

.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.sticky-wrapper .site-navbar {
  transition: 0.3s all ease;
}

.sticky-wrapper.is-sticky .site-navbar {
  box-shadow: 4px 0 20px -10px rgba(0, 0, 0, 0.2);
}

.sticky-wrapper .shrink {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* Blocks */
.primary-gradient,
.bg-image2.overlay:before {
  background: #455d6b;
  background: -webkit-gradient(left top, right top, color-stop(0%, #455d6b), color-stop(100%, #42e2f4));
  background: linear-gradient(to right, #455d6b 0%, #455d6b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#455D6B", endColorstr="#455D6B", GradientType=1);
}

.img-absolute {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 60%;
  transform: translateY(-50%) translateX(30%);
}

.img-absolute img {
  max-width: 100%;
}

@media (max-width: 991.98px) {
  .img-absolute {
    position: relative;
    top: auto;
    transform: translateY(0%) translateX(0%);
    margin-bottom: 30px;
  }
}
.site-block-subscribe .btn {
  padding-left: 20px;
  padding-right: 20px;
}

.site-block-subscribe .form-control {
  font-weight: 300;
  border-color: #fff !important;
  height: 80px;
  font-size: 22px;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
}

.site-block-subscribe .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}

.site-block-subscribe .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}

.site-block-subscribe .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}

.site-block-subscribe .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-style: italic;
}

.bg-custom {
  background: #f6f6f6 !important;
}

.team-member {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
}

.team-member img {
  transition: 0.3s all ease;
  transform: scale(1);
}

.team-member:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(66, 133, 244, 0.8);
  z-index: 2;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;
}

.team-member .text {
  top: 50%;
  text-align: center;
  position: absolute;
  padding: 20px;
  transform: translateY(-30%);
  transition: 0.5s all ease;
  opacity: 0;
  visibility: hidden;
  color: #fff;
}

.team-member:hover:before,
.team-member:focus:before,
.team-member:active:before {
  opacity: 1;
  visibility: visible;
}

.team-member:hover img,
.team-member:focus img,
.team-member:active img {
  transform: scale(1.1);
}

.team-member:hover .text,
.team-member:focus .text,
.team-member:active .text {
  transform: translateY(-50%);
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
  z-index: 4;
}

.feature-blocks-1 {
  position: relative;
  margin-top: -70px;
  z-index: 2;
  color: #999999;
}

.feature-blocks-1.no-margin-top {
  margin-top: 0px;
}

.feature-blocks-1 .feature-block-1 {
  position: relative;
  top: 0;
  transition: 0.3s all ease;
  overflow: hidden;
  z-index: 1;
}

.feature-blocks-1 .feature-block-1.bg {
  position: relative;
  background-size: cover;
  transform: scale(1);
  transition: 0.3s all ease-in-out;
}

.feature-blocks-1 .feature-block-1 .text {
  z-index: 10;
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease-in-out;
}

.feature-blocks-1 .feature-block-1:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  transition: 0.3s all ease-in-out;
}

.feature-blocks-1 .feature-block-1 p {
  color: #cccccc;
}

.feature-blocks-1 .feature-block-1:hover,
.feature-blocks-1 .feature-block-1:focus,
.feature-blocks-1 .feature-block-1:active {
  top: -10px;
  box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.4);
}

.feature-blocks-1 .feature-block-1:hover .text,
.feature-blocks-1 .feature-block-1:focus .text,
.feature-blocks-1 .feature-block-1:active .text {
  opacity: 1;
  visibility: visible;
}

.feature-blocks-1 .feature-block-1:hover.bg,
.feature-blocks-1 .feature-block-1:focus.bg,
.feature-blocks-1 .feature-block-1:active.bg {
  position: relative;
  background-size: cover;
  transform: scale(1.1);
  z-index: 2;
}

.feature-blocks-1 .feature-block-1:hover:before,
.feature-blocks-1 .feature-block-1:focus:before,
.feature-blocks-1 .feature-block-1:active:before {
  background: rgba(0, 0, 0, 0.9);
}

.site-blocks-vs .bg-image {
  padding-top: 3em;
  padding-bottom: 3em;
}

@media (min-width: 992px) {
  .site-blocks-vs .bg-image {
    padding-top: 5em;
    padding-bottom: 5em;
  }
}
.site-blocks-vs .image {
  width: 80px;
  flex: 0 0 80px;
}

@media (max-width: 991.98px) {
  .site-blocks-vs .image {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.site-blocks-vs .image img {
  border-radius: 50%;
}

.site-blocks-vs .image.image-small {
  width: 50px;
  flex: 0 0 50px;
}

.site-blocks-vs .country {
  color: rgba(255, 255, 255, 0.7);
}

.match-entry {
  position: relative;
  transition: 0.2s all ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.match-entry .small {
  color: #ccc;
  font-weight: normal;
  letter-spacing: 0.15em;
}

.match-entry:hover {
  transform: scale(1.05);
  z-index: 90;
  box-shadow: 0 5px 40px -5px rgba(0, 0, 0, 0.1);
}

.post-entry {
  position: relative;
  overflow: hidden;
  border: 1px solid #e6e6e6;
}

.post-entry,
.post-entry .text {
  border-radius: 4px;
  border-top: none !important;
}

.post-entry .date {
  color: #ccc;
  font-weight: normal;
  letter-spacing: 0.1em;
}

.post-entry .image {
  overflow: hidden;
  position: relative;
}

.post-entry .image img {
  transition: 0.3s all ease;
  transform: scale(1);
}

.post-entry:hover .image img,
.post-entry:active .image img,
.post-entry:focus .image img {
  transform: scale(1.2);
}

.site-block-tab .nav-item {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 0 20px;
}

.site-block-tab .nav-item:first-child {
  margin-left: 0 !important;
}

.site-block-tab .nav-item > a {
  padding-left: 0 !important;
  background: none !important;
  padding-right: 0 !important;
  border-bottom: 2px solid transparent;
  border-radius: 0 !important;
  font-weight: bold;
  color: #cccccc;
}

.site-block-tab .nav-item > a:hover,
.site-block-tab .nav-item > a.active {
  border-bottom: 2px solid #4285f4;
  color: #000;
}

.block-13,
.slide-one-item {
  position: relative;
  z-index: 1;
}

.block-13 .owl-nav,
.slide-one-item .owl-nav {
  position: relative;
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
}

.block-13 .owl-nav .owl-prev,
.block-13 .owl-nav .owl-next,
.slide-one-item .owl-nav .owl-prev,
.slide-one-item .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  padding: 20px;
  font-size: 30px;
  color: #000;
}

.block-13 .owl-nav .owl-prev.disabled,
.block-13 .owl-nav .owl-next.disabled,
.slide-one-item .owl-nav .owl-prev.disabled,
.slide-one-item .owl-nav .owl-next.disabled {
  opacity: 0.2;
}

.slide-one-item.home-slider .owl-nav {
  position: absolute !important;
  top: 50% !important;
  bottom: auto !important;
  width: 100%;
}

@media (max-width: 991.98px) {
  .slide-one-item.home-slider .owl-nav {
    display: none;
  }
}
.slide-one-item.home-slider .owl-prev {
  left: 10px !important;
}

.slide-one-item.home-slider .owl-next {
  right: 10px !important;
}

.slide-one-item.home-slider .owl-prev,
.slide-one-item.home-slider .owl-next {
  color: #fff;
  position: absolute !important;
  top: 50%;
  padding: 0px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.2);
  transition: 0.3s all ease-in-out;
  line-height: 0;
  text-align: center;
  font-size: 25px;
}

@media (min-width: 768px) {
  .slide-one-item.home-slider .owl-prev,
  .slide-one-item.home-slider .owl-next {
    font-size: 25px;
  }
}
.slide-one-item.home-slider .owl-prev > span,
.slide-one-item.home-slider .owl-next > span {
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-one-item.home-slider .owl-prev:hover,
.slide-one-item.home-slider .owl-prev:focus,
.slide-one-item.home-slider .owl-next:hover,
.slide-one-item.home-slider .owl-next:focus {
  background: black;
}

.slide-one-item.home-slider:hover .owl-nav,
.slide-one-item.home-slider:focus .owl-nav,
.slide-one-item.home-slider:active .owl-nav {
  opacity: 10;
  visibility: visible;
}

.slide-one-item .owl-dots {
  text-align: center;
}

.slide-one-item .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}

.slide-one-item .owl-dots .owl-dot span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #dee2e6;
}

.slide-one-item .owl-dots .owl-dot.active span {
  background: #4285f4;
}

.block-12 {
  position: relative;
}

.block-12 figure {
  position: relative;
}

.block-12 figure:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#cc000000", GradientType=0);
}

.block-12 .text-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 10;
}

.block-12 .text-overlay h2 {
  color: #fff;
}

.block-12 .text {
  position: relative;
  top: -100px;
}

.block-12 .text .meta {
  text-transform: uppercase;
  padding-left: 40px;
  color: #fff;
  display: block;
  margin-bottom: 20px;
}

.block-12 .text .text-inner {
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 10px;
  margin-right: 30px;
  position: relative;
}

@media (min-width: 576px) {
  .block-12 .text .text-inner {
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .block-12 .text .text-inner {
    padding: 30px 40px;
  }
}
.block-12 .text .text-inner:before {
  position: absolute;
  content: "";
  width: 80px;
  height: 4px;
  margin-top: -4px;
  background: #4285f4;
  top: 0;
}

.block-12 .text .text-inner .heading {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.block-16 figure {
  position: relative;
}

.block-16 figure .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  width: 70px;
  height: 70px;
  background: #fff;
  display: block;
  border-radius: 50%;
  opacity: 1;
  color: #4285f4 !important;
}

.block-16 figure .play-button:hover {
  opacity: 1;
}

.block-16 figure .play-button > span {
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-60%, -50%);
}

.block-25 ul,
.block-25 ul li {
  padding: 0;
  margin: 0;
}

.block-25 ul li a .image {
  flex: 0 0 90px;
}

.block-25 ul li a .image img {
  border-radius: 4px;
  max-width: 100%;
  opacity: 1;
  transition: 0.3s all ease-in-out;
}

.block-25 ul li a .text .heading {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  transition: 0.3s all ease;
  color: #999999;
}

.block-25 ul li a .meta {
  color: #4285f4;
}

.block-25 ul li a:hover img {
  opacity: 0.5;
}

.block-25 ul li a:hover .text .heading {
  color: #fff;
}

#date-countdown .countdown-block {
  color: #b3b3b3;
}

#date-countdown .label {
  font-size: 40px;
  color: #000;
}

.next-match .image {
  width: 50px;
  border-radius: 50%;
}

.player {
  position: relative;
}

.player img {
  max-width: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.player .team-number {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #4285f4;
  border-radius: 50%;
  color: #fff;
}

.player .team-number > span {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.player h2 {
  font-size: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.player .position {
  font-size: 14px;
  color: #b3b3b3;
  text-transform: uppercase;
}

.site-block-27 ul,
.site-block-27 ul li {
  padding: 0;
  margin: 0;
}

.site-block-27 ul li {
  display: inline-block;
  margin-bottom: 4px;
}

.site-block-27 ul li a,
.site-block-27 ul li span {
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.site-block-27 ul li.active a,
.site-block-27 ul li.active span {
  background: #4285f4;
  color: #fff;
  border: 1px solid transparent;
}

.site-block-feature-7 .icon {
  transition: 0.2s all ease-in-out;
  position: relative;
  transform: scale(1);
}

.site-block-feature-7:hover .icon,
.site-block-feature-7:focus .icon,
.site-block-feature-7:active .icon {
  transform: scale(1.2);
}

.unit-1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;
}

.unit-1:after {
  content: "";
  z-index: 1;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#cc000000", GradientType=0);
}

.unit-1 img {
  object-fit: cover;
  transform: scale(1);
}

.unit-1 img,
.unit-1 .unit-1-text {
  transition: 0.3s all ease-in-out;
}

.unit-1 .unit-1-text {
  z-index: 2;
  position: absolute;
  bottom: -90px;
  color: #fff;
  display: block;
  width: 100%;
}

.unit-1 .unit-1-text .unit-1-heading {
  font-size: 1.5rem;
  position: relative;
}

.unit-1 p {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;
  color: rgba(255, 255, 255, 0.5);
}

.unit-1:hover p {
  opacity: 1;
  visibility: visible;
}

.unit-1:hover .unit-1-text {
  bottom: 30px;
}

.unit-1:hover img {
  transform: scale(1.05);
}

.overlap-section {
  margin-top: -150px;
  position: relative;
  z-index: 9;
}

.unit-4 a {
  color: #71a0b8;
}

.unit-4 {
  padding: 30px;
  background: #fff;
  border-radius: 15px;
  transition: 0.3s all ease;
  position: relative;
  text-align: left;
  top: 0;
  margin-bottom: 1em;
  text-align: left;
}

/* .unit-4 .unit-4-icon {
     position: absolute;
     -webkit-transform: translateX(-50%);
     -ms-transform: translateX(-50%);
     transform: translateX(-50%);
     left: 50%;
     top: -50px;
}
 */
.unit-4 .unit-4-icon .icon-wrap {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  background-color: #455d6b;
  box-shadow: 0 4px 20px -5px rgba(66, 133, 244, 0.4);
}

.unit-4 .unit-4-icon .icon-wrap span {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  color: #fff !important;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit-4 .unit-4-icon .icon-wrap span img {
  width: 35px;
  margin: auto;
  display: flex;
}

.unit-4 h3 {
  font-size: 18px;
  color: #000;
  transition: 0.3s all ease;
  font-family: "poppins-bold";
}

.unit-4 *:last-child {
  margin-bottom: 0;
}

/* .unit-4:hover {
     background: #71A0B8 !important;
     -webkit-box-shadow: 0 4px 20px -5px rgba(66, 133, 244, 0.4);
     box-shadow: 0 4px 20px -5px rgba(66, 133, 244, 0.4);
     top: -7px;
}
 .unit-4:hover .icon-wrap {
     background-color: #fff;
}
 .unit-4:hover .icon-wrap span {
     color: #71A0B8 !important;
}
 .unit-4:hover h3 {
     color: #fff;
}
 .unit-4:hover p {
     font-weight: 300;
     color: rgba(255, 255, 255, 0.7);
}
 .unit-4:hover p a {
     color: #fff;
}
 */
.h-entry img {
  margin-bottom: 30px;
}

.h-entry .meta {
  color: #b3b3b3;
  font-size: 14px;
}

.h-entry h2 {
  font-size: 20px;
}

.overlap-left {
  margin-left: -100px;
}

@media (max-width: 991.98px) {
  .overlap-left {
    margin-left: 0px;
  }
}
.overlap-section {
  margin-top: -100px;
}

.feature-1,
.free-quote,
.feature-3 {
  background: #4285f4;
  padding: 30px 50px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}

.feature-1 .heading,
.free-quote .heading,
.feature-3 .heading {
  font-size: 22px;
  text-transform: uppercase;
  color: #fff;
}

.feature-1 .icon,
.free-quote .icon,
.feature-3 .icon {
  border: 2px solid rgba(255, 255, 255, 0.5);
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: relative;
  border-radius: 50%;
  margin: 0 auto !important;
}

.feature-1 .icon > span,
.free-quote .icon > span,
.feature-3 .icon > span {
  font-size: 35px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.free-quote {
  background: #2a75f3;
}

.free-quote .form-control {
  border: 2px solid rgba(255, 255, 255, 0.5);
  background: none;
  color: #fff;
}

.free-quote .form-control:active,
.free-quote .form-control:focus {
  border: 2px solid #fff;
}

.free-quote .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-style: italic;
}

.free-quote .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-style: italic;
}

.free-quote .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-style: italic;
}

.free-quote .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-style: italic;
}

.feature-3 {
  background: #206ff2;
}

.border-primary {
  position: relative;
}

.border-primary h2 {
  font-weight: 700 !important;
}

.border-primary:after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 80px;
  height: 3px;
  background: #4285f4;
}

.border-primary.text-center:after {
  left: 50%;
  transform: translateX(-50%);
}

.border-primary.text-left:after {
  transform: translateX(0%);
}

.testimonial {
  max-width: 800px;
  margin: 0 auto !important;
  text-align: center;
}

.testimonial blockquote {
  font-size: 1.5rem;
  font-style: italic;
}

.testimonial figure img {
  max-width: 100px;
  margin: 0 auto;
  border-radius: 50%;
}

.breadcrumb-custom {
  font-size: 1rem !important;
}

.text-muted {
  color: #ccc !important;
  font-size: 12px;
  text-transform: uppercase;
}

.how-it-work-item .number {
  width: 50px;
  height: 50px;
  background: #4285f4;
  color: #fff;
  line-height: 55px;
  font-weight: 300;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  border-radius: 50%;
  margin-bottom: 40px;
}

.how-it-work-item h2 {
  font-size: 18px;
  margin-bottom: 30px;
  color: #fff;
}

.how-it-work-item p {
  color: white;
}

.person h3 {
  font-size: 18px;
}

.ul-social-circle {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ul-social-circle li {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.ul-social-circle li:first-child {
  margin-left: 0;
}

.ul-social-circle li span {
  width: 30px;
  height: 30px;
  background: #777;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  display: inline-block;
}

.ul-social-circle li a span {
  color: #fff;
  transition: 0.3s all ease;
}

.ul-social-circle li a:hover span {
  background: #2b2b2b;
  color: #fff;
}

.custom-pagination a,
.custom-pagination span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  background: #4285f4;
  color: #fff;
}

.custom-pagination span {
  background: #e6e6e6;
  color: #000;
}

.custom-pagination .more-page {
  background: none;
  letter-spacing: 0.1em;
}

.form-search-wrap {
  background: #fff;
  border-radius: 30px;
}

.form-search-wrap .form-control {
  border: none;
}

@media (max-width: 1199.98px) {
  .form-search-wrap .form-control {
    height: 55px;
  }
}
.form-search-wrap .btn {
  border-radius: 30px;
  padding: 10px 30px;
}

@media (max-width: 1199.98px) {
  .form-search-wrap .btn {
    width: 100% !important;
    display: block;
  }
}
.popular-category {
  background: #fff;
  display: block;
  text-align: center;
  padding: 30px 10px;
  border-radius: 7px;
  background: #f8f9fa;
  position: relative;
}

.popular-category .icon {
  display: block;
  transition: 0.1s all ease;
}

.popular-category .icon > span {
  line-height: 0;
  font-size: 40px;
}

.popular-category .caption {
  color: #666666;
  transition: 0.1s all ease;
}

.popular-category .number {
  padding: 2px 20px;
  border-radius: 30px;
  display: inline-block;
  background: #e9ecef;
  color: #000;
  font-size: 14px;
  transition: 0.1s all ease;
}

.popular-category:hover {
  background: #4285f4;
  box-shadow: 0 5px 30px -5px rgba(66, 133, 244, 0.5);
}

.popular-category:hover .caption {
  color: #fff;
}

.popular-category:hover .icon {
  color: #fff;
}

.popular-category:hover .number {
  background: #2a75f3;
  color: #fff;
}

@media (max-width: 1199.98px) {
  .no-sm-border {
    border: none !important;
  }
}
.listing-item {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.listing-item:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
}

.listing-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 0.3s all ease;
}

.listing-item:hover img {
  transform: scale(1.2);
}

.listing-item .listing-item-content {
  position: absolute;
  bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 2;
  width: 100%;
}

.listing-item .listing-item-content .category {
  font-size: 12px;
  display: inline-block;
  padding: 5px 30px;
  background: #4285f4;
  border-radius: 30px;
  color: #fff;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.listing-item .listing-item-content h2 {
  font-size: 22px;
}

.listing-item .listing-item-content h2,
.listing-item .listing-item-content h2 a {
  color: #fff;
}

.listing-item .listing-item-content span {
  color: #fff;
}

.listing-item .listing-item-content .address {
  color: rgba(255, 255, 255, 0.8);
}

.listing-item .bookmark {
  position: absolute;
  right: 20px;
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(255, 255, 255, 0.3);
  transition: 0.3s all ease;
}

.listing-item .bookmark span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.listing-item .bookmark:hover {
  background: #f23a2e;
}

.listing-item .bookmark:hover span {
  color: #fff;
}

.accordion-item[aria-expanded=true] {
  color: #4285f4;
}

.social-media {
  display: inline-block;
  float: left;
  margin-bottom: 0px;
  padding: 0;
}

.social-media li {
  display: inline-block;
}

.ft-feature-1 {
  margin-bottom: 80px;
}

.ft-feature-1 .ft-feature-1-content {
  padding: 30px;
  position: relative;
  z-index: 10;
}

.ft-feature-1 h2,
.ft-feature-1 h3 {
  font-size: 20px;
  color: #fff;
}

.ft-feature-1 h3 {
  font-size: 18px;
}

.ft-feature-1 h3 .icon {
  font-size: 30px;
}

.ft-feature-1 a {
  color: #fff;
  text-decoration: underline;
}

.ft-feature-1 a:hover {
  color: #fff;
  text-decoration: none;
}

.ft-feature-1 .play {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #4285f4;
  margin-right: 1.5rem;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  text-decoration: none !important;
}

.ft-feature-1 .play span {
  color: #fff;
  display: inline;
}

.ft-feature-1 .img-feature {
  position: relative;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .ft-feature-1 .img-feature {
    margin-bottom: -80px;
  }
}
.ft-feature-1 p {
  color: #737373;
}

.box-with-humber {
  position: relative;
}

.box-with-humber .number-behind {
  font-size: 6rem;
  position: absolute;
  z-index: -1;
  top: -3rem;
  left: -3rem;
  font-weight: 900;
  color: #f2f4f5;
}

.box-with-humber h2 {
  font-size: 20px;
}

.media-1 {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.media-1:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  content: "";
  transition: 0.3s all ease;
  opacity: 0;
  visibility: hidden;
}

.media-1 img {
  object-fit: cover;
  transition: 0.3s all ease;
}

.media-1 .media-1-content {
  z-index: 4;
  top: 50%;
  position: absolute;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

.media-1 .media-1-content h2 {
  position: relative;
  color: #fff;
  font-size: 1.5rem;
  transition: 0.4s all ease-in-out;
  opacity: 0;
  visibility: hidden;
  bottom: -10px;
  margin-bottom: 0px;
}

.media-1 .media-1-content .category {
  position: relative;
  transition: 0.3s all ease-in-out;
  opacity: 0;
  bottom: -10px;
  visibility: hidden;
  color: #999999;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.media-1:hover:after {
  opacity: 1;
  visibility: visible;
}

.media-1:hover h2 {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.media-1:hover .category {
  opacity: 1;
  visibility: visible;
  bottom: 0px;
  transition-delay: 0.09s;
}

.media-1:hover img {
  transform: scale(1.05);
}

.testimonial-wrap .owl-nav {
  display: none;
}

.section-sub-title {
  font-size: 13px;
  color: #4285f4;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.section-title {
  color: #455d6b;
  font-family: "poppins-bold";
  /* font-size: 34px; */
  font-weight: 900;
}

@media (max-width: 991.98px) {
  .section-title {
    /* font-size: 30px; */
  }
}
.position-relative {
  position: relative;
}

.experience {
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: #007bff;
  padding: 20px;
}

@media (max-width: 991.98px) {
  .experience {
    bottom: 0px;
    right: 0px;
  }
}
.experience span {
  display: block;
}

.experience .year {
  font-weight: 900;
  color: #fff;
  font-size: 3rem;
  letter-spacing: normal;
  line-height: 1.2;
}

.experience span {
  letter-spacing: 0.2em;
}

.experience .caption {
  color: #fff;
  letter-spacing: 0.4em;
  text-transform: uppercase;
}

.footer-subscribe .btn {
  height: 43px;
}

.feature-big h2 {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 30px;
}

.author-box {
  box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #eee;
  border-left: 10px solid #4285f4;
}

.author-box *:last-child {
  margin-bottom: 0;
}

.author-box img {
  max-width: 50px;
}

.author-box blockquote {
  font-style: italic;
}

.shadow {
  box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
}

.h-entry h2 a {
  color: #000;
  font-size: 30px;
}

@media (max-width: 991.98px) {
  .site-logo {
    top: 0;
  }
}
@media (max-width: 991.98px) {
  .main-menu {
    display: none !important;
  }
}
.overlap-image-wrap {
  position: relative;
}

.overlap-image-wrap .image-1 {
  position: relative;
}

.overlap-image-wrap .image-2 {
  max-width: 250px;
  position: absolute;
  bottom: -50px;
  right: -50px;
  z-index: 99;
}

.bg-image2 {
  background: url(/static/media/auth_bg.8142efff.png);
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.bg-image2.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
}

.person {
  position: relative;
  text-align: center;
  background-color: white;
}

/* .last-child-team-row .col-lg-3 .person{
     margin-bottom: 0;
}
 */
.person figure {
  position: relative;
  margin-bottom: 0;
}

.person figure:before {
  display: none;
  position: absolute;
  content: "";
  z-index: 1;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  border: 2px solid #4285f4;
  opacity: 1;
  visibility: visible;
  transition: 0.3s all ease;
}

.person .bio-img {
  position: relative;
  text-align: center;
  overflow: hidden;
  margin-bottom: 30px;
}

.person .bio-img img {
  position: relative;
  transition: 0.3s all ease-in-out;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  transform: scale(1);
}

.person .bio-img .social {
  z-index: 2;
  padding: 10px 0;
  width: 100%;
  position: absolute;
  bottom: 0%;
  transform: translateY(100%);
  left: 0;
  transition: 0.3s all ease;
  background: #71a0b8;
}

.person .bio-img .social a {
  display: inline-block;
  padding: 5px;
  color: #fff;
}

.person:hover img {
  transform: scale(1.05);
}

.person:hover .social {
  bottom: 0;
  transform: translateY(0%);
}

.person:hover figure:before {
  opacity: 0;
  visibility: hidden;
}

.sub-title {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #6e98ae;
  margin-bottom: 10px;
  font-family: "poppins-bold";
  height: 42px;
}

/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-subscribe .btn:hover,
.form-subscribe .btn:active,
.form-subscribe .btn:focus {
  background-color: #4285f4 !important;
  border-color: #4285f4 !important;
  color: #fff;
}

.bg-gray-features {
  background-color: #f1f1f1;
}

.bg-right-curve {
  border-top-right-radius: 25%;
}

.footer {
  background: #455d6b;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

.subtitle {
  font-family: poppins-italic;
  color: #455d6b;
  opacity: 0.5;
  margin-top: 18px;
}

.title {
  font-size: 36px !important;
}

.lead {
  font-family: poppins-regular;
  color: #455d6b;
  opacity: 0.5;
  margin-top: 18px;
}

.font-team {
  letter-spacing: 0px;
  color: #455d6b !important;
  text-transform: capitalize;
  font-family: "poppins-SemiBold";
}

.form-register {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -2px 4px 8px rgba(27, 30, 33, 0.0588235294);
  border: none !important;
  margin-top: 25px;
  font-family: "poppins-medium";
  color: #848484 !important;
}

input.btn-login {
  background: #6e98ae 0% 0% no-repeat padding-box;
  box-shadow: -2px 4px 8px rgba(27, 30, 33, 0.0588235294);
  border-radius: 5px;
  width: 60%;
  text-align: center;
  height: 40px;
  border: none;
  color: #fff;
  font-family: "poppins-regular";
  margin-top: 30px;
}

.box-register {
  box-shadow: 0px 31px 72px rgba(0, 0, 0, 0.0784313725);
  border-radius: 15px;
}

.box-register h2 {
  /* font-size: 28px; */
  font-family: "poppins-bold";
  margin-bottom: 15px;
}

.box-register small {
  font-size: 14px;
  display: block;
  width: 70%;
  margin: auto;
}

.formReg {
  width: 75%;
  margin: auto !important;
}

.formReg .col-md-12 input {
  height: 55px;
  border-radius: 5px;
  cursor: pointer;
}

.formReg .col-md-12 input:focus {
  box-shadow: -2px 4px 12px rgba(27, 30, 33, 0.0588235294) !important;
}

.formReg .col-md-12 input:hover {
  box-shadow: -2px 4px 12px rgba(27, 30, 33, 0.0588235294) !important;
}

.formReg .col-md-12 input::placeholder {
  font-family: "poppins-regular";
  padding-left: 20px;
}

.bg-about-image {
  background-image: url(/static/media/Intersection3.b62e1e4a.png);
  background-size: cover;
  min-height: 600px;
  padding-left: 60px !important;
  padding-right: 100px !important;
}

.bg-about-image-new {
  background-image: url(/static/media/about-us.f00a57a6.png);
  background-size: cover;
  padding: 40px;
  min-height: 600px;
}

.bg-about-image:before {
  content: "";
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.font-about {
  font-family: "poppins-bold";
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.font-mission {
  font-family: "poppins-Semibold";
  margin-top: 3rem;
  margin-bottom: 3rem;
  position: relative;
}

/* Brian */
.container {
  max-width: 1200px !important;
  margin: 20px auto;
}

@media (max-width: 768px) {
  nav#navigation {
    padding: 5px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
}
nav#navigation {
  max-width: 1300px !important;
  padding: unset;
}

nav#navigation .d-flex {
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-transform: capitalize;
  color: inherit;
  text-decoration: none;
}

nav#navigation .d-flex ul li a {
  white-space: pre;
}
nav#navigation .d-flex ul li a:hover {
  color: #455d6b !important;
  font-weight: 600 !important;
  font-family: "poppins-bold";
}

ul.nav.navbar-nav.navList.pt-3 {
  padding: 0 !important;
}

ul.nav.navbar-nav.navList.pt-3 a.pl-md-5 {
  padding: 10px 0px !important;
  margin: 0 8px;
  font-family: "poppins-regular";
}

ul.sign-up.mobile-hide.navbar-nav ul.nav.navbar-nav.navList.pt-3 li:nth-child(2) {
  background: #6e98ae;
  padding: 17px 0;
  display: block;
  border-radius: 5px;
  color: #fff;
  font-family: "poppins-regular";
  cursor: pointer;
  border: 1px solid transparent;
}

/* ul.sign-up.mobile-hide.navbar-nav ul.nav.navbar-nav.navList.pt-3 li:nth-child(2){   
     background: transparent;
     color: #455D6B;
     border: 1px solid #455D6B;
} */
ul.nav.navbar-nav.navList.pt-3 {
  display: flex;
  align-items: center;
}

li.loginBtns {
  display: flex;
  height: 100%;
  grid-gap: 2px;
  gap: 2px;
}

.signup_btn a {
  /* background: #6e98ae; */
  padding: 12px;
  display: block;
  max-height: 100%;
  border-radius: 5px;
  color: #fff !important;
  font-family: "poppins-regular";
  cursor: pointer;
  border: 1px solid transparent;
  /* margin-right: 2px; */
  white-space: nowrap;
}

.btnLogin:hover {
  background: #fff;
  border: 1px solid black;
  color: inherit;
}

.signup_btn:hover a {
  color: black !important;
}

.authorization-logo {
  width: 20px;
}

nav#navigation .logo-title {
  font-size: 18px !important;
  font-family: "poppins-regular";
  padding-top: 3px !important;
  color: #fff !important;
  padding-left: 2px;
}

nav#navigation .navbar-brand {
  padding: 3px 24px;
  /* height: 100%;
  width: 100%;
  max-width: 150px; */
}

.btn.btn-primary {
  font-size: 24px;
  border-radius: 10px !important;
  height: 70px;
  border: 2px solid #455d6b;
  background: transparent !important;
  border-color: #455d6b !important;
  text-transform: capitalize;
  color: #455d6b;
  position: relative;
  min-width: 315px;
  top: 0;
  transition: 0.3s all ease;
  box-shadow: none !important;
  z-index: 12;
  line-height: 48px;
}

.unit-4.d-block {
  min-height: 358px;
  border-radius: 20px;
  padding: 30px 30px 40px 30px;
}

.unit-4 span.icon-wrap {
  margin-bottom: 16px !important;
}

.unit-4 h3 {
  margin-bottom: 20px;
  /* font-size: 24px; */
  color: #455d6b;
  transition: 0.3s all ease;
  font-family: "poppins-SemiBold";
  line-height: 35px;
}

.unit-4 p {
  font-weight: 500;
  color: #455d6b;
  opacity: 0.6;
  transition: 0.3s all ease;
  font-size: 12px;
  line-height: 25px;
  font-family: "poppins-italic";
}

.bg-about-image:before {
  content: "";
  background: rgba(69, 93, 107, 0.8);
}

p.lead-about {
  font-family: "poppins-italic";
  /* font-size: 20px; */
  border-bottom: 2px solid #fff;
  padding-bottom: 20px;
  position: relative;
  color: rgba(255, 255, 255, 0.3803921569);
}

.person {
  position: relative;
  text-align: center;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.0509803922);
  border-radius: 10px;
  padding: 32px 25px 15px 25px;
  min-height: 434px;
  margin-bottom: 5rem;
  margin-top: 1rem;
}

.person .bio-img {
  text-align: center;
  overflow: hidden;
  margin-bottom: 30px;
  position: absolute;
  top: -108px;
  left: 19%;
}

.row.justify-content-center .col-lg-3 {
  position: relative;
}

.person h2 {
  font-size: 26px;
  padding-top: 85px;
}

.person h5 {
  font-size: 26px;
  padding-top: 85px;
  min-height: 135px;
}

.person p {
  font-style: italic;
  color: grey;
}

.bg-step {
  background: #455d6b;
  height: 600px;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.bg-step .lead {
  color: #fff;
  padding-bottom: 2rem;
  width: 33%;
  margin: auto;
  /* height: 150px; */
}

.bg-step .section-title {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: "poppins-Semibold" !important;
}

.stepper-item-outer:nth-of-type(odd) .stepper-item-inner {
  width: 80px !important;
  height: 80px !important;
  transition: 0.2s;
}

.stepper-item-outer:nth-of-type(odd) {
  width: 80px !important;
  height: 80px !important;
  border: none !important;
}

.stepper-item-inner-active {
  background-color: #fff !important;
}

.stepper-item-inner-completed {
  background-color: white !important;
}

.stepper-item-outer:nth-of-type(odd) .stepper-item-inner.stepper-item-inner-active {
  transform: scale(1.4);
}

.step-button {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  width: 100% !important;
  bottom: 0;
}

.step-button input {
  letter-spacing: 0px;
  color: #fbfbfb;
  background: transparent;
  border: none;
  font-size: 24px;
  font-family: "poppins-Semibold";
}

.about-features {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 6rem !important;
  position: relative !important;
  top: 70px;
}

h3.head-bidlight {
  text-align: left;
  letter-spacing: 0px;
  color: #455d6b;
  font-family: "poppins-Medium";
  font-size: 44px;
}

h3.head-bidlight b {
  font-family: "poppins-bold";
}

p.lead-bidlight-estimate {
  letter-spacing: 0px;
  color: #455d6b;
  opacity: 0.5;
  font-family: "poppins-italic";
  /* font-size: 18px; */
  /* padding-right: 150px; */
  padding-top: 1rem;
}

.img-lead {
  position: relative;
  /* top: 90px; */
  width: 75%;
  z-index: 9;
}

.stepper-container .stepper-item .stepper-title {
  left: 34px;
  position: absolute;
  display: inline-block;
  width: auto !important;
  max-width: auto !important;
  min-width: auto !important;
  transform: translate(-39%, 5px);
  text-align: center !important;
  color: #455d6b !important;
  font-size: 12px !important;
  top: 15px;
  text-align: center;
  right: 0;
  font-size: 18px !important;
  font-family: "poppins-Semibold" !important;
  line-height: 22px;
  pointer-events: none;
}

.stepper-container {
  /* width: 1440px; */
  margin: 0 auto;
}

.stepper-container .stepper-item .stepper-title-active {
  color: #455d6b !important;
}

.img-custom-step {
  position: absolute;
  left: 0;
  right: 0;
  width: 450px;
  max-width: 40%;
}

.box-vacate {
  height: 250px;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  .box-vacate {
    height: 60px;
  }
}
.step-button svg path {
  fill: #fff;
}

.step-button svg {
  font-size: 24px;
  margin-left: 10px;
  margin-right: 10px;
}

#pricing-section .row .col-lg-3 {
  padding: 10px;
}

#pricing-section > div > ul > li.react-multi-carousel-item:not(.react-multi-carousel-item--active) {
  height: 0;
}

.pricing-box {
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.0509803922);
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 12px 14px 32px;
  padding-bottom: 32px;
  transition: all 500ms ease 0s;
  cursor: pointer;
  height: 658px;
}

.pricing-box h3 {
  color: #455d6b;
  font-family: "poppins-Bold";
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pricing-box p {
  font-size: 12px;
  font-family: "poppins-italic";
  padding-bottom: 1rem;
}

.pricing-montly h2 {
  color: #455d6b;
  text-transform: capitalize;
  font-family: "poppins-bold";
  /* font-size: 38px; */
  letter-spacing: 0;
}

.pricing-montly span {
  color: #455d6b;
  text-transform: capitalize;
  opacity: 0.6;
  font-size: 16px;
  justify-content: center;
  font-family: "poppins-regular";
  line-height: 0;
  position: relative;
  bottom: 8px;
}

.pricing-montly {
  padding-bottom: 1rem;
}

ul.list-pricing-features {
  padding: 0;
  color: #8d9ca4;
  opacity: 1;
  line-height: 28px;
  font-family: "poppins-regular";
  padding-bottom: 18px;
  min-height: 340px;
  /* min-height: 600px; */
}

ul.trialtext {
  color: #8d9ca4;
  opacity: 1;
  font-family: "poppins-regular";
  padding-bottom: 18px;
}

ul.list-pricing-features li {
  list-style: none;
  font-size: 12px;
  background-image: url(/static/media/Component531.a5b6189a.svg);
  background-size: 15px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  padding-left: 40px;
  /* line-height: 40px; */
}

.pricing-form-image {
  background-image: url(/static/media/landing-pricing-image.1fe38b78.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 100%;
  background-size: 100% 100%;
  border-radius: 10px;
}

.pricing-form-title {
  line-height: 0.5 !important;
  margin-top: -5px;
  color: #d6d8d9;
  opacity: 0.5;
  padding-bottom: 1rem;
  font-family: poppins-italic;
}

.info-container {
  background: rgb(69, 93, 107);
  background: linear-gradient(180deg, rgb(69, 93, 107) 0%, transparent 40%);
  width: 104.5%;
  margin: 0 -15px;
  padding: 0.1px 1.5rem;
  height: 80%;
  border-radius: 10px;
}

.btn-choose {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.0509803922);
  border-radius: 10px;
  width: 100%;
  border: 2px solid #6e98ae;
  height: 50px;
  display: block;
  text-align: center;
  line-height: 48px;
  font-family: "poppins-Medium";
  color: #6e98ae !important;
}

.pricing-box.bg-gradient {
  position: relative;
  background: linear-gradient(180deg, #6e98ae 0%, #374c57 100%) !important;
  color: #fff;
}

.pricing-box h3 {
  font-size: 1.3rem;
}

.pricing-box.bg-gradient h3 {
  color: #fff;
}

.pricing-box.bg-gradient p {
  color: #fff;
}

.pricing-box.bg-gradient.pricing-montly {
  color: #fff;
}

.pricing-box.bg-gradient ul.list-pricing-features {
  color: #fff;
}

.pricing-box.active:hover .btn-choose {
  background: #71b8ad;
  color: #fff !important;
  border: 1px solid #71b8ad;
}

.bg-gradient .btn-choose {
  background: #71b8ad;
  color: #fff !important;
  border: 1px solid #71b8ad;
}

.pricing-box.bg-gradient .pricing-montly h2 {
  color: #fff;
}

.pricing-box.bg-gradient .pricing-montly span {
  color: #fff !important;
}

.pricing-box.active:hover {
  background: transparent linear-gradient(180deg, #6e98ae 0%, #374c57 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  transition: all 500ms ease 0s;
}

.pricing-box.active:hover .pricing-montly h2 {
  color: #fff;
}

.pricing-box.active:hover .pricing-montly span {
  color: #fff !important;
}

.pricing-box.active:hover ul.list-pricing-features {
  color: #fff;
}

.pricing-box.active:hover h3 {
  color: #fff;
}

.pricing-box.active:hover p {
  color: #fff;
}

label.bestOffer {
  font-size: 10px;
  background: #71b8ad;
  padding: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: absolute;
  right: 0;
}

.feature-shape-img {
  position: absolute;
  right: -19%;
  top: -160px;
  width: 600px;
  animation: rotation 50s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.stepper-item-inner-future {
  background-color: #84939d !important;
}

.stepper-item-outer:nth-of-type(even) {
  height: 2px !important;
  width: 9% !important;
  border: unset !important;
  border-bottom: 4px dashed white !important;
}

.stepper-item-outer:nth-of-type(odd) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='5' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
}

.feature-row-width {
  width: 80%;
  margin: auto !important;
}

#howto-section .lead {
  font-size: unset;
}

.stepper-bg-circle {
  position: absolute;
  left: -19%;
  top: -160px;
  width: 600px;
  max-width: 100vw;
  animation: rotation 20s infinite linear;
}

.about-bg-logo {
  width: 75%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

.about-bg-circle {
  position: absolute;
  right: -19%;
  top: -160px;
  width: 456px;
  animation: rotation 30s infinite linear;
}

.team-inner-container {
  position: relative;
  z-index: 2;
}

.team-bg-container {
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  top: 345px;
  z-index: 1;
}

.team-bg-container img {
  width: 70%;
  margin: auto;
  display: block;
  animation: rotation 30s infinite linear;
}

@media (min-width: 1775px) {
  .team-bg-container img {
    width: 46%;
    margin: auto;
    display: block;
    animation: rotation 100s infinite linear;
  }
}
.teams-linkedin-img {
  width: 50px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 0;
  margin: auto;
  left: 0;
}

.bg-grey-300 {
  background-color: #f9f9f9;
}

.licence-btn {
  height: 50px;
  background-color: #71b8ad !important;
  outline: none;
  border: none;
  box-shadow: none;
  width: 50%;
  color: white;
  margin-top: 1.8rem;
}

.licence-btn-cancel {
  height: 50px;
  background-color: #455d6b !important;
  outline: none;
  border: none;
  box-shadow: none;
  width: 50%;
  color: white;
  margin-top: 1.8rem;
}

.social-icons {
  margin-right: 14px;
}

.mail-icon {
  width: 25px;
  margin-right: 14px;
}

.banner-btn {
  display: flex;
  width: 238px;
  height: 68px;
  padding: 20px 17px 21px 17px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px !important;
  border: 2px solid #71a0b8;
  color: white;
  font-size: 20px;
}
.banner-btn img {
  width: 20px;
  margin-left: 20px;
}

.second-banner-btn {
  margin-left: 1rem;
}

.bg-secondary-bid {
  background-color: #71a0b8;
}

.affilation-img-container {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.affilation-img-container img {
  width: 270px;
}

.landing-heading {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  color: #1d1d1d;
  text-transform: capitalize;
}

.affiliation_arrow_group {
  position: absolute;
  top: 0;
  width: 95%;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  margin: auto;
}
.affiliation_arrow_group button {
  border: 1px solid #ddd;
  border-radius: 50% !important;
  padding: 10px;
  background-color: transparent;
}
.affiliation_arrow_group button:first-child {
  left: 20px;
}
.affiliation_arrow_group button:last-child {
  right: 20px;
}

.end-to-end-container {
  margin: 140px 0;
  text-align: center;
  margin-bottom: 0px;
}
.end-to-end-container .content-img {
  width: 65%;
  margin-top: 80px;
}
.end-to-end-container .plan-do-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}
.end-to-end-container .plan-do-div img {
  width: 100%;
}
.end-to-end-container .plan-do-div .plan {
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  background-color: #91bacb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25.139px;
  margin-right: -25px;
  margin-top: -34px;
  z-index: 9;
  font-family: "Inter";
  font-weight: 600;
}
.end-to-end-container .plan-do-div .do {
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  background-color: #91bacb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25.139px;
  margin-left: -25px;
  margin-top: -34px;
  z-index: 9;
  font-family: "Inter";
  font-weight: 600;
}

.testimonial-container {
  background-color: #2e4651;
  padding: 96px 17%;
  margin: auto;
  position: relative;
}
.testimonial-container .testimonial-div {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}
.testimonial-container .testimonial-div .client-img {
  width: 280px;
  height: 300px;
  margin-right: 120px;
  border-radius: 15px;
  margin-left: 3px;
}
.testimonial-container .testimonial-div .star-div {
  display: flex;
  margin-bottom: 20px;
}
.testimonial-container .testimonial-div .star-div img {
  margin: 3px;
  width: 16px;
}
.testimonial-container .testimonial-div .main-text {
  font-family: "Inter";
  font-size: 34px;
  font-weight: 500;
  color: white;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
.testimonial-container .testimonial-div .testimonial_info_text p {
  margin-bottom: 0;
  color: white;
}
.testimonial-container .testimonial-div .testimonial_info_text p:first-child {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.testimonial-container .testimonial-div .testimonial_info_text p:last-child {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.testimonial-container .testimonial_arrow_group {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  right: 19%;
  margin: auto;
  grid-gap: 20px;
  grid-gap: 20px;
  gap: 20px;
  margin-top: -104px;
}
.testimonial-container .testimonial_arrow_group button {
  border: 1px solid #ddd;
  border-radius: 50% !important;
  padding: 10px;
  background-color: rgb(234, 236, 240);
}
.testimonial-container .testimonial_arrow_group button:first-child {
  left: 20px;
}
.testimonial-container .testimonial_arrow_group button:last-child {
  right: 20px;
}

.bg-dark-bid {
  background-color: rgb(58, 87, 101) !important;
}

.saas-navigation-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  border-top: 3px solid #ddd;
  width: 80%;
  margin: auto;
  margin-top: 50px;
}
.saas-navigation-container .inner-container {
  text-align: center;
  padding: 20px;
  cursor: pointer;
}
.saas-navigation-container .inner-container .tracking_number {
  border-radius: 50%;
  font-size: 15px;
  color: white;
  background-color: rgb(156, 156, 156);
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.saas-navigation-container .inner-container .head {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
.saas-navigation-container .inner-container .subhead {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: rgb(71, 84, 103);
}
.saas-navigation-container .active {
  border-top: 3px solid rgb(58, 87, 101) !important;
  margin-top: -3px;
}
.saas-navigation-container .active .tracking_number {
  background-color: rgb(58, 87, 101) !important;
}

.banner-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.saas-img {
  max-width: 1500px !important;
  margin: 50px 0 !important;
  width: 40%;
}

.saas-features-container {
  background-color: #2e4651;
  padding: 96px 17%;
  margin: auto;
  position: relative;
}
.saas-features-container .feature-container {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
.saas-features-container .feature-container .img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 20px;
}
.saas-features-container .feature-container .img_container img {
  width: 20px;
}
.saas-features-container .feature-container p:nth-child(2) {
  text-transform: capitalize;
}

.bim-services-container {
  margin: 80px auto;
  width: 80%;
}

.bim-section {
  background-color: rgb(249, 250, 251);
  border-radius: 24px;
  display: flex;
  min-height: 800px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;
}
.bim-section p:first-child {
  color: var(--Gray-900, #101828);
  /* Text xl/Semibold */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.bim-section p:last-child {
  color: var(--Gray-600, #475467);
  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.staff-container {
  background-color: #f9fafb;
  padding: 96px 15%;
  margin: auto;
  position: relative;
}
.staff-container .augmentation-div {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}
.staff-container .augmentation-div .left-side {
  width: 80%;
  border-left: 5px solid #ddd;
}
.staff-container .augmentation-div .left-side .inner-div {
  padding: 15px 20px;
  margin-left: -5px;
  cursor: pointer;
}
.staff-container .augmentation-div .left-side .inner-div p:first-child {
  color: var(--1-d-1-d-1-d, #1d1d1d);
  /* Text xl/Semibold */
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  margin-bottom: 8px;
  text-transform: capitalize;
}
.staff-container .augmentation-div .left-side .inner-div p:last-child {
  color: var(--Neutral-Colors-Muted-Text---Elements, #8f98aa);
  /* Text md/Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 0;
}
.staff-container .augmentation-div .left-side .active {
  border-left: 5px solid #3a5765;
  background-color: #e4edf2;
}
.staff-container .augmentation-div .aug-img {
  width: 100%;
  margin-left: 49px;
}
.staff-container .augmentation-div .staff-img-text {
  color: white;
  position: absolute;
  z-index: 1;
  top: 20px;
  font-family: Inter;
  font-size: 57px;
  font-style: normal;
  font-weight: 600;
  left: 70px;
  text-transform: capitalize;
}

.banner-content-div {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .saas-navigation-container {
    grid-template-columns: none;
  }
  .landing-heading {
    font-size: 30px;
  }
  .staff-container {
    padding: 96px 8%;
  }
  .staff-container .augmentation-div {
    display: block;
  }
  .staff-container .augmentation-div .left-side {
    width: 100%;
  }
  .staff-container .augmentation-div .aug-img {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }
  .staff-container .augmentation-div .staff-img-text {
    color: white;
    position: absolute;
    z-index: 1;
    top: 75px;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    left: 30px;
    text-transform: capitalize;
  }
  .pricing-box {
    margin-bottom: 20px;
  }
  .footer-head {
    margin-top: 20px;
  }
  li.loginBtns {
    display: block;
  }
  .btnLogin {
    justify-content: center;
  }
  .banner-btn-div {
    display: block;
  }
  .banner-container .main-text {
    font-size: 44px !important;
    font-weight: 600;
    line-height: 1.2;
  }
  .banner-content-div {
    width: 75%;
  }
  .banner-btn {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .affilation-img-container img {
    width: 118px;
  }
  .testimonial-container {
    padding: 96px 8% 50px 8%;
  }
  .testimonial-container .testimonial-div {
    display: block;
  }
  .testimonial-container .testimonial-div .client-img {
    width: 150px;
    height: 150px;
    margin-right: 0;
    border-radius: 15px;
    margin-left: 3px;
    margin-bottom: 26px;
  }
  .second-banner-btn {
    margin-left: auto;
    margin-right: auto;
  }
  .bannerImg {
    width: 90% !important;
    margin-top: 20px;
  }
  .end-to-end-container .content-img {
    width: 100%;
  }
  .end-to-end-container {
    text-align: center;
    margin: 90px 0 0;
  }
  .testimonial-container .testimonial-div .main-text {
    font-size: 21px;
  }
  .bim-services-container {
    margin: 80px auto;
    width: 85%;
  }
  .wrapper-video {
    position: relative;
  }
  .wrapper-video iframe {
    width: 82% !important;
    height: 188px !important;
  }
  .wrapper-video .playpause {
    position: absolute;
    top: 40%;
    bottom: 50%;
    margin: auto;
    left: 0;
    right: 0;
    cursor: pointer;
  }
  .wrapper-video .playpause img {
    width: 60px !important;
  }
}
.wrapper-video {
  position: relative;
}
.wrapper-video iframe {
  width: 1000px;
  height: 560px;
}
.wrapper-video .playpause {
  position: absolute;
  top: 40%;
  bottom: 50%;
  margin: auto;
  left: 0;
  right: 0;
  cursor: pointer;
}
.wrapper-video .playpause img {
  width: 100px;
}

div.list-pricing-features {
  padding: 0;
  color: #8d9ca4;
  opacity: 1;
  line-height: 28px;
  font-family: "poppins-regular";
  padding-bottom: 18px;
  display: grid;
  grid-template-columns: 40% 50%;
  /* min-height: 600px; */
}

div.list-pricing-features div {
  list-style: none;
  font-size: 17px;
  background-image: url(/static/media/Component531.a5b6189a.svg);
  background-size: 15px;
  background-position: 0% 50%;
  background-repeat: no-repeat;
  padding-left: 40px;
  margin-bottom: 25px;
  /* line-height: 40px; */
}

.pricing-box.active:hover div.list-pricing-features div {
  color: white;
}

.img-autoEstimate {
  width: 100%;
  margin-top: 70px !important;
}
.font-size-1 {
  font-size: 14px;
}

.font-size-2 {
  font-size: 18px;
}

.font-size-3 {
  font-size: 22px;
}

.font-size-4 {
  font-size: 26px;
}

.cursor-pointer {
  cursor: pointer;
}

.user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

body {
  max-width: 100vw;
  width: 100%;
}

.disabled,
:disabled {
  pointer-events: none !important;
  filter: brightness(90%);
  cursor: all-scroll;
}

p {
  color: black;
}

.resize-horizontal {
  resize: horizontal;
}

.btn-fixed-size {
  width: 200px;
}

.w-49 {
  width: 49%;
}

* {
  outline: none;
}

.pl-8 {
  padding-left: 50px;
}

.pl-12 {
  padding-left: 100px;
}

.pt-10 {
  padding-top: 56px;
}

.pt-12 {
  padding-top: 75px;
  padding-bottom: 50px;
}

.min-h-100 {
  min-height: 100vh;
}

.w-95 {
  width: 95%;
}

.left-0 {
  left: 0;
}

.mdb-btn {
  text-transform: inherit !important;
  font-size: 13px !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-style-inherit {
  text-transform: inherit !important;
}

button {
  border-radius: 5px !important;
}

.bg-bid {
  background-color: #3a5765 !important;
}

.border-bid {
  border: 1px solid #3a5765 !important;
}

.text-bid {
  color: #3a5765 !important;
}

.text-success {
  color: rgb(59, 135, 62) !important;
}

.text-warning {
  color: rgb(255, 165, 0) !important;
}

.text-danger {
  color: rgb(178, 34, 34) !important;
}

.bg-success {
  background-color: #3b873e !important;
}

.bg-warning {
  background-color: rgb(255, 165, 0) !important;
}

.bg-danger {
  background-color: rgb(178, 34, 34) !important;
}

.bg-light-bid {
  background-color: #e9f1f5;
}

.bg-light-success {
  background-color: #eefaea !important;
}

.bg-light-warning {
  background-color: #fff6e6 !important;
}

.bg-light-danger {
  background-color: #f8e9e9 !important;
}

.personal-area {
  position: relative;
  background-color: #f6f7fb;
}

.border-top-none {
  border-top: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.btn-tool-group {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
}
.btn-tool-group button {
  background-color: #91bacb;
  min-height: 30px;
  height: 30px;
  padding: 0 8px;
  display: flex;
  border: none;
  margin-right: 8px;
  font-size: 13px;
  font-family: "roboto";
  color: white;
  align-items: center;
  letter-spacing: 0.15px;
}
.btn-tool-group button svg {
  width: 15px;
  height: 15px;
  color: white;
  margin-right: 5px;
}
.btn-tool-group button:last-child {
  margin-right: 0;
}

.white-space-pre {
  white-space: pre;
}

.my-heading {
  font-size: 18px;
  font-family: "poppins-Medium";
  color: #000;
  margin: 0;
  letter-spacing: -0.5px;
  white-space: nowrap;
}

.projActionButtons {
  grid-gap: 5px;
  gap: 5px;
}

.search-bar {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 15px;
  width: 400px;
  max-width: 100%;
  min-height: 30px;
  height: 30px;
  background-image: url(/static/media/search.f6b7c5be.svg);
  background-position: 97% 50%;
  background-repeat: no-repeat;
}
.search-bar::placeholder {
  color: #ddd;
  font-size: 15px;
}

.bg-light-100 {
  background: #f9f9f9;
}

.inner-header-position {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  width: 100%;
  z-index: 9;
  background-image: linear-gradient(#f9f9f9, #fff);
  margin-top: -2px;
  box-shadow: 0px 0px 7px #ddd;
}

@media only screen and (max-width: 768px) {
  .inner-header-position {
    top: 40px;
  }
}
.adminTable {
  width: 100%;
  margin: auto;
  height: 80vh;
  overflow: auto;
}

.card {
  font-weight: 400;
  border: 0;
  box-shadow: 0px 0px 15px #ddd !important;
  border-radius: 8px !important;
  overflow: hidden;
}

.card-footer {
  background: #fff !important;
}

.card-title {
  font-family: "poppins-Bold";
  font-size: 0.9rem;
  color: #3a5765;
  margin-bottom: 10px !important;
  text-transform: capitalize;
}

.card-text {
  font-size: 0.8rem !important;
  font-weight: 400;
  color: #3a5765;
  font-family: "poppins-Regular";
}
.card-text .box {
  padding: 10px 0;
}

.personal-area .container {
  max-width: 1260px !important;
}

.card-price {
  font-size: 12px;
  font-weight: 500;
  color: #747373;
  font-family: "poppins-Regular";
}

.capitalize {
  text-transform: capitalize;
}

.card-button {
  width: 50%;
  margin: 0 10px;
  font-family: "poppins-regular";
  font-size: 13px !important;
  height: 35px;
  padding: 0 !important;
}

.card-footer {
  border-top: none !important;
}

.refresh-btn {
  padding: 8px 15px !important;
}
.refresh-btn svg {
  margin-right: 8px;
}

.historyCards {
  max-width: 1300px;
  margin: auto;
}

.timelines-page {
  background-color: #f6f7fb;
  min-height: 110vh;
}

.modal-dialog .modal-content {
  border-radius: 8px !important;
  padding: 10px 28px;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-family: "poppins-Medium";
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.modal-header {
  border: none !important;
}

.modal-header .close {
  padding: 0px 5px !important;
  margin: 0 0 0 auto !important;
  border: none !important;
}

.close:focus {
  outline: none !important;
}

.mdb-input {
  border: 1px solid #ddd !important;
  border-radius: 7px !important;
  padding-left: 34px !important;
}

.md-form.form-sm label {
  margin-left: 17px;
  font-weight: 600;
}

.md-form label {
  top: -3px !important;
}

.md-form label.active {
  background-color: white !important;
}

.radius-btn {
  border-radius: 8px;
}

.modal-side-table {
  width: 100%;
}
.modal-side-table table {
  width: 20%;
}

.tree-item {
  position: relative;
}

.tree-item div {
  font-family: "poppins-medium" !important;
  font-size: 11px;
  border: 1px solid #ddd;
  margin: 0;
  height: 90%;
  border-radius: 5px;
  padding-left: 10px;
  background-color: #fbfbfb;
  margin-right: 10px;
  position: relative;
  width: 100%;
}

.tree-item i:last-child {
  position: absolute;
  right: 10px;
  background: #fbfbfb;
}

.grey.lighten-4 {
  background-color: #f9f9f9 !important;
}

.component-sign-label {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.component-sign-label i:first-child {
  font-size: 12px;
  padding: 5px 6px;
  color: white;
  border-radius: 50%;
}
.component-sign-label i:last-child {
  font-size: 6px;
  padding: 0;
  position: absolute;
  top: -3px;
  border-radius: 50%;
  background-color: #fff;
  right: -5px;
  height: 15px;
  width: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-bid-success {
  background-color: #87d7d2 !important;
}

.bg-bid-danger {
  background-color: #fb9292 !important;
}

.bid-border-success {
  border: 3px solid #87d7d2;
}

.bid-border-danger {
  border: 3px solid #fb9292;
}

.bid-success-text {
  color: #87d7d2;
}

.bid-danger-text {
  color: #fb9292;
}

.bg-existing {
  background-color: #b871a0;
  border: 1px solid #b871a0;
}

.bg-demolition {
  background-color: #c5842f;
  border: 1px solid #c5842f;
}

.project-admin-header {
  font-family: "Poppins-medium";
  color: #2c3e50;
  letter-spacing: -1px;
  padding: 0 3px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 15px;
}
.project-admin-header:first-child {
  margin-right: 10px;
}
.project-admin-header img {
  margin-right: 10px;
  width: 20px;
}

.delete-timeline-label {
  font-size: 20px;
  font-family: "poppins-Medium";
  text-align: center;
  min-height: auto !important;
}

.border-none {
  border: none !important;
}

.project-item-date {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  padding: 3px;
  color: white;
  background-color: #455e6c;
  font-size: 13px;
  font-family: "poppins-regular";
  padding: 3px 10px;
  box-shadow: 0px 0px 10px black;
}

.spinner-text {
  font-family: "poppins-regular";
  color: #3a5765;
  font-size: 12px;
}

.btnFlex {
  width: 46%;
  display: inline-block;
  margin: 5px !important;
  font-family: "poppins-regular";
  font-size: 12px !important;
  height: 35px;
  padding: 0 !important;
  border-radius: 8px !important;
}
.btnFlex:last-child {
  margin-right: 0;
}
.btnFlex:first-child {
  margin-left: 0;
}

.spinner-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 99999;
}

.dlt-btnFlex {
  width: 20%;
  display: inline-block;
  margin: 5px !important;
  font-family: "poppins-regular";
  font-size: 13px !important;
  height: 35px;
  padding: 0 !important;
  border-radius: 8px !important;
}

.expiration-box {
  border: none;
}
.expiration-box li {
  border: 1px solid #ddd !important;
  border-radius: 6px !important;
  margin: 10px;
  height: 58px;
  font-size: 15px;
  display: flex;
  align-items: center;
}
.expiration-box li.active {
  background-color: #3a5765 !important;
  border: 1px solid #3a5765 !important;
  margin: 10px !important;
}

.expiration-modal > .modal-content > .modal-body {
  min-height: 100px !important;
  padding-bottom: 0px !important;
}

.expiration-btn {
  height: 40px !important;
  margin: 10px !important;
}

.user-name-container {
  border: 1px solid #3a5765 !important;
  border-radius: 5px;
  padding: 3px 10px;
  height: 32px;
  display: flex;
}
.user-name-container p {
  margin: 0;
  font-family: "poppins-regular";
  font-weight: 600;
  color: #3a5765;
  line-height: 1.2;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.user-name-container p img {
  margin-right: 10px;
  width: 15px;
}

.view-switch-container {
  padding: 0 10px;
  grid-gap: 10px;
  gap: 10px;
}
.view-switch-container > div {
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 3px;
  border: 1px solid #ddd;
}
.view-switch-container > div.active, .view-switch-container > div:hover {
  background: #455e6c;
}
.view-switch-container > div.active svg path, .view-switch-container > div:hover svg path {
  fill: white;
}
.view-switch-container > div.active i, .view-switch-container > div:hover i {
  color: white;
}

.head-filter-icon {
  cursor: pointer;
  height: 34px;
  width: 34px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  background: transparent !important;
}

.member-list {
  border: 1px solid #ddd !important;
  margin-bottom: 5px;
  border-radius: 10px !important;
  align-items: center;
}
.member-list div {
  font-size: 15px !important;
}
.member-list span {
  font-size: 15px !important;
}

.member-list.active {
  background-color: rgb(68, 93, 107) !important;
  color: white !important;
}
.member-list.active div {
  font-size: 15px !important;
}
.member-list.active span {
  font-size: 15px !important;
}

.user-projects {
  background-color: #f6f7fb;
}

.upsideDown-svg svg {
  transform: scaleX(-1);
}

.modal-body {
  min-height: 365px;
  overflow: auto;
  max-height: 550px;
}

.modal-body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #3a5765;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: #283f4b;
}

.modal-body::-webkit-scrollbar-thumb:active {
  background: #283f4b;
}

.footer-desc {
  color: #a2aeb5;
  font-style: italic;
  margin: 50px 0;
}

.footer-social-container {
  display: flex;
  align-items: center;
}
.footer-social-container img {
  width: 45px;
  margin-right: 10px;
}

.footer-logo {
  width: 50px;
}

.logo-title-footer {
  font-size: 40px !important;
  font-family: "poppins-bold";
  color: #fff !important;
  padding-left: 6px;
  position: relative;
  bottom: 5px;
}
.logo-title-footer span {
  font-family: "poppins-regular";
}

.footer-head {
  font-family: "poppins-SemiBold";
  color: white;
  font-size: 20px;
}

.footer-links-div a {
  display: block;
  font-size: 0.8em;
  color: #a2aeb5 !important;
  font-family: "Poppins-medium";
  display: flex;
}
.footer-links-div a:hover {
  color: #a2aeb5;
  opacity: 0.8;
}
.footer-links-div a svg {
  color: #a2aeb5;
  margin-right: 10px;
  width: 25px;
  height: 21px;
  margin-top: 10px;
}

.all-right-reserve {
  display: block;
  color: #a2aeb5;
  font-size: 16px;
  font-family: "Poppins-medium";
  text-align: center;
  margin-top: 30px;
}

.footer-container {
  max-width: 100%;
  width: 100%;
  padding: 0 8%;
  margin-left: auto;
  margin-right: auto;
}

.overflowX-hidden {
  overflow-x: hidden;
}

.btn-radius {
  border-radius: 5px !important;
}

.kanban-box {
  width: 100%;
  overflow: auto;
  height: 84vh;
}

.karban-container::-webkit-scrollbar {
  display: block;
}

.karban-container {
  grid-gap: 10px;
  gap: 10px;
  background-color: #fafafa;
  padding: 20px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
}
.karban-container .board-box {
  min-width: 19.5%;
  background-color: rgb(246, 246, 246);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-top: 18px;
  min-height: 100%;
  transition: 0.2s;
  padding-top: 5px;
}
.karban-container .board-box:hover {
  box-shadow: 0px 0px 10px #ddd;
  transition: 0.2s;
}
.karban-container .board-box .board-box-header {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-bottom: 3px solid;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins-medium";
  text-transform: capitalize;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: rgb(246, 246, 246);
  height: 56px;
  z-index: 1;
}
.karban-container .board-box .board-box-header .color-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 100%;
}
.karban-container .board-box .board-box-header .count {
  margin-left: auto;
  font-size: 12px;
  width: 24px;
  border-radius: 100%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.karban-container .board-box .card-container {
  grid-gap: 10px;
  gap: 10px;
  flex-grow: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.karban-container .board-box .kanban-card {
  padding: 20px 16px;
  background-color: white;
  border-radius: 15px;
}
.karban-container .board-box .kanban-card .card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.karban-container .board-box .kanban-card .card-title p {
  font-size: 14px;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
  font-family: "Poppins-medium";
  width: 70%;
  white-space: pre;
  margin-bottom: 0;
  color: black;
}
.karban-container .board-box .kanban-card .card-title .dots {
  width: 20px;
}
.karban-container .board-box .kanban-card.dragging {
  transform: rotate(2.007deg);
  box-shadow: 8.0005016327px 19.0011901855px 51.0031967163px 0px rgba(0, 0, 0, 0.3882352941);
}
.karban-container .board-box .kanban-card .card-detail-container {
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
}
.karban-container .board-box .kanban-card .card-detail-container .item {
  width: 50%;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins-medium";
  color: rgb(114, 114, 114);
}
.karban-container .board-box .kanban-card .card-detail-container .item .d-icon {
  width: 16px;
}
.karban-container .board-box .kanban-card .card-detail-container .item > div {
  color: #727272;
  text-align: center;
  background: rgb(250, 250, 250);
  border-radius: 5px;
  padding: 8px 4px;
  margin: 2px;
  height: 100%;
}

.chat-room-container {
  padding: 5px;
}

.chat-room-container.selected {
  background: rgba(145, 186, 203, 0.2);
  border-radius: 5px;
}

.chat-status {
  background-color: #3a5765;
  color: white !important;
}

.project-list-view-table {
  width: 100%;
}
.project-list-view-table .users-profile-list {
  display: flex;
  align-items: center;
}
.project-list-view-table .users-profile-list .u-profile {
  height: 24px;
  width: 24px;
  margin-left: -13px;
  border: 1px solid white;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
.project-list-view-table tr {
  border: 1px solid #e2e2e2;
  background-color: white;
  border-right: none;
  border-left: none;
}
.project-list-view-table tr .cost {
  border-radius: 20px;
  background-color: #f7faff;
  color: #2785ff;
  padding: 5px 10px;
}
.project-list-view-table tr td {
  padding-top: 9px;
  padding-bottom: 9px;
}
.project-list-view-table tr td:first-child {
  padding-left: 24px;
}
.project-list-view-table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.project-list-view-table thead tr {
  border: none;
  border-bottom: 1px solid #ddd;
}
.project-list-view-table thead tr th {
  border-bottom: none;
  background-color: #eaeaea;
  border-radius: 0px;
}

thead tr th {
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #667085;
  font-size: 12px;
  font-weight: 400;
}
thead tr th:first-child {
  padding-left: 24px;
}

.card-placeholder {
  background-color: red;
}

.profile-sidebar {
  border: 1px solid rgb(234, 236, 240);
  background-color: white;
  display: block;
  width: 310px;
  position: fixed;
  padding: 20px;
  border-radius: 10px;
  padding-right: 0;
  min-height: 79vh;
}
.profile-sidebar p {
  font-size: 18px;
  font-weight: 700;
  color: rgb(58, 87, 101);
}
.profile-sidebar button,
.profile-sidebar a {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: 500;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
  border: none;
  text-align: left;
  line-height: 1.5;
  padding-left: 10px;
  text-decoration: none;
}
.profile-sidebar button svg,
.profile-sidebar a svg {
  margin-right: 15px;
  width: 20px;
  height: 20px;
}
.profile-sidebar .ActiveTab {
  background-color: rgba(145, 186, 203, 0.2);
  color: #283f4b;
  width: 97%;
}
.profile-sidebar .ActiveTab svg {
  fill: #283f4b;
}

.profile-content {
  padding: 20px;
  border: 1px solid rgb(234, 236, 240);
  background-color: white;
  margin-left: 320px;
  border-radius: 10px;
  width: 100%;
  min-height: 79vh;
  overflow-x: hidden;
}

.content-bg {
  background-color: #fafafa;
}

.profile-upload-box {
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin: 15px 0;
}
.profile-upload-box p {
  margin: 0;
}

.pricingDropdown {
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid #e2e2e2;
  border-radius: 4px !important;
  width: 100%;
  color: rgb(29, 29, 29) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  padding: 8px 8px !important;
  margin: 0 !important;
}

.tabledropdown {
  background-color: transparent !important;
  box-shadow: none;
  border: unset !important;
  width: 100%;
  color: rgb(29, 29, 29) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  padding: 10px 8px !important;
  margin: 0 !important;
}
.tabledropdown .truncated {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  vertical-align: middle;
  white-space: nowrap;
}

.tabledropdown:not([disabled]):not(.disabled):active,
.tabledropdown:not([disabled]):not(.disabled).active,
.show > .tabledropdown.dropdown-toggle {
  background-color: #f0f2f3 !important;
}

.newCatalogSelect {
  height: 38px;
}

.newCatalogInput {
  border: 1px solid !important;
}

.dropdownList .lable, .dropdownList .dropdownListItems {
  top: -9px;
  left: 17px;
  background-color: #fff;
  z-index: 2;
  font-size: 12px;
  color: #9c9c9c;
  line-height: 18px;
  font-weight: 400;
  font-family: Roboto;
}
.dropdownList .dropdown-menu {
  height: auto;
  max-height: 300px;
  border: 1px solid #e2e2e2 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922) !important;
  border-radius: 8px !important;
  overflow: auto;
}
.dropdownList .dropdown-menu::-webkit-scrollbar {
  display: none !important;
}
.dropdownList .dropdown-menu .dropdown-item:not([disabled]):not(.disabled):active,
.dropdownList .dropdown-menu .dropdown-item:not([disabled]):not(.disabled).active,
.dropdownList .dropdown-menu .show > .dropdown-item.dropdown-toggle {
  background-color: #f0f2f3 !important;
  color: rgb(29, 29, 29) !important;
}
.dropdownList .optionItems {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
}
.dropdownList .dropdownItems {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  width: 100%;
  text-align: start;
}
.dropdownList .truncatedText {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: middle;
}

.dropdownListItems .dropdown-menu {
  height: auto;
  max-height: 300px;
  border: 1px solid #e2e2e2 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922) !important;
  border-radius: 8px !important;
}
.dropdownListItems .dropdown-menu .dropdown-item:not([disabled]):not(.disabled):active,
.dropdownListItems .dropdown-menu .dropdown-item:not([disabled]):not(.disabled).active,
.dropdownListItems .dropdown-menu .show > .dropdown-item.dropdown-toggle {
  background-color: #f0f2f3 !important;
  color: rgb(29, 29, 29) !important;
}
.dropdownListItems .dropDownInput {
  padding: 0px 12px 10px 12px;
}
.dropdownListItems .btnDropdown {
  padding: 10px 0px 0px 3px;
  background-color: transparent !important;
  border: unset;
  color: rgb(29, 29, 29) !important;
  font-weight: bold;
}
.dropdownListItems .btnDropdown .fas {
  font-weight: 600 !important;
}
.dropdownListItems .list {
  height: auto;
  max-height: 200px;
  overflow: auto;
}
.dropdownListItems .list::-webkit-scrollbar {
  display: none !important;
}
.dropdownListItems .optionItems {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
}
.dropdownListItems .dropdownItems {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  width: 100%;
  text-align: start;
}

.bg-bid:not([disabled]):not(.disabled):active,
.bg-bid:not([disabled]):not(.disabled).active,
.show > .bg-bid.dropdown-toggle {
  background-color: #3a5765 !important;
}

.pricingDropdown:not([disabled]):not(.disabled):active,
.pricingDropdown:not([disabled]):not(.disabled).active,
.show > .pricingDropdown.dropdown-toggle {
  background-color: #f0f2f3 !important;
}

.revitDropdown {
  width: 33%;
}
.revitDropdown .dropdowntoggle {
  white-space: nowrap;
}

.dataNotFounnd {
  position: absolute;
  left: 50%;
  top: 65%;
}

.catalogbtn {
  width: 33%;
}
.catalogbtn .btn {
  padding: 10px 8px !important;
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  width: 100%;
  margin: 0;
}
.catalogbtn .btn:hover {
  color: #fff !important;
}
.catalogbtn .dropdown-item {
  white-space: break-spaces !important;
  font-size: 12px !important;
}
.catalogbtn .dropdown-menu {
  border: 1px solid #e2e2e2 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922) !important;
  border-radius: 8px !important;
}
.catalogbtn .dropdown-menu .dropdown-item:not([disabled]):not(.disabled):active,
.catalogbtn .dropdown-menu .dropdown-item:not([disabled]):not(.disabled).active,
.catalogbtn .dropdown-menu .show > .dropdown-item.dropdown-toggle {
  background-color: #f4f9fa !important;
  color: rgb(29, 29, 29) !important;
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px !important;
  padding: 8px !important;
}
.breadcrumb a {
  color: rgb(29, 29, 29) !important;
  font-weight: 500;
  font-size: 14px;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}

.pricindDatabaseMain {
  position: fixed;
  z-index: 100;
  width: 100%;
}

.pricing-table-container {
  height: 600px;
  overflow-y: auto;
}

.pricing-table-container::-webkit-scrollbar {
  display: none;
}

.input-placeholder {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.placholder-main {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.placeholder {
  background: #fff !important;
  position: absolute;
  left: 15px !important;
}

.placeholder span {
  color: red;
  position: absolute;
  font-size: 17px !important;
}

.dataTable {
  margin-top: 24px;
}
.dataTable .lowestUnitCost {
  text-align: center !important;
  border-bottom: 3px solid rgb(107, 208, 111);
  overflow: hidden;
}
.dataTable .highestUnitCost {
  border-bottom: 3px solid rgba(178, 34, 34, 0.5);
  text-align: center !important;
  overflow: hidden;
}
.dataTable .secondHighestUnitCost {
  border-bottom: 3px solid rgb(240, 231, 47);
  text-align: center !important;
  overflow: hidden;
}
.dataTable .middleUnitCost {
  border-bottom: 3px solid rgb(9, 9, 234);
  text-align: center !important;
  overflow: hidden;
}
.dataTable .unitCountInput {
  width: 70px;
}
.dataTable .unitCountInput .form-control {
  border: 1px solid #d0d5dd !important;
  border-radius: 4px !important;
  height: 30px !important;
  padding: 5px 0px !important;
  max-width: 112px;
  width: 100%;
  background: #eee;
  font-weight: 600;
}
.dataTable .numberInput input::-webkit-outer-spin-button,
.dataTable .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dataTable .company {
  color: #9c9c9c !important;
}
.dataTable .defaultCompany {
  color: #3a5765 !important;
}
.dataTable th {
  padding: 5px 12px !important;
  color: rgb(102, 112, 133);
  font-size: 12px;
  font-weight: 400;
  font-family: "poppins-Regular";
}
.dataTable td {
  font-size: 16px;
  font-weight: 400;
  color: #101828;
  border-right: 2px solid #ededed;
  border-top: 2px solid #ededed;
  font-family: "poppins-Regular";
  display: table-cell;
  vertical-align: middle;
}
.dataTable th:nth-child(2) {
  width: 15%;
}
.dataTable th:nth-child(6) {
  width: 7%;
}
.dataTable th:nth-child(7) {
  width: 7%;
}
.dataTable th:nth-child(8) {
  width: 7%;
}
.dataTable .Div {
  font-family: "poppins-Regular";
}

.help-btn {
  background-color: #f0f2f3 !important;
  box-shadow: none;
  border: 1px solid rgb(58, 87, 101);
  width: 100%;
  color: rgb(58, 87, 101) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto" !important;
  border-radius: 12px !important;
  font-weight: 400 !important;
  padding: 8px 0 !important;
}

.help-btn:not([disabled]):not(.disabled):active,
.help-btn:not([disabled]):not(.disabled).active,
.show > .help-btn.dropdown-toggle {
  background-color: #f0f2f3 !important;
}

.inter {
  font-family: "Inter", sans-serif;
}

.poppins-medium {
  font-family: "Poppins-medium";
}

.card-body-help {
  background-color: white;
  color: black;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #ddd;
  font-size: 14px;
  font-weight: 500;
  padding: 20px 25px 10px 25px;
  margin-top: 2px;
}

.help-icons {
  width: 24px !important;
  margin-right: 20px !important;
}

.plan-container {
  border-radius: 15px;
  width: 100%;
  border: 1px solid rgb(234, 236, 240);
  overflow: hidden;
}
.plan-container .plan-header {
  background-color: #f4f9fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.plan-container .plan-header img {
  width: 40px;
}
.plan-container .plan-header p {
  font-size: 16px;
  font-weight: 600 !important;
  margin: 0;
  color: black;
  margin-left: 10px;
  font-family: "Inter", sans-serif;
}
.plan-container .plan-content {
  padding: 10px 10px 10px 15px;
}
.plan-container .plan-content .price {
  font-size: 30px;
  font-weight: 600 !important;
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  color: black;
}
.plan-container .plan-content .price span {
  font-size: 14px;
  color: rgb(71, 84, 103);
  font-family: "Inter", sans-serif;
  font-weight: 500 !important;
}
.plan-container .plan-content .limited {
  color: rgb(2, 122, 72);
  background-color: #ecfdf3;
  border-radius: 6px;
  padding: 2px 12px;
  margin: 0;
  font-size: 12px;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif;
}

.rounded-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid grey;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.rounded-checkbox:checked {
  -webkit-appearance: auto;
          appearance: auto;
  -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
  accent-color: rgb(58, 87, 101);
}

.squared-checkbox {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 6px;
  vertical-align: middle;
  border: 1px solid grey;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.squared-checkbox:checked {
  -webkit-appearance: auto;
          appearance: auto;
  -webkit-clip-path: circle(60% at 50% 50%);
          clip-path: circle(60% at 50% 50%);
  accent-color: rgb(58, 87, 101);
}

.small-squared-checkbox {
  width: 14px;
  height: 14px;
  border-radius: 6px;
  vertical-align: middle;
  border: 1px solid grey;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.small-squared-checkbox:checked {
  -webkit-appearance: auto;
          appearance: auto;
  -webkit-clip-path: circle(60% at 50% 50%);
          clip-path: circle(60% at 50% 50%);
  accent-color: rgb(58, 87, 101);
}

.w-10 {
  width: 10px !important;
}

.w-15 {
  width: 15px !important;
}

.w-40px {
  width: 40px;
}

.w-50 {
  width: 50%;
}

.rounded-5 {
  border-radius: 20px;
}

.rounded-4 {
  border-radius: 16px;
}

.rounded-3 {
  border-radius: 12px;
}

.rounded-2 {
  border-radius: 8px;
}

.rounded-1 {
  border-radius: 4px;
}

.leading-1 {
  line-height: 1;
}

.left-arrow-img {
  width: 8px;
}

.delete-modal .modal-content {
  border-radius: 12px !important;
  padding: 0px 5px !important;
  text-align: left !important;
  width: 85% !important;
}
.delete-modal .modal-content .modal-body {
  min-height: auto !important;
}
.delete-modal .modal-content .modal-header {
  align-items: center;
}
.delete-modal .modal-content .delete-modal-label-head {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
  color: black;
  margin-bottom: 5px;
}
.delete-modal .modal-content .delete-modal-label {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: rgb(71, 84, 103);
  margin-bottom: 0;
}
.delete-modal .modal-content .delete-modal-btn {
  border-radius: 8px !important;
  padding: 8px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  text-transform: capitalize;
  box-shadow: none;
}
.delete-modal .modal-content .cancel-confirm {
  border: 1px solid rgba(16, 24, 40, 0.5);
  color: rgb(52, 64, 84);
  background-color: white;
  margin-right: 15px;
}
.delete-modal .modal-content .delete-confirm {
  background-color: rgb(217, 45, 32);
  color: white;
  border: 1px solid rgb(217, 45, 32);
  margin-left: 15px;
}
.delete-modal .modal-content input {
  border-radius: 8px;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.form-group-div label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: rgb(52, 64, 84);
  width: 100%;
}
.form-group-div input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 8px 15px;
}
.form-group-div select {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 8px 15px;
}
.form-group-div .form-check-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: black;
  width: 100%;
  font-family: "Inter", sans-serif;
}
.form-group-div .form-check {
  margin-left: 20px;
}

.rounded-box {
  border-radius: 8px;
}

.poppins-regular {
  font-family: "poppins-Regular";
}

.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.loader-container .inner-loader-container {
  position: relative;
  width: 10rem;
  height: 10rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.loader-container .inner-loader-container img {
  width: 60px;
  padding: 12px;
  margin: 10px auto;
  animation: breathing 2s ease-out infinite normal;
  font-family: "Lantinghei SC";
  font-size: 16px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  border-radius: 2px;
  text-align: center;
}
.loader-container .inner-loader-container .spinner-border {
  width: 10rem !important;
  height: 10rem !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@keyframes breathing {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.9);
  }
}

.health-cards {
  display: flex;
  box-shadow: 0px 4px 13.1px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: #fff;
  padding: 5px;
}
.health-cards .linked-projects {
  width: 24%;
  padding: 10px;
  border-right: 1px solid #ddd;
  padding-right: 0;
}
.health-cards .scrollable-div {
  overflow-y: auto;
  width: 100%;
  height: 108px;
  padding-right: 15px;
}

.number-count {
  border-radius: 50%;
  margin: 0;
  width: 22px;
  height: 22px;
  font-size: 12px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins-medium";
}

.rule-count {
  border-radius: 3px;
  margin: 0;
  width: auto;
  height: auto;
  font-size: 12px;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-family: "Poppins-medium";
}

.title-icon {
  width: 32px;
}

.bid-table {
  width: 100%;
  padding: 5px;
  border-top: 1px solid #ddd;
  border-radius: 15px;
  overflow: auto;
}
.bid-table thead {
  border-bottom: 1px solid #ddd;
}
.bid-table thead th {
  color: rgb(102, 112, 133);
  font-size: 11px;
  font-weight: 400;
  padding: 10px 10px;
  background-color: #eaeaea;
  border-radius: 0;
}
.bid-table thead th:first-child {
  padding-left: 10px;
}
.bid-table tbody td,
.bid-table tfoot td {
  font-size: 12px;
  font-weight: 400;
  color: #101828;
  padding: 10px 10px;
}

.health-result-status {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  border-radius: 10px !important;
}

.action-icon {
  width: 20px;
}

.collapsing {
  transition: none !important;
}

.dots-btn {
  background-color: transparent !important;
  box-shadow: none;
  border: none !important;
  width: 100%;
  color: rgb(58, 87, 101) !important;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  padding: 0 !important;
  margin: 0;
}

.dots-btn:not([disabled]):not(.disabled):active,
.dots-btn:not([disabled]):not(.disabled).active,
.show > .dots-btn.dropdown-toggle {
  background-color: transparent !important;
}

.create-modal {
  max-width: 40% !important;
}
.create-modal .modal-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
}
.create-modal .modal-header {
  padding: 10px 8px;
}
.create-modal .modal-body {
  padding: 10px 8px;
  max-height: none;
}
.create-modal .modal-content {
  padding: 10px;
}
.create-modal input {
  border-radius: 8px;
}
.create-modal textarea {
  border-radius: 8px;
}

.create-modal-full-size {
  max-width: 55% !important;
}
.create-modal-full-size .modal-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
}
.create-modal-full-size .modal-header {
  padding: 10px 8px;
}
.create-modal-full-size .modal-body {
  padding: 10px 8px;
  max-height: none;
}
.create-modal-full-size .modal-content {
  padding: 10px;
}
.create-modal-full-size input {
  border-radius: 8px;
}
.create-modal-full-size textarea {
  border-radius: 8px;
}

.upload-icon {
  width: 50px;
}

.uploaded-icon {
  width: 70px;
  border-radius: 10px;
  height: 70px;
  margin-left: 12px;
  border: 1px solid #ddd;
  object-fit: cover;
}

.w-fill {
  width: -webkit-fill-available;
}

.tableRow {
  position: relative;
}
.tableRow .checkBox {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}
.tableRow .headcol {
  position: -webkit-sticky;
  position: sticky;
  left: 40px;
  background-color: white;
  z-index: 1;
}
.tableRow .headcol span {
  min-width: 112px;
}
.tableRow .role {
  position: -webkit-sticky;
  position: sticky;
  left: 168px;
  background-color: white;
  z-index: 1;
}
.tableRow .actions {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  background-color: white;
}

.toggle-group .btn-secondary {
  background-color: white !important;
  box-shadow: none;
  border: 1px solid #ddd !important;
  color: black;
  text-transform: capitalize;
  font-size: 14px;
  padding: 8px 20px;
  line-height: 1;
  margin: 0;
  font-family: "Inter", sans-serif;
}
.toggle-group .btn-secondary:not([disabled]):not(.disabled):active,
.toggle-group .btn-secondary:not([disabled]):not(.disabled).active,
.toggle-group .show > .btn-secondary.dropdown-toggle {
  background-color: #3a5765 !important;
  box-shadow: none;
  border: 1px solid #3a5765 !important;
  color: white;
  text-transform: capitalize;
  font-size: 14px;
  padding: 8px 20px;
  line-height: 1;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.form-check-label {
  font-weight: 500;
  color: black;
}

.table-icon svg {
  width: 20px;
}

.table td,
.table th {
  vertical-align: middle;
}

.project-table-div {
  width: 97%;
  overflow-x: auto;
  height: 82vh;
  background: white;
  margin: 0 auto;
  padding: 0px;
  border-radius: 20px;
}

.border-top-none {
  border-top: none !important;
}

.modal-health-accordian .accordion__item {
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.modal-health-accordian .card-body {
  padding: 10px !important;
}

.checkbox_accordian .form-group-div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.checkbox_accordian .form-group-div div:first-child {
  width: 60%;
}

.permission-table-div {
  width: 100%;
  overflow-x: auto;
  height: 67vh;
}

.heading-indicator {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.dropdown-menu {
  border: none !important;
}

.toggle-small-group {
  transform: scale(0.8);
}

.rules-states-outer-container {
  background-color: #f5f5f5;
  border: 1px solid #ececec;
  padding: 10px;
  border-radius: 20px;
}
.rules-states-outer-container .rules-stats-div {
  border-radius: 5px;
  text-align: center;
  width: 32%;
  padding-top: 20px;
}
.rules-states-outer-container .rules-stats-div h2 {
  font-size: 30px;
}
.rules-states-outer-container .rules-stats-div p {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 10px 7px;
  border-radius: 5px;
  padding: 3px 7px;
  font-size: 14px;
  font-weight: 900 !important;
  background: white;
}
.rules-states-outer-container .total {
  background-color: white;
}
.rules-states-outer-container .total h2 {
  color: black;
}
.rules-states-outer-container .total p {
  background-color: #f1f1f1;
}
.rules-states-outer-container .total p svg {
  fill: #000;
  stroke: #000;
}
.rules-states-outer-container .great {
  background-color: #eefaea;
}
.rules-states-outer-container .great h2 {
  color: #3f8a42;
}
.rules-states-outer-container .great p {
  color: #3f8a42;
}
.rules-states-outer-container .great p svg {
  fill: #3f8a42;
  stroke: #3f8a42;
}
.rules-states-outer-container .fail {
  background-color: #f8e9e9;
}
.rules-states-outer-container .fail h2 {
  color: #b22222;
}
.rules-states-outer-container .fail p {
  color: #b22222;
}
.rules-states-outer-container .fail p svg {
  fill: #b22222;
  stroke: #b22222;
}
.rules-states-outer-container .pass {
  background-color: #fff6e6;
}
.rules-states-outer-container .pass h2 {
  color: rgb(255, 165, 0);
}
.rules-states-outer-container .pass p {
  color: rgb(255, 165, 0);
}
.rules-states-outer-container .pass p svg {
  fill: rgb(255, 165, 0);
  stroke: rgb(255, 165, 0);
}

.btn {
  transition: 0.1s;
}

.btn:hover {
  transition: 0.1s;
  opacity: 0.95;
  box-shadow: 0px 0px 3px grey !important;
}

.header-btn:hover {
  transition: 0.1s;
  opacity: 0.95;
  box-shadow: 0px 0px 3px grey !important;
}

.row-low-padding {
  width: 100%;
  margin: auto;
}

.row-low-padding > div {
  padding: 3px;
}

.bid-table-small tbody tr {
  border-bottom: 1px solid #ddd !important;
}

.bid-table-small tbody td {
  font-size: 13px;
}

.site-wrap {
  margin-top: -60px;
}

.theme-text-color {
  color: #3a5765;
}

.text-capitalize {
  text-transform: capitalize;
}

.headerLink {
  text-decoration: none;
}
.headerLink:hover {
  color: #283f4b;
}

.select-proj-box {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #ddd;
  padding: 15px;
  width: 50%;
}
.select-proj-box img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.select-proj-box-active {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 2px solid #3a5765;
  padding: 15px;
  width: 50%;
}
.select-proj-box-active img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.gap-15 {
  grid-gap: 15px;
  gap: 15px;
}

.stepper-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
}
.stepper-div .step-01 {
  position: relative;
  width: 100%;
}
.stepper-div .step-01 img {
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}
.stepper-div .step-01::after {
  content: "";
  position: absolute;
  top: 11px;
  height: 2px;
  width: 46%;
  right: 0;
  background-color: #ddd;
}
.stepper-div .step-02 {
  position: relative;
  width: 100%;
}
.stepper-div .step-02 img {
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}
.stepper-div .step-02::after {
  content: "";
  position: absolute;
  top: 11px;
  height: 2px;
  width: 46%;
  right: 0;
  background-color: #ddd;
}
.stepper-div .step-02::before {
  content: "";
  position: absolute;
  top: 11px;
  height: 2px;
  width: 46%;
  left: 0;
  background-color: #ddd;
}
.stepper-div .step-03 {
  position: relative;
  width: 100%;
}
.stepper-div .step-03 img {
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}
.stepper-div .step-03::before {
  content: "";
  position: absolute;
  top: 11px;
  height: 2px;
  width: 46%;
  left: 0;
  background-color: #ddd;
}

.param-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 42%;
}
.param-box .overflow-ul-div {
  height: 315px;
  overflow-y: auto;
}
.param-box .overflow-ul-div ul {
  padding-left: 0px;
}
.param-box .overflow-ul-div ul li {
  display: flex;
  padding: 7px 16px;
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  width: 100%;
}
.param-box .overflow-ul-div ul li input {
  margin-right: 20px;
  min-width: 20px;
}

.matching-param-box {
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.successfully-project-created-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 400px;
}
.successfully-project-created-div img {
  width: 55px;
  margin-bottom: 18px;
}

.hamburger-div {
  border-radius: 50%;
  padding: 10px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
}
.hamburger-div:hover {
  background-color: #faf9f6;
  transition: 0.5s;
}

.scrollable-permissions {
  width: 470px;
  overflow: auto;
}

.table-key-width {
  width: 300px;
}

.progress-bar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(hotpink 75%, pink 0);
}

.pagination-select {
  border-radius: 5px;
}

.pagination-css {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  padding-bottom: 10px;
}

.parameter-content {
  height: 67vh;
  overflow-y: auto;
}

.list-user-img {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  object-fit: contain;
}

.health-overflow-div {
  overflow-y: auto;
  height: 75.5vh;
}

.profile-div .form-control {
  border-radius: 0.375rem !important;
}

.upsideDown-svg {
  transform: rotate(180deg);
  margin-right: 5px;
}

.toggle-btn-group .btn:hover {
  color: inherit !important;
}

.small-spinner {
  animation: mymove 1s infinite;
}

@keyframes mymove {
  100% {
    transform: rotate(360deg);
  }
}
.header-profile-icon img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.parameter-table {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 15% 27% 10% 11% 5%;
}
.parameter-table div {
  line-break: anywhere;
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  color: black;
}
.parameter-table .div-1 {
  padding-left: 10px;
}

.parameter-table-header {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 15% 27% 10% 11% 5%;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  background-color: #eaeaea;
}
.parameter-table-header div {
  line-break: anywhere;
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  color: grey;
}
.parameter-table-header .div-1 {
  padding-left: 10px;
}

.copyIcon {
  padding: 5px;
  border: 1px #3a5765 solid;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.passwordIcon {
  padding: 5px;
  border: 1px #3a5765 solid;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.validation-error-msg {
  color: red;
  margin-top: 5px;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.sidebar-profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.admin-nav-list {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 400;
  position: relative;
  padding-left: 32px;
  transition: 0.2s;
}
.admin-nav-list::after {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  left: 10px;
  background-color: #ddd;
  border-radius: 50%;
  transition: 0.2s;
}
.admin-nav-list::before {
  content: "";
  width: 20px;
  height: 2px;
  position: absolute;
  left: -11px;
  background-color: #ddd;
  transition: 0.2s;
}

.input-box-spinner {
  position: absolute;
  right: 10px;
  top: 29%;
}

.kanban-card-projectNo {
  font-size: 11px;
  margin-bottom: 0;
}
.kanban-card-projectNo i {
  float: right;
  margin-right: 5px;
}

.filter-input {
  background-image: url(/static/media/search.f6b7c5be.svg);
  background-position: 97% 50%;
  background-repeat: no-repeat;
  height: 32px !important;
  padding: 0 11px !important;
}

.filter-indicator {
  width: 15px;
  height: 15px;
  background-color: #283f4b;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  text-decoration: none;
  border: none;
  position: absolute;
  right: 0;
  top: 2px;
}

.report-btn-group label:hover {
  color: black !important;
}
.report-btn-group .active {
  background-color: #3a5765 !important;
}
.report-btn-group .active:hover {
  color: white !important;
}

.export-save-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}

.overflow-hidden {
  overflow: hidden;
}

.save-btn-position {
  position: absolute;
  top: 12px;
  right: 12px;
}

.trend-div {
  border-bottom: 1px solid #ddd;
}
.flex-overallCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px !important;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

.unit-cost-height {
  height: 46px;
}

.tree-item-table-view {
  height: 50px !important;
  font-size: 20px !important;
}

.threeD-viewer {
  height: calc(100vh - 140px) !important;
}

.h-76vh {
  height: 76vh;
}

.assestmgmt-table-div {
  width: 97%;
  overflow-x: auto;
  height: 82vh;
  background: white;
  margin: 0 auto;
  padding: 0px;
  border-radius: 20px;
  border: 1px solid #ddd;
}
.assestmgmt-table-div .users-profile-list {
  display: flex;
  align-items: center;
}
.assestmgmt-table-div .users-profile-list .u-profile {
  height: 24px;
  width: 24px;
  margin-left: -13px;
  border: 1px solid white;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.assestmgmt-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky-table-header thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.sticky-table-header-1 thead {
  position: -webkit-sticky;
  position: sticky;
  top: 110px;
  background-color: white;
}

.assestmgmt-checkbox-div {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 10px;
  gap: 10px;
}

.warehousetable-height {
  height: 76vh !important;
  margin-bottom: 50px;
}

.asset-pagination-div {
  position: relative;
  width: 95%;
  background: white;
  border-radius: 20px;
}

input[type=checkbox][disabled] {
  cursor: not-allowed;
  background-color: #ececec;
}

.disable-btn {
  cursor: not-allowed !important;
}

.download-btn-size {
  font-size: 17px;
}

.container-large {
  max-width: 98vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.search-bar-parameters {
  width: 97%;
  margin: auto;
  display: flex;
  margin-bottom: 10px;
}

.ext-select {
  width: 180px;
}

.enterprize-head {
  font-size: 35px !important;
}

.enterprize-subhead {
  font-size: 45px !important;
}

.enterprize-p {
  font-size: 17px !important;
}

.heading-svg-wrapper svg {
  width: 20px;
  height: 20px;
}

.switch-img-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;
}
.switch-img-container img {
  margin-right: 11px;
  width: 45px;
  cursor: pointer;
}
.switch-img-container p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
}
.switch-img-container .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  top: 30px;
  font-size: 14px;
}

.switch-img-container:hover .tooltiptext {
  visibility: visible !important;
}

.underline_textboc {
  border: none !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0 !important;
}

.battery-container img {
  width: 28px;
  cursor: pointer;
}

.currency-prefix {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  font-size: 16px;
}

.poppins-bold {
  font-family: "poppins-bold";
}

.tracking-date-div {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  margin: 0 10px;
}
.tracking-date-div i {
  margin: 0 10px;
  cursor: pointer;
}
.tracking-date-div input {
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  padding: 0 10px;
  text-align: center;
  font-size: 14px;
}

.dateStatus {
  font-size: 14px;
  font-weight: 600;
  margin: 10px;
}

.tabel-action-div {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.tabel-action-div i {
  cursor: pointer;
  font-size: 25px;
  margin: 0;
  padding: 0;
  color: #3a5765;
}

.tracking-table-input {
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 5px;
  height: 35px;
  padding-left: 10px;
  font-size: 15px;
}

.tracking-td {
  width: 8%;
}

.daysInput-width {
  width: 100%;
}

.track-input:disabled {
  background-color: rgba(251, 247, 247, 0.2705882353) !important;
}

.icon-div-model {
  width: 60px;
  height: 60px;
  background-color: rgb(244, 249, 250);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.icon-div-model i {
  color: #283f4b;
  font-size: 25px;
}

.text-underline {
  text-decoration: underline;
}

.excel-link {
  text-decoration: none;
}
.excel-link:hover {
  text-decoration: underline;
}

.expand-div {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #283f4b;
  border-radius: 50%;
  cursor: pointer;
}
.expand-div i {
  color: #283f4b;
  font-size: 12px;
}

html {
  background-color: #f6f7fb;
}

.disabled-inputs-track {
  border: none;
  color: black;
  background-color: white;
  font-size: 14px;
}

.track-name-width {
  min-width: 300px;
  max-width: 300px;
}

.disabled-inputs-track:disabled {
  background-color: white;
  filter: brightness(100%) !important;
}

.logo-title b {
  text-transform: math-auto;
}

.input-with-cancelBtn {
  display: flex;
  position: relative;
}
.input-with-cancelBtn button {
  position: absolute;
  right: 8px;
  top: 3px;
  bottom: 0;
  border: none;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  font-size: 11px;
}

.header-select {
  min-width: 150px;
}
.header-select .header-select__indicator {
  padding: 5px;
}
.header-select .header-select__control {
  height: 30px;
  min-height: 30px;
}
.header-select .header-select__value-container {
  padding: 0px 8px;
}
.header-select .header-select__placeholder,
.header-select .header-select__single-value {
  line-height: 1;
  font-size: 12px;
}

.global-btn {
  min-height: 30px;
  height: 30px;
  padding: 0 8px;
  display: flex;
  border: none;
  margin-right: 8px;
  font-size: 13px;
  font-family: "roboto";
  align-items: center;
  letter-spacing: 0.15px;
}

.bg-table-header {
  background-color: #eaeaea !important;
}

.pdf-svg svg {
  fill: #283f4b;
}

.admin-card-body {
  position: relative;
  transition: 0.2s;
}
.admin-card-body::before {
  content: "";
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0px;
  background-color: #ddd;
  top: 0;
  transition: 0.2s;
}

.admin-inner-ul {
  transition: 0.2s;
}
.admin-inner-ul a {
  position: relative;
  padding-left: 32px;
  transition: 0.2s;
}
.admin-inner-ul a::before {
  content: "";
  width: 20px;
  height: 2px;
  position: absolute;
  left: -11px;
  background-color: #ddd;
  transition: 0.2s;
}
.admin-inner-ul a::after {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  left: 10px;
  background-color: #ddd;
  border-radius: 50%;
  transition: 0.2s;
}

.admin-card-body1::before {
  top: 19.5px !important;
}

.collapse-active {
  border-left: 3px solid #ddd;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.collapse-active:hover {
  border-left: 3px solid #ddd !important;
}

.siderbar-inner-ul li:last-child {
  margin-bottom: 0px;
}

.inner-accordian {
  position: relative;
}

.inner-accordian-heading {
  transition: 0.2s;
  position: relative;
  padding-left: 32px;
  transition: 0.2s;
}
.inner-accordian-heading::before {
  content: "";
  width: 20px;
  height: 2px;
  position: absolute;
  left: 10px;
  background-color: #283f4b;
  transition: 0.2s;
  top: 50%;
}
.inner-accordian-heading::after {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  left: 29px;
  background-color: #283f4b;
  border-radius: 50%;
  transition: 0.2s;
  top: 43%;
}

.inner-accordian-item {
  transition: 0.2s;
  position: relative;
}
.inner-accordian-item::before {
  content: "";
  width: 2px;
  height: 98%;
  position: absolute;
  left: 10px;
  background-color: #283f4b;
  transition: 0.2s;
  top: 0%;
}

.nested-inner-panel {
  padding-left: 40px;
}

.dropdownList .optionItems {
  font-size: 12px;
}

.dropdown-item {
  font-size: 12px !important;
}

.spinner-large-size {
  font-size: 50px;
}

.small-redirect-text {
  font-size: 10px;
  color: #283f4b;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #283f4b;
  border-radius: 5px;
  padding: 2px 5px;
}
.small-redirect-text:hover {
  text-decoration: underline;
}

.date-picker-input {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 10px;
  text-transform: lowercase;
  font-size: 12px;
}

.header-select__menu {
  font-size: 12px;
}

.password-eye {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.bid-table-border {
  border: 1px solid #ddd;
}

.editBankAccount {
  border: 1px solid #2c3e50;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
}
.editBankAccount i {
  color: #2c3e50;
}

.fail-box {
  background-color: rgb(178, 34, 34);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.fail-box svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.chat-head {
  font-family: "poppins-regular";
  font-weight: 600;
  font-size: 22px;
}

.chat-form-div {
  position: relative;
}
.chat-form-div i {
  position: absolute;
  left: 15px;
  color: gray;
  top: 13px;
  font-size: 12px;
}
.chat-form-div input {
  border-radius: 0px;
  font-family: "poppins-regular";
  padding-left: 35px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1.5px solid rgba(58, 87, 101, 0.5);
}
.chat-form-div input::placeholder {
  font-size: 14px;
}

.start-chat-btn {
  display: flex;
  align-items: center;
}
.start-chat-btn i {
  margin-left: 20px;
}

.cancel-chat-div {
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-chat-div i {
  font-size: 20px;
}

.chat-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgb(58, 87, 101);
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;
  transition: 0.2s;
}
.chat-icon i {
  color: white;
  font-size: 30px;
}
.chat-icon:hover {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
  box-shadow: 0px 0px 20px rgb(58, 87, 101);
  transition: 0.2s;
}

@keyframes bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.chat-card-body {
  background-color: rgb(243, 243, 243);
}

.chat-send-div {
  position: relative;
}
.chat-send-div button {
  position: absolute;
  right: 0px;
  background-color: #2c3e50;
  width: 45px;
  border: none;
  height: 100%;
  border-top-right-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
  z-index: 10;
}
.chat-send-div button i {
  color: white;
}

.chat-send-div input {
  width: 100%;
  border-radius: 22px !important;
  padding-right: 50px;
}
.chat-send-div input:focus {
  box-shadow: none;
  outline: grey;
  border: 1px solid #ddd;
}

.user-msgs {
  background-color: #283f4b;
  border-radius: 20px;
  border-bottom-right-radius: 0px;
  padding: 5px 12px;
  color: white;
}

.sender-msgs {
  background-color: #fff;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  padding: 5px 12px;
  color: black;
  margin-left: 3px;
}

.messages-list {
  background: rgb(243, 243, 243);
  padding: 14px;
  border-radius: 12px;
  height: 55vh;
  overflow: auto;
}

.admin-chat-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chat-username-div {
  width: 30%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 12px;
  height: 70vh;
  overflow: auto;
}

.chat-main-div {
  position: relative;
  width: 70%;
}

.chat-div-info p {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .admin-topnavbar .search-bar {
    width: 100%;
  }
  .profile-content {
    margin-left: 0px;
    margin-top: 20px;
  }
  .admin-chat-div {
    flex-direction: column;
  }
  .chat-username-div {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 12px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .chat-room-container {
    box-shadow: 0px 0px 10px #ddd;
    margin: 0 10px;
    border-radius: 8px;
    padding: 5px 10px;
    white-space: pre;
    margin-bottom: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .chat-main-div {
    position: relative;
    width: 100%;
  }
  .chat-div-info p {
    font-size: 12px;
  }
  .profile-sidebar {
    width: -webkit-max-content;
    width: max-content;
    border: none;
    padding-left: 5px;
  }
  .sidebar-close-btn {
    position: absolute;
    right: -7px;
    width: 20px;
    top: -33px;
  }
  .sidebar-close-btn i {
    font-size: 25px;
  }
}
.search-div {
  display: flex;
  margin-top: 20px;
}

.input-with-cancelBtn {
  display: flex;
  position: relative;
}

.search-btn1 {
  position: absolute;
  right: 8px;
  top: 3px;
  bottom: 0;
  border: none;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  font-size: 11px;
  background-color: #ddd;
}

.search-btn2 {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;
  font-size: 11px;
  background-color: #3A5765;
  min-height: 30px;
  height: 30px;
  padding: 0 15px;
  margin-left: 10px;
}

.search-bar {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 15px;
  width: 240px;
  max-width: 100%;
  min-height: 30px;
  height: 30px;
  /* background-image: url("../assets/images/icons/search.svg"); */
  background-position: 97% 50%;
  background-repeat: no-repeat;
  /* &::placeholder {
    color: #ddd;
    font-size: 15px;
  } */
}
.fileListTable .col-01 {
  width: 130px;
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileListTable .col-02 {
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileListTable .col-03 {
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileListTable .col-04 {
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileListTable .col-05 {
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileListTable .col-06 {
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileListTable .col-07 {
  width: 5%;
  max-width: 5%;
}
.fileListTable .users-profile-list {
  display: flex;
  align-items: center;
}
.fileListTable .users-profile-list .u-profile {
  height: 24px;
  width: 24px;
  margin-left: -13px;
  border: 1px solid white;
  border-radius: 5px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.user-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
}

.parameter-header {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
}

.parameter-container {
  margin-top: 60px !important;
}

.bg-detail {
  background-color: white;
  border-radius: 5px;
}
.curvedScore {
  font-size: 23px;
  color: black;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}
.timeline-viewer-container {
  background-color: #fff;
}

#WebglViewer #clippingTooltip {
  position: absolute;
  font-size: small;
  right: 0;
  top: -20px;
  background-color: #3a5765;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}

.viewer-zoom {
  bottom: 40px !important;
  color: gray;
}

#WebglViewer .buttonEnabled {
  background-color: #3a5765 !important;
  color: white;
}

.ifcjs-dimension-label {
  background-color: white;
  padding: 0 3px;
  border-radius: 100vw;
  font-size: 0.8rem;
}

.viewer {
  height: calc(100vh - 60px);
}
.viewer canvas {
  outline: none;
  width: 100%;
}
.viewer-total-price {
  top: 15px;
  right: 15px;
  width: 200px;
  box-shadow: 1px 2px 9px grey;
}
.viewer-zoom {
  z-index: 10;
  right: 50%;
  transform: translateX(50%);
  bottom: 15px;
}
.viewer-header {
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: rgb(113, 161, 183);
  font-size: 17px;
}
.viewer-lowerheader {
  height: 38px;
}
.viewer-header-item {
  border-right: 2px solid rgb(200, 200, 200);
}
.viewer-lowertree {
  height: calc(100% - 38px);
}
.viewer-selected {
  min-width: 160px;
}
.viewer-icon {
  cursor: pointer;
}
.viewer-icon svg {
  width: 25px;
  height: 10px;
  fill: white;
  transform: rotate(0deg);
  transition: 0.3s;
}
.viewer-icon-rotate svg {
  transform: rotate(90deg);
}
.viewer-popover {
  padding: 3px 7px 3px 5px;
  background-color: white;
}
.viewer-searching {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}

.estimation-detail-itemViewer {
  position: absolute;
  display: flex;
  overflow: hidden;
  max-height: 1000px;
  max-width: 300px;
  overflow-y: scroll;
}

.viewer {
  background-color: #fff;
}

.viewer-header {
  background-color: #fbfcfc !important;
  top: 0px;
  font-size: 12px;
}

.viewer-outer-div {
  overflow: hidden;
  background-color: #fbfcfc;
  min-width: 80px;
}

.viewer-header-item {
  position: relative;
  color: #455e6c;
  background: #f2f2f2;
  border-radius: 10px 10px 0px 0px;
  border: none !important;
  margin: 0px;
  font-family: "poppins-Medium";
  letter-spacing: -0.7px;
  font-size: 12px;
  white-space: pre;
  grid-gap: 8px;
  gap: 8px;
}

.viewer-gapping {
  margin: 5px;
  height: 38px;
}

.resize-panel-container {
  margin-top: 0px;
}

.resize-panel {
  margin: 0px;
  background: #fff;
}

.inner_resizable {
  margin: 0px 5px;
  border: 1.5px solid #f2f2f2;
  width: auto;
  border-top: none;
  border-bottom: none;
}

.resize-panel:last-child {
  max-width: inherit;
}

.w-40 {
  width: 40%;
}

.w-20 {
  width: 20%;
}

.viewer-icon {
  position: absolute;
  right: 0;
  background: inherit;
}
.viewer-icon svg {
  color: #455e6c;
  fill: #455e6c;
  width: 25px !important;
  height: 28px !important;
}

.modal-side-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  padding: 0px 5px;
}

.modal-side-table tbody tr td {
  color: #2c3e50;
  padding: 0px 10px;
  font-family: "Poppins-medium";
  font-size: 11px !important;
  background: #fbfbfb;
  height: 29px;
}

.modal-side-table tbody tr td.total-summary {
  display: none;
}

.modal-side-table tbody .item-child td {
  border: 1px solid #ddd;
  border-radius: 0px;
  border-left: none;
  border-right: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 7rem;
}

.modal-side-table tbody .item-child td:first-child {
  border: 1px solid #ddd;
  border-radius: 5px 0px 0px 5px;
  border-right: none;
}

.modal-side-table tbody .item-child td:last-child {
  border: 1px solid #ddd;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}

.modal-sideShare-table tbody .item-child td:first-child {
  padding-left: 20px !important;
}

@media only screen and (max-width: 768px) {
  .custom-control {
    display: none !important;
  }
}
.custom-control-label1 {
  font-size: 12px !important;
  color: #455e6c !important;
  font-family: "poppins-Medium" !important;
  padding-top: 2px !important;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 13px;
  color: #455e6c;
  font-weight: 700;
}

.amber-light {
  background-color: #ffebc4;
}

#WebglViewer .modal3d-tool-btn {
  padding: 10px !important;
  margin-right: 13px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
#WebglViewer .modal3d-tool-btn:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.resizable-fragment {
  height: 25%;
}
.resizable-fragment:last-child {
  max-height: inherit;
}
.resizable-fragment div::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #3a5765;
}
.resizable-fragment div::-webkit-scrollbar-thumb:hover {
  background: #283f4b;
}
.resizable-fragment div::-webkit-scrollbar-thumb:active {
  background: #283f4b;
}

.resize-panel:last-child .resizable-fragment:first-child {
  max-height: 50%;
}

.clock-icon {
  width: 20px;
  margin-right: 10px;
}

.modal-sideShare-table {
  width: auto;
  border-collapse: separate;
  table-layout: fixed;
  border-spacing: 0 10px;
  display: inline-table;
}

.modal-sideShare-table .item-child {
  outline: 1px solid #ddd;
  border-radius: 5px;
}

.modal-sideShare-table tbody {
  width: 100%;
}

.modal-sideShare-table tbody tr td {
  color: #2c3e50;
  padding: 8px 20px;
  font-family: "Poppins-medium";
  font-size: 12px !important;
  max-height: 50px;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.viewer-lowertree {
  height: calc(100% - 50px) !important;
}

.h-86vh {
  height: 86vh;
}

.existing-img {
  width: 32px;
  margin-right: 15px;
}

.demo-img {
  width: 28px;
  margin-right: 15px;
}

.unpriced-img {
  width: 32px;
  margin-right: 15px;
}

.priced-img {
  width: 32px;
}

.estimation-icon {
  width: 28px;
}

.project-icon {
  width: 30px;
}

.viewer-lowerheader {
  font-size: 12px;
  background-color: #f2f2f2;
  margin-top: 2px;
}
.heading-bidlight {
  font-family: "poppins-bold";
  color: #455d6b;
}

.homeButtons {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 500px;
}

.homeButtons .btn {
  padding: 15px !important;
  font-size: 20px !important;
}

.btn-primary-custom {
  color: #fff !important;
  text-align: start !important;
  font-size: 24px !important;
  border-radius: 10px !important;
  border: 2px solid #71a0b8 !important;
  background: #71a0b8 !important;
  text-transform: unset !important;
  position: relative !important;
  top: 0;
  transition: 0.3s all ease;
  box-shadow: none !important;
  z-index: 12;
  line-height: 48px;
}

.homeButtons .btn-primary-custom {
  line-height: unset;
}

.btn-primary-custom:hover {
  color: #455d6b !important;
  background: #ffff !important;
}

.custome-home-review .react-multiple-carousel__arrow {
  bottom: 0 !important;
  right: 0;
}

.custom-dot-list-style.custom-dot-list-style-home {
  bottom: 0 !important;
}
.carousel-track-custom-home .react-multi-carousel-track {
  height: 250px !important;
  left: 0 !important;
}

.wrapperEstimation {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
}
@media (max-width: 768px) {
  #videoIdEstimation {
    width: 100% !important;
  }
  .playpauseEstimation {
    width: 100% !important;
  }
}

#videoIdEstimation {
  width: 120%;
  height: 100%;
  object-fit: cover;
}
.playpauseEstimation {
  background-image: url(/static/media/big-play-button.3f5cd452.png);
  background-repeat: no-repeat;
  width: 120%;
  height: 25%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}

/* Bootstrap Icons */
.icon-shape {
  width: 50px;
  height: 50px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectname_number_container {
  transition: 0.2s;
  overflow: hidden;
}

.project-cont-height {
  overflow-y: auto;
  height: 220px;
  transition: 0.2s;
}

.projectname_number_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  padding: 6px 7px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 600;
}
.projectname_number_div:hover {
  background-color: #eaeaea;
}
.projectname_number_div p {
  font-size: 12px;
  margin: 0;
}
.projectname_number_div .code {
  width: 15%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.projectname_number_div .desc {
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.projectname_number_div .totalCost {
  width: 33%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.activity-img {
  border-radius: 50%;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}
.activity-img svg {
  width: 20px;
  height: 20px;
  fill: #283f4b;
}

a.text-heading.font-semibold {
  font-size: 12px !important;
  text-decoration: none;
}

.battery-card-container {
  position: absolute;
  right: 16px;
  top: 60px;
}

.height-0 {
  height: 0px;
  overflow: hidden;
}

.dashboard-text {
  font-size: 14px;
  font-weight: 600;
  color: grey;
}

.legend-p {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.legend-p .legend-box {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin-right: 7px;
}

.chart-header {
  font-size: 15px;
  font-weight: 500;
  margin: 20px;
  font-family: "poppins-Medium";
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.chart-header::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 70%;
  background-color: #283f4b;
  left: 0;
  bottom: -10px;
}

.dashboard-cards {
  box-shadow: 0px 0px 4px #ddd !important;
  /* border: 1px solid #ddd !important; */
  border-radius: 15px !important;
}
:root {
  --white: #fff;
  --orange: #f48024;
  --black-025: #fafafb;
  --black-050: #eff0f1;
  --black-075: #e4e6e8;
  --black-100: #d6d9dc;
  --black-200: #bbc0c4;
  --black-300: #6a737c;
  --black-350: #9199a1;
  --black-400: #848d95;
  --black-500: #6a737c;
  --black-600: #535a60;
  --black-700: #3c4146;
  --black-800: #242729;
  --black-900: #0c0d0e;
  --blue-300: #6cbbf7;
  --blue-500: #0095ff;
  --blue-600: #0077cc;
  --blue-700: #0064bd;
  --red-400: #de535e;
  --red-500: #d1383d;
  --powder-100: #e1ecf4;
  --powder-200: #d1e5f1;
  --powder-300: #b3d3ea;
  --powder-400: #a0c7e4;
  --powder-500: #7aa7c7;
  --powder-600: #5b8db1;
  --powder-700: #39739d;
  --powder-800: #2c5777;

  --fade: 120ms;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ea761a;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ea761a, 0 0 5px #ea761a;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: none;
  position: fixed;
  z-index: 1031;
  top: 16px;
  right: 15px;

  @media (min-width: 1330px) {
    display: block;
  }
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #ea761a;
  border-left-color: #ea761a;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (max-width: 768px) {
  .articleView{
    margin-top: 40px;
  }}
.post-metadata-bar > div {
  max-width: 95vw;
  margin: auto;
}

button.theme-btn.btn {
  color: #fff;
  background-color: #6e98ae;
  border: 1px solid transparent;
  box-shadow: inset 0 1px 0 0 rgb(255 255 255 / 40%);
  padding: 0.7em;
}

.gray {
  color: #828282;
}

.orange {
  background-color: #6e98ae;
}

.background-gray {
  background-color: rgb(246,246,239);
}

.fil {
  font-size: 11px;
}
.w100 {
  width: 100%;
}
.w85 {
  width: 85%;
}

/* 14-06-22 */
#__next{
  margin-top:16px;
}
.center {
  margin-right: auto;
  margin-left: auto;
}
.flex{
  display: flex;
  /* align-items: center; */
}
.flex.justify-between{
  justify-content: space-between;
}
.p-2{
  padding: 10px;
}
.mr1 {
  margin-right: 0.25rem;
}
.fw7 {
  font-weight: 700;
}
.white , .white a{
  color: #fff;
}
.ml1{
  margin-left: 4px;
}
.center .flex{
  font-size: 16px;
}
.ph3 {
  padding-left: 13px;
  padding-right: 13px;
}
.pv1 {
  padding-top:4px;
  padding-bottom:4px;
}
.mt2 {
  margin-top: 8px;
}
.mv3 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.ml4 {
  margin-left: 32px;
}
.mr2 {
  margin-right: 8px;
}
.lh-copy {
  font-size: 14px;
  display: flex;
}
.ml1.pointer.white a {
  padding-left: 6px;
  border-right: 2px solid;
  padding-right: 6px;
}
.react-tagsinput-tag {
  display: inline-block;
  background-color: var(--powder-100);
  color: var(--powder-700);
  font-size: 12px;
  padding: 5px 6px;
  border: 1px solid transparent;
  margin: 2px 5px 2px 0;
  line-height: 1;
  white-space: nowrap;
  border-radius: 3px;
}

.react-tagsinput-input {
  width: 200px;
  color: var(--black-900);
}
.react-tagsinput-input::placeholder {
  color: var(--black-200);
}

.loading {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading svg {
  color: var(--black-200);
  height: 40px;
  width: 40px;
}

.not-found {
  color: var(--red-500);
  margin-left: 24px;
}

.not-found-questions {
  margin-top: 10px;
  text-align: center;
}

.menu-container {
  position: relative;
  display: grid;
  justify-content: end;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 2;
}

.menu-icon .bar {
  height: 4px;
  width: 100%;
  background-color: #6e98ae;
  border-radius: 2px;
}

#menu-toggle {
  display: none;
}

.menu {
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  border-bottom: 1px solid #f0f0f0;
}

.menu li:last-child {
  border-bottom: none;
}

.menu a {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 10px 20px;
}

#menu-toggle:checked + .menu-icon + .menu {
  display: block;
}

.learning-btn-group {
  display: flex;
}
.learning-btn-group button {
  width: 75px;
  border-radius: 5px;
  border: 1px solid #283f4b;
  text-align: center;
  height: 35px;
  font-weight: 600;
  color: #283f4b !important;
  margin-right: 8px;
  background: white;
}
.learning-btn-group .active {
  background-color: #283f4b !important;
  color: white !important;
}

.post-panel {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
}

.post-metadata {
  display: flex;
}
.post-metadata img.avatar-image {
  border-radius: 50%;
}

.post-metadata .post-info {
  padding-left: 10px;
}

.post-metadata .post-info small {
  color: rgba(0, 0, 0, 0.44);
}

.post-picture-wrapper {
  padding: 1.2em 0 0;
}

.post-picture-wrapper img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.main-body {
  margin-bottom: 20px;
}
.main-body a {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.44);
}

.post-panel .main-body .post-body {
  font-size: 21px;
  line-height: 1.18;
  letter-spacing: -0.022em;
  font-family: "poppins-regular";
}

.post-panel .main-body .post-body h2, .post-panel .main-body .post-body h3 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.44);
}

.post-metadata .post-info a {
  color: #6e98ae;
  text-decoration: none;
}

.post-title {
  color: #3a5765 !important;
  margin-top: 20px;
  text-decoration: none;
}
.post-title:hover {
  text-decoration: underline;
}

.post-title-1 {
  color: #3a5765 !important;
  margin-top: 20px;
  text-decoration: none;
  font-weight: 600 !important;
  font-family: "Roboto";
}
.post-title-1:hover {
  text-decoration: underline;
}

.article-font > p, h2, h3, h4, h5, h6 {
  font-family: "Roboto" !important;
}

.like-button, .bookmark-button {
  background: transparent;
  color: #6e98ae;
  border: none;
  display: flex;
  align-items: center;
}
.like-button span, .bookmark-button span {
  margin-right: 10px;
}

.small-heading {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0 0 8px;
  font-size: 13px;
  font-family: "poppins-regular";
  font-weight: 500;
}

aside.main-sidebar {
  padding-top: 2em;
  background-color: #fafafa;
}

.aside_rootAside__vAyQl {
  padding-top: 5px !important;
}

aside.main-sidebar .sidebar-top-stories ul {
  list-style: none;
  padding: 1em;
}

aside.main-sidebar .sidebar-top-stories .top-stories-list-item {
  display: flex;
  margin-bottom: 1.1em;
}

.count-button-wrapper {
  position: relative;
  min-width: 35px;
  width: 35px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-color: #fff;
  margin-right: 20px;
}

.count-button-wrapper .count-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 700;
}

.top-stories-links {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}
.top-stories-links small a {
  text-decoration: none;
  color: grey;
}
.top-stories-links small a:hover {
  text-decoration: underline;
}

.post-body p {
  font-family: "poppins-regular";
  font-size: 20px;
  line-height: 1.7;
  font-weight: 300;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}

.like-button-wrapper {
  display: flex;
  align-items: center;
}

.follow-button {
  border-radius: 15px !important;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}

.unfollow {
  background-color: white;
  border: 1px solid #283f4b;
  color: #283f4b;
}

.follow {
  background-color: green;
  border: 1px solid green;
  color: white;
}

.author-metadata {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.author-metadata img {
  border-radius: 50%;
  margin-right: 15px;
}

.learning-bg {
  padding: 20px;
  border: 1px solid rgb(234, 236, 240);
  background-color: white;
  border-radius: 10px;
  width: 100%;
  min-height: 79vh;
  overflow-x: hidden;
}

.article-action-box {
  display: flex;
}
.article-action-box li {
  list-style: none;
  border: 1px solid #283f4b;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.article-action-box li i {
  color: #283f4b;
}

.learning-content {
  padding: 20px;
  border: 1px solid rgb(234, 236, 240);
  background-color: white;
  margin-left: 20%;
  border-radius: 10px;
  min-height: 79vh;
  overflow-x: hidden;
  width: 60%;
}

.learning-sidebar {
  border: 1px solid rgb(234, 236, 240);
  background-color: white;
  display: block;
  position: fixed;
  padding: 20px;
  border-radius: 10px;
  padding-right: 0;
  min-height: 88vh;
  width: 19%;
  min-width: 19%;
}
.learning-sidebar p {
  font-size: 18px;
  font-weight: 700;
  color: rgb(58, 87, 101);
}
.learning-sidebar button, .learning-sidebar a {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: 500;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
  border: none;
  text-align: left;
  line-height: 1.5;
  padding-left: 10px;
  text-decoration: none;
}
.learning-sidebar button svg, .learning-sidebar a svg {
  margin-right: 15px;
  width: 20px;
  height: 20px;
}
.learning-sidebar .ActiveTab {
  background-color: rgba(145, 186, 203, 0.2);
  color: #283f4b;
  width: 97%;
}
.learning-sidebar .ActiveTab svg {
  fill: #283f4b;
}

.poppins-regular {
  font-family: "poppins-regular";
}

.add-article-btn {
  border-radius: 5px;
  text-decoration: none;
  padding: 5px 10px;
}

.mobile-hidden {
  display: none;
}

.mobile-show {
  display: none;
}

.desktop-show {
  display: block;
}

.learning-container-flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .learning-container-flex {
    display: block;
  }
  .learning-content {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    padding: 5px;
  }
  .learning-sidebar {
    border: 1px solid rgb(234, 236, 240);
    background-color: white;
    display: flex;
    position: relative;
    padding: 5px;
    border-radius: 10px;
    padding-right: 0;
    min-height: 0;
    width: 100%;
    min-width: 100%;
    padding-right: 5px;
  }
  .learning-sidebar p {
    display: none;
  }
  .learning-sidebar button, .learning-sidebar a {
    margin-bottom: 0px;
    font-size: 12px;
    padding: 6px;
  }
  .learning-btn-group button {
    width: 55px;
    border-radius: 5px;
    border: 1px solid #283f4b;
    text-align: center;
    height: 25px;
    font-weight: 600;
    color: #283f4b !important;
    margin-right: 8px;
    background: white;
    font-size: 10px;
  }
  .custom-container {
    margin: 0 5px;
    padding: 5px;
  }
  .hamburger-div {
    padding: 5px;
  }
  .header-logo {
    width: 70px;
    cursor: pointer;
  }
  .header-profile-icon img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
  }
  .mobile-show {
    display: block;
  }
  .desktop-show {
    display: none;
  }
  .post-metadata .post-info small {
    color: rgba(0, 0, 0, 0.44);
    font-size: 10px;
    position: relative;
    display: block;
  }
  .post-panel .main-body .post-body {
    font-size: 10px;
    line-height: 1.18;
    letter-spacing: -0.022em;
    font-family: "poppins-regular";
  }
  .post-panel .main-body .post-body h2, .post-panel .main-body .post-body h3 {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.44);
  }
  .add-article-btn {
    font-size: 12px;
  }
  .count-button-wrapper {
    position: relative;
    min-width: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    background-color: #fff;
    margin-right: 10px;
  }
  .post-title {
    font-size: 14px;
  }
}
.upload-box {
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
}

.editor-image-upload-box {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
}

.editor-bg {
  padding: 20px;
  border: 1px solid rgb(234, 236, 240);
  background-color: white;
  border-radius: 10px;
  width: 100%;
  min-height: 79vh;
}

.article-font {
  font-weight: 500;
  font-size: 18px;
}
.main_border__3mMvo {
  border-left: 1px solid var(--black-100);
}

.question-wrapper_container__1QWJZ {
  display: flex;
  width: 100%;
  padding: 8px 12px 20px 12px;
  border-bottom: 1px solid var(--black-050);
}

.question-stats_container__1CqUZ {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 58px;
  margin-right: 12px;
  color: var(--black-500);
  font-size: 11px;

  @media (--m) {
    margin-left: 4px;
    margin-right: 16px;
  }
}

.question-stats_vote__1We3m {
  width: 58px;
  text-align: center;

  span {
    font-size: 20px;
    color: var(--black-500);
  }
}

.question-stats_answer__37V1J {
  width: 58px;
  text-align: center;
  margin-bottom: 5px;

  span {
    font-size: 20px;
    color: var(--black-500);
  }
}

.question-stats_view__265tn {
  padding-top: 10px;
  text-align: center;
}


@media screen and (max-width: 768px){ 
  .question-stats_vote__1We3m {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
  
    span {
      font-size: 12px;
      color: var(--black-500);
    }
  }

  .question-stats_answer__37V1J {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    margin-bottom: 5px;
  
    span {
      font-size: 12px;
      color: var(--black-500);
    }
  }

}
.tag_tag__2BsIJ {
  display: inline-block;
  background-color: var(--powder-100);
  color: var(--powder-700);
  font-size: 13px;
  padding: 5px 6px;
  border: 1px solid transparent;
  margin: 2px 5px 2px 0;
  line-height: 1;
  white-space: nowrap;
  border-radius: 5px;
  text-decoration: none;
  font-family: "poppins-regular",;

  &:active {
    color: var(--blue-500);
  }

  &:hover {
    color: var(--powder-800);
    background-color: var(--powder-200);
    border-color: transparent;
  }
}

.tag_multiplier__3tsw_ {
  color: var(--black-400);
}

.tag_count__3ijck {
  color: var(--black-500);
  font-size: 11px;
}

@media screen and (max-width: 768px){ 

  .tag_tag__2BsIJ{
    font-size: 10px
  }
}
.question-summary_container__2lmO4 {
  flex: 1 1 auto;
  overflow: hidden;
}

.question-summary_link__15xq5 {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 8px;
  word-break: break-word;
}

.question-summary_excerpt__Xdaph {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 5px;
  white-space: pre-wrap;
  word-break: break-word;
}

.question-summary_footer__2Otau {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 60px;
}

.question-summary_tagContainer__L2Gwx {
  flex: 1 1 auto;
  margin-bottom: 8px;
  a{
    display: inline-block;
    background-color: var(--powder-100);
    color: var(--powder-700);
    font-size: 15px;
    padding: 5px 6px;
    border: 1px solid transparent;
    margin: 2px 5px 2px 0;
    line-height: 1;
    white-space: nowrap;
    border-radius: 3px;
  }
}

.question-summary_userDetails__2KUYu {
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span {
    color: var(--black-400);
  }

  .question-summary_info__35mu5 {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    a {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      color: #455d6b;
      text-decoration: none;
      
    }
  }
}


.question-summary_title__I5fhD{
  font-size: 36px;
  color: #283f4b;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  color: #283f4b;
  }
}

@media screen and (max-width: 768px){ 
  .question-summary_title__I5fhD{
    font-size: 14px;
  }

  .question-summary_excerpt__Xdaph {
    font-size: 10px;
  }

  .question-summary_tagContainer__L2Gwx {
    flex: 1 1 auto;
    margin-bottom: 8px;
    a{
      display: inline-block;
      background-color: var(--powder-100);
      color: var(--powder-700);
      font-size: 10px;
      padding: 5px 6px;
      border: 1px solid transparent;
      margin: 2px 5px 2px 0;
      line-height: 1;
      white-space: nowrap;
      border-radius: 3px;
    }
  }

  .question-summary_userDetails__2KUYu { 
    span{
      font-size: 10px;
    }
    a{
      font-size: 10px;
    }
  }
}
.button_button__j5KEk {
  background-color: transparent;
  border-radius: 3px;
  outline: none;
  font-family: inherit;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.15;
  text-decoration: none;
  cursor: pointer;
  color: var(--black-400);
  transition: var(--fade);

  &:hover {
    background-color: var(--black-075);
  }

  &:focus {
    box-shadow: 0 0 0 2.5px rgba(36, 39, 41, 0.1);
  }

  &:active {
    background: var(--black-050);
  }
}

.button_primary__3eedE {
  color: #fff;
  background-color: #6e98ae;
  border: 1px solid transparent;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
  padding: 0.8em;

  &:hover {
    color: #000;
    background-color: #fafafb;
    border: 1px solid;
  }

  &:active {
    background-color: var(--blue-700);
    box-shadow: none;
  }

  &:focus {
    color: #fff;
    background-color: var(--blue-600);
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
  }
}

.button_secondary__1GmxI {
  color: var(--powder-700);
  background-color: var(--powder-100);
  border: 1px solid transparent;
  border-color: var(--powder-500);
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.7);
  padding: 0.8em;

  &:hover {
    color: var(--powder-800);
    background-color: var(--powder-300);
  }

  &:active {
    background-color: var(--powder-400);
    border-color: var(--powder-600);
    box-shadow: none;
  }

  &:focus {
    color: var(--powder-800);
    background-color: var(--powder-300);
  }
}

.button_full__2U0ps {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_isLoading__XHYi8 {
  padding-left: 2.2em;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    opacity: 0.3;
    left: 0.5em;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-color: currentColor;
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0.5em;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-left-color: currentColor;
    border-radius: 50%;
    animation: button_spin__1UphC 0.9s infinite cubic-bezier(0.5, 0.1, 0.5, 0.9);
    filter: invert(0);
    transform-origin: 50% 50% 1px;
  }

  @keyframes button_spin__1UphC {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
}

.page-title_container__URrwt {
  padding: 24px 12px 24px 24px;
  padding-top: 0;
}

.page-title_borderBottom__28cuQ {
  border-bottom: 1px solid var(--black-100);
}

.page-title_title__2-COX {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  word-break: break-word;

  h1 {
    font-size: 27px;
    max-width: 950px;
    font-weight: 600 !important;
    font-family: 'poppins-SemiBold';
  }
}

.page-title_summary__25yVh {
  margin-top: 20px;
  font-size: 15px;
  max-width: 630px;
}

.page-title_buttonContainer__2G1nD {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;

  a {
    background-color: #283f4b !important;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    /* border-radius: ; */
    flex-shrink: 0;
    @media (max-width: 1200px) {
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 768px){ 
  .page-title_container__URrwt {
    padding: 10px;
  }
  .page-title_title__2-COX {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    word-break: break-word;
  
    h1 {
      font-size: 17px;
      max-width: 950px;
      font-weight: 600 !important;
      font-family: 'poppins-SemiBold';
    }
  }
  .page-title_buttonContainer__2G1nD {
    a{
      font-size: 10px;
      margin-bottom: 0px;
      padding: 5px 10px;
    }
  }
}
.button-group_container__2jh6R {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  button {
    color: var(--black-500);
    border: 1px solid var(--black-300);
    border-radius: 3px;
    padding: 0.8em;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child):not(:first-child) {
      border-radius: 0px;
    }

    &:not(:last-child) {
      margin-right: -1px;
    }

    &:hover {
      color: var(--black-600);
      background-color: var(--black-025);
    }
  }
}

.button-group_active__2IJh1 {
  color: #71a0b8 !important;
  background-color: var(--black-075) !important;

  &:hover {
    background-color: var(--black-075) !important;
  }
}

.button-group_borderBottom__bB-RN {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--black-100);
  margin-right: 5px;
}

.navigation_learningNav__2pdrp {
    /* top: 74px;
    position: sticky;
    width: auto; */
    background-color: white;

    a{
        display: block;
        color: black;
        font-size: 14px;
        font-weight: 600;
    }
}
.extra_container__cM6J_ {
  position: relative;
  width: 350px;
  background: white;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  margin-left: 10px;
  font-family: 'poppins-regular';

}

.extra_tagContainer__2iMdn {
  position: -webkit-sticky;
  position: sticky;
  top: 74px;
  display: flex;
  flex-direction: column;
  padding-left: 12px;

  h2 {
    color: var(--black-700);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 1em;
  }
}

.extra_popularTags__3lxCZ {
  display: grid;
  grid-template-columns: auto auto;
}


@media screen and (max-width: 768px){ 
  .extra_container__cM6J_ {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  
  }
  .extra_tagContainer__2iMdn{
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding: 10px;
    margin-top: 0px !important;
    h2 {
      font-size: 14px;
    }
  }
} 
.aside_topList__2NQCW {
  position: -webkit-sticky;
  position: sticky;
  top: 74px;
  width: 100%;
  overflow-y: auto;
  height: 90vh;
}

.aside_topList__2NQCW::-webkit-scrollbar {
  width: 6px;
}

.aside_topList__2NQCW::-webkit-scrollbar-thumb {
  background-color: #4e6471;
  border-radius: 100vw;
}

.aside_topList__2NQCW::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px grey;
  border-radius: 100vw;
}

.aside_rootAside__2M6lG {
  padding-top: 5px !important;
}

.aside_topListRoot__21sxx {
  margin-left: 15px;
  background: white;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  width: 20%;
  max-width: 20%;
  min-width: 20%;

  @media (--t) {
    display: initial;
  }
}

@media screen and (max-width: 768px) {
  .aside_topListRoot__21sxx {
    margin-left: 0px;
    background: white;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-top: 10px;
  }
}

.navigation_nav__1f96- {
  display: flex;
  background-color: white;
  border: 1px solid lightgray;
  top: 74px;

  position: -webkit-sticky;

  position: sticky;
  width: auto;

  @media (--m) {
    display: initial;
    border: none;
  }
}

.navigation_stickyResponsive__1khxt {
  position: -webkit-sticky;
  position: sticky;
  top: 49px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .navigation_nav__1f96- {
    border: none;
  }
}

.detail-page-container_container__3KAoN {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 6px;
  padding-right: 6px;

  @media (--m) {
    padding-left: 12px;
  }

  @media (--t) {
    padding-left: 24px;
    padding-right: 0;
    grid-template-columns: 1fr 250px;
  }
}

.post-wrapper_layout__2Z0Jx {
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  padding-top: 16px;
  padding-bottom: 16px;
}

.post-wrapper_borderBottom__Jr-Zw {
  border-bottom: 1px solid var(--black-075);
}

.post-vote_voteCell__3poHM {
  width: auto;
  padding-right: 16px;
  vertical-align: top;
  grid-column: 1;
  display: flex;
  flex-direction: column;
}

.post-vote_voteButton__1lYnM {
  margin: 2px;
  border: none;
  &:hover {
    background-color: transparent;
  }
  
  svg {
    color: var(--black-200);
    width: 36px;
    height: 36px;
  }
}

.post-vote_voted__2czAB {
  color: #455d6b !important;
}

.post-vote_score__3yfVa {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  margin: 2px;
  color: var(--black-500)
}

.post-summary_postCell__HZVPq {
  width: auto;
  padding-right: 16px;
  vertical-align: top;
  grid-column: 2;
}

.post-summary_text__1pKlm {
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 1.3;
  white-space: pre-wrap;
  word-break: break-word;
  font-family: "poppins-regular";
  p {
    margin-bottom: 1em;
  }
}

.post-summary_footer__2sd8K {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.post-summary_row__3cuzc {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.post-summary_tagContainer__3Jk9P {
  flex: 1 1 auto;
  margin-bottom: 8px;
}

.post-summary_userDetails__2SUgw {
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span {
    color: var(--black-400);
  }

  .post-summary_info__2_5eM {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
}

.post-summary_delete__22Uqm {
  background: #b91414;
  padding: 5px 10px;
  color: white !important;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 10px;

  &:hover {
    color: var(--red-500);
  }
}
.textarea_container__3X6bE {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.textarea_textarea__3S9fs {
  padding: 10px;
  height: 100px;
  width: 100%;
  font-size: 14px;
  border: 1px solid var(--black-200);
  background-color: var(--white);
  color: var(--black-800);
  resize: vertical;
  margin: 2px 0;

  &:focus {
    border-color: var(--blue-300);
    box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
    color: var(--black);
    outline: 0;
  }

  &::placeholder {
    color: var(--black-200);
  }
}

.textarea_hasError__2BnxU {
  border-color: var(--red-400);

  &:focus {
    border-color: var(--red-400);
    box-shadow: 0 0 0 4px rgba(192, 45, 46, 0.15);
  }
}

.textarea_errorMessage__1gLg1 {
  color: var(--red-500);
  padding: 2px;
  font-size: 12px;
}

.textarea_label__3r89I {
  margin: 2px 0;
  padding: 0 2px;
  color: var(--black-900);
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.textarea_inputInfo__3SRGt {
  padding: 0 2px;
  margin-top: 2px;
  color: var(--black-700);
  font-size: 12px;
}

.add-comment_container__2Asy5 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 10px;

  @media (--m) {
    grid-template-columns: 1fr 110px;
  }
}

.add-comment_button__vYECB {
  max-height: 34px;
}

.add-comment_status__2FRfd {
  color: var(--red-500);
  font-size: 12px;
}
.comment-list_commentCell__uLj0y {
  width: auto;
  padding-left: 0px;
  vertical-align: top;
  grid-column: 2;
  margin-top: 12px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  @media (--m) {
    padding-left: 16px;
  }
}

.comment-list_animation__2VPlF {
  div {
    animation: comment-list_background-fade__3nrxS 3s ease-in-out;
  }
}

@keyframes comment-list_background-fade__3nrxS {
  0% {
    background-color: var(--yellow);
  }
  100% {
    opacity: transparent;
  }
}

.comment-list_addComment__21G0c {
  /* color: var(--black-400); */
  /* opacity: 0.6; */
  padding: 5px;
  margin-top: 10px;
  width: 150px;
  background-color: #3a5765;
  color: white !important;
  text-transform: capitalize;
  border-radius: 5px;
  text-align: center;
  /* border: 1px solid #ddd;
  width: 100%;
  min-height: 100px;
  border-radius: 5px; */
}

.comment-list_showMore__2t9P3 {
  width: 175px;
  padding: 0 3px 2px 3px;
  margin-top: 10px;
}

.comment-item_commentContainer__2kAvA {
  padding: 6px;
  border-bottom: 1px solid var(--black-050);
  display: inline-block;

  p {
    display: inline;
    word-break: break-word;
  }

  &:first-child {
    border-top: 1px solid var(--black-050);
  }
}

.comment-item_owner__1Unr8 {
  padding: 1px 5px;
  border-radius: 3px;
  background-color: var(--powder-100);
}

.comment-item_dateText__29dTn {
  color: var(--black-350);
}

.comment-item_delete__3PoSS {
  color: var(--red-400);

  &:hover {
    color: var(--red-500);
  }
}
.answer-container_container__1zGud {
  padding-top: 10px;
}

.answer-container_header__mqROo {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.answer-container_fill__3CXWv {
  flex: 1 1;

  h2 {
    font-size: 18px;
    font-weight: 400;
    color: var(--black-800)
  }
}

.add-answer_container__3Oaus {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  padding: 20px;
  grid-gap: 10px;

  h2 {
    color: var(--black-800);
    font-size: 18px;
    font-weight: 400;
  }

  h3 {
    font-size: 16px;
    padding-top: 10px;
  }
}

.add-answer_textarea__F6-Fr {
  height: 200px;
}

.add-answer_status__3UUiI {
  color: var(--red-500);
  font-size: 12px;
}
.search-input_container__2fWEX {
  position: relative;
  margin: 2px 0;
  width: 220px;
  height: 36px;
}

.search-input_input__2h_HQ {
  width: 100%;
  height: 100%;
  padding: 7.5px 9px;
  border: 1px solid var(--black-200);
  border-radius: 10px;
  color: var(--black-900);
  font-size: 13px;
  line-height: 1.15;
  box-sizing: border-box;
  padding-left: 32px;

  &:focus {
    border-color: var(--blue-300);
    box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
    outline: 0;
  }

  &::placeholder {
    color: var(--black-200);
  }
}

.search-input_search__2hCFG {
  position: absolute;
  font-size: 18px;
  color: var(--black-200);
  left: 9px;
  top: 9px;
}

.search-input_fullWidth__2AOmR {
  width: 100%;
}

.search-input_ml24__1OpT3 {
  margin-left: 24px;
}

.search-input_isLoading__2Oq3_ {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    opacity: 0.3;
    right: 0.5em;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-color: var(--black-200);
    border-radius: 50%;
    top: 9.5px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0.5em;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-left-color: var(--black-200);
    border-radius: 50%;
    animation: search-input_spin__GPjGV 0.9s infinite cubic-bezier(0.5, 0.1, 0.5, 0.9);
    filter: invert(0);
    transform-origin: 50% 50% 1px;
    top: 9.5px;
  }

  @keyframes search-input_spin__GPjGV {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.search-input_paddingRight__WPTT2 {
  padding-right: 27px;
}


@media screen and (max-width: 768px){ 
  .search-input_ml24__1OpT3 {
    margin-left: 12px;
  }
  .search-input_container__2fWEX {
    position: relative;
    margin: 2px 0;
    width: 93%;
    height: 32px;
  }
 }
.tag-list_container__1nKpW {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(157px, 1fr));
}

.tag-item_card__2P4_m {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid var(--black-100);
  border-radius: 3px;
  background-color: var(--white);

  .tag-item_header__1mclK {
    margin-bottom: 12px;
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--black-500);
  }
}

.question-ask-view_layout__3g9-S {
  background-color: var(--black-050);
  min-height: 100vh;
}

.question-ask-view_container__3iXN1 {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 950px;
}

.question-ask-view_topForm__tm_f1 {
  height: 60px;
  display: flex;
  align-items: center;
  transition: var(--fade);

  @media (--m) {
    height: 130px;
    background-image: url('/images/background.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  h1 {
    font-size: 22px;
    padding-left: 16px;

    @media (--m) {
      font-size: 27px;
    }
  }
}


@media screen and (max-width: 768px) {
  .question-ask-view_container__3iXN1 {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 950px;
  
  }
}


.form-input_container__3SUTP {
  display: flex;
  flex-direction: column;
  margin: 6px 0;
  width: 100%;
}

.form-input_label__1PKNg {
  margin: 2px 0;
  padding: 0 2px;
  color: var(--black-900);
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.form-input_inputMessage__2xW1w {
  margin: 2px 0;
  color: var(--red-500);
  padding: 2px;
  font-size: 12px;
}

.form-input_inputContainer__3JTHL {
  position: relative;
  margin: 2px 0;
}

.form-input_input__1JUox {
  width: 100%;
  margin: 0;
  padding: 7.5px 9px;
  border: 1px solid var(--black-200);
  border-radius: 3px;
  color: var(--black-900);
  font-size: 13px;
  line-height: 1.3;
  font-weight: normal;
  box-sizing: border-box;

  &::placeholder {
    color: var(--black-200);
  }

  &:focus {
    border-color: var(--blue-300);
    box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
    outline: 0;
  }
}

.form-input_hasError__CHsvt {
  border-color: var(--red-400);
  padding-right: 32px;

  &:focus {
    border-color: var(--red-400);
    box-shadow: 0 0 0 4px rgba(192, 45, 46, 0.15);
    outline: 0;
  }
}

.form-input_alert__3kdjQ {
  position: absolute;
  color: var(--red-400);
  font-size: 15px;
  right: 8px;
  top: 8px;
}

.form-input_inputInfo__1SU_p {
  padding: 0 2px;
  margin-top: 2px;
  color: var(--black-700);
  font-size: 12px;
}

.tag-input_container__3-MFW {
  display: flex;
  flex-direction: column;
  margin: 6px 0;
  width: 100%;
}

.tag-input_label__32HPi {
  margin: 2px 0;
  padding: 0 2px;
  color: var(--black-900);
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.tag-input_inputInfo__3Uc1g {
  padding: 0 2px;
  margin-top: 2px;
  color: var(--black-700);
  font-size: 12px;
}

.tag-input_inputContainer__2INPc {
  position: relative;
  margin: 2px 0;
}

.tag-input_error__3YAyE {
  padding-right: 32px;
  box-shadow: 0 0 0 4px rgba(192, 45, 46, 0.15);
  outline: 0;
  border: 1px solid var(--red-400);
  border-radius: 3px;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.tag-input_inputFocused__1KLda {
  border-color: var(--blue-300);
  box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
  outline: 0;
}

.tag-input_errorMessage__23cNw {
  margin: 2px 0;
  color: var(--red-500);
  padding: 2px;
  font-size: 12px;
}

.tag-input_alert__9Ecr0 {
  position: absolute;
  color: var(--red-400);
  font-size: 15px;
  right: 11px;
  height: 100%;
  top: 0;
}

.tag-input_tagContainer__3qf7o {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  left: 7px;
  top: 0;
}



.tag-input_reactTagsinput__3QOwV{
  display: inline-block;
  background-color: var(--powder-100);
  color: var(--powder-700);
  font-size: 12px;
  padding: 5px 6px;
  border: 1px solid transparent;
  margin: 2px 5px 2px 0;
  line-height: 1;
  white-space: nowrap;
  border-radius: 3px;
  text-decoration: none;
  font-family: "poppins-regular";
}



.question-form_container__3oy3N {
  padding: 16px;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.05),
    0 2px 8px rgba(0, 0, 0, 0.05);
}

.question-form_buttonContainer__3UWGm {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  margin: 10px 0px;
  padding-left: 16px;
}

.question-form_status__1GC57 {
  color: var(--red-500);
  font-size: 12px;
}

.user-list_container__qoU5T {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  margin-bottom: 24px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}
@media screen and (max-width: 768px){ 
  .user-list_container__qoU5T{
    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
    padding-left: 10px;
    padding-right: 10px;

  }
} 
.user-item_card__1MNwp {
  color: var(--black-500);
  display: flex;
  padding: 6px;
}

.user-item_body__1Ejl0 {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  a {
    font-size: 14px;
    color: black !important;
    font-family: 'poppins-regular';
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }

  p {
    font-size: 12px;
  }
}

.user-item_avatar__36-oZ {
  width: 48px;
  height: 48px;
  border-radius: 3px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}


@media screen and (max-width: 768px){ 
  .user-item_avatar__36-oZ {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }

  .user-item_body__1Ejl0 {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  
    a {
      font-size: 10px;
      color: black !important;
      font-family: 'poppins-regular';
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  
    p {
      font-size: 8px;
      margin-bottom: 0px;
    }
  }

 }
.user-card_container__2bu4V {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  padding: 24px;

  @media (--t) {
    grid-template-columns: 210px 1fr;
  }
}

.avatar-card_avatarCard__11Gdw {
  box-shadow: inset 0 10em 0 var(--black-050);
  border: 1px solid var(--black-100);
  border-radius: 3px;
  padding: 12px;
  min-height: 265px;
}

.avatar-card_avatar__Nl14N {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    
  }
}

.avatar-card_username__3pDss {
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  margin-top: 10px;
  text-align: center;
}

.avatar-card_created__3gJVD {
  margin-top: 10px;
  text-align: center;
  p {
    font-family: monospace;
  }

  span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-weight: bold;
    font-size: 14px;
  }
}

.post-list_header__3jFi7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid var(--black-100);

  h2 {
    font-weight: 700;
    font-size: 15px;
  }
}

.post-list_notfound__3wl7E {
  margin-top: 10px;
  text-align: center;
}
.post-item_container__rvekc {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid var(--black-075);
  a{
    flex: 1 1;
    font-size: 15px;
    text-decoration: none;
    color: black;
    font-family: 'poppins-regular';
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.post-item_vote__B6Yzi {
  text-align: center;
  color: var(--black-500);
  min-width: 36px;
  padding: 3px 6px;
  margin-right: 10px;
  border: 1px solid var(--black-300);
  border-radius: 2px;
}

.post-item_title__1Ve0V {
  flex: 1 1;
  font-size: 15px;
  text-decoration: none;
  color: black;
  font-family: 'poppins-regular';
  &:hover{
    text-decoration: underline;
  }
}

.post-item_created__1xtAT {
  color: var(--black-300);
  margin-left: 6px;
  display: none;

  @media (--m) {
    display: block;
  }
}

.share_body__3Z_jW {
    font-family: 'Arial', sans-serif;
    background-color: white;
    padding: 20px;
  }
  
  .share_Demo__some_network__1X71C {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
  }
  
  .share_Demo__some_network__share_count__3RiN8 {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    margin-top: 3px;
    font-size: 12px;
  }
  
  .share_Demo__some_network__share_button__1AoZU {
    cursor: pointer;
  }
  
  .share_Demo__some_network__share_button__1AoZU:hover:not(:active) {
    opacity: 0.75;
  }
  
  .share_Demo__some_network__custom_icon__36abs {
    width:32px;
    height:32px;
  }
/* div#medium-editor-toolbar-1 ul {
    list-style: none;
    display: flex !important;
    gap: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    background: #eaeaea;
    width: fit-content !important;
    border-radius: 10px;
}
div#medium-editor-toolbar-1 ul li{
    border-right: 1px solid #ddd;
}

div#medium-editor-toolbar-1 ul li button{
 background-color: white;
 border-radius: 0px;
 border: none;
 padding: 8px;
 min-width: 20px;
}

.medium-editor-button-active{
 background-color: grey !important;
}



 */

.medium-editor-element{
    min-height: 300px;
    height: auto;
}

.Editor-title{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: 'Roboto' !important;
}

.editor-summary{
    font-family: 'Roboto' !important;
}

.medium-editor-toolbar li button{
    background-color: #242424;
    background: linear-gradient(180deg,#242424,rgba(36,36,36,.89));
    border: 0;
    border-right: 1px solid #000;
    border-left: 1px solid #333;
    border-left: 1px solid hsla(0,0%,100%,.1);
    box-shadow: 0 2px 2px rgba(0,0,0,.3);
    color: #fff;
    height: 50px;
    min-width: 50px;
    transition: background-color .2s ease-in;
}

.medium-editor-toolbar li button {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 1.33;
    margin: 0;
    padding: 15px;
    text-decoration: none;
    border-radius: 0px !important;
}

.medium-editor-toolbar li .medium-editor-button-active {
    background-color: #000;
    background: linear-gradient(180deg, #242424, rgba(0, 0, 0, .89));
    color: #ff0;
}

.medium-editor-toolbar li button:hover {
    background-color: #000;
    color: #ff0;
}

.editor-image-preview{
    width: 100%;
    border-radius: 5px;
}
