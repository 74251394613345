@import url('https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/medium-editor.min.css');

/* div#medium-editor-toolbar-1 ul {
    list-style: none;
    display: flex !important;
    gap: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    background: #eaeaea;
    width: fit-content !important;
    border-radius: 10px;
}
div#medium-editor-toolbar-1 ul li{
    border-right: 1px solid #ddd;
}

div#medium-editor-toolbar-1 ul li button{
 background-color: white;
 border-radius: 0px;
 border: none;
 padding: 8px;
 min-width: 20px;
}

.medium-editor-button-active{
 background-color: grey !important;
}



 */

.medium-editor-element{
    min-height: 300px;
    height: auto;
}

.Editor-title{
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: 'Roboto' !important;
}

.editor-summary{
    font-family: 'Roboto' !important;
}

.medium-editor-toolbar li button{
    background-color: #242424;
    background: -webkit-linear-gradient(top,#242424,rgba(36,36,36,.89));
    background: linear-gradient(180deg,#242424,rgba(36,36,36,.89));
    border: 0;
    border-right: 1px solid #000;
    border-left: 1px solid #333;
    border-left: 1px solid hsla(0,0%,100%,.1);
    box-shadow: 0 2px 2px rgba(0,0,0,.3);
    color: #fff;
    height: 50px;
    min-width: 50px;
    -webkit-transition: background-color .2s ease-in;
    transition: background-color .2s ease-in;
}

.medium-editor-toolbar li button {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 1.33;
    margin: 0;
    padding: 15px;
    text-decoration: none;
    border-radius: 0px !important;
}

.medium-editor-toolbar li .medium-editor-button-active {
    background-color: #000;
    background: -webkit-linear-gradient(top, #242424, rgba(0, 0, 0, .89));
    background: linear-gradient(180deg, #242424, rgba(0, 0, 0, .89));
    color: #ff0;
}

.medium-editor-toolbar li button:hover {
    background-color: #000;
    color: #ff0;
}

.editor-image-preview{
    width: 100%;
    border-radius: 5px;
}